import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import AcademyTab from "../Tabs/AcademyTab";

function Webinars() {
  return (
    <div className="siteBg academy-wrapper FaqPageWrap Terms-wrapper">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar />
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <AcademyTab name={"webinar"} />
              <div className="col rightContent">
                <div className="faqWrapper card2">
                  <p>
                    {" "}
                    For International travel, plan to arrive at the airport a
                    minimum of 3 hours in advance of departure, unless your
                    carrier advises otherwise. Baggage Fees and Carry On Limits
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default Webinars;
