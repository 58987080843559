import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import Header from "../../Component/Header";
import { FreemiumSignUpValidation } from "../../Component/Validation/FreemiumSignUpValidation";
import LogoWhite from "../../Component/FormComponent/LogoWhite";
import Logo from "../../Assets/img/logo.png";
import Button from "../../Component/FormComponent/Button";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import IconInputField from "../../Component/FormComponent/IconInputField";
import IconSelectField from "../../Component/FormComponent/IconSelectField";
import userIcon from "../../Assets/img/signup/user-icon.svg";
import emailIcon from "../../Assets/img/signup/email.svg";
import lockIcon from "../../Assets/img/signup/lock.svg";
import Checkbox from "../../Component/FormComponent/Checkbox";
import { authActions } from "../../Stores/Actions/authActions";
import { useDispatch, useSelector } from "react-redux";
let checkoutCountries = require("../../Util/static-service");
import { useNavigate } from "react-router-dom";
import { getLocationParams } from "../../Util/util";
import Scrollbars from "react-custom-scrollbars";
import ReCAPTCHA from "react-google-recaptcha";
function FreemiumSignup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationSearch = getLocationParams();
  const formikRef = useRef();
  const [isHeaderSticky, setHeaderSticky] = useState(false);

  const isUserExist = useSelector((state) => state.authReducer.isUserExist);

  const freemiumSignupData = useSelector(
    (state) => state.authReducer.freemiumSignupData
  );
  const [isCaptcha, setIsCaptcha] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [user_id, setUser_id] = useState(null);
  const onScrollFrame = (e) => {
    if (e.scrollTop > 5) {
      setHeaderSticky(true);
    } else {
      setHeaderSticky(false);
    }
  };
  let initialValues = {
    // form inital Values
    firstName: locationSearch?.first_name || "",
    lastName: locationSearch?.last_name || "",
    email: locationSearch?.email || "",
    password: "",
    confirmPassword: "",
    country: "",
    terms: false,
  };

  useEffect(() => {
    if (locationSearch?.ref && !user_id) {
      dispatch(authActions.isEmailExistRequest(locationSearch.ref)).then(
        (res) => {
          if (
            res.status == 200 &&
            (res.data.type_id == process.env.REACT_APP_PREMIUM_USER_TYPE_ID ||
              res.data.type_id ==
                process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID)
          ) {
            setUser_id(res?.data?.user_id);
            return;
          } else {
            setUser_id(process.env.REACT_APP_USER_ID);
          }
        }
      );
    }
  }, []);

  const handleSubmit = (values) => {
    const payload = {
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      email: values.email.trim(),
      password: values.password.trim(),
      country: values.country.value.trim(),
      sponsor_id: user_id,
    };

    dispatch(authActions.isEmailExistRequest(values.email)).then((response) => {
      if (response.status == 200) {
        return formikRef.current.setErrors({
          email:
            "You are already a member, please login or use a different email",
        });
      }

      if (response?.response?.status == 422) {
        return dispatch(authActions.freemiumSignupRequest(payload)).then(
          (res) => {
            if (res.status == 200) {
              navigate("/thankyou");
            }
          }
        );
      }
    });
  };
  const handleCustomChange = (field, value, formikProps) => {
    formikProps.setFieldValue(field, value);
  };

  //Array list one
  const arrayListOne = [
    "Gimmicks",
    "Advertisement",
    "Buying Pressure",
    "Risk",
    "Obligations",
    "Time-Limits",
    "Up-Sales",
    "Commissions",
    "Booking Fees",
  ];

  const countryOptions = checkoutCountries.map((curr) => {
    return { value: curr.alpha2, label: curr.name };
  });

  // Array list two
  const arrayListTwo = [
    <>
      • <strong>No Time Limit</strong> - Freemium Membership
    </>,
    <>
      • Savings of <strong>up to 25%</strong> over other Online Travel Sites
    </>,
    <>
      • Access to <strong>1.5 Million Hotels and Resorts</strong> only
    </>,
    <>
      • Preview of Premium <strong>Member Pricing</strong> and{" "}
      <strong>Savings</strong>
    </>,
    <>
      • <strong>No Credit/Debit Card</strong> required at Sign-up
    </>,
  ];

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;
      const onCaptchaChange = (value) => {
        if (value !== null)
          setIsCaptcha(false);
        else
          setIsCaptcha(true);
      }
    return (
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <IconInputField
            name="firstName"
            type="text"
            placeholder="First Name"
            src={userIcon}
            value={values.firstName}
            touched={touched.firstName}
            errors={errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />
          <IconInputField
            name="lastName"
            type="text"
            placeholder="Last Name"
            src={userIcon}
            value={values.lastName}
            touched={touched.lastName}
            errors={errors.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />
          <IconInputField
            name="email"
            type="email"
            placeholder="Your Email"
            src={emailIcon}
            value={values.email}
            touched={touched.email}
            errors={errors.email}
            onBlur={handleBlur}
            onChange={(e) =>
              handleCustomChange("email", e.target.value, formikProps)
            }
            inputClassName="form-control"
          />
          <IconInputField
            name="password"
            type="password"
            placeholder="Password"
            src={lockIcon}
            value={values.password}
            errors={errors.password}
            touched={touched.password}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />
          <IconInputField
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            src={lockIcon}
            value={values.confirmPassword}
            touched={touched.confirmPassword}
            errors={errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />

          <IconSelectField
            name="country"
            placeholder="Country  "
            // src={lockIcon}
            value={values.country}
            touched={touched.country}
            errors={errors.country}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
            options={countryOptions}
            formikProps={formikProps}
            iconName="icon-globe"
            className="country-code-wrap"
          />
          {/* Checkbox input */}
          <div className="checkBoxContent">
            <label className="customCheckbox">
              <Checkbox
                name="terms"
                value={values.terms}
                onChange={handleChange}
              />
              <p className={errors.terms && touched.terms && "color-red"}>
                I agree to the{" "}
                <Link to="/terms-conditions">Terms and Conditions </Link>
              </p>
            </label>
          </div>
          <div className="buttonWrap">
            <div className="content">
              <h4>Free</h4>
              <h6>(no time limit)</h6>
            </div>

            {/* Form Submit button  */}

            {/* <div className="button">
              <Button text="I'm In" type="submit" className="btn" />
            </div> */}
            <div className="google-recaptcha-wrap">
              {isCaptcha ? <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={onCaptchaChange}
              /> : <>
                <div className="button">
                  <Button text="I'm In" type="submit" className="btn" />
                </div></>}
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div
      className={
        isHeaderSticky
          ? "siteBg freemiumsignupPageWrap header-sticky"
          : "siteBg freemiumsignupPageWrap"
      }
    >
      <div className="pageWrap">
        <section className="mainContent">
          <Scrollbars
            autoHeight={true}
            onScrollFrame={onScrollFrame}
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
            id="pageInternalScrollforMemberSignup"
          >
            <div className="container pageInternalScrollTopPadding">
              <div>
                <div
                  className={
                    openForm ? "signupWrapper openForm" : "signupWrapper"
                  }
                >
                  <Header headerName={true} />
                  {/*<div className="fixedHeader">
                     <header className="header">
                      <div className="container">
                        <a href="index.html" className="logoWrap">
                          <img src={Logo} alt="Tripello" className="logo1" />
                          <img
                            src={Logo}
                            alt="Tripello"
                            className="logo-sticky"
                          />
                        </a>
                      </div>
                    </header> 
                  </div>*/}

                  <div className="row">
                    <div className="col left-content">
                      <div className="left-content-wrapper">
                        <h1>Welcome,</h1>
                        <h2>
                          to a <strong>Unique</strong> and
                          <strong> New Way</strong> of{" "}
                          <strong> Booking Travel.</strong>
                        </h2>
                        <h2>
                          The <strong>First</strong> true subscription-based
                          Travel Service to offer{" "}
                          <strong> Wholesale/Net Travel Prices</strong> to{" "}
                          <strong>YOU</strong>, the End Consumer.
                        </h2>
                        <div className="way-wrapper">
                          <h5>
                            We say <strong>NO</strong> to:
                          </h5>
                          {/* Array list one getting iterated here */}
                          <ul>
                            {arrayListOne.map((listItem, i) => {
                              return (
                                <li key={i}>
                                  <h6>{listItem}</h6>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        {/* <h3>
                          See for Yourself how much Tripello can{" "}
                          <strong>save you,</strong> so you can enjoy More
                          Freedom, More Choices and{" "}
                          <strong>Better Experiences.</strong>
                        </h3> */}
                        <h3>
                          Sign-up <strong>today</strong> and{" "}
                          <strong>get access</strong> to:
                        </h3>
                        <div className="list-content">
                          {/* Array list two getting iterated here */}

                          <ul>
                            {arrayListTwo.map((listItem, i) => {
                              return <li key={i}>{listItem}</li>;
                            })}
                          </ul>
                        </div>
                        <div className="freemium-getstarted-btn">
                          <Button
                            className="btn"
                            type="button"
                            onClick={() => setOpenForm(true)}
                            text="Get Started"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col right-content">
                      <div className="right-box-content">
                        <div className="infoBox">
                          <div className="membership-content">
                            <LogoWhite src={Logo} />
                            <div className="planName">Freemium Membership</div>
                          </div>
                        </div>
                        <Formik
                          onSubmit={handleSubmit}
                          innerRef={formikRef}
                          validationSchema={FreemiumSignUpValidation}
                          initialValues={initialValues}
                          enableReinitialize={true}
                        >
                          {renderForm}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
          <div className="skew-background"></div>
        </section>
        {/* Footer Section */}
        <FooterWithMediaIcon />
      </div>
    </div>
  );
}

export default FreemiumSignup;
