import * as Yup from "yup";

export const AffiliateUpgradeValidation = () => {
  const phoneRegExp = /^[0-9\b]+$/;
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,7}$/;
  const cvvRegExp = /^[0-9]{3,4}$/;
  const cardNumberRegExp = /^[0-9]{14,17}$/;
  const mmyyFormatRegExp = /([0-9]{2})\/([0-9]{2})/;

  // Validation for affiliate premium membership

  return Yup.object().shape({
    title: Yup.string().required("Required"),
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    email: Yup.string("")
      .email("Enter a valid email address.")
      .required("Required"),
    // paypalEmail: Yup.string("").email("Enter a valid email address.")
    //   .required("Required"),
    // confirmPaypalEmail: Yup.string("")
    //   .oneOf([Yup.ref("paypalEmail"), null], "Email must match")
    //   .required("Please confirm Email"),
    phoneNumber: Yup.string("").when("nationality", (nationality, field) => {
      return nationality === "US" ? field.required("Phone number is required") : field;
    }),
    promoCode: Yup.string('').when("hasPromo", {
      is: true,
      then: Yup.string().required("Promo/Gift Code can not be empty")
    }),
    // .required("Phone number is required"),
    nationality: Yup.string("").required("Required"),
    addressOne: Yup.string("").required("Required"),
    city: Yup.string("").required("Required"),
    state: Yup.string("").when("stateAvailable", {
      is: true,
      then: Yup.string().required("Required")
    }),
    memberId: Yup.string("").required("Required"),
    // zipCode: Yup.string("").required("Required").min(3).max(10),
    zipCode: Yup.string("").required("Required").min(3, "Invalid zip code").max(10),
    // zipCode: Yup.string("").required("Required").matches(/^([a-zA-Z0-9]){3,7}$/,"Invalid zip code"),
    // zipCode: Yup.string("").min(4).max(6)
    //   .required("Required"),
    // password: Yup.string("")
    //   .min(8, "Must be 8 characters or more")
    //   .required("Password Required"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "Passwords must match")
    //   .required("Please confirm password"),

    terms: Yup.boolean().oneOf([true], 'Required'),
    affiliateTerms: Yup.boolean().oneOf([true], 'Required'),

    //card
    cardHolderName: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').matches(/^[aA-zZ\s]+$/, 'Must only have alphabet letters')
    }),
    security_code: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').matches(cvvRegExp, 'Enter a valid security code').max(4),
    }),
    exp_month: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    exp_year: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    credit_number: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').min(15).max(16).matches(cardNumberRegExp, 'Enter a valid card number.'),
    }),
    mmyy: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').min(5),
    }),
    // ssnModal
    ssnNumber: Yup.string().length(11, "Enter a valid SSN")
      .when(["confirmEinNumber", "nationality"], {
        is: (confirmEinNumber, nationality) => nationality === "US" && !confirmEinNumber,
        then: Yup.string().required("Required")
      })
      .oneOf([Yup.ref('confirmSsnNumber')], 'SSN must match with confirm SSN'),
    confirmSsnNumber: Yup.string()
      .oneOf([Yup.ref('ssnNumber')], 'confirm SSN must match with SSN'),

    einNumber: Yup.string()
      .when(["confirmSsnNumber", "nationality"], {
        is: (confirmSsnNumber, nationality) => nationality === "US" && !confirmSsnNumber,
        then: Yup.string().required("Required").matches(/^[1-9]\d?-\d{7}$/,"Enter valid EIN;")
      }),
      // .oneOf([Yup.ref('confirmEinNumber')], 'EIN must match with confirm EIN'),
    confirmEinNumber: Yup.string()
      .oneOf([Yup.ref('einNumber')], 'confirm EIN must match with EIN'),


  });
};
