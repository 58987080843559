import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import { date } from "yup";
import { StyledDateRangePickerWrapper } from "../StyledComponents/StyledComponent";

function DateRangePickerComponent(props) {
  // Styling wrapper of date range picker component

  const currentGeoLocation =
    localStorage.getItem("currentGeoLocation") &&
    JSON.parse(localStorage.getItem("currentGeoLocation"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  const {
    startDatePlaceholderText,
    endDatePlaceholderText,
    startDateId,
    endDateId,
    startDate,
    endDate,
    focusedInput,
    onFocusChange,
    isOutsideRange,
    onDatesChange,
    disableBeforeDate,
    isDateResponsive,
    orientation,
    isDayBlocked,
    focus,
    openDirection,
  } = props;

  const [focused, setfocused] = useState(isDateResponsive || null);
  return (
    <StyledDateRangePickerWrapper>
      <DateRangePicker
        startDate={startDate ? moment(new Date(startDate)) : startDate}
        startDateId={startDateId}
        endDate={endDate ? moment(new Date(endDate)) : endDate}
        endDateId={endDateId}
        onDatesChange={onDatesChange}
        focusedInput={focused}
        onFocusChange={(focusedInput) => {
          if (onFocusChange) {
            onFocusChange(focusedInput);
          }
          setfocused(focusedInput);
        }}
        customInputIcon={
          <span className="startDate">
            <i className="icon-invitation" />
          </span>
        }
        customArrowIcon={
          <>
            <div className="icon-arrow" />
            <span className="endDate">
              <i className="icon-invitation" />
            </span>
          </>
        }
        // startDateTitleText="Check in"
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        displayFormat={dateDisplayFormat}
        orientation={orientation}
        readOnly={true}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        openDirection={openDirection ? openDirection : "down"}
        // enableOutsideDays={true}
        // readOnly={true}
      />
    </StyledDateRangePickerWrapper>
  );
}

export default DateRangePickerComponent;
