export const formatCarAutoCompleteData = ({ airport_data, city_data }) => {
  let data = [];

  for (let airport in airport_data) {
    data.push({
      label: `${airport_data[airport]?.airport_name} (${airport_data[airport]?.airport_code})`,
      secondary_label: `${airport_data[airport]?.airport_name} (${airport_data[airport]?.airport_code})`,
      expedia_id: airport_data[airport]?.airport_code,
    });
  }

  for (let city in city_data) {
    if (city_data[city]?.airport_data.length !== 0) {
      data.push({
        label: `${city_data[city]?.airport_data.airport_0?.airport_name} (${city_data[city]?.airport_data.airport_0?.airport_code})`,
        secondary_label: `${city_data[city]?.airport_data.airport_0?.airport_name} (${city_data[city]?.airport_data.airport_0?.airport_code})`,
        expedia_id: city_data[city]?.airport_data.airport_0?.airport_code,
      });
    }
  }

  for (let city in city_data) {
    data.push({
      label: `${city_data[city]?.city} (${city_data[city]?.country_code})`,
      secondary_label: `${city_data[city]?.city} (${city_data[city]?.country_code})`,
      expedia_id: city_data[city]?.country_code,
    });
  }

  return {
    data,
  };
};
