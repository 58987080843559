import React from "react";
import { Link } from "react-router-dom";
function HotelImageSection({ id,savings_percentage, image }) {
  return (
    <div className="imgWrap col col-width">
      <Link className="detail-hotel-url" to={`/hotel-detail/${id}`}></Link>
      <div className="bg-image" style={{ backgroundImage: `url('${image}')` }}>
        {savings_percentage == 0 ? (
          " "
        ) : (
          <div className="savings">{savings_percentage}% Savings</div>
        )}
      </div>
    </div>
  );
}

export default HotelImageSection;
