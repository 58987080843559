import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hotelActions } from "../../Stores/Actions";
import CarDetails from "./CarDetails";
import FllightDetails from "./FllightDetails";
import HotelDetails from "./HotelDetails";

function Cancelled() {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(hotelActions.getCancelledBookings("cancelled"));
  // }, []);

  const cancelledBookings = useSelector(
    (state) => state.hotelReducer.cancelledBookings
  );
  return cancelledBookings.length > 0 ? (
    <div className="mainWrap booking">
      {cancelledBookings &&
        cancelledBookings.map((item, index) => {
          if (item.booking_type === "hotel") {
            return <HotelDetails {...item} status={"CANCELLED"} key={`${index}hotel`} />;
          } else if (item.booking_type === "flight") {
            return <FllightDetails {...item} status={"CANCELLED"} key={`${index}flight`} />;
          }
        })}
    </div>
  ) : (
    <div className="not-found-booking">
      <h2>No Bookings</h2>
      <p>You have no cancelled bookings.</p>
      <p>Start your amazing journey with a new booking.</p>
      <Link to={"/landing"} className="btn">Back To Home</Link>
    </div>
  );
}

export default Cancelled;
