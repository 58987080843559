import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export const StripeForm = forwardRef((clientSecret, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    async confirmStripePayment() {
      if (!stripe || !elements) {
        return;
      }
      const paymentResult = await stripe.confirmCardPayment(
        clientSecret.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              // name: "",
            },
          },
        }
      );
      return true;
    },
  }));

  return (
    <>
      <div
        className="col"
      >
        <h5>Enter Card Details</h5>
        <CardElement
          className="cardWrapper stripe-card"
        />
      </div>
    </>
  );
});
