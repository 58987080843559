import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {NavLink} from 'react-router-dom';
import DetailsInfoModal from './DetailsInfoModal';

// Tab Menu list
function TabMenu({modalOpen, infoIcon, className}) {

	const tabMenuList = [
		{
			id:0,
			title:'Dashboard',
			path:'/affiliate-dashboard'
		},
		{
			id:1,
			title:'Member Invites',
			path:'/member-invites'
		},
		{
			id:2,
			title:'Affiliate Invites',
			path:'/affiliate-invites'
		},
		{
			id:3,
			title:'Marketing',
			path:'/marketing'
		},
		{
			id:4,
			title:'Wallet',
			path:'/wallet'
		}
	]

	return (
		<div
         className="tab-menu-wrapper  desktop-hide"> 
			<ul>
				{
					tabMenuList.map((item, index) => {
						return(
							<li key={index}>
							<NavLink activeclassname='active' to={item.path}>{item.title}</NavLink>
							{item.title === 'Dashboard' && 
							<button className="btn details-btn mobile-details-btn desktop-hide" onClick={modalOpen}>
								<img className="ml-2" src={infoIcon} alt="icon" />
							</button>}
						</li>
						)
					})
				}
			</ul>
			{className && <button className={className} onClick={modalOpen}>
				<img src={infoIcon} alt="icon" /> Details
			</button>}
		</div>
	);
}

export default TabMenu;
