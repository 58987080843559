import React from "react";
import img from "../../Assets/img/doller-box-grey.png"
import {commafy} from '../../Util/util';


function CommissionTabMenu({ 
  image, 
  id,
  href,
  index, 
  item,
  commisionSummary,
  setSelectedTab,
  selectedTab
}) {
  return (
    <div className="col">
      <div className="content">
        <a
          href={href}
          className={id == selectedTab.id ? "anchor-tab active-tab" : "anchor-tab"}
          key={index}
          onClick={() => setSelectedTab({...selectedTab , id: id , commission_type: item.id , label : item.label , filter:{value: 'this_week', label: 'This Week'}})}
        ></a>
        <div className="heading">
          <div className="image">
            <img src={img} alt="icon" key={index} />
          </div>
          <h6 key={index}>{item?.label}</h6>
        </div>
        <h5 className="text-right"> ${commafy(commisionSummary?.data?.dashboard_details?.commisions[item.id]) }</h5>
      </div>
    </div>
  );
}

export default CommissionTabMenu;
