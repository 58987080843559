import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getCookie } from "../../Util/util";

import Button from "../FormComponent/Button";
import PriceDetailType from "./PriceDetailType";

function SingleCarPriceDetails({
  total,
  payLater,
  currency,
  priceDetails,
  freeCancellation,
  creditCardRequired,
  saveReservedCarsData,
}) {
  const [showPrice, setShowPrice] = useState(false);
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    setIsPremium(getCookie("is_premium") === "true");
  }, []);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });
  return (
    <div className="col-3">
      <div className="shadowBox">
        <div className="boxTopSec">
          {!creditCardRequired && (
            <div className="tickNote mobileShow">
              <span className="icon-creditCard" /> No Credit Card Required
            </div>
          )}

          {freeCancellation && (
            <div className="centerAlign">
              <div className="greenBdrTag">
                <i className="icon-tick" />
                Free Cancellation
              </div>
            </div>
          )}

          {payLater && (
            <div className="centerAlign">
              <div className="greenBdrTag">
                <i className="icon-tick" />
                Pay Later
              </div>
            </div>
          )}
        </div>

        <div className="sideSpace">
          <div className="dflex totalAmout">
            <div className="leftText">Total:</div>
            <div className="rightText">{formatter.format(total)}</div>
          </div>

          <div className="dflex taxNPrice">
            <div className=" priceDetails dropDownTooltip toggleClass">
              <div className="dropdownBtn toggleBtn">
                Price Details
                <i className={`fa fa-angle-${showPrice ? "up" : "down"}`} />
              </div>
              <div className="dropdownInWrap">
                <h4>Price Details</h4>
                {priceDetails &&
                  priceDetails.map((item, index) => (
                    <PriceDetailType
                      {...item}
                      currency={currency}
                      key={index}
                      lastEle={priceDetails.length - 1 === index}
                    />
                  ))}
                <div className="rightAlign smlText">Tax Included</div>
              </div>
            </div>

            <span className="smlText">Tax Included</span>
          </div>

          <div className="centerAlign">
            {isPremium ? (
              <Button className="btn" onClick={saveReservedCarsData}>
                Reserve Car
              </Button>
            ) : (
              <Link to="/membership-upgrade" className="btn">
                Upgrade
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleCarPriceDetails;
