import * as Yup from "yup";

export const HotelCheckoutValidation = () => {
  const phoneRegExp = /^[0-9\b]+$/;
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,7}$/;
  const cvvRegExp = /^[0-9]{3,4}$/;
  const cardNumberRegExp = /^[0-9]{14,17}$/;
  const mmyyFormatRegExp = /([0-9]{2})\/([0-9]{2})/;

  // Validation for membership premium checkout

  return Yup.object().shape({
    title: Yup.string("").required("Required"),
    title2: Yup.string(""),
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"), 
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName2: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters"),
    
    email: Yup.string("")
      .email("Enter a valid email address.")
      .required("Required"),
    // countryCode: Yup.string("").required("Required"),
    phoneNumber: Yup.string("").required("Phone number is required").nullable(),
    nationality: Yup.string("").required("Required"),
    addressOne: Yup.string("").required("Required"),
    addressTwo: Yup.string(""),
    city: Yup.string("").required("Required"),
    state: Yup.string("").when("stateAvailable", {
      is: true,
      then: Yup.string().required("Required")
    }),
    // zipCode: Yup.string('').min(4, 'Invalid Zip Code').max(6, 'Invalid Zip Code').required('Required'),
    // zipCode: Yup.string("").required("Required").matches(/^([a-zA-Z0-9]){3,7}$/,"Invalid zip code"),
    // zipCode: Yup.string("").required("Required").min(3).max(10),
    zipCode: Yup.string("").required("Required").min(3, "Invalid zip code").max(10),
    age: Yup.string(""),
    amountAdded: Yup.string("").when("use_wallet", {
      is: true,
      then: Yup.string().required("Required")
    }),
    // walletAmount: Yup.string("").required("Required"),
    // remainingAmount: Yup.string("").required("Required"),
 
    terms : Yup.boolean().oneOf([true],'Required') ,
  
    //card
    cardHolderName: Yup.string("")
      .required("Required")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters"),
 

  });
};
