import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LogoWhite from "./FormComponent/LogoWhite";
import logo from "../Assets/img/logo.png";
import logo2 from "../Assets/img/logo2.png";
import networkLogo from "../Assets/img/network/network-logo.png";
function Header({ headerName }) {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = (e) => {
    const header = document.querySelector("#root");
    const scrollTop = window.scrollY;
    scrollTop >= 120
      ? header.classList.add("header-sticky")
      : header.classList.remove("header-sticky");
  };

  return (
    <div className="fixedHeader">
      <header className={headerName ? "header header-removed-nav" : "header"}>
        <div className="container">
          <Link to={"/"} className="logoWrap  ">
            <LogoWhite src={networkLogo} />
            {/* <img src={logo2} alt="Tripello" className="logo-sticky"  /> */}
            <div className="network-logo-sticky">
              <img src={logo2} alt="Tripello" className="logo-sticky" />
              <span>Network</span>
            </div>
          </Link>
          {!headerName && (
            <nav className="navigation">
              <ul>
                <li>
                  <a href="https://tripello.com/blog/" target="_blank" rel="noopener noreferrer">Tripello Hub</a>
                </li>
              </ul>
            </nav>
          )}
          {!headerName && !localStorage.getItem("accessToken") && (
            <Link to="/login" className="btnBorder3">
              Sign In
            </Link>
          )}
          {!headerName && localStorage.getItem("accessToken") && (
            <Link to="/landing" className="btnBorder3">
              Sign In
            </Link>
          )}
        </div>
      </header>
    </div>
  );
}

export default Header;
