import React, { useEffect } from "react";
import Modal from "react-modal";
import FlightSubDetails from "../FlightListing/FlightSubDetails";
import SingleFlightDetails from "../FlightListing/SingleFlightDetails";
import SingleFlightModal from "../FlightListing/SingleFlightModal";
import Button from "../FormComponent/Button";
import moment from "moment";

Modal.setAppElement("#root");
function FlightDetailsModal({
  isOpen,
  closeModal,
  flights,
}) {

  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName("body")[0].classList.add("siteBg", "listingPage", "listingFlight", "popupOpned")
    } else {
      document.getElementsByTagName("body")[0].classList.remove("siteBg", "listingPage", "listingFlight", "popupOpned")
    }

  }, [isOpen])
  useEffect(() => {
    return () => {
      document.getElementsByTagName("body")[0].classList.remove("siteBg", "listingPage", "listingFlight", "popupOpned")
    }
  }, [])

 
  console.warn("::::::::", flights?.flightSegments);
  return (
    <Modal className="flightDetailsModal" isOpen={isOpen} ariaHideApp={false}>
      <div id="flightDetailPopup" className={isOpen ? "popupStyle" : ""}>
        <div className="popUpBox">
          <Button onClick={() => closeModal(false)} className="popupCloseBtn">
            <span className="icon-close"></span>
          </Button>
          <h3 className="popupTitle">Flight Details</h3>
          <div className="popupScrollBox">

            <div className="popupFlightBox">
              {flights?.flightSegments.map((item, index) => { 
                return <>
                  <div className="mobileSectionTitle">
                 
                   {index == 0 && `Departure — ${moment(item.flightLegs[0].departureDateTime).format("ddd,D MMM")}`}
                   {index == 1 && `Returning — ${moment(item.flightLegs[item.flightLegs?.length - 1].arrivalDateTime).format("ddd,D MMM")}`}
                  </div>
                  <SingleFlightModal key={index} flightLegs={item} />
                </>
              })}

              {/* <SingleFlightModal flights={flights} /> */}

              {/* {legs &&
                legs.map((item, index) => {
                  return <SingleFlightDetails {...item} key={index} />;
                })} */}
              {/* {onwardFlightSubDetails &&
                onwardFlightSubDetails.map((item, index) => {
                  return <FlightSubDetails key={index} {...item} />;
                })} */}
            </div>
            {/* {Object.keys(returningFlightDetails).length && (
              <>
                <div className="mobileSectionTitle">
                  Returning — Sat, 17 Nov 2021
                </div>
                <div className="popupFlightBox">
                  <SingleFlightDetails {...returningFlightDetails} />
                  {returnFlightSubDetails &&
                    returnFlightSubDetails.map((item, index) => {
                      return <FlightSubDetails key={index} {...item} />;
                    })}
                </div>
              </>
            )} */}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FlightDetailsModal;