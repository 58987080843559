import { times } from "lodash";
import { FieldArray } from "formik";
import { defaultFormat } from "moment";
import { handleMaskedInput } from "../../Util/util";
import { commonActions } from "../../Stores/Actions";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState, useRef } from "react";

import moment from "moment";
import InputField from "./InputField";
import CustomPhoneInput from "../../Component/PhoneInput";
import Icomoon from "../../Component/FormComponent/Icomoon";
import Checkbox from "../../Component/FormComponent/Checkbox";
import OptionButton from "../../Component/FormComponent/OptionButton";
import SelectFieldOption from "../../Component/FormComponent/SelectFieldOption";
import DateInput from "../../Component/DatePicker/DateInput";
import SimpleInputField from "../../Component/FormComponent/SimpleInputField";

function infants(props) {
  const {
    formikProps,
    titleOption,
    ageOptions,
    nationalityOptions,
    passengersCount,
    childOrInfrant
    // getGuestData
  } = props;

  const dispatch = useDispatch();
  const fieldArrayPropsRef = useRef(null);

  const flightsWidgetData =
    localStorage.getItem("flightPayload") &&
    JSON.parse(localStorage.getItem("flightPayload"));

  const { values, errors, touched, handleBlur, handleChange } = formikProps;

  const [dateInputFocused, setDateInputFocused] = useState({
    dob: new Array(passengersCount).fill(false),
    passportExpiryDate: new Array(passengersCount).fill(false),
  });

  const handleFocusChange = (index, focused, dateColl) => {
    setDateInputFocused((pre) => {
      let copy = { ...pre };
      copy[dateColl][index] = focused;
      return copy;
    });
  };

  const getGuestData = useSelector((state) => state.commonReducer.getGuestData);

  const handleSelectChange = (value, fieldName, formikProps) => {
    formikProps.setFieldValue(fieldName, value);
  };

  const handleDateChange = (value, fieldName, formikProps) => {
    formikProps.setFieldValue(fieldName, moment(value)._d);
  };

  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (getGuestData && status) {
      setStatus(false);
 
      for (let i = 0; i < childOrInfrant; i++) {
        fieldArrayPropsRef?.current?.push({
          title: "",
          gender: "",
          frequentFlyerNumber: "",
          firstName: "",
          middleName: "",
          lastName: "",
          nationality: "",
          dob: "",
          passportCountry: "",
          phoneNumber: "",
          passportNumber: "",
          passportExpireDate: `01-02-2024`,
          check: false,
        });
      }


      // for (let i = 0; i < flightsWidgetData?.children; i++) {
      //     fieldArrayPropsRef?.current?.push(
      //         {
      //             title:"",
      //             gender:"",
      //             frequentFlyerNumber: "",
      //             firstName: "",
      //             middleName: "",
      //             lastName:"",
      //             nationality: "",
      //             dob: "",
      //             passportCountry: "",
      //             phoneNumber: "",
      //             passportNumber:"",
      //             passportExpireDate:"",
      //             check: false
      //         })
      // }
    }
  }, [getGuestData]);

  return (
    <FieldArray
      name="infants"
      render={(fieldArrayProps) => {
        const { push, remove, form } = fieldArrayProps;
        const { values, errors, handleChange,setFieldError } = form;

        fieldArrayPropsRef.current = fieldArrayProps;

        const selectedAdults = values?.infants?.filter((curr) => {
          return curr.check == true;
        });

        return (
          <>
            {/* {times(passengersCount, (index) => { */}
            <div className="card2 roomsInfo">
              <div className="sectionTitle justify-space-between">
                <h2> Infants Information</h2>
                <p>
                  {`${!errors?.infants ? flightsWidgetData?.infants : flightsWidgetData?.infants - (errors?.infants?.filter((item) => item).length)} / ${flightsWidgetData?.infants} added`}
                </p>
              </div>
              {values?.infants?.map((curr, index) => {
                return (
                  <>
                    <div className="d-flex promocodeWrap row">
                      {/* <Checkbox
                        name="check"
                        value={values?.infants[index]?.check}
                        disabled={
                          selectedAdults?.length >
                            flightsWidgetData?.children - 1 &&
                          !values?.infants[index]?.check
                        }
                        onChange={() => {
                          handleSelectChange(
                            !values?.infants[index]?.check,
                            `infants[${index}].check`,
                            formikProps
                          );
                        }}
                      /> */}

                      <div className="checkBoxContent">
                        <label className="checkbox-label" htmlFor="checkbox-id">
                          {`Infant ${index + 1}`}
                        </label>

                        <p className="add-traveller-button flex-center">
                          {/* <Icomoon className="icon-minus add-traveller-icon" />
                          <span onClick={() => remove(index)}>
                            <i className="icon-close"></i>
                          </span> */}
                        </p>
                      </div>
                    </div>

                    {values?.infants[index]?.check || true && (
                      <>
                        <div className="row new-lead-roomss-row mobile-view-room">
                          <div className="col wrapper-padding profileTitle2 ">
                            <SelectFieldOption
                              label="Title"
                              options={titleOption}
                              name={`infants[${index}].title`}
                              value={values.infants[index]?.title}
                              onChange={(_, name, value) =>
                                handleSelectChange(
                                  value,
                                  `infants[${index}].title`,
                                  formikProps
                                )
                              }
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.infants &&
                                touched.infants[index] &&
                                touched.infants[index]?.title
                              }
                              error={
                                errors.infants &&
                                errors.infants[index] &&
                                errors.infants[index]?.title
                              }
                            />
                          </div>

                          <div className="col wrapper-padding">
                            <OptionButton
                              textOptionOne="M"
                              textOptionTwo="F"
                              type="button"
                              groupClassName="genderBtnGroup"
                              className="genderBtn"
                              activeClassName="active"
                              label="Gender"
                              name={`infants[${index}].gender`}
                              value={values.infants[index]?.gender}
                              onClick={(name, value) => {
                                handleSelectChange(
                                  value,
                                  `infants[${index}].gender`,
                                  formikProps
                                );
                              }}
                              touched={
                                touched.infants &&
                                touched.infants[index] &&
                                touched.infants[index]?.gender
                              }
                              error={
                                errors.infants &&
                                errors.infants[index] &&
                                errors.infants[index]?.gender
                              }
                            />
                          </div>

                          <div className="col wrapper-padding mobile-view-width">

                            <InputField
                              type={"number"}
                              label="Frequent Flyer Number"
                              placeholder="Frequent Flyer Number"
                              name={
                                `infants[${index}].frequentFlyerNumber` ??
                                undefined
                              }
                              value={values.infants[index]?.frequentFlyerNumber}
                              onChange={handleChange}
                              formikProps={formikProps}
                              touched={
                                touched.infants &&
                                touched.infants[index] &&
                                touched.infants[index]?.frequentFlyerNumber
                              }
                              error={
                                errors.infants &&
                                errors.infants[index] &&
                                errors.infants[index]?.frequentFlyerNumber
                              }
                            />

                          </div>
                        </div>

                        <div className="row new-lead-roomss-row">
                          <div className="col col-room ">
                            <InputField
                              label="First Name"
                              placeholder="First Name"
                              name={`infants[${index}].firstName`}
                              value={
                                values.infants[index]?.firstName ?? undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.infants &&
                                touched.infants[index] &&
                                touched.infants[index]?.firstName
                              }
                              error={
                                errors.infants &&
                                errors.infants[index] &&
                                errors.infants[index]?.firstName
                              }
                            />
                          </div>

                          <div className="col  col-room ">
                            <InputField
                              label="Middle Name"
                              placeholder="Middle Name"
                              name={`infants[${index}].middleName`}
                              value={values.infants[index]?.middleName}
                              onChange={handleChange}
                              formikProps={formikProps}
                            />{" "}
                          </div>

                          <div className="col  col-room ">
                            <InputField
                              label="Last Name"
                              placeholder="Last Name"
                              name={`infants[${index}].lastName`}
                              value={
                                values.infants[index]?.lastName ?? undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.infants &&
                                touched.infants[index] &&
                                touched.infants[index]?.lastName
                              }
                              error={
                                errors.infants &&
                                errors.infants[index] &&
                                errors.infants[index]?.lastName
                              }
                            />
                          </div>
                        </div>

                        <div className="row new-lead-roomss-row">
                          <div className="col wrapper-padding profileTitle2">
                            <SelectFieldOption
                              label="Nationality"
                              options={nationalityOptions}
                              name={`infants[${index}].nationality`}
                              value={values.infants[index]?.nationality}
                              onChange={(_, name, value) =>
                                handleSelectChange(
                                  value,
                                  `infants[${index}].nationality`,
                                  formikProps
                                )
                              }
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.infants &&
                                touched.infants[index] &&
                                touched.infants[index]?.nationality
                              }
                              error={
                                errors.infants &&
                                errors.infants[index] &&
                                errors.infants[index]?.nationality
                              }
                            />
                          </div>

                          <div
                            className="col wrapper-padding single-date-picker-wrap"
                            style={{ flexDirection: "column" }}
                          >
                            <DateInput
                              {...formikProps}
                              placeholder="Date of Birth"
                              label="Date of Birth"
                              value={
                                values.infants[index]?.dob &&
                                moment(values.infants[index]?.dob).format(
                                  "MM/DD/YYYY"
                                )
                              }
                              icon="icon-invitation"
                              name={`infants[${index}].dob`}
                              onDateChange={(event) =>
                                handleDateChange(
                                  event,
                                  `infants[${index}].dob`,
                                  formikProps,
                                  index
                                )
                              }
                              focused={dateInputFocused.dob[index]}
                              onFocusChange={({ focused }) =>
                                handleFocusChange(index, focused, "dob")
                              }
                              isOutsideRange={(day) =>
                                moment().add(1, "days").diff(day) < 0
                              }
                              renderMonthYearSelector={true}
                              arrayFieldArray={true}
                            />

                            {touched.infants &&
                              touched.infants[index] &&
                              touched.infants[index]?.dob &&
                              errors.infants &&
                              errors.infants[index] &&
                              errors.infants[index]?.dob && (
                                <div className="inputStyle error-messages">
                                  {errors.infants[index]?.dob}
                                </div>
                              )}
                          </div>

                          <div className="col wrapper-padding profileTitle2">
                            <SelectFieldOption
                              label="Country/Territory Code"
                              options={nationalityOptions}
                              name={`infants[${index}].passportCountry`}
                              value={values.infants[index]?.passportCountry}
                              onChange={(_, name, value) =>
                                handleSelectChange(
                                  value,
                                  `infants[${index}].passportCountry`,
                                  formikProps
                                )
                              }
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.infants &&
                                touched.infants[index] &&
                                touched.infants[index]?.passportCountry
                              }
                              error={
                                errors.infants &&
                                errors.infants[index] &&
                                errors.infants[index]?.passportCountry
                              }
                            />
                          </div>
                        </div>

                        <div className="row new-lead-roomss-row">
                          <div className=" wrapper-padding ">
                            <CustomPhoneInput
                              label="Phone Number"
                              placeholder="Phone Number"
                              inputProps={{
                                name: `infants[${index}].phoneNumber`,
                              }}
                              value={values.infants[index]?.phoneNumber}
                              onChange={(phoneNumber) => {
                                formikProps.setFieldValue(
                                  `infants[${index}].phoneNumber`,
                                  phoneNumber
                                );
                              }}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.infants &&
                                touched.infants[index] &&
                                touched.infants[index]?.phoneNumber
                              }
                              error={
                                errors.infants &&
                                errors.infants[index] &&
                                errors.infants[index]?.phoneNumber
                              }
                            />
                          </div>

                          <div className="col wrapper-padding">
                            <InputField
                              label="Passport Number"
                              placeholder="Passport Number"
                              name={`infants[${index}].passportNumber`}
                              value={
                                values.infants[index]?.passportNumber ??
                                undefined
                              }
                              onChange={handleChange}
                              formikProps={formikProps}
                              onBlur={handleBlur}
                              touched={
                                touched.infants &&
                                touched.infants[index] &&
                                touched.infants[index]?.passportNumber
                              }
                              error={
                                errors.infants &&
                                errors.infants[index] &&
                                errors.infants[index]?.passportNumber
                              }
                            />
                          </div>

                          <div
                            className="col wrapper-padding single-date-picker-wrap"
                            style={{ flexDirection: "column" }}
                          >
                            <DateInput
                              {...formikProps}
                              placeholder="Passport Expire Date"
                              label="Passport Expire Date"
                              value={
                                values?.infants && values?.infants[index]?.passportExpiryDate &&
                                moment(values?.infants && values?.infants[index]?.passportExpiryDate).format(
                                  "YYYY/MM/DD"
                                )
                              }
                              icon="icon-invitation"
                              name={`infants[${index}].passportExpiryDate`}
                              onDateChange={(event) =>
                                handleDateChange(
                                  event,
                                  `infants[${index}].passportExpiryDate`,
                                  formikProps,
                                  index,
                                  setFieldError
                                )
                              }
                              // disabled={index < getGuestData?.length && !isFreemium }

                              focused={dateInputFocused?.passportExpiryDate[index]}
                              onFocusChange={({ focused }) =>
                                handleFocusChange(index, focused, "passportExpiryDate")
                              }
                              isOutsideRange={(day) =>
                                day.isBefore(moment().subtract(0, "days"))
                              }
                              renderMonthYearSelector={true}
                              arrayFieldArray={true}
                            />
 

                            {
                              touched?.infants && touched?.infants[index] && touched?.infants[index].passportExpireDate && errors.infants &&
                              errors.infants[index] &&
                              errors.infants[index]?.passportExpiryDate &&
                              <div className="inputStyle error-messages">
                                {errors?.infants && errors?.infants[index]?.passportExpiryDate} 
                              </div>
                            }


                          </div>
                        </div>
                        <div />
                      </>
                    )}
                  </>
                );
              })}

              <div>
                {/* <p
                  className="add-traveller-button add-guest"
                  onClick={() =>
                    push({
                      title: "",
                      gender: "",
                      frequentFlyerNumber: "",
                      firstName: "",
                      middleName: "",
                      lastName: "",
                      nationality: "",
                      dob: null,
                      passportCountry: "",
                      phoneNumber: "",
                      passportNumber: "",
                      passportExpireDate: null,
                      check: false,
                    })
                  }
                >
                  <Icomoon className="icon-add add-traveller-icon" />
                  <span>ADD NEW CHILD</span>
                </p> */}
              </div>
            </div>
          </>
        );
      }}
    />
  );
}

export default infants;