import { useEffect, useRef, useState } from "react";

import Button from "../FormComponent/Button";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import { has, pull, isEmpty } from "lodash";

const chunksMaxLength = 9;

function CheckBoxFilter({
  label,
  allFilters,
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  applyFilters,
  showMore,
  setShowMore,
  openTag,
  setOpenTag,
}) {
  const filterRef = useRef(null);

  const [isMobile, setIsMobile] = useState(false);
  const [showOption, setShowOption] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [window.innerWidth]);

  const toggleCloseFilter = () => {
    setShowOption(false);
  };

  const toggleFilter = (item) => {
    let index = selectedFilter[label].indexOf(item);

    if (index === -1) {
      setSelectedFilter((prevState) => {
        return { ...prevState, [label]: [...prevState[label], item] };
      });
    } else {
      let localArray = [...selectedFilter[label]];
      localArray.splice(index, 1);
      setSelectedFilter((preState) => {
        return {
          ...preState,
          [label]: localArray,
        };
      });
    }
  };

  useOutsideHandler(filterRef, showOption, toggleCloseFilter);

  function splitArrayIntoChunks(filterOptions) {
    let chunks = [],
      i = 0,
      n = filterOptions.length;

    while (i < n) {
      chunks.push(filterOptions.slice(i, (i += chunksMaxLength)));
    }

    return chunks;
  }

  const splittedOptionsChunks =
    filterOptions && splitArrayIntoChunks(filterOptions);

  return (
    <div
      className={
        showOption ? "filterDropdownWrap" : "filterDropdownWrap openedOption"
      }
      ref={filterRef}
    >
      <div className="flterOption" onClick={() => setShowOption((pre) => !pre)}>
        <span>
          {label}
          {allFilters[label]?.length !== 0 && `(${allFilters[label].length})`}
        </span>
        <span className={`fa fa-angle-${showOption ? "up" : "down"}`} />
      </div>

      <div className={`cardBody checkbox-card ${showOption ? "d-block" : ""}`}>
        {filterOptions && !isMobile && (
          <div style={{ display: "flex" }}>
            {splittedOptionsChunks.map((chunk, index) => {
              return (
                <div className="checkBox-width" key={index}>
                  {chunk.map((item, index) => {
                    return (
                      <label
                        className="customCheckbox"
                        key={index}
                        htmlFor={item.name}
                        onClick={() => toggleFilter(item.name)}
                      >
                        <input
                          type="checkbox"
                          name={item.name}
                          checked={selectedFilter?.[label]?.includes(item.name)}
                          onChange={() => {}}
                        />
                        <span>{item.label}</span>
                      </label>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}

        {filterOptions && isMobile ? (
          <div className="filterDropdwon">
            <div className="showMoreInWrap">
              <div className="showMoreInWrap">
                <div className="multiCol3">
                  {showMore
                    ? filterOptions.map((item, index) => (
                        <label
                          className="customCheckbox"
                          key={index}
                          htmlFor={item.name}
                          onClick={() => toggleFilter(item.name)}
                        >
                          <input
                            type="checkbox"
                            name={item.name}
                            checked={selectedFilter?.[label]?.includes(
                              item.name
                            )}
                            onChange={() => {}}
                          />
                          <span>{item.label}</span>
                        </label>
                      ))
                    : filterOptions.map((item, index) => {
                        if (index < 4) {
                          return (
                            <label
                              className="customCheckbox"
                              key={index}
                              htmlFor={item.name}
                              onClick={() => toggleFilter(item.name)}
                            >
                              <input
                                type="checkbox"
                                name={item.name}
                                checked={selectedFilter?.[label]?.includes(
                                  item.name
                                )}
                                onChange={() => {}}
                              />
                              <span>{item.label}</span>
                            </label>
                          );
                        }
                      })}
                </div>
              </div>
            </div>

            {filterOptions.length >= 5 && (
              <Button
                className="mobileShow showMore"
                onClick={() => setShowMore(!showMore)}
              >
                Show {showMore ? "less" : "more"}{" "}
                <i className={`fa fa-angle-${showMore ? "up" : "down"}`} />
              </Button>
            )}
          </div>
        ) : null}

        {!isMobile && (
          <div className="btnsCnt">
            <Button
              // className="borderBtn"
              className={`btnBorder ${
                isEmpty(selectedFilter?.[label]) ? "disabled" : ""
              }`}
              disabled={!isEmpty(selectedFilter?.[label]) ? false : true}
              onClick={() => {
                applyFilters();
                setOpenTag((prev) => !prev);
                setShowOption((prev) => !prev);
              }}
            >
              Apply
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CheckBoxFilter;