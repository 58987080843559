

import React, { useEffect, useRef, useState } from "react";
import Button from "../FormComponent/Button";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import {useFilters} from "../../Stores/ConstomHooks/useFilters"

import { has, pull, isEmpty } from "lodash";
function FlightRadioFilter({
  label,
  filterOptions,
//   filters,
//   setFilters,
  searchFlight,
  type,
}) {
  const [filters,setFilters,removeFilter,appliedFiltersList] = useFilters("flightFilters")
  const [showOption, setshowOption] = useState(false);
  const [showMore, setshowMore] = useState(false);
  const [isMobile, setisMobile] = useState(false);
  const filterRef = useRef(null);
  useEffect(() => {
    if (window.innerWidth <= 767) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }, [window.innerWidth]);


  const toggleCloseFilter = () => {
    setshowOption(false);
  };
  useOutsideHandler(filterRef, showOption, toggleCloseFilter);

  function handleFilterChange(e, value) {
      setFilters(type,[value]);
  }
  function handleApply() {
    searchFlight();
    setshowOption((prev) => !prev);
  }
  return (
    <div
      className={`filterDropdownWrap  ${
        type == "connections" && "connecting-airlines-wrap "
      } ${showOption && "openedOption"}`}
      ref={filterRef}
    >
      <div className="flterOption" onClick={() => setshowOption(!showOption)}>
        <span>{label}</span>
        <span className={`fa fa-angle-${showOption ? "up" : "down"}`} />
      </div>
      <div className={`filterDropdown ${showOption ? "d-block" : ""}`}>
        {/* <span>{label}</span> */}
        {  !isMobile &&
          filterOptions?.map((item, index) => {
            return (
              <label className="customCheckbox" key={index}>
                <input
                  type="radio"
                  name={item.name}
                  value={item.value}
                  checked={
                    filters[type]?.filter((opt) => opt == item?.value).length ? "checked" : false
                  }
                  onChange={(e) => handleFilterChange(e, item.value)}
                />
                <span>{item.label}</span>
              </label>
            );
          })}
        {filterOptions && isMobile ? (
          <div className="showMoreWrap">
            <div className="showMoreInWrap">
              {showMore
                ? filterOptions?.map((item, index) => (
                    <label className="customCheckbox" key={index}>
                      <input
                        type="radio"
                        name={item.name}
                        value={item.value}
                        checked={ filters[type]?.filter((opt) => opt == item.value).length ? "checked" : false }
                        onChange={(e) => handleFilterChange(e, item.value)}
                      />
                      <span>{item.label}</span>
                    </label>
                  ))
                : filterOptions?.map((item, index) => {
                    if (index < 4) {
                      return (
                        <label
                          className="customCheckbox"
                          key={index}
                        >
                          <input
                            type="radio"
                            name={item.name}
                            value={item.value}
                            checked={(filters[type]?.filter((opt) => opt == item.value).length)? "checked" : false }
                            onChange={(e) => handleFilterChange(e, item.value)}
                          />
                          <span>{item.label}</span>
                        </label>
                      );
                    }
                  })}
            </div>
            {filterOptions?.length >= 5 && (
              <Button
                className="mobileShow showMore"
                onClick={() => setshowMore(!showMore)}
              >
                Show {showMore ? "less" : "more"}{" "}
                <i className={`fa fa-angle-${showMore ? "up" : "down"}`} />
              </Button>
            )}
          </div>
        ) : null}
        {!isMobile && (
          <div className="btnsCnt">
            <Button className="borderBtn" onClick={handleApply}>
              Apply
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
export default FlightRadioFilter;