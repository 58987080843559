import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flightActions } from "../../Stores/Actions/flightActions";
import { carsActions, commonActions } from "../../Stores/Actions";
import { FlightCheckoutValidation } from "./FlightCheckoutValidation";
import { ErrorModel } from "../../Assets/scss/components/ErrorModal/ErrorModal";
import moment from "moment";
import { isEmpty } from 'lodash'

import {
  countries,
  checkoutCountries,
  optionsAge,
} from "../../Data/static.service";

import Traveler from "./Traveler";
import FlightInfo from "./FlightInfo";
import InputField from "./InputField";
import PrimaryInfo from "./PrimaryInfo";
import Childs from "./Childs";
import Infant from "./Infant";
import WalletDetails from "./WalletDetails";
import Scrollbars from "react-custom-scrollbars";
import HeaderNavbar from "../../Component/HeaderNavbar";
import TermsCheckbox from "../../Component/TermsCheckbox";
import BillingDetails from "../../Component/BillingDetails";
import WalletDetailInfo from "../../Component/WalletDetailInfo";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";

// Images
import summeryIcon from "../../Assets/img/summary-icon.png";
import creditCardImage from "../../Assets/img/cardIcon.png";

// Stripe imports
import { Elements } from "@stripe/react-stripe-js";
import { StripeForm } from "../TravelWebappHotel/StripeForm";
import { loadStripe } from "@stripe/stripe-js";

function FlightCheckout() {
  const formikRef = useRef();
  const dispatch = useDispatch();

  const stripeFormRef = useRef();
  const stripe = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

  const [style, setStyle] = useState("none");
  const [activeTab, setActiveTab] = useState(1);
  const [userOptions, setUserOptions] = useState([]);
  const [checkedBox, setCheckedBox] = useState(false); 
  const [userFromDrop, setUserFromDrop] = useState(null); 
  const [expireDateFocus, setExpireDateFocus] = useState(false); 

  const [showFlightInfoPopup, setShowFlightInfoPopup] = useState(false);


  const validatePayload =
    localStorage.getItem("validatePayload") &&
    JSON.parse(localStorage.getItem("validatePayload"));
  const flightPayload =
    localStorage.getItem("flightPayload") &&
    JSON.parse(localStorage.getItem("flightPayload"));
 

  let cabinClass = ""
  switch (validatePayload?.cabinClass) {
    case "Y":
      cabinClass = "economy"
      break;
    case "C":
      cabinClass = "business"
      break;
    case "F":
      cabinClass = "first"
      break;
    case "S":
      cabinClass = "premiumEconomy"
      break;
  }


  const revalidatedFlightData = useSelector(
    (state) => state.flightReducer.revalidatedFlightData
  );

  const stripeIntentData = useSelector(
    (state) => state.flightReducer.stripeIntentData
  );

  const bookingDataError = useSelector(
    (state) => state.flightReducer.bookingDataError
  );
  const sessionExpireError = useSelector(
    (state) => state.flightReducer.sessionExpireError
  );
  const confirmPaymentError = useSelector(
    (state) => state.flightReducer.confirmPaymentError
  );

  const currency = useSelector(
    (state) => state.currencyLanguageReducer?.data?.currency || {}
  );


  const usersData = useSelector((state) => state.commonReducer.getGuestData);
  const guestListOptions = useSelector((state) => state.commonReducer.guestListOptions) || [];

  const [popUpErrorMsg,setPopUpErrorMsg] = useState(null)
 

  const flightsWidgetData =
    localStorage.getItem("flightPayload") &&
    JSON.parse(localStorage.getItem("flightPayload"));

  const passengersCount =
    flightsWidgetData?.adults +
    flightsWidgetData?.children +
    flightsWidgetData?.infants;

  const handleErrorModal = (status) => {
    setPopUpErrorMsg(null);
  };

  const optionsTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
  ];

 
  useEffect(() => {
    if (!usersData) return;
    const result = usersData?.map((curr) => {
      return { id: curr.email, label: `${curr.fname} ${curr.lname}` };
    });
    setUserOptions(result);
  }, [usersData]);

  useEffect(() => {
    validatePayload.currency_code = currency?.isoCode;
    const payload = {
      sequenceId: validatePayload?.sequence_no,
      cabinClass
    }

    dispatch(flightActions.validateFlightActions(payload)).then((validateData)=>{
      console.log("validateData",validateData);
      if (validateData?.status != 200){
        setPopUpErrorMsg(validateData?.data?.message)
        return
      }
    });
  }, []);


  const handleCheckbox = () => {
    setCheckedBox(!checkedBox);
    setStyle(!style);
  };

  const handleExpireDate = (state) => {
    setExpireDateFocus(state);
  };



  const user = JSON.parse(localStorage.getItem("user"));

  const intialValues = {
    user: { id: user.email, label: `${user?.first_name} ${user?.last_name}` },
    title: user?.title || "",
    title2: "",
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
    phoneNumber: user?.phone || "",
    phoneNumberCode: "",
    frequentFlyNumber: "",
    gender: "",
    nationality: user?.country|| "",
    dob: null,
    city: user?.user_address?.city || "",
    state: "",
    addressLineOne: user?.user_address?.address_line_1 || "",
    addressLineTwo: user?.user_address?.address_line_2 || "",
    passportNumber: "",
    passportExpiryDate: null,
    cardHolderName: "",
    cardNumber: "",
    mmyy: "",
    cvv: "",
    zipCode: user?.user_address?.zip || "",
    terms: false,
    adults: [],
    childs: [],
    infants: [],
    passengerArray: [],
    use_wallet: false,
    amountAdded: 0,
  };
  const [adultsError, setAdultError] = useState(null)

  useEffect(() => {
    dispatch(
      commonActions.getGuestListAction(
        JSON.parse(localStorage.getItem("user")).user_id
      )
    );
  }, []);

  const [stateOption, setStateOption] = useState([]);

  const countrySelect = (e) => {
    if (e?.value) {
      const stateSelect = checkoutCountries
        ?.find((ele) => ele["alpha-2"] === e?.value)
        ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
      if (stateSelect && !isEmpty(stateSelect)) {
        setStateOption(stateSelect);
        formikRef?.current?.setFieldValue("state", stateSelect.length > 0 && stateSelect[0].value);
      } else {
        setStateOption([]);
      }
    }
  };

  useEffect(() => {
    const stateSelect = checkoutCountries
    ?.find((ele) => ele["alpha-2"] == user?.country|| "")
    ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
  if (stateSelect && !isEmpty(stateSelect)) {
    setStateOption(stateSelect);
    console.log("stateSelect", stateSelect);
    if(user?.user_address?.state ){
      formikRef?.current?.setFieldValue("state", user?.user_address?.state);
    }else{
    formikRef?.current?.setFieldValue("state", stateSelect[0].value);
    }
  } else {
    setStateOption([]);
  }
  }, [])
  

  useEffect(() => {
    if (!userFromDrop) return;

    const result = usersData?.filter((curr) => {
      return curr.email == userFromDrop.id;
    });

    const stateSelect = checkoutCountries
      ?.find((ele) => ele["alpha-2"] == result[0]?.country || "")
      ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
    if (stateSelect && !isEmpty(stateSelect)) {
      setStateOption(stateSelect);
      console.log("stateSelect", stateSelect);
      formikRef?.current?.setFieldValue("state", stateSelect[0].value);
    } else {
      setStateOption([]);
    }

    formikRef?.current?.setFieldValue("title", result[0]?.title);
    formikRef?.current?.setFieldValue("firstName", result[0]?.fname);
    formikRef?.current?.setFieldValue("lastName", result[0]?.lname);
    formikRef?.current?.setFieldValue("email", result[0]?.email);
    formikRef?.current?.setFieldValue("nationality", result[0]?.country);
    formikRef?.current?.setFieldValue("phoneNumber", result[0]?.phone);

    countrySelect(null, result[0]?.country)

  }, [userFromDrop]);

  const countryOptions = countries?.map((ele) => {
    return { value: ele.sortname, label: ele.name, id: ele.id };
  });

  const handleCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value);
    if (field == "user") setUserFromDrop(value);
  };


  const handleSubmit = (value) => {
    const bookingPayload = {
      sequenceId: 1,
      cabinClass: cabinClass,
      primaryContact: {
        firstName: value.firstName,
        lastName: value.lastName,
        phoneCountryCode: "91",
        phoneNumber: value.phoneNumber,
        email: value.email,
        postalCode: value.zipCode
      },
      travellers: []
    }

    value?.adults?.forEach((adult) => {
      if (adult.check) {
        const temp = {
          passengerType: "ADT",
          gender: adult.gender,
          title: adult.title.replace(".", ""),
          firstName: adult.firstName,
          lastName: adult.lastName,
          dateOfBirth: moment(adult.dob).format("YYYY-MM-DD"),
          passportNumber: adult.passportNumber,
          passportExpiryDate: moment(adult.passportExpireDate).format("YYYY-MM-DD"),
          passportCountry: adult.passportCountry,
          frequentFlyerNumber: String(adult.frequentFlyerNumber) || "",
          nationality: adult.nationality,
          knownTravelerNumber: null,
          redressNo: null,
          nationalId: null,
        }
        bookingPayload.travellers.push(temp);
      }
    });
    value?.childs?.forEach((adult) => {
      
        const temp = {
          passengerType: "CHD",
          gender: adult.gender,
          title: adult.title.replace(".", ""),
          firstName: adult.firstName,
          lastName: adult.lastName,
          dateOfBirth: moment(adult.dob).format("YYYY-MM-DD"),
          passportNumber: adult.passportNumber,
          passportExpiryDate: moment(adult.passportExpireDate).format("YYYY-MM-DD"),
          passportCountry: adult.passportCountry,
          frequentFlyerNumber: String(adult.frequentFlyerNumber) || "",
          nationality: adult.nationality,
          knownTravelerNumber: null,
          redressNo: null,
          nationalId: null,
        }
        bookingPayload.travellers.push(temp);
    
    });
    value?.infants?.forEach((adult) => {
     
        const temp = {
          passengerType: "INF",
          gender: adult.gender,
          title: adult.title.replace(".", ""),
          firstName: adult.firstName,
          lastName: adult.lastName,
          dateOfBirth: moment(adult.dob).format("YYYY-MM-DD"),
          passportNumber: adult.passportNumber,
          passportExpiryDate: moment(adult.passportExpireDate).format("YYYY-MM-DD"),
          passportCountry: adult.passportCountry,
          frequentFlyerNumber: String(adult.frequentFlyerNumber) || "",
          nationality: adult.nationality,
          knownTravelerNumber: null,
          redressNo: null,
          nationalId: null,
        }
        bookingPayload.travellers.push(temp);
    
    });

    validatePayload.currency_code = currency?.isoCode;
    const reValidatePayload = {
      sequenceId: validatePayload?.sequence_no,
      cabinClass
    } 

    console.log("bookingPayload",bookingPayload);

    dispatch(flightActions.reValidateFlightActions(reValidatePayload)).then((revalidateData) => {
      console.log("revalidateData......", revalidateData); 
      if (revalidateData?.status != 200){
        setPopUpErrorMsg(revalidateData?.data?.message)
        return
      }
      dispatch(flightActions.getStripeIntentRequest(reValidatePayload)).then((stripeIntentData) => {
        console.log("stripeIntentData....", stripeIntentData);

        if (stripeIntentData?.status != 200){
          setPopUpErrorMsg(stripeIntentData?.data?.message)
          return
        }

        if (stripeIntentData?.data?.intentId) {
          stripeFormRef.current.confirmStripePayment().then((comfirm) => {
            if (!comfirm){
               setPopUpErrorMsg("Payment Error")
               return
            }
            dispatch(flightActions.checkoutFlightActions(bookingPayload)).then((bookData) => {
              console.log("::::::book res:::::", bookData);
              if (bookData?.status == 200) {
                 navigate(`/flight-confirmation/${bookData?.data?.id}`);
                // alert("booking successfull")
              }else{
                setPopUpErrorMsg(bookData?.data?.message)
                return
              }
            })
          });
        }
      })
    })
  };

  const handleAdultsError = () => {
    const selectedAdults = formikRef?.current?.values?.adults?.filter((curr, i) => curr.check).length || 0
    if (selectedAdults != validatePayload?.adults) {
      setAdultError(`Please select ${validatePayload?.adults} adults`)
      return
    } else {
      setAdultError(null)
    }
  }

  const numberCustomChange = (formikProps, field, value) => {
    if (field === "phoneNumber" || field === "cardNumber" || field === "cvv") {
      const temp = Number(value);
      if (temp || value === "") {
        formikProps.setFieldValue(field, value);
      }
    }
  };

  const renderForm = (formikProps) => {
    const { values, handleSubmit, handleBlur, errors, touched, handleChange } =
      formikProps;

    // console.error("errors", errors);
    // console.warn("values", values); 
    // console.log("touched", touched); 

    const selectedAdults = formikRef?.current?.values?.adults?.filter((curr, i) => curr.check).length || 0
    if (selectedAdults == validatePayload?.adults) {
      setAdultError(null)
    }


    return (
      <form onSubmit={handleSubmit}>

        <PrimaryInfo
          formikProps={formikProps}
          handleCustomChange={handleCustomChange}
          nationalityOptions={countryOptions}
          stateOption={stateOption}
          setStateOption={setStateOption}
          titleOption={optionsTitle}
          countrySelect={countrySelect}
          userOptions={guestListOptions}
          numberCustomChange={numberCustomChange}
          hideUserSelect={process.env.REACT_APP_FREMIUM_USER_TYPE_ID == user.type_id}

        />

        {flightPayload?.adults > 0 && (
          <Traveler
            formikProps={formikProps}
            handleCustomChange={handleCustomChange}
            expireDateFocus={expireDateFocus}
            handleExpireDate={handleExpireDate}
            ageOptions={optionsAge}
            titleOption={optionsTitle}
            nationalityOptions={countryOptions}
            passengersCount={passengersCount}
            formikValues={values}
            adultsError={adultsError}
          />
        )}

        {flightPayload?.children > 0 && (
          <Childs
            formikProps={formikProps}
            handleCustomChange={handleCustomChange}
            expireDateFocus={expireDateFocus}
            handleExpireDate={handleExpireDate}
            ageOptions={optionsAge}
            titleOption={optionsTitle}
            nationalityOptions={countryOptions}
            passengersCount={passengersCount}
            getGuestData={usersData}
            childOrInfrant={flightPayload.children}
            travlerType={"childs"}
          />
       )} 

        {flightPayload?.infants > 0 && (
          <Infant
            formikProps={formikProps}
            handleCustomChange={handleCustomChange}
            expireDateFocus={expireDateFocus}
            handleExpireDate={handleExpireDate}
            ageOptions={optionsAge}
            titleOption={optionsTitle}
            nationalityOptions={countryOptions}
            passengersCount={passengersCount}
            getGuestData={usersData}
            childOrInfrant={flightPayload.infants}
          />
        )}

        <div className="card2 billingInfo GlobalPaymentIntegrationWrap">
          <div className="billingDetailsToggle">
            <ul>
              <li onClick={() => setActiveTab(1)}>
                <a
                  className={activeTab == 1 ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  Pay Using Debit / Credit Cards
                </a>
              </li>

              <li onClick={() => setActiveTab(2)}>
                <a
                  className={activeTab == 2 ? "active" : ""}
                  style={{ cursor: "pointer" }}
                >
                  Pay Using Other Options
                </a>
              </li>
            </ul>
          </div>

          <div
            id="cardPaymentOption"
            className={`cardPaymentOption tabContent ${activeTab === 1 ? "activeTab" : ""
              }`}
          >
            <div className="sectionTitle walletwrapper">
              <h4 className="boxTitle">Billing Details</h4>
            </div>
            <div className="cardDetailsBox row">
              <InputField
                name="cardHolderName"
                type="text"
                label="Card holder name"
                placeholder="Card holder name"
                value={values.cardHolderName}
                error={errors.cardHolderName}
                cardError={errors.cardHolderName}
                touched={touched.cardHolderName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="cardHolder"
              />

              <Elements stripe={stripe}>
                <StripeForm
                  ref={stripeFormRef}
                  clientSecret={stripeIntentData?.clientSecret}
                />
              </Elements>
            </div>
          </div>
          <div
            id="walletPaymentOption"
            className={`tabContent WalletPaymentOption  ${activeTab === 2 ? "activeTab" : ""
              }`}
          >
            <div className="sectionTitle walletwrapper">
              <h4 className="boxTitle">Billing Details</h4>
            </div>
            <WalletDetails />
          </div>

          <div className="d-flex checkBoxWrap">
            <TermsCheckbox
              name="terms"
              id="terms"
              value={values.terms}
              onChange={(e) => {
                handleCustomChange(formikProps, "terms", e.target.checked);
              }}
              error={errors.terms}
              touched={touched.terms}
              onBlur={handleBlur}
            />

            <div className="buttonWrap">
              <div className="desktopViewDisplayNone">
                <h4>
                  Amount Due Today:{" "}
                  <strong>
                    {" "}
                    &nbsp;{revalidatedFlightData?.currency_symbol}
                    {revalidatedFlightData?.price_details?.total_price}
                  </strong>
                </h4>
              </div>


              <button onClick={handleAdultsError} type="submit" className="btn btn2 centerAlign">
                <span className="icon-lock"></span> Pay Now
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      <ErrorModel
        openErrorModal={popUpErrorMsg}
        handleErrorModal={handleErrorModal}
        message={popUpErrorMsg}
      />
 

      <div className="siteBg checkOutPage checkoutPageWrap flight-checkout">
        <div className="pageWrap">
          <div className="fixedHeader">
            <HeaderNavbar />
          </div>

          <section className="mainContent">
            <Scrollbars
              autoHide
              autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div
                className="desktop-hide mobile-popup-bar"
                onClick={() => setShowFlightInfoPopup(true)}
              >
                <div className="booking-details-title">
                  <h4>Review Booking Details</h4>
                  <a className="detailsLink" href="#"></a>
                  <div className="info-icon">
                    <img src={summeryIcon} alt="icon" className="" />
                  </div>
                </div>
              </div>

              <div className="container dflex pageInternalScrollTopPadding">
                <div className="mainWrap">
                  <Formik
                    innerRef={formikRef}
                    onSubmit={handleSubmit}
                    initialValues={intialValues}
                    validationSchema={FlightCheckoutValidation}
                  >
                    {renderForm}
                  </Formik>
                </div>

                <div className="sideBar">
                  <div className="card2">
                    <FlightInfo revalidatedFlightData={revalidatedFlightData} />
                  </div>
                </div>
              </div>
            </Scrollbars>
          </section>

          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />
        </div>
      </div>

      {showFlightInfoPopup && (
        <div id="flightDetailPopup" className="popupStyle flightDetailPopup">
          <div className="popUpBox">
            <button
              type="button"
              className="popupCloseBtn"
              onClick={() => setShowFlightInfoPopup(false)}
            >
              <span className="icon-close"></span>
            </button>
            <h3 className="popupTitle">Booking Details</h3>
            <div className="popupScrollBox">
              <div className="sideBar">
                <div className="card2">
                  <FlightInfo revalidatedFlightData={revalidatedFlightData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FlightCheckout;