import { cardsConstant } from "../Constants/cardsConstant";
import { cardsService } from "../../api/cardsService";
import { commonActions } from "./commonActions";

export const cardsAction = {
  dashboardSummary,
  assignCard,
};

// Get Affiliate Dashboard summary
function dashboardSummary(id,payload,selectedTab) {
  return (dispatch) => {
    dispatch(request(cardsConstant.AFFILIATE_DASHBOARD_SUMMARY));
    dispatch(commonActions.showLoader());
    return cardsService
      .dashboardSummary(id,payload,selectedTab)
      .then((res) => {
        dispatch(
          success(cardsConstant.AFFILIATE_DASHBOARD_SUMMARY_SUCCESS, res)
        );
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(
          failure(cardsConstant.AFFILIATE_DASHBOARD_SUMMARY_FAILURE, error)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function assignCard(object,userType) {
  return (dispatch) => {
    dispatch(request(cardsConstant.ASSIGN_CARDS_REQUEST));
    dispatch(commonActions.loaderText("Sending..."));
    dispatch(commonActions.showLoader());
       cardsService.assignCard(object)
      .then((res) => {
        dispatch(success(cardsConstant.ASSIGN_CARDS_SUCCESS, {res,userType}));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(cardsConstant.ASSIGN_CARDS_FAILURE, {error,userType}));
        dispatch(commonActions.hideLoader());
      }); 
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
