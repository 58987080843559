import React from "react";
import ResortFeeImg from "../../Assets/img/profile/warning.svg";
import moment from "moment";
function TotalNightPrice({
  savings,
  roomCount,
  nightCount,
  publicPrice,
  from,
  totalPriceToPay,
  savingPrice,
  perNight,
  tax,
  mandatory_fee,
  free_cancellation_date,
  hasMandatoryFee,
  currency,
  no_of_nights,
  currencySymbol,
  currencyCode,
  nightPrice,
}) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  });

  return (
    <>
      {savings > 0 && (
        <div className="dflex publicPrice">
          <span className="lightText">Public Price:</span>
          <span className="font14">{formatter.format(publicPrice)}</span>
        </div>
      )}
      <div>
        <div
          className={`dflex btmSpace fromPrice ${
            savings > 0 ? "" : "singlePrice"
          }`}
        >
          <div className="fromPriceWrap">
            <span className="font17 lightText">From:</span>
            <p className="btmSpace lightText">
              ({no_of_nights} night incl. taxes &amp; fees)
            </p>
          </div>
          <div className="from-details-price">
            <span className="font17">{formatter.format(from)}</span>

            {/* Price Details MobileView */}
            <div className="desktopViewDisplayNone">
              <div className="priceDetailsWrapper">
                <div className="priceDetails d-flex align-items-center">
                  <h4>Price Details</h4>
                  <i className="fa fa-angle-down"></i>
                  <div className="pricedetail-dropdown">
                    <div className="dropdown-box">
                      <div
                        className="dropdown-content"
                        style={{ borderBottom: savings > 0 ? "" : "none" }}
                      >
                        {free_cancellation_date && (
                          <div className="note">
                            <p>
                              Free cancellation before{" "}
                              {moment(free_cancellation_date).format(
                                "ddd, MMMM DD"
                              )}
                            </p>
                          </div>
                        )}
                        <div className="dropdown-info">
                          <h3>Price Details</h3>
                          <h4>
                            {`${roomCount} Room${roomCount > 1 ? "s" : ""} X ${
                              nightPrice
                                ? "1 Night"
                                : `${nightCount} Night${
                                    nightCount > 1 ? "s" : ""
                                  }`
                            }`}
                            <span>
                              {formatter.format(Number(perNight).toFixed(2))}
                            </span>
                          </h4>
                          <h4>
                            Taxes and Fees<span>{formatter.format(tax)}</span>
                          </h4>
                          <div className="total-amount">
                            <h4>
                              <strong className="total-text">Total:</strong>
                              <span>{formatter.format(totalPriceToPay)}</span>
                            </h4>
                          </div>
                          <div className="saving-box">
                            {savings <= 0 ? (
                              ""
                            ) : (
                              <h4>
                                Savings ({savings}%):
                                <span>{formatter.format(savingPrice)}</span>
                              </h4>
                            )}
                          </div>
                        </div>
                        {mandatory_fee && (
                          <div className="resortFeessection">
                            <h3>
                              <span className="circle">
                                <img src={ResortFeeImg} alt="icon" />
                              </span>
                              <strong>
                                Resort Fees per Room: ${mandatory_fee}
                              </strong>
                            </h3>
                            <p>
                              (<strong>NOT </strong>
                              included in the price, due at the Hotel)
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Price Details DesktopView */}
      <div
        className={`mobileViewDisplayNone ${savings > 0 ? "" : "singlePrice"}`}
      >
        <div className="priceDetailsWrapper">
          <div className="priceDetails d-flex align-items-center">
            <h4>Price Details</h4>
            <i className="fa fa-angle-down"></i>
            <div className="pricedetail-dropdown">
              <div className="dropdown-box">
                <div
                  className="dropdown-content"
                  style={{ borderBottom: savings > 0 ? "" : "none" }}
                >
                  {free_cancellation_date && (
                    <div className="note">
                      <p>
                        Free cancellation before{" "}
                        {moment(free_cancellation_date).format("ddd, MMMM DD")}
                      </p>
                    </div>
                  )}
                  <div className="dropdown-info">
                    <h3>Price Details</h3>
                    <h4>
                      {`${roomCount} Room${roomCount > 1 ? "s" : ""} X ${
                        nightPrice
                          ? "1 Night"
                          : `${nightCount} Night${nightCount > 1 ? "s" : ""}`
                      }`}
                      <span>
                        {formatter.format(Number(perNight).toFixed(2))}
                      </span>
                    </h4>
                    <h4>
                      Taxes and Fees<span>{formatter.format(tax)}</span>
                    </h4>
                    <div className="total-amount">
                      <h4>
                        <strong className="total-text">Total:</strong>
                        <span>{formatter.format(totalPriceToPay)}</span>
                      </h4>
                    </div>
                    <div className="saving-box">
                      {savings <= 0 ? (
                        ""
                      ) : (
                        <h4>
                          Savings ({savings}%):
                          <span>{formatter.format(savingPrice)}</span>
                        </h4>
                      )}
                    </div>
                  </div>
                  {mandatory_fee ? (
                    <div className="resortFeessection">
                      <h3>
                        <span className="circle">
                          <img src={ResortFeeImg} alt="icon" />
                        </span>

                        <strong>{`Resort Fees per Room: ${currency} ${mandatory_fee}`}</strong>
                      </h3>
                      <p>
                        (<strong>NOT</strong>
                        included in the price, due at the Hotel)
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {savings <= 0 ? (
        ""
      ) : (
        <div className="dflex blueText btmSpace2 savings">
          <span>Savings ({savings}%):</span>
          <span className="font14">{formatter.format(savingPrice)}</span>
        </div>
      )}
    </>
  );
}

export default TotalNightPrice;
