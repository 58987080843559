import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { convertTime12To24 } from "../../Util/util";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  carTypeList,
  timingOptions,
  discountTypeList,
  rentalCarCompanyList,
  timingOptions12HourFormat,
} from "../../Stores/Constants/siteConstants/dropdownConstants";
import { commonActions } from "../../Stores/Actions/commonActions";
import { CarSearchValidation } from "../../Component/Validation/CarSearchValidation";

import moment from "moment";
import Loader from "../../Component/Loader/Loader";
import Button from "../../Component/FormComponent/Button";
import DateInput from "../../Component/DatePicker/DateInput";
import SelectComponent from "../../Component/Select/SelectComponent";
import SearchAutocomplete from "../../Component/Autocomplete/SearchAutocomplete";
import IconButton from "../../Component/FormComponent/IconButton";


const CarsTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isMobile, setMobile] = useState(false);
  const [isResponsive, setResponsive] = useState(false);
  const [dateInputFocused, setDateInputFocused] = useState({});
  const [selectedLocations, setSelectedLocations] = useState({});
  const [advanceSettingVisibility, setAdvanceSettingVisibility] =
    useState(false);
  const [showAutocomplete, setShowAutocomplete] = useState({
    pickUpLocation: false,
    dropOffLocation: false,
  });
  const [showAdditionalDiscountOptions, setShowAdditionalDiscountOptions] =
    useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    }
  }, []);

  const currentGeoLocation =
    localStorage.getItem("currentGeoLocation") &&
    JSON.parse(localStorage.getItem("currentGeoLocation"));

  const isUS = currentGeoLocation === "US"; 

  const selectedTimingOptions = isUS
    ? timingOptions12HourFormat
    : timingOptions;

// states to track timing options for pickup time options
  const [pickupTimeOptions, setPickupTimeOptions] = useState(isUS
    ? timingOptions12HourFormat
    : timingOptions) 
// states to track timing options for dropoff time options
  const [dropoffTimeOptions, setDropoffTimeOptions] = useState(isUS
    ? timingOptions12HourFormat
    : timingOptions) 

  const currencyAndLanguage =
    localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const { searchLoading, autoCompleteData: locationSearchAutoCompleteData } =
    useSelector((state) => state.commonReducer);

  const childrenCount =
    localStorage.getItem("passengerValue") &&
    JSON.parse(localStorage.getItem("passengerValue")).children;

  const loading = useSelector((state) => state.commonReducer.loader);

  const toggleAdvanceSetting = () => {
    setAdvanceSettingVisibility((preVal) => !preVal);
  };

  const handleDateChange = async (value, formikProps, field) => {
    await formikProps.setFieldValue(field, moment(value)._d);
    const isDropOffDateSelected = moment(formikProps.values.dropOffDate).diff(
      value
    );

    if (
      field === "pickUpDate" &&
      (isNaN(isDropOffDateSelected) || isDropOffDateSelected < 0)
    ) {
      formikProps.setFieldValue("dropOffDate", moment(value)._d);
    }

    setDateInputFocused((preVal) => {
      return { ...preVal, dropOffDate: true };
    });
  };

  const handleFocusChange = (field, value) => {
    setDateInputFocused((prevVal) => {
      return { ...prevVal, [field]: value };
    });
  };

  const handleSelectChange = (value, fieldName, formikProps) => {
    formikProps.setFieldValue(fieldName, value);

    if (fieldName === "discountCouponCode") {
      setShowAdditionalDiscountOptions(true);
    }
  };

  const handleAutocompleteClick = (fieldName) => {
    setShowAutocomplete((preVal) => {
      return {
        ...preVal,
        [fieldName]: !preVal[fieldName],
      };
    });
  };

  const showResponsive = (fieldName) => {
    setResponsive(true);
    handleAutocompleteClick(fieldName);
  };

  const handleOnSearch = (query) => {
    const queryPayload = {
      format: "json",
      refid: process.env.REACT_APP_CARS_AUTO_DATA_REF_ID,
      api_key: process.env.REACT_APP_CARS_AUTO_DATA_API_KEY,
      string: query,
      numCities: 6,
      numAirports: 5,
      numPOIs: 3,
      numPartnerLocations: 4,
      max_results: 40,
    };
    dispatch(commonActions.carSearchAutoComplete(queryPayload));
  };

  const handleOnSelect = (value, fieldName, formikProps) => {
    // [0]?.name
    formikProps.setFieldValue(fieldName, value);
    setSelectedLocations((prevVal) => {
      return { ...prevVal, [fieldName]: value[0]?.id };
    });

    if(value[0]){
      localStorage.setItem("CarsautoCompleteData", JSON.stringify(value[0]))
      handleAutocompleteClick(fieldName); 
    }else{
      // formikProps.setFieldValue(fieldName, [JSON.parse(localStorage.getItem("CarsautoCompleteData"))]); 
    }
  };

  const initialValues = {
    pickUpLocation: "",
    dropOffLocation: "",
    pickUpDate: moment().add(0, "days"),
    dropOffDate: moment().add(2, "days"),
    pickUpTime: isUS
      ? { value: "10:00 AM", label: "10:00 AM" }
      : { value: "10:00", label: "10:00" },
    dropOffTime: isUS
      ? { value: "10:00 AM", label: "10:00 AM" }
      : { value: "10:00", label: "10:00" },
    rentalCarCompany: "",
    carType: "",
    discountCouponCode: "",
    corporateID: "",
    couponCode: "",
    productCode: "",
    showDropOffPoint: false,
  };

  const handleSubmit = (values) => {
    // Todo : Payload
    const dataPayload = {
      home: false, // what is this
      format: "json",
      currency: currency.isoCode,
      native_currency_symbol: currency.symbol,
      refid: process.env.REACT_APP_CARS_AUTO_DATA_REF_ID,
      api_key: process.env.REACT_APP_CARS_AUTO_DATA_API_KEY,

      children_count: childrenCount,

      carType: values.carType.value,
      pickup_time: isUS
        ? convertTime12To24(values.pickUpTime.value)
        : values.pickUpTime.value,
      dropoff_time: isUS
        ? convertTime12To24(values.dropOffTime.value)
        : values.dropOffTime.value,
      pickup_code: selectedLocations.pickUpLocation,
      dropoff_code: selectedLocations.dropOffLocation,
      pickup_date: moment(values.pickUpDate).format("YYYY-MM-DD"),
      dropoff_date: moment(values.dropOffDate).format("YYYY-MM-DD"),
    };

    localStorage.setItem(
      "showDropOffPoint",
      JSON.stringify(values.showDropOffPoint)
    );

    localStorage.setItem(
      "pickUpLocation",
      JSON.stringify(values.pickUpLocation)
    );

    localStorage.setItem(
      "dropOffLocation",
      JSON.stringify(values.dropOffLocation)
    );

    localStorage.setItem("carsPayload", JSON.stringify(dataPayload));
    navigate("/cars-listing");
  };

  const [isDateResponsive, setDateResponsive] = useState({
    pickUpDate: false,
    dropOffDate: false,
  });

  const handleDateClick = () => {
    setDateResponsive({ pickUpDate: false, dropOffDate: false });
  };

  const showDateResponsive = (fieldName) => {
    setDateResponsive((preVal) => {
      return {
        ...preVal,
        [fieldName]: !preVal[fieldName],
      };
    });
  };

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;

      console.warn("pickUpLocation",values.pickUpLocation)

    useEffect(() => {
      if(moment(values.pickUpDate).format("YYYY-MM-DD") === moment(values.dropOffDate).format("YYYY-MM-DD")){
        let timeOptions = selectedTimingOptions.map(obj => {
          if(moment(values.pickUpTime.value, "h:mma").isBefore(moment(obj.value, "h:mma"))){
            return obj;
          }else{
            let tempObj = {...obj, isDisabled: true}
            return tempObj;
          }
        })
        setDropoffTimeOptions(timeOptions);
        let timeObj = timeOptions.find(obj => {
          return moment(values.pickUpTime.value, "h:mma").isBefore(moment(obj.value, "h:mma"))
        })
        if(moment(values.pickUpDate).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")){
          formikProps.setFieldValue("dropOffTime", timeObj);
        }
        
      }else{
        // formikProps.setFieldValue("dropOffTime", values.pickUpTime);
      }
    }, [values.pickUpTime])

    useEffect(() => {
      if(moment(values.pickUpDate).format("YYYY-MM-DD") === moment(values.dropOffDate).format("YYYY-MM-DD") && (moment(values.pickUpDate).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"))){
        let timeOptions = selectedTimingOptions.map(obj => {
          if(moment().isBefore(moment(obj.value, "h:mma"))){
            return obj;
          }else{
            let tempObj = {...obj, isDisabled: true}
            return tempObj;
          }
        })
        setPickupTimeOptions(timeOptions);
        setDropoffTimeOptions(timeOptions);

        let timeObj = timeOptions.find(obj => {
          return moment().isBefore(moment(obj.value, "h:mma"))
        })
        formikProps.setFieldValue("pickUpTime", timeObj);        
      }else{
        
        setPickupTimeOptions(selectedTimingOptions);
        setDropoffTimeOptions(selectedTimingOptions);
      }
    }, [values.pickUpDate, values.dropOffDate])


    return (
      <form onSubmit={handleSubmit}>
        <div className="tabContent activeTab" id="carsTab">
          <div className="rightBtnSpace">
            <div className="flex-wrapper">
              <div className="flex-col customFlex">
                {/* pick up point */}
                <div className="pickup-drop-main">
                  <div className="pickup-drop-wrap">
                    <div
                      className={
                        values.showDropOffPoint
                          ? "pickUpPonint pickUpPonintCol col input-pickup"
                          : "pickUpPonint pickUpPonintCol col"
                      }
                    >
                      <div className="inputStyle showDropdown input-for-mobile">
                        {!isMobile && (
                          <SearchAutocomplete
                            {...formikProps}
                            options={locationSearchAutoCompleteData}
                            name="pickUpLocation"
                            onSearch={(query) => handleOnSearch(query)}
                            onChange={(selected) =>
                              handleOnSelect(
                                selected,
                                "pickUpLocation",
                                formikProps
                              )
                            }
                            value={values.pickUpLocation}
                            onBlur={handleBlur}
                            onClick={() =>
                              handleAutocompleteClick("pickUpLocation")
                            }
                            placeholder="Pick-up"
                            icon="icon-location-on"
                            isLoading={searchLoading}
                            errors={touched.pickUpLocation ? errors : {}}
                            drop={false}
                          />
                        )}

                        {isMobile && (
                          <div className="responsive-input">
                            <button
                              className="inputStyle grow1"
                              onClick={() => showResponsive("pickUpLocation")}
                              type="button"
                            >
                              {" "}
                              <i className="icon-location-on" />{" "}
                              {values?.pickUpLocation
                                ? values?.pickUpLocation[0]?.secondary_label || JSON.parse(localStorage.getItem("CarsautoCompleteData"))?.secondary_label
                                : "Pick-up"}
                            </button>
                          </div>
                        )}


                        {isResponsive && showAutocomplete.pickUpLocation && (
                          <div className="inputStyle showDropdown">
                            <div className="mobilePopup">
                              <Link to="#" className="closePopup">
                                <span
                                  className="icon-close"
                                  onClick={() =>
                                    handleAutocompleteClick("pickUpLocation")
                                  }
                                />
                              </Link>
                              <h4 className="inputPopTitle">
                                Where are you leaving form?
                              </h4>
                              <div className="dropdown-content">
                                <SearchAutocomplete
                                  {...formikProps}
                                  options={locationSearchAutoCompleteData}
                                  name="pickUpLocation"
                                  onSearch={(query) => handleOnSearch(query)}
                                  onChange={(selected) => {
                                    handleOnSelect(
                                      selected,
                                      "pickUpLocation",
                                      formikProps
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  value={values.pickUpLocation}
                                  onClick={() =>
                                    handleAutocompleteClick("pickUpLocation")
                                  }
                                  placeholder="Pick-up"
                                  icon="icon-location-on"
                                  isLoading={searchLoading}
                                  autoFocus={true}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* drop off point */}
                    {values.showDropOffPoint && (
                      <div className="col dropOfPoint dropOfPointCol">
                        <div className="inputStyle showDropdown input-for-mobile">
                          {!isMobile && (
                            <SearchAutocomplete
                              {...formikProps}
                              options={locationSearchAutoCompleteData}
                              name="dropOffLocation"
                              onSearch={(query) => handleOnSearch(query)}
                              onChange={(selected) =>
                                handleOnSelect(
                                  selected,
                                  "dropOffLocation",
                                  formikProps
                                )
                              }
                              value={values.dropOffLocation}
                              placeholder="Drop-off"
                              icon="icon-location-on"
                              isLoading={searchLoading}
                              errors={touched.dropOffLocation ? errors : {}}
                              drop={false}
                            />
                          )}

                          {isMobile && (
                            <div className="responsive-input">
                              <button
                                className="inputStyle grow1"
                                onClick={() => showResponsive("dropOffLocation")}
                                type="button"
                              >
                                {" "}
                                <i className="icon-location-on" />{" "}
                                {values?.dropOffLocation
                                  ? values.dropOffLocation[0]?.secondary_label  || JSON.parse(localStorage.getItem("CarsautoCompleteData"))?.secondary_label
                                  : "Drop-off"}
                              </button>
                            </div>
                          )}

                          {isResponsive && showAutocomplete.dropOffLocation && (
                            <div className="inputStyle showDropdown">
                              <div className="mobilePopup">
                                <Button className="closePopup">
                                  <span
                                    className="icon-close"
                                    onClick={() =>
                                      handleAutocompleteClick("dropOffLocation")
                                    }
                                  />
                                </Button>
                                <h4 className="inputPopTitle">
                                  Where are you going?
                                </h4>
                                <div className="dropdown-content">
                                  <SearchAutocomplete
                                    {...formikProps}
                                    options={locationSearchAutoCompleteData}
                                    name="dropOffLocation"
                                    onSearch={(query) => handleOnSearch(query)}
                                    onChange={(selected) =>
                                      handleOnSelect(
                                        selected,
                                        "dropOffLocation",
                                        formikProps
                                      )
                                    }
                                    onBlur={handleBlur}
                                    value={values.dropOffLocation}
                                    onClick={() =>
                                      handleAutocompleteClick("dropOffLocation")
                                    }
                                    placeholder="Drop-off"
                                    icon="icon-location-on"
                                    isLoading={searchLoading}
                                    errors={errors}
                                    autoFocus={true}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="return-car-col col">
                    <label className="customCheckbox">
                      <input
                        type="checkbox"
                        name="showDropOffPoint"
                        checked={values.showDropOffPoint}
                        value={values.showDropOffPoint}
                        onChange={() => {
                          formikProps.setFieldValue(
                            "showDropOffPoint",
                            !values.showDropOffPoint
                          );
                          formikProps.setFieldValue("dropOffLocation", "");
                        }}
                      />
                      <span>Return car at a different location</span>
                    </label>
                  </div>
                </div>

                <div className="BSrow">
                  <div className="col-3">
                    {!isMobile && (
                      <>
                        <div className="startEndDate">
                          <DateInput
                            {...formikProps}
                            placeholder="Pick-up date"
                            value={
                              values.pickUpDate &&
                              moment(values.pickUpDate).format("MM/DD/YYYY")
                            }
                            icon="icon-invitation"
                            name="pickUpDate"
                            onDateChange={(event) =>
                              handleDateChange(event, formikProps, `pickUpDate`)
                            }
                            focused={dateInputFocused?.pickUpDate}
                            onFocusChange={({ focused }) =>
                              handleFocusChange("pickUpDate", focused)
                            }
                            errors={errors}
                            touched={touched} 

                            isOutsideRange={(day) => {
                              return day.isBefore(moment().subtract(1, 'days'));
                            }}
                          />
                        </div>

                        {touched.pickUpDate && (
                          <div className="cars-invalid-feedback">
                            {errors.pickUpDate}
                          </div>
                        )}
                      </>
                    )}



                    {isMobile && (
                      <>
                        <div className="date-picker-type">
                          <div className="check-in-date date-picker-style">
                            <i className="icon-invitation" />
                            <Button
                              className="inputStyle grow1"
                              onClick={() => showDateResponsive("pickUpDate")}
                              type="button"
                              text={
                                values.pickUpDate
                                  ? moment(values.pickUpDate).format(
                                    "MM/DD/YYYY"
                                  )
                                  : "Pick Up Date"
                              }
                            />
                          </div>
                        </div>
                        {touched.pickUpDate && (
                          <div className="cars-invalid-feedback">
                            {errors.pickUpDate}
                          </div>
                        )}
                      </>
                    )}

                    {isMobile && isDateResponsive.pickUpDate && (
                      <div className="inputStyle showDropdown">
                        <div className="date-picker-popup ">
                          <Button className="closePopup">
                            <span
                              className="icon-close"
                              onClick={() => handleDateClick()}
                            />
                          </Button>

                          <h4 className="inputPopTitle">Pick-up date </h4>

                          <DateInput
                            {...formikProps}
                            isDateResponsive={isDateResponsive}
                            placeholder="Pick-up date"
                            value={
                              values.pickUpDate &&
                              moment(values.pickUpDate).format("MM/DD/YYYY")
                            }
                            icon="icon-invitation"
                            name="pickUpDate"
                            onDateChange={(event) =>
                              handleDateChange(event, formikProps, `pickUpDate`)
                            }
                            focused={dateInputFocused?.pickUpDate}
                            onFocusChange={({ focused }) =>
                              handleFocusChange("pickUpDate", focused)
                            }
                            errors={errors}
                            touched={touched}

                            isOutsideRange={(day) => {
                              const res = moment().add(0, "days");
                              return day.isBefore(moment(res).subtract(1, "days"));
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-3">
                    <div className="timeWrap inputStyle">
                      <span className="icon-timer"></span>
                      <SelectComponent
                        isComponents={false}
                        className="btnSml width180 toggleBtn"
                        name="pickUpTime"
                        placeholder="Pick-up time"
                        options={pickupTimeOptions}
                        onChange={(target) => {
                          {
                            handleSelectChange(target, "pickUpTime", formikProps);
                          }
                        }}
                        value={values.pickUpTime}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    {!isMobile && (
                      <div className="startEndDate">
                        <DateInput
                          {...formikProps}
                          placeholder="Drop-off date"
                          value={
                            values.dropOffDate &&
                            moment(values.dropOffDate).format("MM/DD/YYYY")
                          }
                          icon="icon-invitation"
                          name="dropOffDate"
                          onDateChange={(event) =>
                            handleDateChange(event, formikProps, `dropOffDate`)
                          }
                          focused={dateInputFocused?.dropOffDate}
                          onFocusChange={({ focused }) =>
                            handleFocusChange("dropOffDate", focused)
                          } 
                          isOutsideRange={(day) => {
                            return (day.isBefore(moment(values.pickUpDate).subtract(1,"days") ));
                          }}

                        />
                      </div>
                    )}
                    {touched.dropOffDate && (
                      <div className="cars-invalid-feedback">
                        {errors.dropOffDate}
                      </div>
                    )}

                    {isMobile && (
                      <>
                        <div className="date-picker-type">
                          <div className="check-in-date date-picker-style">
                            <i className="icon-invitation" />
                            <Button
                              className="inputStyle grow1"
                              onClick={() => showDateResponsive("dropOffDate")}
                              type="button"
                              text={
                                values.dropOffDate
                                  ? moment(values.dropOffDate).format(
                                    "MM/DD/YYYY"
                                  )
                                  : "Drop Off Date"
                              }
                            />
                          </div>
                        </div>
                        {touched.dropOffDate && (
                          <div className="cars-invalid-feedback ">
                            {errors.dropOffDate}
                          </div>
                        )}
                      </>
                    )}

                    {isMobile && isDateResponsive.dropOffDate && (
                      <div className="inputStyle showDropdown">
                        <div className="date-picker-popup ">
                          <IconButton
                            className="closePopup"
                            type="button"
                            icon="icon-close"
                            onClick={() => handleDateClick()}
                          />

                          <h4 className="inputPopTitle">Drop-off date </h4>

                          <DateInput
                            {...formikProps}
                            numberOfMonths={1}
                            isDateResponsive={isDateResponsive}
                            placeholder="Drop-off date"
                            value={
                              values.dropOffDate &&
                              moment(values.dropOffDate).format("MM/DD/YYYY")
                            }
                            icon="icon-invitation"
                            name="dropOffDate"
                            onDateChange={(event) =>
                              handleDateChange(
                                event,
                                formikProps,
                                `dropOffDate`
                              )
                            }
                            focused={dateInputFocused?.dropOffDate}
                            onFocusChange={({ focused }) =>
                              handleFocusChange("dropOffDate", focused)
                            }
                            isOutsideRange={(day) => {
                              const res = moment().add(0, "days"); 
                                return (day.isBefore(
                                  moment(values.pickUpDate).subtract(1, "days")
                                )
                                );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-3">
                    <div className="timeWrap inputStyle">
                      <span className="icon-timer"></span>
                      <SelectComponent
                        isComponents={false}
                        className="btnSml width180 toggleBtn"
                        name="dropOffTime"
                        placeholder="Drop-off time"
                        options={dropoffTimeOptions}
                        onChange={(target) => {
                          handleSelectChange(
                            target,
                            "dropOffTime",
                            formikProps
                          );
                        }}
                        value={values.dropOffTime}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="flex-col dropOfsection">
              </div> */}
            </div>
          </div>

          <a
            className="advancedSettings settingON"
            onClick={toggleAdvanceSetting}
          >
            <span>Advanced Settings</span>
            <i
              className={`fa ${advanceSettingVisibility ? "fa-angle-up" : "fa-angle-down"
                }`}
            ></i>
          </a>

          <div className="rightBtnSpace advancedSettingsBox">
            {advanceSettingVisibility && (
              <div>
                <div className="BSrow">
                  <div className="col-3">
                    <SelectComponent
                      isComponents={false}
                      className="btnSml width180 toggleBtn inputStyle noIconInput"
                      icon="fa fa-angle-down"
                      name="rentalCarCompany"
                      placeholder="Rental Car Company"
                      options={rentalCarCompanyList}
                      onChange={(value) => {
                        handleSelectChange(
                          value,
                          "rentalCarCompany",
                          formikProps
                        );
                      }}
                      value={values.rentalCarCompany}
                    />
                  </div>

                  <div className="col-3">
                    <SelectComponent
                      isComponents={false}
                      className="btnSml width180 toggleBtn inputStyle noIconInput"
                      icon="fa fa-angle-down"
                      name="carType"
                      placeholder="Car Type"
                      options={carTypeList}
                      onChange={(value) => {
                        handleSelectChange(value, "carType", formikProps);
                      }}
                      value={values.carType}
                    />
                  </div>

                  <div className="col-3">
                    <SelectComponent
                      isComponents={false}
                      className="btnSml width180 toggleBtn inputStyle noIconInput"
                      icon="fa fa-angle-down"
                      name="discountCouponCode"
                      placeholder="Discount coupon code"
                      options={discountTypeList}
                      onChange={(value) => {
                        handleSelectChange(
                          value,
                          "discountCouponCode",
                          formikProps
                        );
                      }}
                      value={values.discountCouponCode}
                    />
                  </div>
                </div>
                {showAdditionalDiscountOptions && (
                  <div className="BSrow">
                    <div className="col-3">
                      <div className="inputStyle noIconInput">
                        <input
                          type="text"
                          placeholder="Corporate ID"
                          value={values.corporateID}
                          name="corporateID"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="inputStyle noIconInput">
                        <input
                          type="text"
                          placeholder="Coupon Code"
                          value={values.couponCode}
                          name="couponCode"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="inputStyle noIconInput">
                        <input
                          type="text"
                          placeholder="Rate/Product Code"
                          value={values.productCode}
                          name="productCode"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="searchBtnWrap">
            <Button className="btn d-block" text="Search" type="submit" />
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={CarSearchValidation()}
        onSubmit={handleSubmit}
      >
        {renderForm}
      </Formik>
      {loading && <Loader />}
    </>
  );
};

export default CarsTab;