import { marketingSiteService } from "../../api/marketingSiteService";
import { marketingSiteConstants } from "../Constants/marketingSiteConstants";
import { commonActions } from "./commonActions";

export const marketingWebsiteAction =  {
    getWallletTransactionAction,
    getTransactionDetailsAction
}

function getWallletTransactionAction(walletId,payload) {
    return async (dispatch) => {
      dispatch(request(marketingSiteConstants.GET_WALLET_TRANSACTION_REQUEST));
      dispatch(commonActions.loaderText("Loading..."));
      dispatch(commonActions.showLoader());
      try {
        const response = await marketingSiteService.getWallletTransactionService(walletId,payload);
        dispatch(commonActions.hideLoader());
        if (response.status == 200)
          dispatch(success(marketingSiteConstants.GET_WALLET_TRANSACTION_SUCCESS, response));
        return response;
      } catch (error) {
        dispatch(failure(marketingSiteConstants.GET_WALLET_TRANSACTION_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      }
    };
  }
function getTransactionDetailsAction(transactionId) {
    return async (dispatch) => {
      dispatch(request(marketingSiteConstants.GET_WALLET_TRANSACTION_DETAILS_REQUEST));
      dispatch(commonActions.loaderText("Loading..."));
      dispatch(commonActions.showLoader());
      try {
        const response = await marketingSiteService.getTransactionDetailsService(transactionId);
        dispatch(commonActions.hideLoader());
        if (response.status == 200)
          dispatch(success(marketingSiteConstants.GET_WALLET_TRANSACTION_DETAILS_SUCCESS, response));
        return response;
      } catch (error) {
        dispatch(failure(marketingSiteConstants.GET_WALLET_TRANSACTION_DETAILS_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      }
    };
  }

  


  function request(action, payload) {
    return { type: action, payload };
  }
  
  function success(action, response) {
    return { type: action, response };
  }
  
  function failure(action, error) {
    return { type: action, error };
  }