import * as Yup from "yup";

export const CarCheckoutValidation = () => {
  const phoneRegExp = /^[0-9\b]+$/;
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,7}$/;

  // Validation for affiliate premium membership

  return Yup.object().shape({
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    email: Yup.string("")
      .email("Enter a valid email address.")
      .required("Required"),
    phoneNumber: Yup.string("")
      .matches(phoneRegExp, "Enter a valid number.")
      .length(10, "Enter 10 digit phone number")
      .matches(/^[0-9-+()]*$/, "Only Numbers are allowed")
      .required("Required"),
    addressLine1: Yup.string("").required("Required"),
    addressLine2: Yup.string("").required("Required"),
    city: Yup.string("").required("Required"),
    state: Yup.string("").when("stateAvailable", {
      is: true,
      then: Yup.string().required("Required")
    }),
    // zipCode: Yup.string("")
    //   .matches(zipCodeRegExp, "Enter valid zip code")
    //   .required("Required"),
    // zipCode: Yup.string("").required("Required").matches(/^([a-zA-Z0-9]){3,7}$/,"Invalid zip code"),
    // zipCode: Yup.string("").required("Required").min(3).max(10),
    zipCode: Yup.string("").required("Required").min(3, "Invalid zip code").max(10),
    nationality: Yup.string("").required("Required"),
    country: Yup.string("").required("Required"),

    yesNo: Yup.string(""),
    cardType: Yup.string("")
      .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required"):field),
    cardHolderName: Yup.string("")
      .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required"):field),
    cardHolderName: Yup.string("")
      .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required"):field),
    cardNumber: Yup.string("")
      .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required"):field),
    card_mmyy: Yup.string("")
      .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required"):field),
    securityCode: Yup.string("")
      .when('yesNo', (yesNo, field) => yesNo ==="Yes"?field.required("Required"):field),

  });
};
