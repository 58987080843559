import http from "./http";

function searchFlightService(payload, use_search_id) {
  return http
    .post(`https://dev-flights.travelsaving.com/api/flight/search`, {     // node
      ...payload,
      params: {
        use_flight_search_id: use_search_id ? true : false,
      },
    })
    .then((response) => response);
}
 

function cancelledBooking(id) {
  return http.get(`http://18.219.251.91:3000/api/flight/cancel/3a55ff3a-3889-4576-8e5b-712c7f0e6c1c`, {
    // params: { use_base_hotel_url: true },
  });
}

function searchFlightPollingService(payload) {
  return http
    .post(
      `https://dev-flights.travelsaving.com/flights/search-v2/cabin-prices`,
      {
        ...payload,
        params: {
          flight_search_key: true,
          flight_search_label: "flight-search-key",
          headerName: "search-id-2",
        },
      }
    )
    .then((response) => response);
}

function validateFlight(payload) {
  return http.post(
    `https://dev-flights.travelsaving.com/api/flight/revalidate`,   //node
    {
      ...payload,
      params: {
        use_flight_search_id: true
      },
    }
  );
}

function checkoutFlightService(payload) {     // flight book
  return http
    .post(
      `https://dev-flights.travelsaving.com/api/flight/book`,     // node
      {
        ...payload,
        params: {
          use_flight_search_id: true
        },
      }
    )
    .then((response) => response);
}

function getFlightBookingDetailsService(id) {
  return http
    .get(`https://dev-flights.travelsaving.com/api/flight/bookings/details/${id}`)
    .then((res) => res);
}

function getStripeIntent(payload) {
  return http.post(`https://dev-flights.travelsaving.com/api/flight/payment/initiate`, {    // node 
    ...payload,
    params: {
      use_flight_search_id: true
    }
  });
}

function downloadPdf(id) {
  return http.get(`flight-reciept-download-api`, {   // replace with flight api
    params: {
      use_base_hotel_url: true,
    },
  });
}



 

export const flightService = {
  searchFlightService,
  searchFlightPollingService,
  validateFlight,
  checkoutFlightService,
  getFlightBookingDetailsService,
  getStripeIntent,
  cancelledBooking,
  downloadPdf
};
