import * as Yup from "yup";

export const ProfileValidation = () => {
  const phoneRegExp = /^[0-9\b]+$/;
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,7}$/;

  // Validation for membership premium checkout

  return Yup.object().shape({
    title: Yup.string("").required("required"),
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    phoneInputValue: Yup.string("")
      //   .matches(phoneRegExp, 'Enter a valid number.'),
      // .length(10, 'Enter 10 digit phone nubmer')
      .required("Required"),
    country: Yup.string("").required("Required"),
    address_line_1: Yup.string("").required("Required"),
    // address_line_2: Yup.string('').required('Required'),
    city: Yup.string("").required("Required"),
    state: Yup.string("").when("stateAvailable", {
      is: true,
      then: Yup.string().required("Required")
    }),
    // zipCode: Yup.string("").required("Required").matches(/^([a-zA-Z0-9]){3,7}$/,"Invalid zip code"),
    zipCode: Yup.string("").required("Required").min(3, "Invalid zip code").max(10),
   
    // zip: Yup.string("")
    //   .required("Required")
    //   .matches(zipCodeRegExp, "Enter valid zip code"),
  });
};

export const updatePasswordValidation = () => {
  return Yup.object().shape({
    old_password: Yup.string("")
      .min(8, "Password is too short - should be 8 characters minimum.")
      .max(16, "Password is too long")
      .required("Please enter valid password"),
    password: Yup.string("")
      .min(8, "Password is too short - should be 8 characters minimum.")
      .max(16, "Password is too long")
      .required("Please enter valid password"),
    password_confirmation: Yup.string("")
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .min(8, "Password is too short - should be 8 characters minimum.")
      .max(16, "Password is too long")
      .required("Please enter valid password"),
  });
};

export const addUserValidation = () => {
  const phoneRegExp = /^[0-9\b]+$/;
  return Yup.object().shape({
    title: Yup.string("").required("Required"),
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    gender: Yup.string("").required("Required").nullable(),
    phone: Yup.string("").required("Required"),
    email: Yup.string("")
      .email("Enter a valid email address.")
      .required("Required"),
    birthday: Yup.string("").required("Required").nullable(),
    country: Yup.string("").required("Required"),
    passportNo: Yup.string("")
      .required("Required")
      .min(8, "Enter atleast 8 characters")
      .max(12, "Enter atmost 12 characters")
      .nullable(),
    expiryDate: Yup.string("").required("Required"),
  });
};
