import React from "react";
import InfoBox from "./InfoBox";
import DateNTime from "./DateNTime";
import PriceDetails from "./PriceDetails";

function FllightDetails(props) {
  const {
    booking_reference_id,
    flight_type,
    flights,
    guest_count,
    price,
    id,
    status,
  } = props;

  const flightTripType = (flightType, sideBar = false) => {
    if (flightType === "round-trip") {
      return !sideBar ? "Round trip flight" : "Round Trip";
    } else if (flightType === "one-way") {
      return !sideBar ? "One way flight" : "One Way";
    } else if (flightType === "multi-city") {
      return !sideBar ? "Multi city flight" : "Multi City";
    }
  };

  return (
    <div className="card2">
      <div className="dflex alignFlexStart">
        <div className="iconWrapMain">
          <div className="iconWrap">
            <span className="icon-flight">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </div>

          <span className="booking-status">{status}</span>
        </div>

        <div className="cardContent">
          <h3 className="cartTitle">
            {flights &&
              `${flights[0].depart_city} (${flights[0].depart_airport})`}

            <span className="arrow">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="25.000000pt"
                height="12.000000pt"
                viewBox="0 0 25.000000 12.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,12.000000) scale(0.100000,-0.100000)"
                  fill="#1d6ec9"
                  stroke="none"
                >
                  <path
                    d="M30 85 l-23 -25 23 -25 c28 -29 51 -34 25 -5 -18 20 -17 20 70 20 87
                        0 88 0 70 -20 -26 -29 -3 -24 25 5 l23 25 -23 25 c-28 29 -51 34 -25 5 18 -20
                        17 -20 -70 -20 -87 0 -88 0 -70 20 26 29 3 24 -25 -5z"
                  />
                </g>
              </svg>
            </span>

            {flights && flight_type === "round-trip"
              ? `${flights[0].arrival_city} (${flights[0].arrival_airport})`
              : `${flights[flights.length - 1].arrival_city} (${
                  flights[flights.length - 1].arrival_airport
                })`}
          </h3>

          <div className="dflex stretch">
            <div className="innerCnt">
              <div className="topSection dflex justifyStart copyText">
                <span className="arrow mobileShow">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="12.000000pt"
                    viewBox="0 0 25.000000 12.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,12.000000) scale(0.100000,-0.100000)"
                      fill="#7e94a8"
                      stroke="none"
                    >
                      <path
                        d="M30 85 l-23 -25 23 -25 c28 -29 51 -34 25 -5 -18 20 -17 20 70 20 87
                        0 88 0 70 -20 -26 -29 -3 -24 25 5 l23 25 -23 25 c-28 29 -51 34 -25 5 18 -20
                        17 -20 -70 -20 -87 0 -88 0 -70 20 26 29 3 24 -25 -5z"
                      />
                    </g>
                  </svg>
                </span>
                {flightTripType(flight_type)}
              </div>

              <div className="flightStart">
                <div className="smlText">
                  Departure Flight: {flights && flights[0]?.airline_name}
                </div>

                <div className="cardCol no-gutters">
                  <DateNTime
                    label={flights[0]?.depart_airport}
                    date={flights[0].depart_date?.split(" ")[0]}
                    time={flights[0].depart_date?.split(" ")[1]}
                  />
                  <span className="icon-flight">
                    <span className="path1"></span>
                  </span>
                  <DateNTime
                    label={flights[0]?.arrival_airport}
                    date={flights[0].arrival_date?.split(" ")[0]}
                    time={flights[0].arrival_date?.split(" ")[1]}
                  />
                </div>
              </div>

              {flight_type !== "one-way" && (
                <div className="flightEnd">
                  <div className="smlText">
                    Departure Flight :
                    {flights && flights[flights.length - 1]?.airline_name}
                  </div>
                  <div className="cardCol no-gutters">
                    <DateNTime
                      label={flights[flights.length - 1].depart_airport}
                      date={
                        flights[flights.length - 1].depart_date?.split(" ")[0]
                      }
                      time={
                        flights[flights.length - 1].depart_date?.split(" ")[1]
                      }
                    />
                    <span className="icon-flight">
                      <span className="path1"></span>
                    </span>
                    <DateNTime
                      label={flights[flights.length - 1]?.arrival_airport}
                      date={
                        flights[flights.length - 1].arrival_date?.split(" ")[0]
                      }
                      time={
                        flights[flights.length - 1].arrival_date?.split(" ")[1]
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="infoBox">
              <div className="dflex mb-10 bookingIdWrap">
                <span className="font14 lightText">Booking ID:</span>
                <span className="font14 darkText">{booking_reference_id}</span>
              </div>
              <div className="keyValueWrap">
                <div>
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-group font12"
                    detail1={"People:"}
                    detail2={`${guest_count}`}
                  />
                  <InfoBox
                    divClassName={"mb-5"}
                    iconName="icon-cabin"
                    detail1={"Cabin Class:"}
                    detail2={"Economy"}
                  />
                  <InfoBox
                    iconName={"icon-flight font17"}
                    detail1="Flight:"
                    detail2={flightTripType(flight_type, true)}
                  />
                </div>
                <PriceDetails
                  details={"Total:"}
                  price={`${price}`}
                  text="View & Manage"
                  nextPage={`/flight-confirmation/${id}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FllightDetails;
