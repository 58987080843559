import React from "react";
import { Link } from "react-router-dom";

//price details of hotel, car and flight

function PriceDetails({ details, price, text, nextPage }) {
  return (
    <div className="centerAlign">
      <div className="topBorder">
        <i className="icon-info"></i>
        <span className="lightText font14">{details}</span>
        <span className="font17 darkText">${price}</span>
      </div>
      <Link to={nextPage} className="btn">
        {text}
      </Link>
    </div>
  );
}

export default PriceDetails;
