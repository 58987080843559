import * as Yup from 'yup';

export const NewUserPremiumCheckoutValidation = () => {
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,7}$/;
  const cvvRegExp = /^[0-9]{3,4}$/;
  const cardNumberRegExp = /^[0-9]{14,17}$/;
  const mmyyFormatRegExp = /([0-9]{2})\/([0-9]{2})/;

  // Validation for membership premium checkout

  return Yup.object().shape({
    nationality: Yup.string('').required('Required'),
    addressOne: Yup.string('').required('Required'),
    addressTwo: Yup.string(''),
    city: Yup.string('').required('Required'),
    state: Yup.string('').required('Required'),
    zipCode: Yup.string('').min(4, 'Invalid Zip Code').max(6, 'Invalid Zip Code').required('Required'),
      promoCode : Yup.string('').when("hasPromo", {
        is: true,
        then: Yup.string().required("Promo/Gift Code is Required ")
      }), 
    terms : Yup.boolean().oneOf([true],'Required'),
    
      //card
      cardHolderName: Yup.string('').required('Required').matches(/^[aA-zZ\s]+$/, 'Must only have alphabet letters'),
      security_code : Yup.string('').required('Required').matches(cvvRegExp, 'Enter a valid security code'),
      exp_month : Yup.string(''),
      exp_year : Yup.string(''),
      billing_zip : Yup.string(''),
      credit_number : Yup.string('').required('Required').min(15).max(16).matches(cardNumberRegExp, 'Enter a valid card number.'),
      billing_country : Yup.string(''),
      mmyy :  Yup.string('').required('Required').min(5)

  });
};


 