import { commonConstants } from "../Constants/commonConstants";
import { autoCompleteConstants } from "../Constants/siteConstants/autocompleteConstants";

const initialState = {
  loader: false,
  error: {},
  autoCompleteData: [],
  autoCompleteFlightFromData: [],
  autoCompleteFlightToData: [],
  searchLoading: false,
  loaderText: "Searching",
  getGuestData: null,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonConstants.SHOW_LOADER:
      return { ...state, loader: true };
    case commonConstants.HIDE_LOADER:
      return { ...state, loader: false };
    case commonConstants.SHOW_LOADER_TEXT:
      return { ...state, loader: true, loaderText: action.response };

    case autoCompleteConstants.AUTO_COMPLETE_REQUEST:
      return { ...state, autoCompleteData: [], searchLoading: true };
      
    case autoCompleteConstants.AUTO_COMPLETE_RESULT:
      // localStorage.setItem("autoCompleteData",JSON.stringify(action.response.data.data))
      action.response.data.data.map(ele => {
        ele.label = ele.full_name
        ele.secondary_label = ele.full_name
      })
      return {
        ...state,
        error: {},
        autoCompleteData: action.response.data.data,
        searchLoading: false,
      };

    case autoCompleteConstants.CARS_AUTO_COMPLETE_RESULT:
      return {
        ...state,
        error: {},
        autoCompleteData: action.response.data.data,
        searchLoading: false,
      };

    case autoCompleteConstants.AUTO_COMPLETE_FAILURE:
      return {
        ...state,
        error: action.error.message,
        autoCompleteData: [],
        searchLoading: false,
      };

    case autoCompleteConstants.AUTO_COMPLETE_FROM_REQUEST:
      return { ...state, searchLoading: true, autoCompleteFlightFromData: [] };
    case autoCompleteConstants.AUTO_COMPLETE_FROM_SUCCESS:
      return {
        ...state,
        searchLoading: false,
        error: {},
        autoCompleteFlightFromData: action.response.data,
      };
    case autoCompleteConstants.AUTO_COMPLETE_TO_REQUEST:
      return { ...state, searchLoading: true, autoCompleteFlightToData: [] };
    case autoCompleteConstants.AUTO_COMPLETE_TO_SUCCESS:
      return {
        ...state,
        searchLoading: false,
        error: {},
        autoCompleteFlightToData: action.response.data,
      };
    case autoCompleteConstants.AUTO_COMPLETE_RESET_REQUEST:
      return {
        ...state,
        autoCompleteFlightToData: [],
        autoCompleteFlightFromData: [],
      };

      case commonConstants.GET_GUEST_LIST_REQUEST:
        return { ...state, getGuestData: null };
        
      case commonConstants.GET_GUEST_LIST_SUCCESS:
        
        return { ...state, getGuestData : action.response.data.data, guestListOptions: action.response.data.guestList };

      case commonConstants.GET_GUEST_LIST_FAILURE:
        return { ...state, getGuestData:null };


    default:
      return state;
  }
};
export default commonReducer;