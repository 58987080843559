import { times } from "lodash";

import moment from "moment";
import leftRightArrow from "../../Assets/img/left-right-arrow2.png";

const FlightInfo = ({ revalidatedFlightData }) => {
  const currencyAndLanguage =
    (localStorage.getItem("currencyAndLanguage") &&
      JSON.parse(localStorage.getItem("currencyAndLanguage"))) ||
    {};

  const currencySymbol =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency?.symbol || "$";

  const flightsWidget =
    localStorage.getItem("flightPayload") &&
    JSON.parse(localStorage.getItem("flightPayload"));

  const price = revalidatedFlightData?.price;

  const flight =
    localStorage.getItem("selectedLegs") &&
    JSON.parse(localStorage.getItem("selectedLegs"));

  const getOccupancy = () => {
    var adult = "",
      children = "",
      infants = "";
    if (flightsWidget.adults > 1) {
      adult = flightsWidget.adults + " Adults";
    } else if (flightsWidget.adults === 1) {
      adult = flightsWidget.adults + " Adult";
    }
    if (flightsWidget.children > 0 && flightsWidget.children === 1) {
      children = flightsWidget.children + " Child";
    } else if (flightsWidget.children > 1) {
      children = flightsWidget.children + " Children";
    }
    if (flightsWidget.infants > 0 && flightsWidget.infants === 1) {
      infants = flightsWidget.infants + " Infant";
    } else if (flightsWidget.infants > 1) {
      infants = flightsWidget.infants + " Infants";
    }

    return adult + children + infants;
  };

  const formatFlightDurationTime = (num) => {
    let hours = num / 60;
    let fHours = Math.floor(hours);
    let minutes = (hours - fHours) * 60;
    let rMinutes = Math.round(minutes);
    return `${fHours}h ${rMinutes}m`;
  };

  const currentGeoLocation =
    localStorage.getItem("currentGeoLocation") &&
    JSON.parse(localStorage.getItem("currentGeoLocation"));

  const timeFormat = currentGeoLocation === "US" ? "hh:mm a" : "HH:MM";

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";


    const flightName = ()=>{
      console.warn("flight type", flightsWidget.searchType);
      if(flightsWidget.searchType == "round-trip"){
        return "Round-Trip Flight"
      }else if(flightsWidget.searchType == "one-way"){
        return "One-Way Flight"
      }else if(flightsWidget.searchType == "multi-city"){
        return "Multi-City Flight"
      }
    }

  return (
    <>
      <div className="flightInfo-1">
        <div className="flightLogo">
          {
            <img
              src={flight[0]?.flightLegs[0]?.operatingAirlineLogoUrl}
              alt={flight[0]?.flightLegs[0]?.operatingAirlineName}
              title={flight[0]?.flightLegs[0]?.operatingAirlineName}
            />
          }
        </div>

        <div className="labelNvalue">
          <label className="labelStyle">
            {flightName()} 
          </label>
          <div className="valueStyle">
            <>
              <span className="icon-location"></span>
              {flight[0]?.flightLegs[0]?.departureAirportCity} (
              {flight[0]?.flightLegs[0]?.departureAirportCode}){" "}
              <span className="arrow">
                <img src={leftRightArrow} alt="arrow" />
              </span>
              {flightsWidget.searchType === "one-way" && (
                <>
                  {
                    flight[flight.length - 1].flightLegs[
                      flight[flight.length - 1].flightLegs.length - 1
                    ].departureAirportCity
                  }{" "}
                  (
                  {
                    flight[flight.length - 1].flightLegs[
                      flight[flight.length - 1].flightLegs.length - 1
                    ].departureAirportCode
                  }
                  )
                </>
              )}
              {flightsWidget.searchType === "round-trip" && (
                <>
                  {flight[flight.length - 1].flightLegs[0].departureAirportCity}{" "}
                  (
                  {flight[flight.length - 1].flightLegs[0].departureAirportCode}
                  )
                </>
              )}
              {flightsWidget.searchType === "multi-city" && (
                <>
                  {
                    flight[flight.length - 1].flightLegs[
                      flight[flight.length - 1].flightLegs.length - 1
                    ].departureAirportCity
                  }{" "}
                  (
                  {
                    flight[flight.length - 1].flightLegs[
                      flight[flight.length - 1].flightLegs.length - 1
                    ].departureAirportCode
                  }
                  )
                </>
              )}
            </>
          </div>
        </div>

        <div className="labelNvalue">
          <label className="labelStyle">
            {flightsWidget.searchType === "one-way"
              ? "Departing"
              : "Departing — Returning"}
          </label>

          <div className="valueStyle">
            <span className="icon-invitation"></span>
            {moment(flight[0].flightLegs[0].departureDateTime).format(
              dateDisplayFormat
            )}

            {flightsWidget?.searchType !== "one-way" && (
              <>
                {" — "}
                {moment(
                  flight[flight?.length - 1]?.flightLegs[
                    flight[flight?.length - 1]?.flightLegs?.length - 1
                  ]?.departureDateTime
                )?.format(dateDisplayFormat)}
              </>
            )}
          </div>
        </div>

        <div className="labelNvalue">
          <label className="labelStyle">Cabin class & Travelers</label>
          <div className="valueStyle">
            <span className="icon-person">
              <span className="path1"></span>
            </span>
            {` ${getOccupancy()}, `}
            {flight[0].flightLegs[0].cabinClassName}
          </div>
        </div>
      </div>

      <div className="flightInfoRow flex-wrap">
        {flight?.map((leg, index) => {
          const lastIndex = leg?.flights?.length - 1;
          return (
            <div className="flightStartEnd" key={index}>
              <div className="flightStartInfo">
                <div className="flgithDate">
                  {moment(leg?.flightLegs[0]?.departureDateTime).format(
                    "ddd,D MMM"
                  )}
                </div>
                <div className="flight-time">
                  <div className="flightTime">
                    {moment(leg?.flightLegs[0]?.departureDateTime).format(
                      timeFormat
                    )}
                  </div>

                  <div className="flightLocation">
                    {leg?.flightLegs[0]?.departureAirportCode}
                  </div>
                </div>
              </div>

              <div className="flightDurNstop">
                <div className="flightDuration">
                  <i className="icon-timer"></i>{" "}
                  {formatFlightDurationTime(leg?.duration)}
                </div>

                <div className="flightBar">
                  <i className="fa fa-circle"></i>
                  <span className="icon-flight">
                    <span className="path1"></span>
                  </span>
                </div>

                <div className="flgithStops">
                  {leg?.numberOfStops == 0
                    ? "Nonstop"
                    : `${leg.numberOfStops} ${
                        leg.numberOfStops < 2 ? "stop" : "stops"
                      } (${leg.stops.map((item) => item.iataCode)})`}
                </div>
              </div>

              <div className="flightEndInfo">
                <div className="flgithDate">
                  {moment(
                    leg?.flightLegs[leg.flightLegs.length - 1]?.arrivalDateTime
                  ).format("ddd,D MMM")}
                </div>
                <div className="flight-time">
                  <div className="flightTime">
                    {moment(
                      leg?.flightLegs[leg.flightLegs.length - 1]
                        ?.arrivalDateTime
                    ).format(timeFormat)}
                  </div>

                  <div className="flightLocation">
                    {
                      leg?.flightLegs[leg.flightLegs.length - 1]
                        ?.arrivalAirportCode
                    }
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="shadowBox">
        <div className="dflex">
          <label className="labelStyle">Base Fare:</label>
          <span className="rightValue">
            {currencySymbol}
            {price?.price?.basePrice}
          </span>
        </div>

        <div className="dflex">
          <label className="labelStyle">Fees and Taxes:</label>
          <span className="rightValue">
            {currencySymbol}
            {price?.price?.tax}
          </span>
        </div>

        <div className="dflex totalValueBox">
          <label className="labelStyle">Total with Tax:</label>
          <span className="rightValue">
            {" "}
            {currencySymbol}
            {price?.price?.totalPrice}
          </span>
        </div>
      </div>
    </>
  );
};

export default FlightInfo;