import axios from "axios";
import { serialize } from "../Util/util";
import http from "./http";

function getAutoCompleteHotelSearch(payload) {
  return http
    .get(
      `https://dev-hotels.travelsaving.com/api/v2/autosuggest/v3?keyword=${payload}`
    )
    .then((response) => response);
}
function getAutoCompleteFlightSearch(payload) { 
  return http
    .get( 
      `https://dev-flights.travelsaving.com/api/autocomplete?keyword=${payload}`    // node
    )
    .then((response) => response);
}

function getAutoCompleteCarSearch(payload) {
  const qs = serialize(payload);
  return http
    .get(`${process.env.REACT_APP_BASE_URL_CARS}/car/getAutoComplete?${qs}`)
    .then((response) => response);
}

function getGuestList(user_id) {
  return http
    .get(`${process.env.REACT_APP_BASE_URL_USER}/api/v1/users/${user_id}/guest`)
    .then((response) => response);
}

function getGeoLocationBasedOnIpAddress() {
  return http.get(
    `${process.env.REACT_APP_IPSTACK_BASE_URL}?access_key=${process.env.REACT_APP_IPSTACK_ACCESS_KEY}`
  );
}

export const autoComplete = {
  getAutoCompleteHotelSearch,
  getAutoCompleteFlightSearch,
  getAutoCompleteCarSearch,
};

export const commonService = {
  getGuestList,
  getGeoLocationBasedOnIpAddress,
};