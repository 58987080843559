import React, { useState, useEffect } from "react";
import SelectFieldOption from "./FormComponent/SelectFieldOption";
import InputField from "./FormComponent/InputField";
import Checkbox from "./FormComponent/Checkbox";
import CustomPhoneInput from "../Component/PhoneInput";

//Primary Contact Information Section
import { checkoutCountries, countries } from "../Data/static.service";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { hotelActions } from "../Stores/Actions";
//Primary Contact Information Section

function PrimaryContactInformation(props) {
  const [memberIdError, setMemberIdError] = useState(null);
  const getGuestData = useSelector((state) => state.commonReducer.getGuestData);

  const getAffiliateUserData = useSelector(
    (state) => state.affiliateReducer?.getAffiliateUserData
  );
  const {
    formikProps,
    checkedBoxValue,
    handleCheckbox,
    handleCustomChange,
    numberCustomChange,

    user,
    userLabel,
    userOptions,
    userPlaceholder,
    hideUserSelect,

    AutoFill,
    memberIdPlaceholder,

    title,
    titleLabel,
    titleOptions,
    titlePlaceholder,
    disableTitle,

    firstName,
    firstNameLabel,
    firstNamePlaceholder,
    disableFirstName,

    lastName,
    lastNameLabel,
    lastNamePlaceholder,
    disableLastName,

    email,
    emailLabel,
    emailPlaceholder,
    disableEmail,

    disablePhone,

    nationality,
    nationalityPlaceholder,
    nationalityLabel,
    nationalityOptions,

    addressOne,
    addressOneLabel,
    addressOnePlaceholder,

    addressTwo,
    addressTwoLabel,
    addressTwoPlaceholder,

    city,
    cityLabel,
    cityPlaceholder,

    state,
    stateLabel,
    statePlaceholder,
    stateOptions,
    stateDisable,

    zipCode,
    zipCodeLabel,
    zipCodePlaceholder,

    hasPromo,
    onChange,
    showPromo,

    promoCode,
    promoCodeLabel,
    promoCodePlaceholder,

    password,
    passwordLabel,
    passwordPlaceholder,

    confirmPassword,
    confirmPasswordLabel,
    confirmPasswordPlaceholder,

    terms,
    ApplyPromo,
    isPromoAppliedMsg,
    // Coupon Code
    showCoupon,
    hasCoupon,
    handleCheckboxChange,
    couponCode,
    couponCodeLabel,
    couponCodePlaceholder,
    isCouponAppliedMsg,
    ApplyCoupon,
    isUpgrade
  } = props;

  const { values, errors, touched, handleChange, handleBlur } = formikProps;

  const countryOptions = countries.map((ele, index) => {
    return { value: ele.sortname, label: ele.name, id: ele.id };
  });

  const optionsTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Neutral.", label: "Neutral." },
  ];

  const [stateOption, setStateOption] = useState([]);

  const dispatch = useDispatch();

  const countrySelect = (e) => {
    const stateSelect = checkoutCountries
      ?.find((ele) => ele["alpha-2"] === e?.value)
      ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
    if (stateSelect && !isEmpty(stateSelect)) {
      setStateOption(stateSelect);
      formikProps.setFieldValue("stateAvailable", true);
    } else {
      setStateOption([]);
      formikProps.setFieldValue("stateAvailable", false);
    }
  };

  useEffect(() => {
    // handleValidateZip(null, formikProps)
  }, [])
  useEffect(() => {
    countrySelect({ value: values.nationality })
  }, [values.nationality])

  const handleValidateZip = (e, formikProps) => {
    if (e) {
      formikProps?.setFieldValue("zipCode", e.target.value);
    }

    if (e?.target?.value?.length >= 4 || values.zipCode.length >= 5) {
      dispatch(hotelActions.validateZip(e?.target?.value || values.zipCode)).then((res) => {
        if (res) {
          const country = countries.find(
            (ele) => ele.sortname === res.data.country_short_name
          );
          formikProps.setFieldValue("nationality", country.sortname);

          const states = checkoutCountries
            .find((ele) => ele["alpha-2"] === res.data.country)
            ?.states.map((ele) => ({ value: ele.code, label: ele.name }));
          setStateOption(states);

          const stateOfCountry = checkoutCountries
            .find((ele) => ele["alpha-2"] === res.data.country)
            ?.states.find((ele) => ele.code === res.data.short_name);

          formikProps.setFieldValue("state", stateOfCountry.code);
        }
      });
    }
  };

  useEffect(() => {
    if (!getAffiliateUserData || getAffiliateUserData.status != 200 || !getAffiliateUserData?.data?.user_address?.country) return;

    const country = countries?.find(
      (ele) =>
        ele?.sortname === getAffiliateUserData?.data?.user_address?.country
    );
    formikProps.setFieldValue("nationality", country?.sortname);

    const states = checkoutCountries
      ?.find(
        (ele) =>
          ele["alpha-2"] === getAffiliateUserData?.data?.user_address?.country
      )
      ?.states.map((ele) => ({ value: ele?.code, label: ele?.name }));
    setStateOption(states);

    const stateOfCountry = checkoutCountries
      ?.find(
        (ele) =>
          ele["alpha-2"] === getAffiliateUserData?.data?.user_address?.country
      )
      ?.states.find(
        (ele) => ele?.code === getAffiliateUserData?.data?.user_address?.state
      );

    formikProps.setFieldValue("state", stateOfCountry?.code);
  }, [getAffiliateUserData]);

  useEffect(() => {
    if (isUpgrade && getAffiliateUserData?.status == 200 &&
      (getAffiliateUserData?.data?.type_id !== process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID &&
        getAffiliateUserData?.data?.type_id !== process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID_LIFETIME
      )
    ) {
      setMemberIdError("Please upgrade your account to Premium Plus Membership to become an Affiliate.");
      return;
    } else if (getAffiliateUserData?.status == 200) {
      setMemberIdError(null);
      return;
    }
    setMemberIdError(getAffiliateUserData?.data?.message);
  }, [getAffiliateUserData]);
  const stateSelect = (e) => { };

  const localUser = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="card2 card-primary-contact">
      <div className="sectionTitle">
        <h2>Primary Contact Information</h2>
        {userOptions && !hideUserSelect && (
          <>
            <label className="labelStyle select-user-label">
              Select User (Optional)
            </label>
            <div className="selectStyle">
              <div className="selectInWrap">
                <SelectFieldOption
                  name={user}
                  // label={userLabel}
                  value={values.user}
                  options={userOptions}
                  onCustomChange={handleCustomChange}
                  onBlur={handleBlur}
                  error={errors.user}
                  touched={touched.user}
                  formikProps={formikProps}
                  isSearchable={false}
                />
              </div>
            </div>
          </>
        )}

        {AutoFill && (
          <>
            <label className="labelStyle select-user-label">
              Select User (Optional)
            </label>
            <div className="selectStyle">
              <div className="selectInWrap">
                <InputField
                  name={"memberId"}
                  type="text"
                  label={"Your Tripello Member Number"}
                  placeholder={memberIdPlaceholder || "Your Tripello Member Number"}
                  error={errors?.memberId || memberIdError}
                  value={values?.memberId}
                  touched={true}
                  onChange={(e) => {
                    setMemberIdError(null);
                    formikProps.setFieldValue("memberId", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    if (values?.memberId.length > 1) AutoFill(values.memberId);
                  }}
                  className="member-number-box "
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row primaryInfo mobileViewDisplayNone">
        <SelectFieldOption
          name={title}
          label={titleLabel}
          value={values.title}
          options={titleOptions || optionsTitle}
          placeholder={titlePlaceholder}
          onChange={handleCustomChange}
          onBlur={handleBlur}
          error={errors.title}
          touched={touched.title}
          formikProps={formikProps}
          disabled={disableTitle}
          isSearchable={false}
        />

        <InputField
          name={`firstName`}
          type="text"
          placeholder={"First Name"}
          label={firstNameLabel}
          value={values.firstName}
          error={errors.firstName}
          touched={touched.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          className="fName"
          disabled={disableFirstName}
        />

        <InputField
          name={lastName}
          label={lastNameLabel}
          type="text"
          placeholder={"Last Name"}
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          className="lName"
          disabled={disableLastName}
        />
      </div>
      <div className="primaryInfo desktopViewDisplayNone">
        <div className="row">
          <div className="col formGroup">
            <SelectFieldOption
              name={title}
              label={titleLabel}
              value={values.title}
              options={titleOptions || optionsTitle}
              placeholder={titlePlaceholder}
              onChange={handleCustomChange}
              onBlur={handleBlur}
              error={errors.title}
              touched={touched.title}
              formikProps={formikProps}
              disabled={disableTitle}
              isSearchable={false}
            />
          </div>
          <div className="col formGroup">
            <InputField
              name={firstName}
              type="text"
              placeholder={"First Name"}
              label={firstNameLabel}
              value={values.firstName}
              error={errors.firstName}
              touched={touched.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              className="fName"
              disabled={disableFirstName}
            />
          </div>
        </div>
        <div className="row">
          <div className="col formGroup">
            <InputField
              name={lastName}
              label={lastNameLabel}
              type="text"
              placeholder={"Last Name"}
              value={values.lastName}
              error={errors.lastName}
              touched={touched.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              className="lName"
              disabled={disableLastName}
            />
          </div>
        </div>
      </div>
      <div className="row email-section">
        <div className="formGroup">
          <InputField
            name={email}
            label={emailLabel}
            type="email"
            placeholder={emailPlaceholder}
            value={values.email}
            error={errors.email}
            touched={touched.email}
            disabled={disableEmail}
            onChange={(e) => {
              handleCustomChange(formikProps, email, e.target.value);
            }}
            onBlur={handleBlur}
          />
        </div>

        <div className="col formGroup">
          <CustomPhoneInput
            inputProps={{
              name: "phoneNumber",
              required: true,
            }}
            value={values.phoneNumber}
            onChange={(phoneNumber) => {
              numberCustomChange(formikProps, "phoneNumber", phoneNumber);
            }}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            onBlur={handleBlur}
            label={"Phone Number"}
            disabled={disablePhone}
          />
        </div>
        <div className="col formGroup">
          <SelectFieldOption
            name={nationality}
            label={nationalityLabel}
            value={values.nationality}
            options={nationalityOptions || countryOptions}
            placeholder={nationalityPlaceholder}
            error={errors.nationality}
            touched={touched.nationality}
            onChange={handleCustomChange}
            onBlur={handleBlur}
            formikProps={formikProps}
            countrySelect={countrySelect}
          />
        </div>
      </div>
      <div className="row">
        <InputField
          name={addressOne}
          label={addressOneLabel}
          type="text"
          placeholder={addressOnePlaceholder}
          value={values.addressOne}
          error={errors.addressOne}
          touched={touched.addressOne}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <InputField
          name={addressTwo}
          label={addressTwoLabel}
          type="text"
          placeholder={addressTwoPlaceholder}
          value={values.addressTwo}
          error={errors.addressTwo}
          touched={touched.addressTwo}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="row">
        <InputField
          name={city}
          label={cityLabel}
          type="text"
          placeholder={cityPlaceholder}
          value={values.city}
          error={errors.city}
          touched={touched.city}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <SelectFieldOption
          name={state}
          label={stateLabel}
          placeholder={statePlaceholder}
          options={stateOption}
          value={values?.state ? values?.state : stateOption?.[0]}
          error={errors.state}
          touched={touched.state}
          onChange={handleCustomChange}
          onBlur={handleBlur}
          formikProps={formikProps}
          countrySelect={stateSelect}
          disabled={!values.nationality}
        />

        <InputField
          name={zipCode}
          type="text"
          label={zipCodeLabel}
          placeholder={zipCodePlaceholder}
          value={values.zipCode}
          error={errors.zipCode}
          touched={touched.zipCode}
          onChange={(e) => {
            if (e.target.value.length > 10) return;
            formikProps.setFieldValue("zipCode", e.target.value);
            handleBlur(e);
          }}
          onBlur={(e) => {
            // handleValidateZip(e, formikProps);
            handleBlur(e);
          }}
        />
      </div>
      {password && (
        <div className="row">
          <InputField
            name={password}
            label={passwordLabel}
            type="password"
            placeholder={passwordPlaceholder}
            value={values.password}
            error={errors.password}
            touched={touched.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputField
            name={confirmPassword}
            label={confirmPasswordLabel}
            type="password"
            placeholder={confirmPasswordPlaceholder}
            value={values.confirmPassword}
            error={errors.confirmPassword}
            touched={touched.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      )}
      <div className="row coupon-promocode-wrap">
        <div className="col promo-col">
          {showPromo && (
            <div className="d-flex promocodeWrap">
              <div className="checkBoxContent">
                <label>
                  <Checkbox
                    id="promo-id"
                    name={"hasPromo"}
                    value={hasPromo}
                    onChange={() => handleCheckboxChange(formikProps, "hasPromo", !values.hasPromo)}
                  />
                  <label htmlFor="promo-id" className="checkbox-label">
                    Select if you have a Promo or Gift Card Code.
                  </label>
                </label>
              </div>
            </div>
          )}
        </div>
        {showCoupon && (
          <div className="col coupon-col">
            <div className="d-flex promocodeWrap">
              <div className="checkBoxContent">
                <label>
                  <Checkbox
                    id="coupon-id"
                    name={"hasCoupon"}
                    value={hasCoupon}
                    onChange={() => handleCheckboxChange(formikProps, "hasCoupon", !values.hasCoupon)}
                  />
                  <label htmlFor="coupon-id" className="checkbox-label">
                    Select if you have a Coupon Code.
                  </label>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      {hasPromo && (
          <>
            <div className="row promocode-row">
              <div className="promoInputCol1">
                <InputField
                  name={promoCode}
                  type="text"
                  label={promoCodeLabel}
                  placeholder={promoCodePlaceholder}
                  value={values.promoCode}
                  error={errors.promoCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.promoCode}
                  isPromoAppliedMsg={isPromoAppliedMsg}
                />
              </div>

              <div className="promoInputCol2 formGroup ">
                <button
                  type="button"
                  onClick={() => ApplyPromo(values.promoCode)}
                  className="btn"
                >
                  Apply
                </button>
              </div>
            </div>
          </>
        )}
      {hasCoupon && (
        <>
          <div className="row promocode-row">
            <div className="promoInputCol1">
              <InputField
                name={couponCode}
                type="text"
                label={couponCodeLabel}
                placeholder={couponCodePlaceholder}
                value={values.couponCode}
                error={errors.couponCode}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.couponCode}
                isPromoAppliedMsg={isCouponAppliedMsg}
              />
            </div>

            <div className="promoInputCol2 formGroup ">
              <button
                type="button"
                onClick={() => ApplyCoupon(values.couponCode)}
                className="btn"
              >
                Apply
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PrimaryContactInformation;
