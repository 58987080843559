

import React from 'react'

function TableHeading({ tableHeading }) {

  return (
    <>
      <div className="row">
        {
          tableHeading && tableHeading.map((item, index) => {
            return (
              <div className={`${item === "STATUS" ?  "col status-col" : "col"}`}>
                <h4 key={index}>{item.label}</h4>
              </div>
            )
          })

        }

      </div>
    </>

  )
}

export default TableHeading