import React, { useEffect, useState } from "react";
import Column from "./Column";
import Pagination from "./Pagination";
import ReactPaginate from "react-paginate";
import facebookAdd1Img from "../../Assets/img/facebook-add1.jpg";
import facebookAdd2Img from "../../Assets/img/facebook-add2.jpg";
import facebookAdd3Img from "../../Assets/img/facebook-add3.png";
import facebookAdd4Img from "../../Assets/img/facebook-add4.png";
import facebookAdd5Img from "../../Assets/img/facebook-add5.png";
import facebookAdd6Img from "../../Assets/img/facebook-add6.png";

import facebookAdImage1 from "../../Assets/img/AdsAssets/Facebook/Facebook (1).png";
import facebookAdImage2 from "../../Assets/img/AdsAssets/Facebook/Facebook (2).png";
import facebookAdImage3 from "../../Assets/img/AdsAssets/Facebook/Facebook (3).png";
import facebookAdImage4 from "../../Assets/img/AdsAssets/Facebook/Facebook (4).png";
import facebookAdImage5 from "../../Assets/img/AdsAssets/Facebook/Facebook (5).png";
import facebookAdImage6 from "../../Assets/img/AdsAssets/Facebook/Facebook (6).png";
import facebookAdImage7 from "../../Assets/img/AdsAssets/Facebook/Facebook (7).png";
import facebookAdImage8 from "../../Assets/img/AdsAssets/Facebook/Facebook (8).png";
import facebookAdImage9 from "../../Assets/img/AdsAssets/Facebook/Facebook (9).png";
import facebookAdImage10 from "../../Assets/img/AdsAssets/Facebook/Facebook 1.png";
import facebookAdImage11 from "../../Assets/img/AdsAssets/Facebook/Facebook 2.png";
import facebookAdImage12 from "../../Assets/img/AdsAssets/Facebook/Facebook 3.png";
import facebookAdImage13 from "../../Assets/img/AdsAssets/Facebook/Facebook 3.png";
import facebookAdImage14 from "../../Assets/img/AdsAssets/Facebook/Facebook 5.png";
import facebookAdImage15 from "../../Assets/img/AdsAssets/Facebook/Facebook 12.png";
import facebookAdImage16 from "../../Assets/img/AdsAssets/Facebook/Facebook 13.png";
import facebookAdImage17 from "../../Assets/img/AdsAssets/Facebook/Facebook 14.png";
import facebookAdImage18 from "../../Assets/img/AdsAssets/Facebook/Facebook 15.png";

import facebookAdVideo1 from "../../Assets/video/AdsVideos/Facebook/AD1.mp4";
import facebookAdVideo2 from "../../Assets/video/AdsVideos/Facebook/AD6.mp4";
import facebookAdVideo3 from "../../Assets/video/AdsVideos/Facebook/AD9.mp4";
import facebookAdVideo4 from "../../Assets/video/AdsVideos/Facebook/AD17.mp4";
import facebookAdVideo5 from "../../Assets/video/AdsVideos/Facebook/AD18.mp4";
import facebookAdVideo6 from "../../Assets/video/AdsVideos/Facebook/AD19.mp4";
import facebookAdVideo7 from "../../Assets/video/AdsVideos/Facebook/AD28.mp4";
import facebookAdVideo8 from "../../Assets/video/AdsVideos/Facebook/AD30.mp4";
import facebookAdVideo9 from "../../Assets/video/AdsVideos/Facebook/AD32.mp4";

import twitterAdImage1 from "../../Assets/img/AdsAssets/Twitter/Twitter (1).png";
import twitterAdImage2 from "../../Assets/img/AdsAssets/Twitter/Twitter (2).png";
import twitterAdImage3 from "../../Assets/img/AdsAssets/Twitter/Twitter (3).png";
import twitterAdImage4 from "../../Assets/img/AdsAssets/Twitter/Twitter (4).png";
import twitterAdImage5 from "../../Assets/img/AdsAssets/Twitter/Twitter (5).png";
import twitterAdImage6 from "../../Assets/img/AdsAssets/Twitter/Twitter (6).png";
import twitterAdImage7 from "../../Assets/img/AdsAssets/Twitter/Twitter (7).png";
import twitterAdImage8 from "../../Assets/img/AdsAssets/Twitter/Twitter (8).png";
import twitterAdImage9 from "../../Assets/img/AdsAssets/Twitter/Twitter (9).png";
import twitterAdImage10 from "../../Assets/img/AdsAssets/Twitter/Twitter 02 (1).png";
import twitterAdImage11 from "../../Assets/img/AdsAssets/Twitter/Twitter 02 (2).png";
import twitterAdImage12 from "../../Assets/img/AdsAssets/Twitter/Twitter 02 (3).png";
import twitterAdImage13 from "../../Assets/img/AdsAssets/Twitter/Twitter 02 (4).png";
import twitterAdImage14 from "../../Assets/img/AdsAssets/Twitter/Twitter 02 (5).png";
import twitterAdImage15 from "../../Assets/img/AdsAssets/Twitter/Twitter 02 (6).png";
import twitterAdImage16 from "../../Assets/img/AdsAssets/Twitter/Twitter 02 (7).png";
import twitterAdImage17 from "../../Assets/img/AdsAssets/Twitter/Twitter 02 (8).png";
import twitterAdImage18 from "../../Assets/img/AdsAssets/Twitter/Twitter 02 (9).png";

import twitterAdVideo1 from "../../Assets/video/AdsVideos/Twitter/AD1.mp4";
import twitterAdVideo2 from "../../Assets/video/AdsVideos/Twitter/AD6.mp4";
import twitterAdVideo3 from "../../Assets/video/AdsVideos/Twitter/AD9.mp4";
import twitterAdVideo4 from "../../Assets/video/AdsVideos/Twitter/AD17.mp4";
import twitterAdVideo5 from "../../Assets/video/AdsVideos/Twitter/AD18.mp4";
import twitterAdVideo6 from "../../Assets/video/AdsVideos/Twitter/AD19.mp4";
import twitterAdVideo7 from "../../Assets/video/AdsVideos/Twitter/AD28.mp4";
import twitterAdVideo8 from "../../Assets/video/AdsVideos/Twitter/AD30.mp4";
import twitterAdVideo9 from "../../Assets/video/AdsVideos/Twitter/AD32.mp4";

import instagramAdImage1 from "../../Assets/img/AdsAssets/Instagram/Instagram  (1).png";
import instagramAdImage2 from "../../Assets/img/AdsAssets/Instagram/Instagram  (2).png";
import instagramAdImage3 from "../../Assets/img/AdsAssets/Instagram/Instagram  (3).png";
import instagramAdImage4 from "../../Assets/img/AdsAssets/Instagram/Instagram  (4).png";
import instagramAdImage5 from "../../Assets/img/AdsAssets/Instagram/Instagram  (5).png";
import instagramAdImage6 from "../../Assets/img/AdsAssets/Instagram/Instagram  (6).png";
import instagramAdImage7 from "../../Assets/img/AdsAssets/Instagram/Instagram  (7).png";
import instagramAdImage8 from "../../Assets/img/AdsAssets/Instagram/Instagram  (8).png";
import instagramAdImage9 from "../../Assets/img/AdsAssets/Instagram/Instagram  (9).png";
import instagramAdImage10 from "../../Assets/img/AdsAssets/Instagram/Instagram 1.png";
import instagramAdImage11 from "../../Assets/img/AdsAssets/Instagram/Instagram 2.png";
import instagramAdImage12 from "../../Assets/img/AdsAssets/Instagram/Instagram 3.png";
import instagramAdImage13 from "../../Assets/img/AdsAssets/Instagram/Instagram 4.png";
import instagramAdImage14 from "../../Assets/img/AdsAssets/Instagram/Instagram 5.png";
import instagramAdImage15 from "../../Assets/img/AdsAssets/Instagram/Instagram 12.png";
import instagramAdImage16 from "../../Assets/img/AdsAssets/Instagram/Instagram 13.png";
import instagramAdImage17 from "../../Assets/img/AdsAssets/Instagram/Instagram 14.png";
import instagramAdImage18 from "../../Assets/img/AdsAssets/Instagram/Instagram 15.png";

import instagramAdVideo1 from "../../Assets/video/AdsVideos/Instagram/AD1.mp4";
import instagramAdVideo2 from "../../Assets/video/AdsVideos/Instagram/AD6.mp4";
import instagramAdVideo3 from "../../Assets/video/AdsVideos/Instagram/AD9.mp4";
import instagramAdVideo4 from "../../Assets/video/AdsVideos/Instagram/AD17.mp4";
import instagramAdVideo5 from "../../Assets/video/AdsVideos/Instagram/AD18.mp4";
import instagramAdVideo6 from "../../Assets/video/AdsVideos/Instagram/AD19.mp4";
import instagramAdVideo7 from "../../Assets/video/AdsVideos/Instagram/AD28.mp4";
import instagramAdVideo8 from "../../Assets/video/AdsVideos/Instagram/AD30.mp4";
import instagramAdVideo9 from "../../Assets/video/AdsVideos/Instagram/AD32.mp4";

import videosAdVideo1 from "../../Assets/video/AdsVideos/Videos/A Journey Of A Thousand Miles.mp4";
import videosAdVideo2 from "../../Assets/video/AdsVideos/Videos/Beach and Bubbles.mp4";
import videosAdVideo3 from "../../Assets/video/AdsVideos/Videos/Beach Fun.mp4";
import videosAdVideo4 from "../../Assets/video/AdsVideos/Videos/Beach Stilt House aerial More Freedom.mp4";
import videosAdVideo5 from "../../Assets/video/AdsVideos/Videos/Create More Family.mp4";
import videosAdVideo6 from "../../Assets/video/AdsVideos/Videos/Landmark background beach.mp4";
import videosAdVideo7 from "../../Assets/video/AdsVideos/Videos/Norway Fjord We are Here.mp4";
import videosAdVideo8 from "../../Assets/video/AdsVideos/Videos/Nothing Creates More Experiences.mp4";
import videosAdVideo9 from "../../Assets/video/AdsVideos/Videos/Plane Takeoff Where Do you want to go.mp4";
import videosAdVideo10 from "../../Assets/video/AdsVideos/Videos/World got smaller.mp4";

import bannerAdImage1 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 32/Overview Set 32.png";
import bannerAdImage2 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 36/Overview Set 36.png";
import bannerAdImage3 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 37/Overview Set 37.png";
import bannerAdImage4 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 44/Overview Set 44.png";
import bannerAdImage5 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 47/Overview Set 47.png";
import bannerAdImage6 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 50/Overview Set 50.png";
import bannerAdImage7 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 66/Overview Set 66.png";
import bannerAdImage8 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 110/Overview Set 110.png";
import bannerAdImage9 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 111/Overview Set 111.png";
import bannerAdImage10 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 114/Overview Set 114.png";
import bannerAdImage11 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 116/Overview Set 116.png";
import bannerAdImage12 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 134/Overview Set 134.png";

import bannerAdZip1 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 32/Tripello Banner Ad Set 32.zip";
import bannerAdZip2 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 36/Tripello Banner Ad Set 36.zip";
import bannerAdZip3 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 37/Tripello Banner Ad Set 37.zip";
import bannerAdZip4 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 44/Tripello Banner Ad Set 44.zip";
import bannerAdZip5 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 47/Tripello Banner Ad Set 47.zip";
import bannerAdZip6 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 50/Tripello Banner Ad Set 50.zip";
import bannerAdZip7 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 66/Tripello Banner Ad Set 66.zip";
import bannerAdZip8 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 110/Tripello Banner Ad Set 110.zip";
import bannerAdZip9 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 111/Tripello Banner Ad Set 111.zip";
import bannerAdZip10 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 114/Tripello Banner Ad Set 114.zip";
import bannerAdZip11 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 116/Tripello Banner Ad Set 116.zip";
import bannerAdZip12 from "../../Assets/img/AdsAssets/Banners/Tripello Banner Ad Set 134/Tripello Banner Ad Set 134.zip";

function CommissionContentInfo({ heading, downloadImg, name, type }) {
  const data = {
    facebook: {
      images: [
        facebookAdImage1,
        facebookAdImage2,
        facebookAdImage3,
        facebookAdImage4,
        facebookAdImage5,
        facebookAdImage6,
        facebookAdImage7,
        facebookAdImage8,
        facebookAdImage9,
        facebookAdImage10,
        facebookAdImage11,
        facebookAdImage12,
        facebookAdImage13,
        facebookAdImage14,
        facebookAdImage15,
        facebookAdImage16,
        facebookAdImage17,
        facebookAdImage18,
      ],
      videos: [
        facebookAdVideo1,
        facebookAdVideo2,
        facebookAdVideo3,
        facebookAdVideo4,
        facebookAdVideo5,
        facebookAdVideo6,
        facebookAdVideo7,
        facebookAdVideo8,
        facebookAdVideo9,
      ],
    },
    twitter: {
      images: [
        twitterAdImage1,
        twitterAdImage2,
        twitterAdImage3,
        twitterAdImage4,
        twitterAdImage5,
        twitterAdImage6,
        twitterAdImage7,
        twitterAdImage8,
        twitterAdImage9,
        twitterAdImage11,
        twitterAdImage12,
        twitterAdImage13,
        twitterAdImage14,
        twitterAdImage15,
        twitterAdImage16,
        twitterAdImage17,
        twitterAdImage18,
      ],
      videos: [
        twitterAdVideo1,
        twitterAdVideo2,
        twitterAdVideo3,
        twitterAdVideo4,
        twitterAdVideo5,
        twitterAdVideo6,
        twitterAdVideo7,
        twitterAdVideo8,
        twitterAdVideo9,
      ],
    },
    instagram: {
      images: [
        instagramAdImage1,
        instagramAdImage2,
        instagramAdImage3,
        instagramAdImage4,
        instagramAdImage5,
        instagramAdImage6,
        instagramAdImage7,
        instagramAdImage8,
        instagramAdImage9,
        instagramAdImage10,
        instagramAdImage11,
        instagramAdImage12,
        instagramAdImage13,
        instagramAdImage14,
        instagramAdImage15,
        instagramAdImage16,
        instagramAdImage17,
        instagramAdImage18,
      ],
      videos: [
        instagramAdVideo1,
        instagramAdVideo2,
        instagramAdVideo3,
        instagramAdVideo4,
        instagramAdVideo5,
        instagramAdVideo6,
        instagramAdVideo7,
        instagramAdVideo8,
        instagramAdVideo9,
      ],
    },
    videos: {
      images: [],
      videos: [
        videosAdVideo1,
        videosAdVideo2,
        videosAdVideo3,
        videosAdVideo4,
        videosAdVideo5,
        videosAdVideo6,
        videosAdVideo7,
        videosAdVideo8,
        videosAdVideo9,
        videosAdVideo10,
      ],
    },
    bannerAds: {
      images: [
        bannerAdImage1,
        bannerAdImage2,
        bannerAdImage3,
        bannerAdImage4,
        bannerAdImage5,
        bannerAdImage6,
        bannerAdImage7,
        bannerAdImage8,
        bannerAdImage9,
        bannerAdImage10,
        bannerAdImage11,
        bannerAdImage12,
      ],
      zip: [
        bannerAdZip1,
        bannerAdZip2,
        bannerAdZip3,
        bannerAdZip4,
        bannerAdZip5,
        bannerAdZip6,
        bannerAdZip7,
        bannerAdZip8,
        bannerAdZip9,
        bannerAdZip10,
        bannerAdZip11,
        bannerAdZip12,
      ],
    },
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [sortedData, setSortedData] = React.useState([]);
  const [sortedZipData, setSortedZipData] = React.useState([]);
  const totalPages = Math.ceil(data[name][type].length / 6);
  const skip = 6 * (currentPage - 1);

  useEffect(() => {
    setSortedData(
      data[name][type].filter((curr, i) => i >= skip && i < skip + 6)
    );

    if (name === "bannerAds") {
      setSortedZipData(
        data[name].zip.filter((curr, i) => i >= skip && i < skip + 6)
      );
    }
  }, [name, currentPage, type]);

  return (
    <>
      <div className="commission-content-info-inner">
        <div className="heading">
          <div className="heading-info">
            <h3>{heading}</h3>
          </div>
        </div>

        <div className="image-container">
          <div className="row">
            {sortedData.map((image, index) => {
              if (index < 3) {
                return (
                  <Column
                    image1={image}
                    image2={downloadImg}
                    key={index}
                    type={type}
                    zipFile={sortedZipData[index]}
                  />
                );
              }
            })}
          </div>

          <div className="row">
            {sortedData.map((image, index) => {
              if (index >= 3) {
                return (
                  <Column
                    image1={image}
                    image2={downloadImg}
                    key={index}
                    type={type}
                    zipFile={sortedZipData[index]}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>

      <div className="paginationWrapper">
        <ReactPaginate
          nextLabel={"Next"}
          previousLabel={"Prev"}
          breakLabel={"..."}
          pageCount={totalPages} //total_pages
          marginPagesDisplayed={1}
          pageRangeDisplayed={4}
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
          }}
          containerClassName={"pagination"}
          pageClassName={"pagi active"}
          previousClassName={"pagiPrevNext"}
          nextClassName={"pagiPrevNext"}
          breakClassName={"pagi"}
          activeClassName={"pagiActive"}
          disabledClassName={"pagiDisabled"}
          forcePage={currentPage - 1}
        />
      </div>
    </>
  );
}

export default CommissionContentInfo;
