// children age list
export const childAge = [
  { value: 0, label: "Under 1" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
];

export const timingOptions = [
  { value: "0:00", label: "0:00" },
  { value: "0:30", label: "0:30" },
  { value: "1:00", label: "1:00" },
  { value: "1:30", label: "1:30" },
  { value: "2:00", label: "2:00" },
  { value: "2:30", label: "2:30" },
  { value: "3:00", label: "3:00" },
  { value: "3:30", label: "3:30" },
  { value: "4:00", label: "4:00" },
  { value: "4:30", label: "4:30" },
  { value: "5:00", label: "5:00" },
  { value: "5:30", label: "5:30" },
  { value: "6:00", label: "6:00" },
  { value: "6:30", label: "6:30" },
  { value: "7:00", label: "7:00" },
  { value: "7:30", label: "7:30" },
  { value: "8:00", label: "8:00" },
  { value: "8:30", label: "8:30" },
  { value: "9:00", label: "9:00" },
  { value: "9:30", label: "9:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },
  { value: "22:00", label: "22:00" },
  { value: "22:30", label: "22:30" },
  { value: "23:00", label: "23:00" },
  { value: "23:30", label: "23:30" },
];

export const timingOptions12HourFormat = [
  { value: "1:00 AM", label: "1:00 AM" },
  { value: "1:30 AM", label: "1:30 AM" },
  { value: "2:00 AM", label: "2:00 AM" },
  { value: "2:30 AM", label: "2:30 AM" },
  { value: "3:00 AM", label: "3:00 AM" },
  { value: "3:30 AM", label: "3:30 AM" },
  { value: "4:00 AM", label: "4:00 AM" },
  { value: "4:30 AM", label: "4:30 AM" },
  { value: "5:00 AM", label: "5:00 AM" },
  { value: "5:30 AM", label: "5:30 AM" },
  { value: "6:00 AM", label: "6:00 AM" },
  { value: "6:30 AM", label: "6:30 AM" },
  { value: "7:00 AM", label: "7:00 AM" },
  { value: "7:30 AM", label: "7:30 AM" },
  { value: "8:00 AM", label: "8:00 AM" },
  { value: "8:30 AM", label: "8:30 AM" },
  { value: "9:00 AM", label: "9:00 AM" },
  { value: "9:30 AM", label: "9:30 AM" },
  { value: "10:00 AM", label: "10:00 AM" },
  { value: "10:30 AM", label: "10:30 AM" },
  { value: "11:00 AM", label: "11:00 AM" },
  { value: "11:30 AM", label: "11:30 AM" },
  { value: "12:00 AM", label: "12:00 AM" },
  { value: "12:30 AM", label: "12:30 AM" },

  { value: "1:00 PM", label: "1:00 PM" },
  { value: "1:30 PM", label: "1:30 PM" },
  { value: "2:00 PM", label: "2:00 PM" },
  { value: "2:30 PM", label: "2:30 PM" },
  { value: "3:00 PM", label: "3:00 PM" },
  { value: "3:30 PM", label: "3:30 PM" },
  { value: "4:00 PM", label: "4:00 PM" },
  { value: "4:30 PM", label: "4:30 PM" },
  { value: "5:00 PM", label: "5:00 PM" },
  { value: "5:30 PM", label: "5:30 PM" },
  { value: "6:00 PM", label: "6:00 PM" },
  { value: "6:30 PM", label: "6:30 PM" },
  { value: "7:00 PM", label: "7:00 PM" },
  { value: "7:30 PM", label: "7:30 PM" },
  { value: "8:00 PM", label: "8:00 PM" },
  { value: "8:30 PM", label: "8:30 PM" },
  { value: "9:00 PM", label: "9:00 PM" },
  { value: "9:30 PM", label: "9:30 PM" },
  { value: "10:00 PM", label: "10:00 PM" },
  { value: "10:30 PM", label: "10:30 PM" },
  { value: "11:00 PM", label: "11:00 PM" },
  { value: "11:30 PM", label: "11:30 PM" },
  { value: "12:00 PM", label: "12:00 PM" },
  { value: "12:30 PM", label: "12:30 PM" },
];

export const carTypeList = [
  { value: "all", label: "All" },
  { value: "Economy", label: "Economy" },
  { value: "Compact", label: "Compact" },
  { value: "Mid Size", label: "Mid Size" },
  { value: "Standard", label: "Standard" },
  { value: "Full Size", label: "Full Size" },
  { value: "Premium", label: "Premium" },
  { value: "Luxury", label: "Luxury" },
  { value: "Mini", label: "Mini" },
  { value: "Convertible", label: "Convertible" },
  { value: "SUV", label: "SUV" },
  { value: "Van", label: "Van" },
];

export const rentalCarCompanyList = [
  { value: "all", label: "All" },
  { value: "ace", label: "Ace" },
  { value: "advantage", label: "Advantage" },
  { value: "avis", label: "Avis" },
  { value: "budget", label: "Budget" },
  { value: "the driving force", label: "The Driving Force" },
  { value: "europcar", label: "Europcar" },
  { value: "easirent", label: "Easirent" },
  { value: "executive car rental", label: "Executive Car Rental" },
  { value: "economy", label: "Economy" },
  { value: "E-Z", label: "E-Z" },
  { value: "firefly", label: "Firefly" },
  { value: "fox", label: "Fox" },
  { value: "hertz", label: "Hertz" },
  { value: "midway car rental", label: "Midway Car Rental" },
  { value: "national", label: "National" },
  { value: "nextCar", label: "NextCar" },
  { value: "routes", label: "Routes" },
  { value: "silvercar", label: "Silvercar" },
  { value: "state van rental", label: "State Van Rental" },
  { value: "u-save", label: "U-Save" },
  { value: "sixt", label: "Sixt" },
  { value: "airport van rental", label: "Airport Van Rental" },
  { value: "payless", label: "Payless" },
  { value: "dollar", label: "Dollar" },
  { value: "thrifty", label: "Thrifty" },
];

export const discountTypeList = [
  { value: "all", label: "All" },
  { value: "advantage", label: "Advantage" },
  { value: "alamo", label: "Alamo" },
  { value: "avis", label: "Avis" },
  { value: "budget", label: "Budget" },
  { value: "enterprise", label: "Enterprise" },
  { value: "hertz", label: "Hertz" },
  { value: "national", label: "National" },
  { value: "dollar", label: "Dollar" },
  { value: "thrifty", label: "Thrifty" },
];


export const carsSortOptions = [
  { value: "Price (Low to High)", label: "Price (Low to High)" },
  { value: "Price (High to Low)", label: "Price (High to Low)" },
  { value: "Company name (A-Z)", label: "Company name (A-Z)" },
  { value: "Company name (Z-A)", label: "Company name (Z-A)" },
];

export const titleOption = [
  { value: "title1", label: "Title" },
  { value: "title2", label: "Mr." },
  { value: "title3", label: "Mrs." },
  { value: "title4", label: "Ms." },
  { value: "title5", label: "Neutral" },
];

export const genderOption = [
  { id: 0, label: "M" },
  { id: 1, label: "F" },
];