import React from 'react';
import Modal from 'react-modal';
import {AffiliateInvitesCommissionList} from '../../main/CustomConstants/AffiliateInvitesCommissionList';
import infoGreyImg from '../../Assets/img/info-grey-icon.png';
import closeIcon from '../../Assets/img/close-blue-icon.png';

// Details Info Modal
function DetailsInfoModal({openModal, closeModal}) {
	return (
		<Modal isOpen={openModal} 
		className="popup detail-info-popup popup-open"
		ariaHideApp={false}>
			<div className="popup-content">
				<div className="popup-title">
					<h3>
						<img src={infoGreyImg} alt="icon" />Dashboard Details
					</h3>
					<button className="btn close" onClick={closeModal}>
						<img src={closeIcon} alt="icon" />
					</button>
				</div>
				<div className="box-content">
					{AffiliateInvitesCommissionList.map(list => (
						<>
							{list.title && <h4>{list.title}</h4>}
							{list.description && <p>{list.description}</p>}
							{list.title2 && <h3>{list.title2}</h3>}
						</>
					))}
				</div>
			</div>
		</Modal>

	);
}

export default DetailsInfoModal;
