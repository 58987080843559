import React, { Fragment, useEffect, useState } from "react";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HeaderNavbar from "../../Component/HeaderNavbar";
import printerImg from "../../Assets/img/printer-white-bg.png";
import emailImg from "../../Assets/img/email-white-bg.png";
import informationImg from "../../Assets/img/information.svg";
import rightShiftImg from "../../Assets/img/right-shift-blue.png";
import starImg from "../../Assets/img/yellow-star.png";
import location from "../../Assets/img/location-gray.png";
import datePicker from "../../Assets/img/date-picker.png";
import SingleHotelMapView from "../HotelSearchResult/SingleHotelCard/SingleHotelMapView";
import { useDispatch, useSelector } from "react-redux";
import { hotelActions } from "../../Stores/Actions";
import { isEmpty, has } from "lodash";
import GreyStarImg from "../../Assets/img/star.png";
import { useParams } from "react-router-dom";
import moment from "moment";
import EmailModal from "./EmailModal";
import CancellationModal from "./CancellationModal";
import { notification } from "antd";
// import "antd/dist/antd.css";
import importantImage from "../../Assets/img/ic-error-outline.png";
import errorImg from "../../Assets/img/ic-error-outline-copy-8.svg";
import Scrollbars from "react-custom-scrollbars";
import {getLocationParams} from "../../Util/util";

function HotelConfirmation() {
  const loading = useSelector((state) => state.commonReducer.loader);
  const [isConfirm, setIsConfirm] = useState(false)
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    let id = params.bookingID;
    let urlParams = getLocationParams();
    if(urlParams?.isConfirm){
      setIsConfirm(true)
    }
    dispatch(hotelActions.getBookingDetails(id));
  }, []);

  const hotelConfirmation = useSelector(
    (state) => state.hotelReducer.userBookingDetails
  );

  const hotelsDetail = !isEmpty(hotelConfirmation)
    ? hotelConfirmation.data
    : [];

  const hotel = !isEmpty(hotelsDetail) ? hotelsDetail.hotel : [];

  const currentGeoLocation =
    localStorage.getItem("currentGeoLocation") &&
    JSON.parse(localStorage.getItem("currentGeoLocation"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  const currency =
    hotelsDetail?.booking_package_details_json?.rates?.currency ?? "USD";

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  function starprint() {
    let star = [];
    let darkStar = 5 - hotel.rating;
    for (let i = 0; i < hotel.rating; i++) {
      star.push(<img src={starImg} alt="yellow-star" key={`${i}_rating1`} />);
    }
    for (let i = 0; i < darkStar; i++) {
      star.push(
        <img
          src={GreyStarImg}
          alt="yellow-star"
          key={`${i}_rating2`}
          width="24"
        />
      );
    }
    return star;
  }

  const downloadInvoice = () => {
    let id = params.bookingID;
    dispatch(hotelActions.downloadPdf(id));
  };

  // Email Receipt
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  //Cancel Itinerary
  const [isCancel, setIsCancel] = useState(false);

  function toggleCancelModal() {
    setIsCancel(!isCancel);
  }

  //**************Error handle for cancel booking*******************//
  const cancelBookingError = useSelector(
    (state) => state.hotelReducer.errorCancelBooking
  );

  let openNotifyCancelBookingError = (type) => {
    notification[type]({
      message: "Notification Title",
      description: cancelBookingError?.message,
    });
    setIsCancel((prev) => !prev);
  };

  // ***********Succes Msg handle for cancel booking**********//
  const cancelBookingData = useSelector(
    (state) => state.hotelReducer.cancelledBooking
  );

  let openNotifyCancelBookingSuccess = (type) => {
    notification[type]({
      message: "Notification Title",
      // description: cancelBookingError?.message,
      description: "Cancelled Successfully",
    });
    setIsCancel((prev) => !prev);
  };
  useEffect(() => {
    let id = params.bookingID;
    if (cancelBookingError !== null) {
      dispatch(hotelActions.getBookingDetails(id));
      openNotifyCancelBookingError("error");
      dispatch(hotelActions.resetCancelledBooking());
    }
    if (cancelBookingData !== null) {
      dispatch(hotelActions.getBookingDetails(id));
      openNotifyCancelBookingSuccess("success");
    }
  }, [cancelBookingError, cancelBookingData]);

  // ***********Error handle for Email****************//
  let errorMsg = useSelector((state) => state.hotelReducer.error);

  let openNotifyEmailError = (type) => {
    notification[type]({
      message: "Notification Title",
      description: errorMsg?.message,
    });
    setIsOpen((prev) => !prev);
  };

  // ***************Succsess msg handle for Email**************//
  const emailReservationData = useSelector(
    (state) => state.hotelReducer.cancelledBooking
  );

  let openNotifyEmailSuccess = (type) => {
    notification[type]({
      message: "Notification Title",
      description: "Sent Successfully",
    });
    setIsCancel((prev) => !prev);
  };

  useEffect(() => {
    let id = params.bookingID;
    if (errorMsg !== null) {
      // dispatch(hotelActions.getBookingDetails(id));
      openNotifyEmailError("error");
    }
  }, [errorMsg]);

  useEffect(() => {
    let id = params.bookingID;
    if (emailReservationData !== null) {
      // dispatch(hotelActions.getBookingDetails(id));
      openNotifyEmailSuccess("success");
    }
  }, [emailReservationData]);

  const getStatus = (status) => {
    if (status === "PAYMENT_COMPLETED") {
      return "Pending";
    } else if (status === "COMPLETED") {
      return "Confirmed";
    } else if (status === "CANCELLED") {
      return "Cancelled";
    } else if (status === "CANCELLED_REFUNDED") {
      return "Refunded";
    }
  };

  const bookingStatus = (checkinDate)=>{
    if(hotelsDetail?.booking_status === "CANCELLED"){
      return "Your Cancelled Booking."
    }else if(hotelsDetail?.booking_status === "CANCELLED_REFUNDED"){
      return "Your Cancelled Booking."
    }else if(hotelsDetail?.booking_status === "COMPLETED" && isConfirm){
      return "Your Completed Booking."
    }else if(hotelsDetail?.booking_status === "COMPLETED" && !isConfirm && moment(
      hotelsDetail?.booking_package_details_json
        ?.check_out_date_format4,
      "DD-MM-YYYY"
    ) > moment()){
      return "Your Upcoming Booking."
    }else if(hotelsDetail?.booking_status === "COMPLETED" && !isConfirm){
      return "Your Completed Booking."
    }
  }

  // console.warn("::::::",moment(
  //   hotelsDetail?.booking_package_details_json
  //     ?.check_out_date_format4,
  //   "DD-MM-YYYY"
  // ) > moment());

  return (
    !loading && (
      <div className="siteBg listingPage confirmationPageWrap">
        <div className="pageWrap pageWrapInner">
          <div className="fixedHeader">
            <HeaderNavbar />
          </div>
          <section className="mainContent">
            <Scrollbars
              autoHide
              autoHeight
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container pageInternalScrollTopPadding">
                <div className="confirmationWrapper">
                  <div className="row">
                    <div className="col leftContent">
                      <div className="card2 ">
                        <div className="messageWrapper">
                          <div className="container">
                            <h1>
                              <span>Thank You! </span>
                              {bookingStatus(hotelsDetail?.booking_package_details_json
                                      ?.check_out_date_format4)}
                              {/* {hotelsDetail?.booking_status === "CANCELLED"
                                ? "Your Cancelled Booking. "
                                : moment(
                                    hotelsDetail?.booking_package_details_json
                                      ?.check_out_date_format4,
                                    "DD-MM-YYYY"
                                  ) > moment().seconds()
                                ? `${
                                    isConfirm
                                      ? "Your Completed Booking "
                                      : "Your Upcoming Booking."
                                  }`
                                : "Your Completed Booking."} */}
                            </h1>

                            <p>
                              Booking reference number :{" "}
                              {hotelsDetail?.booking_id}
                            </p>
                            <p>
                              Confirmation Number:{" "}
                              {hotelsDetail?.confirmation_number}
                            </p>
                            {(hotelsDetail?.booking_status === "CANCELLED" ||
                              hotelsDetail?.booking_status ===
                                "CANCELLED_REFUNDED") && (
                              <p>Status : {getStatus(hotelsDetail?.status)}</p>
                            )}
                            <p>
                              {hotelsDetail?.booking_status === "CANCELLED"
                                ? "Cancellation "
                                : "Booking "}
                              itinerary emailed to:{" "}
                              {hotelsDetail?.booking_contact_person_json?.email}
                            </p>
                          </div>
                          <div className="buttonWrap">
                            <button className="btn" onClick={downloadInvoice}>
                              <img src={printerImg} alt="icon" />
                              Print Receipt
                            </button>
                            <button className="btn" onClick={toggleModal}>
                              <img src={emailImg} alt="icon" />
                              Email Receipt
                            </button>
                            {hotelsDetail?.booking_status === "CANCELLED" ? (
                              " "
                            ) : (
                              <button
                                className="btnBorder"
                                onClick={toggleCancelModal}
                              >
                                Cancel Itinerary
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="imgContentWrapper">
                          <div className="row no-gutters">
                            <div className="col contentWrap">
                              <div className="content">
                                <h3 className="cartTitle">
                                  {hotel.original_name}
                                </h3>
                                <div className="topSection dflex justifyStart">
                                  <div className="rating">{starprint()}</div>
                                </div>
                                <div className="contactInfo">
                                  <div className="location">
                                    <img src={location} alt="icon" />
                                    {hotel.address}
                                  </div>
                                  <div className="phone">
                                    <span className="icon-phone"></span>
                                    {hotel.phone}
                                  </div>
                                </div>
                                <div className="timeWrapper">
                                  <div className="row no-gutters">
                                    <div className="col">
                                      <h5>
                                        <img src={datePicker} alt="icon" />
                                        Check-In:
                                      </h5>
                                    </div>
                                    <div className="col">
                                      <h5>
                                        <img src={datePicker} alt="icon" />
                                        Check-Out:
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="row no-gutters dateWrapper">
                                    <div className="col">
                                      <h4>
                                        {!isEmpty(hotel) &&
                                          moment(
                                            hotel.check_in_date_format4,
                                            "DD-MM-YYYY"
                                          ).format(dateDisplayFormat)}
                                      </h4>
                                    </div>
                                    <div className="col">
                                      <h4>
                                        {!isEmpty(hotel) &&
                                          moment(
                                            hotel.check_out_date_format1,
                                            "DD-MM-YYYY"
                                          ).format(dateDisplayFormat)}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="infoDetail">
                                <div className="d-flex justify-content-between">
                                  <h4>Number of Nights:</h4>
                                  <h5>{hotel.no_of_nights} Nights</h5>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4>Number of Rooms:</h4>
                                  <h5>
                                    {hotel.no_of_rooms}{" "}
                                    {`${
                                      hotel.no_of_rooms > 1 ? "Rooms" : "Room"
                                    }`}
                                  </h5>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <h4>Number of Guests:</h4>
                                  <h5>{hotelsDetail.total_adults} Adults</h5>
                                </div>
                              </div>
                            </div>
                            <div className="col imgWrapmain">
                              <div className="imgWrap">
                                <img
                                  src={hotel.thumbnail_image_url}
                                  alt="confirmationImg"
                                />
                              </div>
                              {!loading && (
                                <SingleHotelMapView
                                  className="mapWrap"
                                  latitude={hotel.latitude}
                                  longitude={hotel.longitude}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card2">
                        <h4 className="d-flex align-items-center">
                          Guest Information
                        </h4>
                        <div className="guestTableWrap">
                          <table className="guestTable">
                            <thead>
                              <tr>
                                <td>
                                  <span>Guest Type</span>
                                </td>
                                <td>
                                  <span className="table-heading">
                                    Salutation
                                  </span>
                                </td>
                                <td>
                                  <span className="table-heading">
                                    First Name
                                  </span>
                                </td>
                                <td>
                                  <span className="table-heading">
                                    Last Name
                                  </span>
                                </td>
                                <td>
                                  <span className="table-heading">
                                    Nationality
                                  </span>
                                </td>
                                <td>
                                  <span className="table-heading">Age</span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {hotelsDetail &&
                                hotelsDetail?.guests?.map(
                                  (guest, roomIndex) => {
                                    return (
                                      <Fragment key={`${roomIndex}`}>
                                        {guest?.adults?.map(
                                          (adult, adultIndex) => {
                                            return (
                                              <tr key={`${adultIndex}Adult`}>
                                                <td>
                                                  <span className="table-value">
                                                    Room {roomIndex + 1}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {adult.salutation}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {adult.first_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {adult.last_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    -
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {adult.age}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                        {guest?.child?.map(
                                          (child, childIndex) => {
                                            return (
                                              <tr key={`${childIndex}Child`}>
                                                <td>
                                                  <span className="table-value">
                                                    Room {roomIndex + 1}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {child.salutation}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {child.first_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {child.last_name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    -
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="table-value">
                                                    {child.age}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </Fragment>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="hotel-card card2 cancellation-policy">
                        <h4 className="d-flex align-items-center">
                          <img src={importantImage} className="mr-2" />
                          Important
                        </h4>
                        <p className="mb-0">
                          The Rates listed above were made available to you as
                          Member of Tripello, a Closed User Group (CUG),
                          accessing Non-Public Rates through our secure login
                          portal and website. These Rates are not available to
                          the General Public.
                        </p>
                      </div>
                      {hotelsDetail?.booking_package_details_json?.room_details
                        .is_refundable && (
                        <div className="hotel-card cancellation-policy card2">
                          <h4 className="d-flex align-items-center">
                            <img src={importantImage} className="mr-2" />
                            Cancellation Policy
                          </h4>
                          <ul>
                            <li>
                              Refundable cancellations (as laid out in our{" "}
                              <a href="/terms-conditions">
                                Terms and Condtions{" "}
                              </a>
                              ) will be subject to a 3.2% merchant processing
                              fee.
                            </li>
                            <li>
                              Booking charges to your Debit/Credit Card will
                              appear in the name of "Tripello" and/or "Stripe".
                            </li>
                            {hotelsDetail?.booking_package_details_json
                              ?.cancellation_policy.cancellation &&
                              hotelsDetail?.booking_package_details_json?.cancellation_policy?.cancellation.map(
                                (policy) => {
                                  let fromDate = policy.from_date.slice(0, 10);
                                  let toDate = policy.to_date.slice(0, 10);
                                  return (
                                    <li key={`${policy.from_date}Date`}>
                                      From Date <strong>{fromDate}</strong>{" "}
                                      {policy.to_date ? (
                                        <>
                                          to{" "}
                                          <strong>
                                            {policy.to_date.slice(0, 10)}
                                          </strong>{" "}
                                        </>
                                      ) : null}
                                      Penalty will be{" "}
                                      <strong>${policy.charges}</strong>
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="col rightContent">
                      <div className="card2 cardSummary">
                        <div className="sectionTitle">
                          <h2>Summary of Charges</h2>
                          <h3>
                            Booking Date:
                            <strong>
                              {hotelsDetail.created_at
                                ? moment(hotelsDetail.created_at).format(
                                    dateDisplayFormat
                                  )
                                : null}
                            </strong>
                          </h3>
                        </div>
                        <div className="customShadow contentInfo">
                          <div className="d-flex justify-content-between">
                            <h4>Nightly Rate:</h4>
                            <h5>
                              {!isEmpty(hotelsDetail) &&
                                formatter.format(
                                  hotelsDetail.booking_package_details_json
                                    .rates.per_night
                                )}
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h4>Number of nights:</h4>
                            <h5>
                              {!isEmpty(hotelsDetail) &&
                                hotelsDetail.booking_package_details_json.rates
                                  .no_of_nights}{" "}
                            </h5>
                          </div>
                          <div className="paidInfo">
                            <div className="d-flex justify-content-between">
                              <h4>You paid:</h4>
                              <h5>
                                $
                                {!isEmpty(hotelsDetail) &&
                                  hotelsDetail.payment_via_stripe}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card2 cardHelp">
                        <div className="sectionTitle">
                          <h2>Need Help?</h2>
                        </div>
                        <div className="links">
                          <a href="/hotel-faq">
                            <img src={informationImg} alt="icon" />
                            <span>See all FAQ’s</span>
                            <img
                              className="arrow-img"
                              src={rightShiftImg}
                              alt="icon"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </section>
          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />
        </div>
        {isOpen && (
          <EmailModal
            openModal={isOpen}
            closeModal={toggleModal}
            setIsOpen={setIsOpen}
          />
        )}
        {isCancel && (
          <CancellationModal
            openModal={isCancel}
            closeModal={toggleCancelModal}
            setIsCancel={setIsCancel}
          />
        )}
      </div>
    )
  );
}

export default HotelConfirmation;
