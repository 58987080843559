import React, { useRef, useState, useEffect } from "react";
import Header from "../../Component/Header";
import Card from "../../Component/Card";
import downArrow from "../../Assets/img/network/down-arrrow.svg";
import Background from "../../Assets/img/tripello-difference.png";
import flightImage from "../../Assets/img/flightImg.png";
import hotelImg from "../../Assets/img/cardImg2.png";
import carImg from "../../Assets/img/cardImg3.png";
import cardImage from "../../Assets/img/cardImg4.png";
import Video from "../../Component/Video";
import plusImg from "../../Assets/img/plus-img.png";
import cardlogo from "../../Assets/img/logo.png";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import posterBg from "../../Assets/img/home/Homepage-Banner-Video.jpg";
import homeVideoDesktop from "../../Assets/video/home/new-tripello-homepage-top-video.mp4";
import homeVideoipad from "../../Assets/video/home/new-tripello-homepage-top-video-ipad5.mp4";
import homeVideowebm from "../../Assets/video/home/new-tripello-homepage-top-video.webm";
import homeVideoMobile from "../../Assets/video/home/new-tripello-homepage-top-video.webm";
import playButton from "../../Assets/img/network/play-button.png";
import featuredOnImg from "../../Assets/img/home/featured-on.png";
import Button from "../../Component/FormComponent/Button";
import AccordianOne from "../../Component/AccordianOne";
import { faqs } from "../../main/CustomConstants/Home";
import ModalHome from "../../Component/ModalHome";
import PlanInfoTwo from "../../Component/PlanInfoTwo";
import { TriptekDifference } from "./TriptekDifference";
import { TripelloWayWrapper } from "./TripelloWayWrapper";
import MoreLessButton from "../../Component/MoreLessButton";
import VideoModal from "../../Component/VideoModal";
import { Formik } from "formik";
import PortraitModeModel from "../../Component/PortraitModeModel";
import { Link, useNavigate } from "react-router-dom";
import { FreemiumSignUpValidation } from "../../Component/Validation/FreemiumSignUpValidation";
import LogoWhite from "../../Component/FormComponent/LogoWhite";
import logo from "../../Assets/img/logo.png";
import IconInputField from "../../Component/FormComponent/IconInputField";
import IconSelectField from "../../Component/FormComponent/IconSelectField";
import userIcon from "../../Assets/img/signup/user-icon.svg";
import emailIcon from "../../Assets/img/signup/email.svg";
import lockIcon from "../../Assets/img/signup/lock.svg";
import { ReactComponent as DownArrowSvg } from "../../Assets/img/down-arrow-svg.svg";
import Checkbox from "../../Component/FormComponent/Checkbox";
import { authActions } from "../../Stores/Actions/authActions";
import { useDispatch, useSelector } from "react-redux";
let checkoutCountries = require("../../Util/static-service");
import { getLocationParams } from "../../Util/util";
import Scrollbars from "react-custom-scrollbars";
import ReCAPTCHA from "react-google-recaptcha";

function Home() {
  const scrollPage = useRef(null);
  const queryString = window.location.href.split("?")[1];
  const [isFaqExpanded, setIsFaqExpanded] = useState(false);
  const [openedAccordian, setOpenedAccordian] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationSearch = getLocationParams();
  const formikRef = useRef();
  const [isCaptcha, setIsCaptcha] = useState(true);

  //state for opening video modal
  const [openModal, setOpenModal] = useState(false);
  const [portraitViewModal, setportraitViewModal] = useState(
    window.outerWidth > 767 && window.outerWidth < 1025 ? true : false
  );
  //state for show top sticky header on scroll
  const [showHeader, setShowHeader] = useState(false);

  // state for scroll button
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

  //getting scroll button while scrolling down
  useEffect(() => {
    scrollPage.current.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", onScrollFrame);
    window.addEventListener("resize", function (event) {
      if (window.outerWidth > 767 && window.outerWidth < 1025) {
        setportraitViewModal(true);
      } else {
        setportraitViewModal(false);
      }
    });
    return () => {
      scrollPage.current.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", onScrollFrame);

      window.removeEventListener("resize", function (event) {
        if (window.outerWidth > 767 && window.outerWidth < 1025) {
          setportraitViewModal(true);
        } else {
          setportraitViewModal(false);
        }
      });
    };
  }, []);

  const onScrollFrame = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > 5) {
      setHeaderSticky(true);
    } else {
      setHeaderSticky(false);
    }
  };
  //setting length after which sticky header and scrollToTop button show
  const handleScroll = () => {
    if (
      (scrollPage.current.scrollTop >= 4 && !showHeader) ||
      scrollPage.current.scrollTop >= 150
    ) {
      setShowHeader(true);
      setShowScrollTopBtn(true);
    }
    if (
      scrollPage.current.scrollTop < 4 ||
      scrollPage.current.scrollTop < 150
    ) {
      setShowHeader(false);
      setShowScrollTopBtn(false);
    }
  };

  //jumping to top of the page on button click
  const scrollElementToTop = () => {
    scrollPage.current.scrollTop = 0;
  };

  //handle open and close accordian list
  const handleOpenClose = (id) => {
    if (id === openedAccordian) {
      setOpenedAccordian(null);
    } else {
      setOpenedAccordian(id);
    }
  };

  var sectionStyle = {
    backgroundImage: `url(${Background})`,
  };

  //handle open and close video modal

  const handleOpenCloseVideoModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handleOpenClosePortraitModal = () => {
    if (portraitViewModal) {
      setportraitViewModal(false);
    } else {
      setportraitViewModal(true);
    }
  };
  //Modal open and close
  const handleOpenCloseModal = () => {
    if (!modalOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };

  // scroll view function

  const scrollView = (e) => {
    let triptekDifference = document.querySelector("#triptek-difference");
    e.preventDefault();
    triptekDifference &&
      triptekDifference.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  // scroll view function end

  // Accordian list
  const getFaqs = (faqList) => {
    let faqListNodes = [];

    // render the whole list
    faqList.forEach((faq, index) => {
      if (isFaqExpanded) {
        faqListNodes.push(
          <AccordianOne
            id={faq.id}
            key={faq.title + index}
            heading={faq.title}
            content={faq.description}
            openedAccordian={openedAccordian}
            handleOpenClose={handleOpenClose}
          />
        );
      } else {
        // render list with limit of 5 items
        if (index < 5) {
          faqListNodes.push(
            <AccordianOne
              id={faq.id}
              key={faq.title + index}
              heading={faq.title}
              content={faq.description}
              openedAccordian={openedAccordian}
              handleOpenClose={handleOpenClose}
            />
          );
        }
      }
    });

    return faqListNodes;
  };

  /////////////

  const [isHeaderSticky, setHeaderSticky] = useState(false);

  const isUserExist = useSelector((state) => state.authReducer.isUserExist);

  const freemiumSignupData = useSelector(
    (state) => state.authReducer.freemiumSignupData
  );
  const [openForm, setOpenForm] = useState(false);
  const [user_id, setUser_id] = useState(null);

  let initialValues = {
    // form inital Values
    firstName: locationSearch?.first_name || "",
    lastName: locationSearch?.last_name || "",
    email: locationSearch?.email || "",
    password: "",
    confirmPassword: "",
    country: "",
    terms: false,
  };

  useEffect(() => {
    if (locationSearch?.ref && !user_id) {
      dispatch(authActions.isEmailExistRequest(locationSearch.ref)).then(
        (res) => {
          if (
            res.status == 200 && (res.data.type_id !== process.env.REACT_APP_FREMIUM_USER_TYPE_ID)
          ) {
            setUser_id(res?.data?.user_id);
            return;
          } else {
            setUser_id(process.env.REACT_APP_USER_ID);
          }
        }
      );
    }
  }, []);

  const handleSubmit = (values) => {
    const payload = {
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      email: values.email.trim(),
      password: values.password.trim(),
      country: values.country.value.trim(),
      sponsor_id: user_id,
    };

    dispatch(authActions.isEmailExistRequest(values.email)).then((response) => {
      if (response.status == 200) {
        return formikRef.current.setErrors({
          email:
            "You are already a member, please login or use a different email",
        });
      }

      if (response?.response?.status == 422) {
        return dispatch(authActions.freemiumSignupRequest(payload)).then(
          (res) => {
            console.warn("res", res);
            if (res.status == 200) {
              navigate("/thankyou/request-to-confirm-email");
            }
          }
        );
      }
    });
  };
  const handleCustomChange = (field, value, formikProps) => {
    formikProps.setFieldValue(field, value);
  };

  const countryOptions = checkoutCountries.map((curr) => {
    return { value: curr.alpha2, label: curr.name };
  });

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;
    const onCaptchaChange = (value) => {
      if (value !== null)
        setIsCaptcha(false);
      else
        setIsCaptcha(true);
    }
    return (
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <IconInputField
            name="firstName"
            type="text"
            placeholder="First Name"
            src={userIcon}
            value={values.firstName}
            touched={touched.firstName}
            errors={errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />
          <IconInputField
            name="lastName"
            type="text"
            placeholder="Last Name"
            src={userIcon}
            value={values.lastName}
            touched={touched.lastName}
            errors={errors.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />
          <IconInputField
            name="email"
            type="email"
            placeholder="Your Email"
            src={emailIcon}
            value={values.email}
            touched={touched.email}
            errors={errors.email}
            onBlur={handleBlur}
            onChange={(e) =>
              handleCustomChange("email", e.target.value, formikProps)
            }
            inputClassName="form-control"
          />
          <IconInputField
            name="password"
            type="password"
            placeholder="Password"
            src={lockIcon}
            value={values.password}
            errors={errors.password}
            touched={touched.password}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />
          <IconInputField
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            src={lockIcon}
            value={values.confirmPassword}
            touched={touched.confirmPassword}
            errors={errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
          />

          <IconSelectField
            name="country"
            placeholder="Country  "
            // src={lockIcon}
            value={values.country}
            touched={touched.country}
            errors={errors.country}
            onBlur={handleBlur}
            onChange={handleChange}
            inputClassName="form-control"
            options={countryOptions}
            formikProps={formikProps}
            iconName="icon-globe"
            className="country-code-wrap"
          />
          {/* Checkbox input */}
          <div className="checkBoxContent">
            <label className="customCheckbox">
              <Checkbox
                name="terms"
                value={values.terms}
                onChange={handleChange}
              />
              <p className={errors.terms && touched.terms && "color-red"}>
                I agree to the{" "}
                <Link to="/terms-conditions">Terms and Conditions </Link>
              </p>
            </label>
          </div>
          <div className="buttonWrap">
            <div className="content">
              <h4>Free</h4>
              <h6>(no time limit)</h6>
            </div>

            {/* Form Submit button  */}

            {/* <div className="button">
              <Button text="I'm In" type="submit" className="btn" />
            </div> */}
            <div className="google-recaptcha-wrap">
              {isCaptcha ? <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={onCaptchaChange}
              /> : <>
                <div className="button">
                  <Button text="I'm In" type="submit" className="btn" />
                </div></>}
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div
        className={`siteBg freemiumsignupPageWrap newfreemiumsignupPageWrap ${isHeaderSticky && "header-sticky"
          }`}
      >
        <div class="pageWrap pageWrapInner">
          <section class="pageContent">
            <div class="pageInternalScrollRemove">
              <div class="freemiumsignupBanner">
                <div class="container">
                  <div
                    className={
                      openForm ? "signupWrapper openForm" : "signupWrapper"
                    }
                  >
                    <div class="fixedHeader">
                      <Header headerName={true} />
                    </div>

                    <div class="row">
                      <div class="col left-content">
                        <div class="left-content-wrapper">
                          <h1>Welcome,</h1>
                          <h2>
                            {" "}
                            to a <strong>Unique</strong> and{" "}
                            <strong>New Way </strong>
                            of <strong>Booking Travel.</strong>
                          </h2>
                          <h2>
                            The <strong>First</strong> true subscription-based
                            Travel Service to offer{" "}
                            <strong> Wholesale/Net Travel Prices</strong> to{" "}
                            <strong>YOU</strong>, the End Consumer.
                          </h2>
                          <div class="way-wrapper">
                            <h5>
                              We say <strong>NO</strong> to:
                            </h5>
                            <ul>
                              <li>
                                <h6>Gimmicks</h6>
                              </li>
                              <li>
                                <h6>Advertisement</h6>
                              </li>
                              <li>
                                <h6>Buying Pressure</h6>
                              </li>
                              <li>
                                <h6>Risk</h6>
                              </li>
                              <li>
                                <h6>Obligations</h6>
                              </li>
                              <li>
                                <h6>Time-Limits</h6>
                              </li>
                              <li>
                                <h6>Up-Sales</h6>
                              </li>
                              <li>
                                <h6>Commissions</h6>
                              </li>
                              <li>
                                <h6>Booking Fees</h6>
                              </li>
                            </ul>
                          </div>
                          <h3>
                            See for Yourself <strong> how much </strong>
                            YOU can save.
                          </h3>
                          <h3>
                            Travel is now <strong>more affordable</strong> than
                            ever.
                          </h3>
                          <h3>Join for Free and come have a look.</h3>

                          <div class="freemium-getstarted-btn">
                            <Button
                              className="btn"
                              type="button"
                              onClick={() => setOpenForm(true)}
                              text="Get Started"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col right-content">
                        <div class="right-box-content">
                          <div class="infoBox">
                            <div class="membership-content">
                              <LogoWhite src={logo} />
                              <div class="planName">Freemium Membership</div>
                            </div>
                          </div>
                          <Formik
                            onSubmit={handleSubmit}
                            innerRef={formikRef}
                            validationSchema={FreemiumSignUpValidation}
                            initialValues={initialValues}
                            enableReinitialize={true}
                          >
                            {renderForm}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scrollbtn-wrap">
                {/* <div class="scrollBtm">
                  <a onClick={scrollView} class="scroll-down">
                    <div class="mousey">
                      <div class="scroller"></div>
                    </div>
                  </a>
                </div> */}
                <div className="scrollBtm">
                  <Link
                    to="#scroll-section"
                    onClick={scrollView}
                    className="scroll-down"
                  >
                    <div class="mousey-wrap">
                      <div class="mousey">
                        <div class="scroller"></div>
                      </div>
                      <DownArrowSvg />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* copy from home,js */}
      <div
        className={
          !showHeader
            ? `grayBg indexPageWrap indexwrap-freemium`
            : "grayBg indexPageWrap header-sticky"
        }
      >
        <div ref={scrollPage} className="newfreemiumsignupPageWrap ">
          <section
            id="triptek-difference"
            className="triptek-difference-wrapper"
            style={sectionStyle}
          >
            <div className="container sectionSpace sideSpace centerAlign">
              <TriptekDifference />
            </div>
          </section>
          <div className="container sectionSpace2 sideSpace triptek-inventory-wrapper">
            <h3 className="sctionTitle centerAlign">The Tripello Inventory</h3>
            <div className="colCard">
              <Card
                title={<h5>Global Flights</h5>}
                description="Big savings on Flights, especially in premium cabins, from all Global Carries and over 140 low-cost carriers from around the world."
                image={flightImage}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />

              <Card
                title={<h5>Hotels & Resorts</h5>}
                description="Huge savings on over 1.5 million Hotels and Resorts worldwide, Including the most popular destinations."
                image={hotelImg}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
              <Card
                title={<h5>Rental Cars & Services</h5>}
                description="Great Deals on Rental Cars by the best and most trusted brands from around the world."
                image={carImg}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
              <Card
                title={<h5>Vacation Homes</h5>}
                description=" More than 1.5 Million bookable Vacation rentals incl. Condos, Cabins, Villas, Bungalows, Beach Houses and more."
                image={cardImage}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
            </div>
          </div>
          <div className="container sectionSpace2 sideSpace centerAlign tripello-way-wrapper">
            <TripelloWayWrapper />
          </div>
          <div className="gradiantBg videoSpace sideSpace centerAlign tripello-magic-wrapper">
            <div className="sctionTitle">The Tripello Magic</div>
            <p>Give us a minute to show you how</p>
            <div className="cardStyle">
              <div className="videoBox">
                <div className="videoInBox">
                  <div className="desktop-video">
                    <Button
                      type="button"
                      className="video-play-btn"
                      imageSrc={playButton}
                      onClick={handleOpenCloseVideoModal}
                    />
                  </div>
                  <div className="mobile-video">
                    <Button
                      type="button"
                      className="video-play-btn"
                      imageSrc={playButton}
                      onClick={handleOpenCloseVideoModal}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="tripello-magic-content">
              <div className="video-section-content-wrap">
                <h6>
                  If you like to <strong>find out more</strong> about{" "}
                  <strong>WHAT</strong>, <strong>HOW</strong>, and{" "}
                  <strong>WHY</strong> we do what <strong>WE</strong> do,
                  <strong>
                    <button
                      onClick={handleOpenCloseModal}
                      className="tripello-magic-link"
                    >
                      CLICK HERE.
                    </button>
                  </strong>
                </h6>
              </div>
              <div className="featured-on-icons">
                <img src={featuredOnImg} alt="image" />
              </div>
            </div>
          </div>

          <div class="planCont">
            <div class="container sideSpace centerAlign">
              <h3 class="sctionTitle">Sign Up For Free Today</h3>
              <div class="container">
                <div class="choosePlan">
                  <div class="col freemium-member-col">
                    <div class="cardStyle">
                      <div class="planImgBox">
                        <div class="planImgInner">
                          <LogoWhite src={logo} />
                          <div class="planName">Freemium Membership</div>
                        </div>
                      </div>
                      <ul class="planDetails">
                        <li>
                          <i class="icon-tick"></i>No Credit/Debit Card
                          Required*
                        </li>
                        <li>
                          <i class="icon-tick"></i>Savings of up to 25%
                        </li>
                        <li>
                          <i class="icon-tick"></i>Limited Platform Access
                          <br /> (Hotels and Resorts Only)
                        </li>

                        <li>
                          <i class="icon-tick"></i>Access to 1.5 million <br />
                          Hotels and Resorts
                        </li>
                      </ul>
                      <div class="planBtmBox">
                        <p class="planNote">
                          * Not required for Sign Up but will be required for
                          Hotel Bookings
                        </p>
                        <div class="planRateBox">
                          <div>
                            <h4>Free</h4>
                            <p>(no time limit)</p>
                          </div>
                          <a href="#" class="btn">
                            I’m In
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="choosePlanOrTitle">
                  <h6>Or</h6>
                </div>
                <div class="choosePlan twoColchoosePlan">
                  <div class="col">
                    <div class="cardStyle paidPlan">
                      <div class="planImgBox">
                        <div class="planImgInner">
                          <LogoWhite src={logo} />
                          <div class="planName">Premium Membership</div>
                          <p>(6 Month)</p>
                        </div>
                      </div>
                      <ul class="planDetails">
                        <li>
                          <i class="icon-tick"></i>Credit/Debit Card Required*
                        </li>
                        <li>
                          <i class="icon-tick"></i>Unlimited Platform Access
                        </li>
                        <li>
                          <i class="icon-tick"></i>Savings of up to 65% and more
                        </li>
                        <li>
                          <i class="icon-tick"></i>Access to 1.5 million Hotels
                          and Resorts
                        </li>
                        <li>
                          <i class="icon-tick"></i>Access to 1.5 million
                          Vacation Homes
                        </li>
                        <li>
                          <i class="icon-tick"></i>Access to Rental Cars and
                          upcoming Chauffeur Service
                        </li>
                        <li>
                          <i class="icon-tick"></i>Access to Flight Inventory of
                          all Global Carriers and 140 Low Cost Carriers
                        </li>
                      </ul>
                      <div class="planBtmBox">
                        <p class="planNote">
                          * Required for Sign Up and Bookings
                        </p>
                        <div class="planRateBox">
                          <div>
                            <h4>
                              $32<sub>.50</sub>
                              <span>/ per month</span>
                            </h4>
                            <p>($195.00 total at checkout)</p>
                          </div>
                          <a href={`membership-premium-checkout?${queryString}`} class="btn">
                            Sign Me Up
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="cardStyle paidPlan">
                      <div class="planImgBox">
                        <div class="planImgInner">
                          <LogoWhite src={logo} />
                          <div class="planName">Premium Membership</div>
                          <p>(12 Month)</p>
                          <div class="plus-img">
                            <img src={plusImg} alt="image" />
                          </div>
                        </div>
                      </div>
                      <ul class="planDetails">
                        <li>
                          <i class="icon-tick"></i>Credit/Debit Card Required*
                        </li>
                        <li>
                          <i class="icon-tick"></i>Unlimited Platform Access
                        </li>
                        <li>
                          <i class="icon-tick"></i>Savings of up to 65% and more
                        </li>
                        <li>
                          <i class="icon-tick"></i>Access to 1.5 million Hotels
                          and Resorts
                        </li>

                        <li>
                          <i class="icon-tick"></i>Access to 1.5 million
                          Vacation Homes
                        </li>

                        <li>
                          <i class="icon-tick"></i>Access to Rental Cars and
                          upcoming Chauffeur Service
                        </li>
                        <li>
                          <i class="icon-tick"></i>Access to Flight Inventory of
                          all Global Carriers and 140 Low Cost Carriers
                        </li>
                        <li>
                          <i class="icon-tick"></i>Access to Member Referral
                          Rewards Program <br />
                          • $25 for each Premium Member <br />• $50 for each
                          Premium Plus Member
                        </li>
                      </ul>
                      <div class="planBtmBox">
                        <p class="planNote">
                          * Required for Sign Up and Bookings
                        </p>
                        <div class="planRateBox">
                          <div>
                            <h4>
                              $24<sub>.95</sub>
                              <span>/ per month</span>
                            </h4>
                            <p>($299.40 total at checkout)</p>
                          </div>
                          <a href={`membership-premium-plus-checkout?${queryString}`} class="btn">
                            Sign Me Up
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container sectionSpace2 sideSpace faqCnt home-faq">
            <h3 className="centerAlign sctionTitle">
              Here are Some Answers to Common Questions
            </h3>
            <div className="accordion">
              <div className="cardStyle accordWrap">
                <div className="toggle-more-accordions">{getFaqs(faqs)}</div>
              </div>

              {faqs.length > 5 && (
                <MoreLessButton
                  isExpandAccordian={isFaqExpanded}
                  setIsExpandAccoridan={setIsFaqExpanded}
                />
              )}
            </div>
          </div>
        </div>
        <FooterWithMediaIcon />
        {showScrollTopBtn ? (
          <div
            id="back-to-top"
            className="back-to-top"
            onClick={scrollElementToTop}
          >
            <button className="btn btn-primary" title="Back to Top">
              <svg
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 31.479 31.479"
                style={{ enableBackground: "new 0 0 31.479 31.479" }}
                width="18px"
                height="18px"
                className="svg lazyloading replaced-svg"
              >
                <path
                  style={{ fill: "#004185" }}
                  d="M26.477,10.274c0.444,0.444,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-8.047-8.047  v26.555c0,0.619-0.492,1.111-1.111,1.111c-0.619,0-1.127-0.492-1.127-1.111V3.813l-8.031,8.047c-0.444,0.429-1.159,0.429-1.587,0  c-0.444-0.444-0.444-1.143,0-1.587l9.952-9.952c0.429-0.429,1.143-0.429,1.571,0L26.477,10.274z"
                ></path>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>
          </div>
        ) : null}
      </div>

      {modalOpen && (
        <ModalHome openModal={modalOpen} closeModal={handleOpenCloseModal} />
      )}
      {openModal && (
        <VideoModal
          openModal={openModal}
          closeModal={handleOpenCloseVideoModal}
        />
      )}
    </>
  );
}

export default Home;
