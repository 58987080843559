const PriceDetailType = ({ right, left, lastEle, currency }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  return (
    <div className={`dropdownRow ${lastEle ? "lastRow" : ""}`}>
      <div className="leftLabel">{left}</div>
      <div className="rightValue">{formatter.format(right)}</div>
    </div>
  );
};

export default PriceDetailType;
