import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { cabinClassOptions } from "../../Util/util";

import NoFlight from "../../Assets/img/noFlightIcon.png";
import FlightInnerLoader from "../../Views/FlightSearchResult/FlightInnerLoader";
function FlightPlanBox(props) {
  const {
    passangerCount,
    cabin_class,
    item,
    sequenceId,
    legs,
    index,
    currencySymbol,
    currency,
    isPremium,
  } = props;
 
  const rates = props; 
  // const isPolling = useSelector((state) => state.flightReducer?.isPolling);
  const isPolling = useState(false);
  const flightsSearchData = useSelector(
    (state) => state.flightReducer?.flightSearchData
  );

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  const noFlightAvailable = !item?.price?.totalPrice;

  // Passenger Count
  const PassengerCount = JSON.parse(localStorage.getItem("travellingInfo"));
  let totalPassenger =
    PassengerCount.adultCount +
    PassengerCount.childCount +
    PassengerCount.infantCount;

  // Navigate to flight-checkout page
  const savePayload = (cabin, price, cabinClass) => {

    let selectedClass = cabin;
    if (cabin === 3) {
      selectedClass = 4;
    }
    const payload = {
      sequence_no: sequenceId,
      adults: flightsSearchData.request.adults,
      children: flightsSearchData.request.children,
      infants: flightsSearchData.request.infants,
      locale: "en",
      cabin: selectedClass,
      cabinClass :cabinClass,
      price: item?.price?.totalPrice.toString(),
      currency_code: "USD",
    };

    localStorage.setItem("selectedLegs", JSON.stringify(legs));
    localStorage.setItem("validateKeyNumber", JSON.stringify(selectedClass));
    localStorage.setItem("validatePayload", JSON.stringify(payload));
  };

  //
  const errorMsg = useSelector(
    (state) => state.flightReducer.flightPollingErrors
  );

  return (
    <div
      className={`flightPlanBox ${
        cabin_class === "Y" || cabin_class === 0
          ? "economyPlan"
          : cabin_class === 1 || cabin_class === "S"
          ? "premiumEconomyPlan"
          : cabin_class === 2 || cabin_class === "C"
          ? "businessClassPlan"
          : "firstClassPlan"
      } ${noFlightAvailable ? "noFlightAvailable" : ""}`}
    >
      <div className="flightPlanName">{`${
        cabin_class === "Y" || cabin_class === 0
          ? "Economy"
          : cabin_class === 1 || cabin_class === "S"
          ? "Premium Economy"
          : cabin_class === 2 || cabin_class === "C"
          ? "Business Class"
          : "First Class"
      } `}</div>
      <div className="flightPlanInfo">
        <div className="flightPlanInfoInWrap">
          {noFlightAvailable ? (
            <>
              <div className="mobilePlanTitle mobileShow">{`${
                cabin_class === "Y" || cabin_class === 0
                  ? "Economy"
                  : cabin_class === 1 || cabin_class === "S"
                  ? "Premium Economy"
                  : cabin_class === 2 || cabin_class === "C"
                  ? "Business Class"
                  : "First Class"
              } `}</div>
              {/* <img className="mobileHide" src={NoFlight} alt="" /> */}
              {/* {isPolling ? (
                <FlightInnerLoader />
              ) : ( */}
              <div className="not-available-content">
                <img className="mobileHide" src={NoFlight} />
                {/* <p>Not Available on this Flight</p> */}
                <p>Sold Out</p>
              </div>
              {/* )} */}
            </>
          ) : (
            <>
              <div className="mobileLeft">
                <div className="mobilePlanTitle mobileShow">{`${
                  cabin_class == "Y" || cabin_class == 0
                    ? "Economy"
                    : cabin_class == 1 || cabin_class == "S"
                    ? "Premium Economy"
                    : cabin_class == 2 || cabin_class == "C"
                    ? "Business Class"
                    : "First Class"
                } `}</div>
                {item?.savingPercentage <= 0? (
                  ""
                ) : (
                  <div className="savingText">
                    {item?.savingPercentage}% Savings
                  </div>
                )}

                {item?.savingPercentage <= 0 ? (
                  ""
                ) : (
                  <>
                    <p className="publicPrice">Public Price:</p>
                    <p className="lineThrText">
                      {formatter.format(item?.publicPrice)}
                    </p>
                  </>
                )}
              </div>
              <div className="mobileRight">
                <p className="totalText">
                  Total for {totalPassenger} Passenger:
                </p>
                <div className="planPrice">
                  {formatter.format(item?.price?.totalPrice)}
                  <a href="#">
                    <span className="icon-info"></span>
                  </a>
                </div>
                <p className="taxNote">incl. taxes and fees</p>
                {isPremium ? (
                  <Link
                    to="/flight-checkout"
                    className="btn btn2"
                    onClick={() =>
                      savePayload(
                        index,
                        item?.price?.totalPrice,
                        cabin_class,
                        sequenceId
                      )
                    }
                  >
                    Book Flight
                  </Link>
                ) : (
                  <Link to="/membership-upgrade" className="btn btn2">
                    Upgrade
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default FlightPlanBox;
