import { useEffect, useState } from "react";
import { carsActions, commonActions } from "../../Stores/Actions";
import { connect, useSelector } from "react-redux";

import CarsResult from "./CarsResult";
import CarsWidget from "./CarsWidget";
import HeaderNavbar from "../../Component/HeaderNavbar";
import Pagination from "../../Component/Pagiantion/Pagination";
import CarsListingFilters from "./CarsListingFilters";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import { Scrollbars } from "react-custom-scrollbars";
import CarFilterDisplay from "./CarFilterDisplay";
import ResultsOnPage from "../../Component/Pagiantion/ResultsOnPage";
import { isEmpty, has } from "lodash";

const carsPerPage = 15;
const initialFilters = {
  "Vehicle Type": [],
  "Car Features": [],
  "Car Companies": [],
};

function CarsListing(props) {
  const { carsData: rawCarsData, serverErrors, searchCarsAction, loaderText } = props;

  const [dataPerPage, setDataPerPage] = useState([]);
  const [hideHeader, setHideHeader] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [allFilters, setAllFilters] = useState(initialFilters);
  const [refactoredCarsData, setRefactoredCarsData] = useState([]);
  const [carFeaturesOptions, setCarFeaturesOptions] = useState(null);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState(null);
  const [carCompaniesOptions, setCarCompaniesOptions] = useState(null);
  const [numberOfAvailableCars, setNumberOfAvailableCars] = useState(0);
  const [sortingType, setSortingType] = useState("Price (Low to High)");

  // Filter Tag
  const [openVehicleTag, setOpenVehicleTag] = useState(false);
  const [openCarTag, setOpenCarTag] = useState(false);
  const [openCarFeatureTag, setOpenCarFeatureTag] = useState(false);

  const carPayloadData = JSON.parse(localStorage.getItem("carsPayload"));
  const pickUpLocation = JSON.parse(localStorage.getItem("pickUpLocation"));

  const currencyAndLanguage =
    localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const toggleFilterHandler = (status) => {
    setToggleFilter(status);
  };

  const resetFilters = () => {
    setAllFilters(initialFilters);
  };

  const refactorObjectToArray = (object) => {
    const tempArray = [];
    for (let element in object) {
      tempArray.push({
        name: element,
        label: `${element} (${object[element]})`,
      });
    }

    return tempArray;
  };

  const carsSortingFunction = (order) => {
    if (filteredData.length === 0) {
      return;
    }

    let sortedData = [];
    const copyArr = JSON.parse(JSON.stringify(filteredData));

    if (order === "Price (High to Low)") {
      sortedData = copyArr.sort((a, b) => b.totalPrice - a.totalPrice);
    } else if (order === "Price (Low to High)") {
      sortedData = copyArr.sort((a, b) => a.totalPrice - b.totalPrice);
    } else if (order === "Company name (A-Z)") {
      sortedData = copyArr.sort((a, b) =>
        a.carCompany < b.carCompany ? -1 : 1
      );
    } else if (order === "Company name (Z-A)") {
      sortedData = copyArr.sort((a, b) =>
        b.carCompany < a.carCompany ? -1 : 1
      );
    }

    setFilteredData(sortedData);
  };

  const carsFilterFunction = () => {
    if (refactoredCarsData.length === 0) return;

    let result = refactoredCarsData.filter((element) => {
      let flag = true;
      if (
        allFilters["Car Companies"].length > 0 &&
        !allFilters["Car Companies"].includes(element.carCompany)
      ) {
        flag = false;
      }

      if (
        allFilters["Vehicle Type"].length > 0 &&
        !allFilters["Vehicle Type"].includes(element.similar)
      ) {
        flag = false;
      }

      if (
        allFilters["Car Features"].length > 0 &&
        !allFilters["Car Features"].reduce(
          (previousVal, currentVal) =>
            element.features[currentVal] && previousVal,
          true
        )
      ) {
        flag = false;
      }
      return flag;
    });

    setFilteredData(result);
    let copy = JSON.parse(JSON.stringify(result));
    setDataPerPage(copy.splice(currentPage * carsPerPage, carsPerPage));
    setNumberOfAvailableCars(result.length);
  };

  const refactorCarsDataResponse = (response) => {
    if (!response) return;

    const array = [];
    let companies = {};
    let vehicle = {};
    let features = {
      "Air Conditioning": 0,
      "Auto Transmission": 0,
    };

    setNumberOfAvailableCars(Object.keys(response).length);

    for (let carData in response) {
      let result = response[carData];

      companies[result.partner.name] =
        companies[result.partner.name] === undefined
          ? 1
          : companies[result.partner.name] + 1;

      features["Air Conditioning"] =
        result.car["air_conditioning"] === "true"
          ? features["Air Conditioning"] + 1
          : features["Air Conditioning"];

      features["Auto Transmission"] =
        result.car["automatic_transmission"] === "true"
          ? features["Auto Transmission"] + 1
          : features["Auto Transmission"];

      vehicle[result.car.type_name] =
        vehicle[result.car.type_name] === undefined
          ? 1
          : vehicle[result.car.type_name] + 1;
      //debugger;
      let obj = {
        image:
          !isEmpty(result?.car?.images) &&
          Object.keys(result?.car?.images).length > 0
            ? Object.values(result.car.images).pop()
            : null,
        ppn_bundle: result.car_reference_id,
        carCompany: result.partner.name,
        carCompanyLogo: result.partner.logo,
        name: result.car.example,
        similar: result.car.type_name,
        isUnlimitedMiles: true,
        perDayPrice: result.price_details.display_total,
        currencySymbol: result.price_details.display_symbol,
        currency: result.price_details.display_currency,
        totalPrice:
          result.price_details.num_rental_days *
          result.price_details.display_price,
        features: {
          "Air Conditioning":
            result.car.air_conditioning === "true" ? "Air Conditioning" : "",
          "Auto Transmission":
            result.car.automatic_transmission === "true"
              ? "Auto Transmission"
              : "",
        },
        moreDetails: {
          carNameModal: result.car.description,
          similar: result.car.type_name,
          features: [
            {
              icon: "icon-seater",
              feature: `${result.car.passengers} seats`,
              available: result.car.passengers > 0,
            },
            {
              icon: "icon-door",
              feature: `${result.car.doors} doors`,
              // available: result.car.doors > 0,
              available: true,
            },
            {
              icon: "icon-gear",
              feature: "Automatic",
              available: result.car.automatic_transmission === "true",
            },
            {
              icon: "icon-suitcase2",
              feature: `${result.car.bags} suitcases`,
              available: result.car.bags !== "",
            },
            {
              icon: "icon-icon3",
              feature: "AC",
              available: result.car.air_conditioning === "true",
            },
            {
              icon: "icon-navigation",
              feature: "GPS",
              available: true,
            },
          ],

          startAdd: result.pickup.location,
          endAdd: result.dropoff.location,
          people: result.car.passengers,
          suitcases: result.car.bags,
          latitude: result.pickup.latitude,
          longitude: result.pickup.longitude,

          carPriceDetails: {
            freeCancellation: result.price_details.free_cancellation === "true",
            payLater: result.price_details.pay_at_booking === "false",
            currency: result.price_details.display_currency,
            creditCardRequired: result.creditCardRequired,
            days: result.price_details.num_rental_days,
            total:
              result.price_details.num_rental_days *
              result.price_details.display_price,
            priceDetails: [
              {
                left: `${result.price_details.base_price} x 1 Day`,
                right: result.price_details.display_price,
              },
              {
                left: `${result.price_details.currency} financial responsibility`,
                right: 0,
              },
              { left: "Rental Facility Charge", right: 0 },
              { left: "Vehicle License Fee", right: 0 },
              { left: "Airport Concession Recovery Fee", right: 0 },
              {
                left: "Total:",
                right:
                  result.price_details.num_rental_days *
                  result.price_details.display_price,
              },
            ],
          },
        },
      };
      array.push(obj);
    }

    setRefactoredCarsData(array);
    setCarCompaniesOptions(refactorObjectToArray(companies));
    setCarFeaturesOptions(refactorObjectToArray(features));
    setVehicleTypeOptions(refactorObjectToArray(vehicle));

    setCurrentPage(0);
    setFilteredData(array);
  };

  useEffect(() => {
    loaderText("Searching...");
    carPayloadData.native_currency_symbol = currency?.symbol;
    carPayloadData.currency = currency?.isoCode;
    resetFilters();
    searchCarsAction(carPayloadData);
  }, []);

  useEffect(() => {
    refactorCarsDataResponse(rawCarsData);
  }, [rawCarsData]);

  useEffect(() => {
    if (filteredData.length === 0 || refactoredCarsData.length === 0) {
      return;
    }

    if (filteredData.length < carsPerPage) {
      setCurrentPage(0);
    }

    let copy = JSON.parse(JSON.stringify(filteredData));
    setDataPerPage(copy.splice(currentPage * carsPerPage, carsPerPage));
  }, [currentPage, allFilters, filteredData]);

  useEffect(() => {
    carsSortingFunction(sortingType);
  }, [refactoredCarsData]);

  useEffect(() => {
    carsFilterFunction();
  }, [allFilters]);

  function hideFunc() {}

  const [selectedFilter, setSelectedFilter] = useState(allFilters);

  return (
    <div className="siteBg listingPage listingCars">
      <div className="pageWrap">
        <div className="fixedHeader">
          {!hideHeader && (
            <HeaderNavbar
              hideFunc={hideFunc}
              toggleFilterHandler={toggleFilterHandler}
            />
          )}
        </div>

        <section className="mainContent">
          <CarsWidget toggleFilter={toggleFilter} resetFilters={resetFilters} />

          <Scrollbars
            autoHide
            autoHeight={true}
            autoHeightMin={175}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className=" container listing-margin-top">
              <div className="listingNote mobileHide">    
                {!rawCarsData ? 0 : numberOfAvailableCars} cars found in{" "}
                {pickUpLocation[0]?.secondary_label} and vicinity
              </div>  

              <CarsListingFilters
                resetFilters={resetFilters}
                allFilters={allFilters}
                setAllFilters={setAllFilters}
                setSortingType={setSortingType}
                carFeaturesOptions={carFeaturesOptions}
                vehicleTypeOptions={vehicleTypeOptions}
                carsSortingFunction={carsSortingFunction}
                carCompaniesOptions={carCompaniesOptions}
                openVehicleTag={openVehicleTag}
                setOpenVehicleTag={setOpenVehicleTag}
                openCarTag={openCarTag}
                setOpenCarTag={setOpenCarTag}
                openCarFeatureTag={openCarFeatureTag}
                setOpenCarFeatureTag={setOpenCarFeatureTag}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />

              <CarFilterDisplay
                openVehicleTag={openVehicleTag}
                openCarTag={openCarTag}
                openCarFeatureTag={openCarFeatureTag}
                filters={allFilters}
                clearFilter={setAllFilters}
                setOpenVehicleTag={setOpenVehicleTag}
                setOpenCarTag={setOpenCarTag}
                setOpenCarFeatureTag={setOpenCarFeatureTag}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />

              {!serverErrors ? (
                <>
                  <div className="carResult">
                    {filteredData && dataPerPage && (
                      <CarsResult carsData={dataPerPage} />
                    )}
                  </div>
                  {carsPerPage < filteredData.length && (
                    <div className="paginationWrapper">
                      <Pagination
                        pageCount={Math.ceil(filteredData.length / carsPerPage)}
                        onPageChange={(e) => setCurrentPage(e.selected)}
                        forcePage={currentPage}
                      />
                      <ResultsOnPage className="results" />
                    </div>
                  )}
                </>
              ) : (
                // <div>{serverErrors}</div>
                <div className="not-found-content">
                  <h2>No Cars Found</h2>
                </div>
              )}
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  carsData: state.carsReducer.carsSearchData,
  serverErrors: state.carsReducer.carsError,
});

const mapDispatchToProps = (dispatch) => ({
  searchCarsAction: (payload) =>
    dispatch(carsActions.searchCarsAction(payload)),
  loaderText: (payload) =>
    dispatch(commonActions.loaderText(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarsListing);