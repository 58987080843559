import React, { useState } from "react";
import Button from "../FormComponent/Button";
import { has, pull, isEmpty } from "lodash";

function CategoriesFilter({
  title,
  keys,
  filterOptions,
  filters,
  setFilters,
  searchHotel,
  appliedFilters,
  setAppliedFilters,
  mobileCategory,
  setOpenFilterTag,
  openFilterTag,
  openFilterTagMob,
  setOpenFilterTagMob,
}) {
  const [showFilter, setshowFilter] = useState(false);
  function handleFilterCheck(isChecked, value) {
    if (isChecked && has(filters, keys)) {
      setFilters({ ...filters, [keys]: [...filters[keys], value] });
    } else if (isChecked && !has(filters, keys)) {
      setFilters({ ...filters, [keys]: [value] });
    } else {
      setFilters({ ...filters, [keys]: pull(filters[keys], value) });
    }

    if (isChecked && has(appliedFilters, keys)) {
      setAppliedFilters({
        ...appliedFilters,
        [keys]: [...appliedFilters[keys], value],
      });
    } else if (isChecked && !has(appliedFilters, keys)) {
      setAppliedFilters({ ...appliedFilters, [keys]: [value] });
    } else {
      setAppliedFilters({
        ...appliedFilters,
        [keys]: pull(appliedFilters[keys], value),
      });
    }

    if (has(filters, keys) && isEmpty(filters[keys])) {
      const newFilters = filters;
      delete newFilters[keys];
      setFilters({ ...newFilters });
    }

    if (mobileCategory) setOpenFilterTagMob(!openFilterTagMob);
  }

  function handleApply() {
    // openModal ? searchHotel(1, 999, true) : searchHotel();
    searchHotel();
    setOpenFilterTag(!openFilterTag);
  }
  return (
    <>
      <div
        className={mobileCategory ? "flterOption" : "cardHead dflex"}
        onClick={() => setshowFilter(!showFilter)}
      >
        {mobileCategory ? <span>{title}</span> : <h6>{title}</h6>}
        <span className={`fa fa-angle-${showFilter ? "up" : "down"}`}></span>
      </div>
      <div className={`cardBody checkbox-card ${!showFilter ? "d-none" : ""}`}>
        {filterOptions &&
          filterOptions.map((ele, index) => {
            return (
              <label className="customCheckbox" key={`${index}_category`}>
                <input
                  type="checkbox"
                  name="categories"
                  checked={
                    !isEmpty(appliedFilters) &&
                    appliedFilters[keys] &&
                    appliedFilters[keys].find((opt) => opt === ele.value)
                      ? "checked"
                      : false
                  }
                  value={ele.value}
                  onChange={(e) =>
                    handleFilterCheck(e.target.checked, ele.value)
                  }
                />
                <span>
                  {ele.label} {ele.count && `(${ele.count})`}
                </span>
              </label>
            );
          })}
        {mobileCategory ? (
          " "
        ) : (
          <Button
            className="btnBorder btnTopMargin"
            type="button"
            onClick={handleApply}
          >
            Apply
          </Button>
        )}
        {/* <Button
          className="btnBorder btnTopMargin"
          type="button"
          onClick={handleApply}
        >
          Apply
        </Button> */}
      </div>
    </>
  );
}

export default CategoriesFilter;