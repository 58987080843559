import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hotelActions } from "../../Stores/Actions";
import FllightDetails from "./FllightDetails";
import HotelDetails from "./HotelDetails";

function Completed() {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(hotelActions.getCompletedBookings("completed"));
  // }, []);
  const completedBookings = useSelector(
    (state) => state.hotelReducer.completedBookings
  );
  return completedBookings.length > 0 ? (
    <div className="mainWrap booking">
      {completedBookings &&
        completedBookings.map((item, index) => {
          if (item.booking_type === "hotel") {
            return <HotelDetails {...item} status={"COMPLETED"} key={`${index}hotel`} />;
          } else if (item.booking_type === "flight") {
            return <FllightDetails {...item} status={"COMPLETED"} key={`${index}flight`} />;
          }
        })}
    </div>
  ) : (
    <div className="not-found-booking">
      <h2>No Bookings</h2>
      <p>You have no completed bookings.</p>
      <p>Start your amazing journey with a new booking.</p>
      <Link to={"/landing"} className="btn">
        Back To Home
      </Link>
    </div>
  );
}

export default Completed;
