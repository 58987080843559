import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {carsActions} from "../../Stores/Actions";
import Iframe from "./Iframe";
import MessageBox from "./MessageBox";
import StartToEnd from "./StartToEnd";
import MobileHide from "./MobileHide";
import CarDetailsInfo from "./CarDetailsInfo";
import GoogleMapReact from "google-map-react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import carCompany from "../../Assets/img/carCompany.png";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";

import { Link } from "react-router-dom";

import carImage from "../../Assets/img/car5.png";
import markerImg from "../../Assets/img/location.png";

const Marker = ({ children }) => {
  return children || null;
};

function CarsBookingConfimation() {
  const dispatch = useDispatch();
  const carsContractData = JSON.parse(localStorage.getItem("carsContract"))?.getCarContractRequest.results;
  const {
    image,
    similar,
    name,
    features,
    isUnlimitedMiles,
    moreDetails,
    carCompanyLogo,
    longitude,
    latitude,
  } =
    localStorage.getItem("selectedCarsDetails") &&
    JSON.parse(localStorage.getItem("selectedCarsDetails"));
  
    const {booking_id, email} = localStorage.getItem("carsBookingRes") && JSON.parse(localStorage.getItem("carsBookingRes"));;

  const { dropoff_date, dropoff_time, pickup_date, pickup_time } =
    localStorage.getItem("carsPayload") &&
    JSON.parse(localStorage.getItem("carsPayload"));

  useEffect(() => {
    var payload= {
      "format": "json",
      "refid": process.env.REACT_APP_CARS_AUTO_DATA_REF_ID,
      "booking_id": booking_id,
      "email": email,
      "refid": process.env.REACT_APP_CARS_AUTO_DATA_REF_ID,
      "api_key": process.env.REACT_APP_CARS_AUTO_DATA_API_KEY,
    }
    dispatch(carsActions.retriveBooking(payload));
  }, [])

  return (
    <div className="siteBg confirmationPage">
      <div className="pageWrap pageWrapInner">
        <div className="fixedHeader">
          <HeaderNavbar />
        </div>
        <section className="mainContent carConfirmation">
          <Scrollbars
            autoHide
            autoHeight={true}
            autoHeightMin={175}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container dflex pageInternalScrollTopPadding">
              <div className="mainWrap">
                <div className="card2">
                  <MessageBox
                    booking_id={booking_id}
                    email={email}
                    message1={
                      <>
                        Thank You! <br className="mobileShow" />
                        Your Reservation is complete.
                      </>
                    }
                    message2="Your confirmation will be emailed to you. You can also access it under your Bookings in the Menu. Enjoy Your Trip."
                  />
                  <div className="travelDetails">
                    <div className="d-flex">
                      <div className="flightInfoLeft">
                        <h4 className="sectionTitle">{similar}</h4>
                        <h5 className="sectionSubTitle">{name} or similar*</h5>

                        <div className="flightInfoInWrap">
                          <div className="carSpecs">
                            <CarDetailsInfo
                              detail1={"People:"}
                              detail2={`${moreDetails?.people} people`}
                              iconName="icon-group font12"
                            />

                            <div className="dflex mb-5">
                              <span className="font14 lightText">
                                <span className="icon-work font12">
                                  <span className="path1"></span>
                                </span>{" "}
                                <span>Suitcases:</span>
                              </span>

                              <span className="font14 darkText">
                                {moreDetails?.suitcases} suitcases
                              </span>
                            </div>

                            {features["Auto Transmission"] ===
                              "Auto Transmission" && (
                              <CarDetailsInfo
                                iconName="icon-transmission font12"
                                detail1="Transmission:"
                                detail2="Automatic"
                                spanClassName="mobileHide"
                              />
                            )}

                            {features["Air Conditioning"] ===
                              "Air Conditioning" && (
                              <CarDetailsInfo
                                iconName="icon-icon3 font12"
                                detail1={"Air Conditioning:"}
                                detail2={"AC Included"}
                                spanClassName="mobileHide"
                              />
                            )}

                            {isUnlimitedMiles && (
                              <CarDetailsInfo
                                iconName={"icon-speedometer font12"}
                                detail1={"Mileage:"}
                                detail2={"Unlimited"}
                                spanClassName="mobileHide"
                              />
                            )}
                          </div>

                          <div className="startToEnd">
                            <StartToEnd
                              className={"startLocation"}
                              dateNTime={`${pickup_date} ${pickup_time}`}
                              content={moreDetails?.startAdd}
                            />

                            <StartToEnd
                              className={"endLocation"}
                              dateNTime={`${dropoff_date} ${dropoff_time}`}
                              content={
                                moreDetails?.startAdd === moreDetails?.endAdd
                                  ? "Same as Pick-up"
                                  : moreDetails?.endAdd
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="rightCol">
                        <div className="cardImg">
                          <div className="dflex carImgInfo">
                            <img
                              src={
                                !carCompanyLogo ? carCompany : carCompanyLogo
                              }
                              alt="company logo"
                            />
                            <span className="imageNote">
                              Actual car may vary from the one shown
                            </span>
                          </div>

                          <div className="carImgWrap">
                            <img
                              src={!image ? carImage : image}
                              alt="selected-car"
                            />
                          </div>
                        </div>

                        <div className="cardImg mb-0">
                          <div className="mapouter">
                            <div className="gmap_canvas">
                              <GoogleMapReact
                                bootstrapURLKeys={{
                                  key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                                }}
                                defaultCenter={{
                                  lat: 28.70406,
                                  lng: 77.102493,
                                }}
                                center={{ lat: latitude, lng: longitude }}
                                defaultZoom={12}
                              >
                                <Marker lat={latitude} lng={longitude}>
                                  <img
                                    src={markerImg}
                                    alt="marker"
                                    height="28px"
                                  />
                                </Marker>
                              </GoogleMapReact>
                              <Iframe src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sideBar">
                <div className="card2">
                  <div className="sideCardInPad">
                    <h5 className="cardTille">Summary of Charges</h5>
                    <div className="btmSpace">
                      <span className="sideLightLabel">Reserve Date: </span>
                      <span className="sideDarkValue">02/04/2020</span>
                    </div>
                  </div>
                  <div className="shadowBox">
                    <MobileHide
                      detail={`Base Fare (${moreDetails?.carPriceDetails?.days} day):`}
                      price={`$ ${moreDetails?.carPriceDetails?.total.toFixed(
                        2
                      )}`}
                    />
                    <MobileHide
                      detail={"Fees and Taxes:"}
                      price={`$${carsContractData?.pricing?.taxes_and_fees?.total}`}
                    />

                    <MobileHide
                      className={"totalValueBox"}
                      detail={"Amount Due at Pick-up:"}
                      price={`$ ${parseFloat(
                        parseFloat(moreDetails?.carPriceDetails?.total) +
                          parseFloat(
                            carsContractData?.pricing?.taxes_and_fees?.total
                          )
                      ).toFixed(2)}`}
                    />
                    <p className="boxLightText centerAlign">
                      This is an estimate of your rental car reservation total.
                      Prices may vary depending on special requests and items
                      purchased at the counter.
                    </p>
                  </div>
                </div>
                <div className="card2 helpBox">
                  <h5 className="cardTille">Need Help?</h5>
                  <Link to="/car-faq" className="faqLink">
                    <span className="icon-help"></span> See all FAQ’s
                    <i className="fa fa-angle-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className={"white-icon"}
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

export default CarsBookingConfimation;
