import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import WelcomeImg from "../../Assets/img/icons/Welcome.png";
import bestImg from "../../Assets/img/icons/best-practice.png";
import TipsImg from "../../Assets/img/icons/Tips-Tricks.png";
import TutorialImg from "../../Assets/img/icons/Tutorials.png";
import WebinarImg from "../../Assets/img/icons/webinar.png";
import EducationImg from "../../Assets/img/icons/Education.png";
import WelcomeBlue from "../../Assets/img/icons/Welcome-blue.png";
import BestImgBlue from "../../Assets/img/icons/best-blue.png";
import TipsImgBlue from "../../Assets/img/icons/Tips-Tricks-blue.png";
import TutorialImgBlue from "../../Assets/img/icons/Tutorials-blue.png";
import WebinarImgBlue from "../../Assets/img/icons/webinar-blue.png";
import EducationImgBlue from "../../Assets/img/icons/Education-blue.png";

function AcademyTab({ name }) {
  // State of class for list
  const location = useLocation();
  const pathName = location.pathname;

  const [active, setActive] = useState(() => {
    if (pathName == "/tripello-academy") return 0;
    else if (pathName == "/tips-tricks") return 1;
    else if (pathName == "/best-practices") return 2;
    else if (pathName == "/tutorials") return 3;
    else if (pathName == "/education") return 4;
    else if (pathName == "/Webinars") return 5;
    return 0;
  });

  // Handling class with id
  const handleActive = (id) => {
    setActive(id);
  };

  // Faqs tab list
  const AcademyList = [
    {
      id: 0,
      title: "Welcome",
      path: "/tripello-academy",
      image: active == 0 ? WelcomeBlue : WelcomeImg,
    },
    {
      id: 1,
      title: "Tips & Tricks",
      path: "/tips-tricks",
      image: active == 1 ? TipsImgBlue : TipsImg,
    },
    {
      id: 2,
      title: "Best Practices",
      path: "/best-practices",
      image: active == 2 ? BestImgBlue : bestImg,
    },
    {
      id: 3,
      title: "Tutorial",
      path: "/tutorials",
      image: active == 3 ? TutorialImgBlue : TutorialImg,
    },
    {
      id: 4,
      title: "Education",
      path: "/education",
      image: active == 4 ? EducationImgBlue : EducationImg,
    },
    {
      id: 5,
      title: "Webinars",
      path: "/Webinars",
      image: active == 5 ? WebinarImgBlue : WebinarImg,
    },
  ];

  return (
    <div className="col acadmey-content leftContent">
      <div className="tabsWrapper">
        {/* getting faqs tab list */}
        <ul className="list-unstyled">
          {AcademyList.map((item, index) => (
            <li
              key={index}
              className={active === index ? "active" : "  "}
              onClick={() => handleActive(index)}
            >
              <Link to={item.path} key={index}>
                <img src={item.image} alt="icon" key={index} />
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AcademyTab;
