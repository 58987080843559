import React, { useEffect, useState, useRef } from "react";
import { Form, Formik } from "formik";
import InputField from "../Component/FormComponent/InputField";
import SelectFieldOption from "../Component/FormComponent/SelectFieldOption";
import HeaderNavbar from "../Component/HeaderNavbar";
import FooterWithMediaIcon from "../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import { get_age } from "../Util/util";
import moment from "moment";
import {
  ProfileValidation,
  updatePasswordValidation,
} from "../Component/Validation/ProfileValidation";
import {
  countries,
  checkoutCountries,
  countryCode,
} from "../Data/static.service";
import { useNavigate } from "react-router-dom";
import { getBase64 } from "../Util/util";
import { authActions, carsActions } from "../Stores/Actions";
import { useDispatch, useSelector } from "react-redux";
import 'antd/dist/antd.css';
import { Tooltip } from 'antd';
import AddGuestInfoModal from "../Component/Modals/AddGuestInfoModal";
import MembershipCancelModal from "../Component/Modals/MembershipCancelModal";
import AddGuestModal from "../Component/Modals/AddGuestModal";
import IpadPortraitPopup from "../Component/Modals/IpadPortraitPopup";
import ProfileCancellation from "../Component/Modals/ProfileCancellation";
import Loader from "../Component/Loader/Loader";
import { ReactComponent as CameraIcon } from "../Assets/img/camera.svg";
import ProfileDefault from "../Assets/img/profileDetault.jpeg";
import CustomPhoneInput from "../Component/PhoneInput";
import { getLocationParams } from "../Util/util";


const Profile = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const locationSearch = getLocationParams();

  const [paypalSuccess, setPaypalSuccess] = useState("")
  const [paypalError, setPaypalError] = useState("")
  useEffect(() => {
    if (!locationSearch?.code) return
    const payload = {
      code: locationSearch?.code
    }
    dispatch(authActions.getPaypalStatus(payload)).then((res) => {
      if (res.status == 200) {
        setPaypalError("")
        setPaypalSuccess("Your paypal account verified successfully")
      } else if (res?.status && res?.status != 200) {
        setPaypalSuccess("")
        setPaypalError(res?.data?.error?.message)
      }
    });
  }, [locationSearch?.code])


  const showLoader = useSelector((state) => state.commonReducer.loader);
  const passwordError = useSelector((state) => state.authReducer.passwordError);
  const guestList = useSelector((state) => state.authReducer.guestList);
  const { cancelMembershipSuccess, cancelMembershipError, loading } =
    useSelector((state) => state.authReducer);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [showGuestDetailModal, setShowGuestDetailModal] = useState(false);
  const [localUserData, setLocalUserData] = useState({});
  const [profileImg, setProfileImg] = useState("");
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [showAddUserInitModal, setShowAddUserInitModal] = useState(false);
  const [showAddUserFinalModal, setShowAddUserFinalModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);


  const [freemiumUser, setFreemiumUser] = useState(true);
  const nagivate = useNavigate();
  const isFreemium = process.env.REACT_APP_FREMIUM_USER_TYPE_ID == JSON.parse(localStorage.getItem("user"))?.type_id

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("user") && localStorage.getItem("accessToken")) {
      setLocalUserData(JSON.parse(localStorage.getItem("user")));
      if (user.type_id !== process.env.REACT_APP_FREMIUM_USER_TYPE_ID) {
        setFreemiumUser(false);
      }
    } else {
      nagivate("/login");
    }
    // fetching guest list for the current user
    dispatch(authActions.getGuestListAction(user.user_id));
  }, []);

  useEffect(() => {
    if (cancelMembershipSuccess) {
      handleCancellationModal(true);
      // set success as null here;
      dispatch(authActions.resetCancelMembershipStatus());
    }
  }, [cancelMembershipSuccess, showCancellationModal]);

  useEffect(() => {
    document.querySelector("body").classList.add("profilePage");
    return () => {
      document.querySelector("body").classList.remove("profilePage");
    };
  }, []);

  const handleImageUpload = (file) => {
    getBase64(file[0]).then((dataUri) => {
      dispatch(
        authActions.updateUserProfileAction({ profile_image: dataUri }, localUserData?.user_id)
      );
      setProfileImg(dataUri)
    });

  };

  const countryOptions = countries.map((ele, index) => {
    return { value: ele.sortname, label: ele.name, id: ele.id };
  });

  const phoneCodeOptions = countryCode.map((ele) => ({
    value: ele.id,
    label: ele.label,
  }));

  const optionsTitle = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Neutral.", label: "Neutral." },
  ];

  const profileInfoInitialValues = {
    title: localUserData?.title || null,
    firstName: localUserData?.first_name || "",
    lastName: localUserData?.last_name || "",
    email: localUserData?.email || "",
    address_line_1: localUserData?.user_address?.address_line_1 || "",
    address_line_2: localUserData?.user_address?.address_line_2 || "",
    city: localUserData?.user_address?.city || "",
    country: localUserData?.country || "",
    state: localUserData?.user_address?.state || "",
    zip: localUserData?.user_address?.zip || "",
    phoneInputValue: localUserData?.phone || "",
    phoneCode: "",
  };

  const updatePasswordInitialValue = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };

  const handleSubmit = (values) => {
    const payload = {
      title: values.title,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phoneInputValue: values.phoneInputValue,
      user_address: {
        address_line_1: values.address_line_1,
        address_line_2: values.address_line_2,
        city: values.city,
        state: values.state,
        country: values.country,
      },
      phoneCountry: countries.find((ele) => ele.sortname === values.country)
        .sortname,
      phone: values.phoneInputValue,
      zip: values.zip,
    };

    dispatch(
      authActions.updateUserProfileAction(payload, localUserData?.user_id)
    );
  };
  const handleCustomChange = (formikProps, field, value) => {
    formikProps.setFieldValue(field, value);
  };

  const handleValidateZip = (value, formikProps) => {
    formikProps.setFieldValue("zipCode", value);
    if (value.length >= 2) {
      dispatch(carsActions.validateZip(value))
        .then((res) => res.data)
        .then((zipData) => {
          if (zipData) {
            checkoutCountries.map((ele) => {
              if (ele["alpha-2"] == zipData.country_short_name) {
                formikProps.setFieldValue("country", ele["alpha-2"]);
                formikProps.setFieldValue("city", zipData.city_long_name);
                formikProps.setFieldValue("state", zipData.short_name);
              }
            });
          }
        });
    }
  };

  const handlePasswordSubmit = (values) => {
    const payload = {
      old_password: values.old_password,
      password: values.password,
      password_confirmation: values.password_confirmation,
    };
    dispatch(authActions.updatePassowrdAction(payload));
  };

  const handleMembershipModal = () => {
    setFreemiumUser(true);
    setShowMembershipModal(!showMembershipModal);
  };

  const handleCancellationModal = (value) => {
    setShowCancellationModal(value);
  };

  const handleGuestDetails = (guest) => {
    setSelectedGuest(guest);
    setShowGuestDetailModal(true);
  };


  return (
    <>
      {(showLoader || loading) && <Loader showLoader={showLoader || loading} />}
      <div className="siteBg profilePage">
        <div className="pageWrap pageWrapInner">
          <div className="fixedHeader">
            <HeaderNavbar />
          </div>

          <section className="mainContent">
            <Scrollbars
              autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container dflex pageInternalScrollTopPadding">
                <div className="leftSideBar">
                  <div className="card2 profileCnt profile-info-card">
                    <div className="profileImage">
                      <img
                        src={
                          profileImg
                            ? profileImg
                            : localUserData?.profile_image || ProfileDefault
                        }
                        alt="user"
                      />
                      <div className="custom-file-upload">
                        <input
                          id="profile-pic"
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(e) => handleImageUpload(e.target.files)}
                        />
                        <CameraIcon />
                      </div>
                    </div>
                    <div className="profile-name-wrap">
                      <h4 className="profileName">
                        <Tooltip placement="topLeft" title={`${localUserData?.first_name} ${localUserData?.last_name}`}>
                          {`${localUserData?.first_name} ${localUserData?.last_name}`}
                        </Tooltip></h4>
                    </div>
                    <p>
                      <a className="profileLink" href={`tel:${localUserData?.phone}`}>
                        <i className="icon-phone"></i>
                        {localUserData?.phone ? `+${localUserData?.phone}` : "(---) --- ----"}
                      </a>
                    </p>
                    <p>
                      <a
                        className="profileLink"
                        href={`mailto:${localUserData?.email}`}
                      >
                        <i className="icon-mail"></i>
                        {localUserData?.email}
                      </a>
                    </p>
                    <div className="member-info">

                      <div className="inputStyle2">
                        <label className="labelStyle" for="">
                          Your Member Number
                        </label>
                        <div className="input-info">
                          {localUserData?.member_id || "--"}
                        </div>
                      </div>

                      {localUserData?.type_id != process.env.REACT_APP_FREMIUM_USER_TYPE_ID ? <div className="cancel-subscription">
                        <label className="labelStyle" for="">
                          Switch to OFF to Cancel Your Subscription
                        </label>
                        <div className="switcher">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customSwitches"
                              checked={!freemiumUser}
                              disabled={freemiumUser}
                              onChange={() => {
                                handleMembershipModal();
                              }}
                            />
                            <label
                              className="custom-control-label"
                              for="customSwitches"
                            ></label>
                          </div>
                          <div className="button-switcher">
                            <label className="labelStyle" for="">
                              ON
                            </label>
                            <label className="labelStyle" for="">
                              OFF
                            </label>
                          </div>
                        </div>
                      </div> : null}

                      <div>
                        {localUserData?.type_id != process.env.REACT_APP_FREMIUM_USER_TYPE_ID && <label className="labelStyle" >
                          (Renewal Date : {localUserData?.renew_date || isFreemium ? (localStorage?.currentGeoLocation == `"${"US"}"` ? moment(localUserData?.renew_date).format("DD-MM-YYYY") : moment(localUserData?.renew_date).format("MM-DD-YYYY")) : "--/--/----"})
                        </label>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mainWrap">
                  <Formik
                    innerRef={formikRef}
                    onSubmit={handleSubmit}
                    validationSchema={ProfileValidation}
                    initialValues={profileInfoInitialValues}
                    enableReinitialize={true}
                  // validateOnBlur={false}

                  >
                    {(formikProps) => {
                      const {
                        values,
                        handleBlur,
                        touched,
                        handleChange,
                        errors,
                        setErrors
                      } = formikProps;


                      // console.error(errors, "errors");
                      // console.warn(values, "valuess");
                      // console.log(touched, "touched");

                      useEffect(() => {
                        if (values.phoneInputValue.slice(values.phoneCode.length).length < 10) {
                          setErrors({
                            "phoneInputValue": "Invalid Phone Number"
                          });
                        }
                      }, [errors.phoneInputValue, values.phoneInputValue])



                      const optionsStateFilter = values.country
                        ? checkoutCountries.find(
                          (ele) => ele["alpha-2"] === values.country
                        ).states
                        : checkoutCountries[0].states;
                      const stateOptions = optionsStateFilter.map((ele) => ({
                        value: ele.code,
                        label: ele.name,
                      }));
                      return (
                        <Form>
                          <div className="card2 cardPad2 profile-info-card">
                            <h4 className="boxTitle">
                              Your Profile Information
                            </h4>
                            <div className="halfCol">
                              <SelectFieldOption
                                name={"title"}
                                label={"Title"}
                                value={values.title}
                                options={optionsTitle}
                                placeholder={"Select Title"}
                                onChange={handleCustomChange}
                                onBlur={handleBlur}
                                error={errors.title}
                                touched={touched.title}
                                formikProps={formikProps}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />

                              <InputField
                                name={"firstName"}
                                type="text"
                                placeholder={"First Name"}
                                label={"First Name"}
                                value={values.firstName}
                                error={errors.firstName}
                                touched={touched.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />

                              <InputField
                                name={"lastName"}
                                type="text"
                                placeholder={"Last Name"}
                                label={"Last Name"}
                                value={values.lastName}
                                error={errors.lastName}
                                touched={touched.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />
                            </div>
                            <div className="halfCol">

                              <div className="col formGroup col inputStyle inputStyle2">
                                <CustomPhoneInput
                                  inputProps={{
                                    name: "phoneInputValue",
                                    required: true,
                                  }}
                                  value={values.phoneInputValue}
                                  onChange={(phoneNumber, data) => {
                                    formikProps.setFieldValue("phoneInputValue", phoneNumber);
                                    formikProps.setFieldValue("phoneCode", data.dialCode);
                                  }}
                                  error={errors.phoneInputValue}
                                  touched={touched.phoneInputValue}
                                  onBlur={handleBlur}
                                  label={"Phone Number"}
                                  labelClassName="labelStyle"
                                // formikProps={formikProps}
                                />
                              </div>

                              <InputField
                                name={"email"}
                                type="text"
                                placeholder={"Enter Email"}
                                label={"Email"}
                                value={values.email}
                                error={errors.email}
                                touched={touched.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                                disabled={true}
                              />
                            </div>
                            <div className="halfCol">
                              <InputField
                                name={"address_line_1"}
                                type="text"
                                placeholder={"Address Line 1"}
                                label={"Address Line 1"}
                                value={values.address_line_1}
                                error={errors.address_line_1}
                                touched={touched.address_line_1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />

                              <InputField
                                name={"address_line_2"}
                                type="text"
                                placeholder={"Address Line 2"}
                                label={"Address Line 2"}
                                value={values.address_line_2}
                                error={errors.address_line_2}
                                touched={touched.address_line_2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />
                            </div>
                            <div className="halfCol">
                              <SelectFieldOption
                                name={"country"}
                                label={"Select Country"}
                                value={values.country}
                                options={countryOptions}
                                placeholder={"Select Country"}
                                onChange={handleCustomChange}
                                onBlur={handleBlur}
                                error={errors.country}
                                touched={touched.country}
                                formikProps={formikProps}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />

                              <SelectFieldOption
                                name={"state"}
                                label={"Select State"}
                                value={values.state}
                                options={stateOptions}
                                placeholder={"Select State"}
                                onChange={handleCustomChange}
                                onBlur={handleBlur}
                                error={errors.state}
                                touched={touched.state}
                                formikProps={formikProps}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />
                            </div>
                            <div className="halfCol">
                              <InputField
                                name={"city"}
                                type="text"
                                placeholder={"City"}
                                label={"City"}
                                value={values.city}
                                error={errors.city}
                                touched={touched.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />

                              <InputField
                                name={"zip"}
                                type="text"
                                placeholder={"ZIP/Postal Code"}
                                label={"ZIP/Postal Code"}
                                value={values.zip}
                                error={errors.zip}
                                touched={touched.zip}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleBlur(e)
                                  handleValidateZip(values.zip, formikProps)
                                }
                                }
                                className="col inputStyle inputStyle2"
                                labelClassName="labelStyle"
                              />
                            </div>

                            <div className="btnWrap rightAlign">
                              <input
                                type="submit"
                                className="btn btn2"
                                value="Update Profile"
                              />
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>

                  <Formik
                    onSubmit={handlePasswordSubmit}
                    validationSchema={updatePasswordValidation}
                    initialValues={updatePasswordInitialValue}
                    enableReinitialize={true}
                  >
                    {(formikProps) => {
                      const { values, touched, handleChange, handleBlur, errors } =
                        formikProps;
                      return (
                        <Form>
                          <div className="card2 cardPad2 passwordBox">
                            <h4 className="boxTitle">Change Your Password</h4>
                            <div className="col3">
                              <div className="inputStyle inputStyle2">
                                <InputField
                                  name="old_password"
                                  type="password"
                                  placeholder="Enter Current Password"
                                  label="Current Password"
                                  value={values.old_password}
                                  error={errors.old_password}
                                  touched={touched.old_password}
                                  onChange={handleChange}
                                  labelClassName="labelStyle"
                                  onBlur={handleBlur}

                                />
                              </div>
                              <div className="inputStyle inputStyle2">
                                <InputField
                                  name="password"
                                  type="password"
                                  placeholder="Enter New Password"
                                  label="New Password"
                                  value={values.password}
                                  error={errors.password}
                                  touched={touched.password}
                                  onChange={handleChange}
                                  labelClassName="labelStyle"
                                  onBlur={handleBlur}
                                />
                              </div>
                              <div className="inputStyle inputStyle2">
                                <InputField
                                  name="password_confirmation"
                                  type="password"
                                  placeholder="Confirm New Password"
                                  label="Confirm New Password"
                                  value={values.password_confirmation}
                                  error={errors.password_confirmation}
                                  touched={touched.password_confirmation}
                                  onChange={handleChange}
                                  labelClassName="labelStyle"
                                  onBlur={handleBlur}

                                />
                              </div>
                            </div>
                            <div className="btnWrap rightAlign">
                              <input
                                type="submit"
                                className="btn btn2"
                                value="Update Password"
                              />
                            </div>

                            {passwordError && (
                              <p style={{ color: "red" }}>
                                Old Password doesn't match
                              </p>
                            )}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>


                  <a style={{ color: "blue" }} href="https://www.sandbox.paypal.com/connect/?flowEntry=static&client_id=AUHg0mopZSqgNQFbmgObPf-PvgssKWcwvS760qWG1fonZYSFPtFFjUrwvhhZ3fPfNYjOtb6dzpaNQAv4&response_type=code&scope=openid%20email%20profile%20https://uri.paypal.com/services/paypalattributes&redirect_uri=https://tripello.com/profile">
                    Varify Email
                  </a>

                  <br />
                  <p className="success-messages"> {paypalSuccess}</p>
                  <br />
                  <p className="error-messages">{paypalError}</p>


                  {localUserData?.type_id != process.env.REACT_APP_FREMIUM_USER_TYPE_ID &&
                    <div className="card2 cardPad2 save-traveller-box">
                      <div className="top-content">
                        <div className="left-content">
                          <h4 className="boxTitle">Add Authorized User</h4>
                          <h6>
                            You have{" "}
                            {guestList?.length == 1 ? 0 + " additional User" : (guestList?.length == 2 ? `1 additional User` : `${guestList?.length - 1} additional Users`)}
                          </h6>

                        </div>
                        {(guestList?.length < 4 || !guestList?.length) &&
                          <div className="right-content">
                            <button
                              className="btn add-traveller-btn"
                              type="button"
                              onClick={() => setShowAddUserInitModal(true)}
                            >
                              {" "}
                              Add User
                            </button>
                          </div>
                        }
                      </div>
                      <div className="travellers-info">
                        {guestList &&
                          guestList?.length &&
                          guestList.map((guest, index) => {
                            // get age from dob difference
                            const age = Math.floor(get_age(guest?.dob)) < 0 ? 1 : Math.floor(get_age(guest?.dob));
                            if (guest.email == localUserData?.email) return
                            return (
                              <div key={index} className="travellers-item">
                                <div className="left-content">
                                  <div className="icon-image">
                                    <i className="icon-account-circle"></i>
                                  </div>
                                  <div className="travellers-name">
                                    <h6>
                                      <strong>{guest.fname}</strong> ({" "}
                                      {guest.gender === "M"
                                        ? "Male"
                                        : guest.gender === "F"
                                          ? "Female"
                                          : "Other"}
                                      {age ? `, ${age} years` : ""})
                                    </h6>
                                  </div>
                                </div>
                                <div className="right-content">
                                  <div className="view-detail-link">
                                    <button
                                      className="travellers-view-detail-btn"
                                      onClick={() => handleGuestDetails(guest)}
                                    >
                                      View Detail
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </Scrollbars>
          </section>

          <FooterWithMediaIcon
            className="white-icon"
            iconClassName="icon-white"
          />

          <div id="back-to-top" className="back-to-top">
            <button className="btn btn-primary" title="Back to Top">
              {/* <svg   version="1.1" id="Capa_1" x="0px" y="0px"
              viewBox="0 0 31.479 31.479" style="enable-background:new 0 0 31.479
										31.479;" width="18px" height="18px" class="svg lazyloading replaced-svg">
              <path style="fill:#004185;" d="M26.477,10.274c0.444,0.444,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-8.047-8.047
											v26.555c0,0.619-0.492,1.111-1.111,1.111c-0.619,0-1.127-0.492-1.127-1.111V3.813l-8.031,8.047c-0.444,0.429-1.159,0.429-1.587,0
											c-0.444-0.444-0.444-1.143,0-1.587l9.952-9.952c0.429-0.429,1.143-0.429,1.571,0L26.477,10.274z">
              </path>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
              <g>
              </g>
            </svg>   */}
            </button>
          </div>
          {showCancellationModal && (
            <ProfileCancellation
              isOpen={showCancellationModal}
              handleCancellationModal={handleCancellationModal}
            />
          )}
          <IpadPortraitPopup isOpen={false} />

          {/* add new guest user */}
          {showAddUserFinalModal && (
            <AddGuestModal
              setShowAddUserFinalModal={setShowAddUserFinalModal}
              showAddUserFinalModal={showAddUserFinalModal}
            />
          )}

          {/* show guest details */}
          {showGuestDetailModal && (
            <AddGuestModal
              setShowAddUserFinalModal={setShowGuestDetailModal}
              showAddUserFinalModal={showGuestDetailModal}
              guest={selectedGuest}
            />
          )}

          <MembershipCancelModal
            setShowMembershipModal={setShowMembershipModal}
            showMembershipModal={showMembershipModal}
            setFreemiumUser={setFreemiumUser}
          />
          <AddGuestInfoModal
            setShowAddUserFinalModal={setShowAddUserFinalModal}
            setShowAddUserInitModal={setShowAddUserInitModal}
            showAddUserInitModal={showAddUserInitModal}
          />
        </div>
      </div>
    </>
  );
};

export default Profile;