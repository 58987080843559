import React,{useState,useEffect} from "react";
import InputField from "./InputField.js";
import SelectFieldOption from "../../Component/FormComponent/SelectFieldOption";
import CustomPhoneInput from "../../Component/PhoneInput";
import { checkoutCountries, countries } from "../../Data/static.service";
import {isEmpty} from 'lodash'

function PrimaryInfo(props) {
  const {
    handleCustomChange,
    formikProps, 
    titleOption,
    handleValidateZip, 
    userOptions,
    numberCustomChange,
    hideUserSelect, 
    stateOption,
    countrySelect,
    setStateOption, 
  } = props;
  const { values, errors, touched, handleBlur, handleChange } = formikProps;



  const countryOptions = countries.map((ele, index) => {
    return { value: ele.sortname, label: ele.name, id: ele.id };
  });



  return (
    <div className="card2 primaryInfo">
      <div className="primaryInfoTitle">
        <h4 className="boxTitle">Primary Contact Information</h4>

        {userOptions && !hideUserSelect && (
          <div className="selectStyle">
            <div className="selectInWrap">
                <SelectFieldOption
                  name={"user"}
                  // label={userLabel}
                  value={values.user}
                  options={userOptions}
                  onCustomChange={handleCustomChange}
                  onBlur={handleBlur}
                  error={errors.user}
                  touched={touched.user}
                  formikProps={formikProps}
                  isSearchable={false}
                />
            </div>
          </div>
        )}
      </div>

      <div className="row primaryInfo">
        <SelectFieldOption
          label="Title"
          name="title"
          value={values.title}
          options={titleOption}
          placeholder="Title"
          onChange={handleCustomChange}
          onBlur={handleBlur}
          error={errors.title}
          touched={touched.title}
          formikProps={formikProps}
        />

        <div className=" formGroup fName">
          <InputField
            name="firstName"
            label="First Name"
            placeholder="First Name"
            type="text"
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <InputField
          name="lastName"
          label="Last Name"
          placeholder="Last Name"
          type="text"
          value={values.lastName}
          error={errors.lastName}
          touched={touched.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          className="lastNameCol"
        />
      </div>

      <div className="col3 flexStart mdFullWidth emailWrapper">
        <InputField
          name="email"
          label="Email"
          placeholder="Email"
          type="email"
          value={values.email}
          error={errors.email}
          touched={touched.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <div className="col formGroup">
          <CustomPhoneInput
            inputProps={{
              name: "phoneNumber",
              required: true,
            }}
            value={values.phoneNumber}
            onChange={(phoneNumber,data) => { 
              numberCustomChange(formikProps, "phoneNumber", phoneNumber);
              numberCustomChange(formikProps, "phoneNumberCode", data.dialCode);
            }}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            onBlur={handleBlur}
            label="Phone Number"
          />
        </div>

        <SelectFieldOption
          name="nationality"
          label="Nationality"
          value={values.nationality}
          options={countryOptions}
          placeholder="Select Country"
          onChange={handleCustomChange}
          onBlur={handleBlur}
          error={errors.nationality}
          touched={touched.nationality}
          formikProps={formikProps}
          countrySelect={countrySelect}
        />
      </div>

      <div className="row">
        <InputField
          name="addressLineOne"
          label="Address Line 1"
          placeholder="Address Line 1"
          value={values.addressLineOne}
          error={errors.addressLineOne}
          touched={touched.addressLineOne}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <InputField
          name="addressLineTwo"
          label="Address Line 2"
          placeholder="Address Line 2"
          value={values.addressLineTwo}
          error={errors.addressLineTwo}
          touched={touched.addressLineTwo}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      <div className="row">
        <InputField
          name="city"
          label="City"
          placeholder="City"
          value={values.city}
          error={errors.city}
          touched={touched.city}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <SelectFieldOption
          name="state"
          label="State/Province/Region"
          value={values.state}
          options={stateOption}
          placeholder="Select State"
          onChange={handleCustomChange}
          onBlur={handleBlur}
          error={errors.state}
          touched={touched.state}
          formikProps={formikProps}
        />

        <InputField
          name="zipCode"
          label="ZIP/Postal Code"
          placeholder="ZIP/Postal Code"
          value={values.zipCode}
          error={errors.zipCode}
          touched={touched.zipCode}
          onChange={(e) => handleValidateZip(e, formikProps)}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
}

export default PrimaryInfo;