import React from "react";
import Icon from "./Icon";
import Select, { components, createFilter } from "react-select";
function IconInputField(props) {
  const {
    type,
    placeholder,
    name,
    onChange,
    value,
    label,
    iconName,
    onFocus,
    onBlur,
    className,
    serverErrors,
    successMessage,
    errors,
    touched,
    src,
    inputClassName,
    autoComplete,
    isEmailExist,
    options,
    formikProps,
  } = props;

  const customStyles = {};

  return (
    //Input field with Icon
    <>
      <div className="inputWrap form-group">
        {iconName ? (
          // <Icon iconName={iconName} className={className} />
          <i
            className={`${iconName} icon countryIcon`}
            style={{ color: "1D6EC9" }}
          ></i>
        ) : (
          <div className="icon">
            <img src={src} alt="icon" />
          </div>
        )}

        {label && <label>{label}:</label>}

        <Select
          className={`${inputClassName} removeSelectPadding`}
          width="100%"
          options={options}
          styles={customStyles}
          value={value}
          onChange={(value) => {
            formikProps.setFieldValue(name, value);
          }}
          placeholder={placeholder}
          classNamePrefix="custom"
          filterOption={createFilter({ matchFrom: "start" })}
        />
      </div>
      {errors && touched && <div className="error-messages">{errors}</div>}
    </>
  );
}

export default IconInputField;
