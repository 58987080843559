import { marketingSiteConstants } from "../Constants/marketingSiteConstants";

const initialState = {
    walletData:null,
    transactionData:null
};

const marketingSiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case marketingSiteConstants.GET_WALLET_TRANSACTION_REQUEST:
      return {
        ...state,
        walletData : null
      };
    case marketingSiteConstants.GET_WALLET_TRANSACTION_SUCCESS: 
      return {
        ...state,
        walletData : action.response.data
      };
    case marketingSiteConstants.GET_WALLET_TRANSACTION_FAILURE:
      return {
        ...state,
        walletData : null
      };

    case marketingSiteConstants.GET_WALLET_TRANSACTION_DETAILS_REQUEST:
      return {
        ...state, 
        transactionData : null
      };
    case marketingSiteConstants.GET_WALLET_TRANSACTION_DETAILS_SUCCESS:  
      return {
        ...state,
        transactionData : action.response.data
      };
    case marketingSiteConstants.GET_WALLET_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        transactionData : null
      };

      default:
        return state;
  }
};

export default marketingSiteReducer;