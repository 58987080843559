import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import TabMenu from "../AffiliateDashbord/TabMenu";
import PremiumMemberInvites from "../AffiliateDashbord/PremiumMemberInvites";
import Link from "../AffiliateDashbord/Link";
import Email from "../AffiliateDashbord/Email";
import QrCode from "../AffiliateDashbord/QrCode";
import Card from "../AffiliateDashbord/Card";
import TabLayout from "../AffiliateDashbord/TabLayout";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import DetailsInfoModal from "../AffiliateDashbord/DetailsInfoModal";
import Scrollbars from "react-custom-scrollbars";
import infoIcon from "../../Assets/img/info-icon-white.png";
import memberInviteImg from "../../Assets/img/rewards.png";
import linkImg from "../../Assets/img/link.png";
import contentCopyImg from "../../Assets/img/content-copy.png";
import whatsAppImg from "../../Assets/img/whatsapp-bg.png";
import telegramImg from "../../Assets/img/telegram-bg.png";
import tiktokImg from "../../Assets/img/tiktok.png";
import instagramImg from "../../Assets/img/instagram-bg2.png";
import pinterestImg from "../../Assets/img/pinterest.png";
import facebookImg from "../../Assets/img/facebook-bg2.png";
import linkedinImg from "../../Assets/img/linkedin-bg.png";
import twitterImg from "../../Assets/img/twitter-bg2.png";
import emailIcon from "../../Assets/img/email-icon.png";
import qrCodeIcon from "../../Assets/img/qr-code-icon.png";
import qrImage from "../../Assets/img/qr-code-image.png";
import fileDownloadImage from "../../Assets/img/file-download.png";
import appleWalletImage from "../../Assets/img/Apple-Wallet.png";
import gPayImage from "../../Assets/img/G-Pay.png";
import exampleImage from "../../Assets/img/air.png";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";

function MemberRewards() {
  // State for details info modal
  const [openModal, setOpenModal] = useState(false);
  const [msgShow, setMsgShow] = useState(false);
  // user data
  const user = JSON.parse(localStorage.getItem("user"));
  const currentHost = window.location.origin;
  // Handle open modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // Handle close modal
  const handleCloseOpenModal = () => {
    setOpenModal(false);
  };

  const freemiumUrl = `${currentHost}/new-freemium-signup?ref=${user?.email}&fname=${user?.first_name}&lname=${user?.last_name}`;

  const copied = "Copied!";

  const shareUrlFreemium =
    `${currentHost}` +
    "/freemium-signup?ref=" +
    (JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user"))?.email
      ? JSON.parse(localStorage.getItem("user"))?.email
      : "");

  const title =
    "Large discounts on Flights, Hotels, Activites, Car Rentals and Extended Stay all round the World!";

  // Sharing icon list
  const socialLinkList = [
    {
      href: "",
      image: (
        <WhatsappShareButton
          url={shareUrlFreemium}
          title={title}
          separator=":: "
          className="Demo__some-network__share-button"
        >
          <img src={whatsAppImg} alt="social icon" />
        </WhatsappShareButton>
      ),
    },
    {
      href: "",
      image: (
        <TelegramShareButton title={title} url={shareUrlFreemium}>
          <img src={telegramImg} alt="social icon" />
        </TelegramShareButton>
      ),
    },
    {
      href: "",
      image: (
        <a
          href="https://www.tiktok.com"
          target="_blank"
          className="Demo__some-network__share-button"
        >
           <img src={tiktokImg} alt="social icon" />
        </a>
      ),
    },
    {
      href: "",
      image: (
        <a
          href="https://instagram.com/"
          target="_blank"
          className="Demo__some-network__share-button"
        >
           <img src={instagramImg} alt="social icon" />
        </a>
      )
    },
    {
      href: "",
      image: (
        <PinterestShareButton
          url={String(window.location)}
          media={`${String(window.location)}/${exampleImage}`}
          className="Demo__some-network__share-button"
        >
          <img src={pinterestImg} alt="social icon" />
        </PinterestShareButton>
      ),
    },
    {
      href: "",
      image: (
        <FacebookShareButton
          url={shareUrlFreemium}
          quote={title}
          className="Demo__some-network__share-button"
        >
          <img src={facebookImg} alt="social icon" />
        </FacebookShareButton>
      ),
    },
    {
      href: "",
      image: (
        <LinkedinShareButton
          url={shareUrlFreemium}
          className="Demo__some-network__share-button"
        >
          <img src={linkedinImg} alt="social icon" />
        </LinkedinShareButton>
      ),
    },
    {
      href: "",
      image: (
        <TwitterShareButton
          url={shareUrlFreemium}
          title={title}
          className="Demo__some-network__share-button"
        >
          <img src={twitterImg} alt="social icon" />
        </TwitterShareButton>
      ),
    },
  ];

  const MsgShowFun = () => {
    setMsgShow((prev) => !prev);
    setTimeout(() => {
      setMsgShow((prevVal) => !prevVal);
    }, 2000);
  };

  const SocialCardComponant = () => {
    return (
      <>
        <Link
          MsgShowFun={MsgShowFun}
          msgShow={msgShow}
          para1="Share your individual invatation link. Copy the link and paste it into you personal email, send it via SMS or share it through your favorite Social Media Channels."
          contentCopyImg={contentCopyImg}
          heading2="Click to Copy Link"
          para2="Click on the icon to take you to your favorite Social Media Platform to post your link."
          socialLinkList={socialLinkList}
          linkText={freemiumUrl}
          copied={copied}
          className="sharingIconsList"
        />
      </>
    );
  };

  const EmailCardComponant = () => {
    return (
      <>
        <Email
          para2="Enter your recipients contact information and we will directly send and Email with your individual invitation."
          para="By sending the invite directly you agree to the Terms and Conditions"
          currentUserId={JSON.parse(localStorage.getItem("user")).user_id}
          affiliateLink={freemiumUrl}
        />
      </>
    );
  };
  const QrCardComponant = () => {
    return (
      <>
        <QrCode
          para={
            <>
              Share your individual QR Code directly from this page or download
              it and share it offline.
            </>
          }
          qrImage={qrImage}
          fileDownloadImage={fileDownloadImage}
          heading="Download QR Code (.PNG)"
          appleWalletImage={appleWalletImage}
          gPayImage={gPayImage}
          linkText={freemiumUrl}
        />
      </>
    );
  };

  return (
    <div className="siteBg membersCheckoutPageWrap membersRewardsPageWrap rewards-page">
      <div className="pageWrap pageWrapInner">
        <div className="fixedHeader">
          <HeaderNavbar />
        </div>
        <section className="mainContent rewards-padding-top" >
          <Scrollbars
            autoHeight={true}
            autoHeightMin={10}
            autoHeightMax={`calc(100vh)`}
            className="pageInternalScroll mCustomScrollbar _mCS_1" 
          >
            <div className="container premium-plus-member-rewards-pageWrap pageInternalScrollTopPadding">
              {/* <TabMenu infoIcon={infoIcon} modalOpen={handleOpenModal}/> */}
              <PremiumMemberInvites
                className="rewardsSection"
                heading="Tripello Rewards"
                para="Share our Tripello Freemium Membership with your friends,
                    family and whoever you think loves and enjoys Travel. For
                    everyone that signs up and then upgrades to a Premium or
                    Premium Plus Membership, you will receive either $25 or $50 Rewards
                    directly into your wallet. ﻿You can apply your Rewards to any of your
                    upcoming bookings on Tripello (Car Rental Services excluded)."
                // para1="You can target prospects with the «Try before you Buy» mindset through your individual Freemium Invite link, or if you are confident that your prospect are ready to sign up immediately, use the Premium Invite link."
                memberInviteImg={memberInviteImg}
                isContent
              />
              <div className="sectionTitle">
                <h2>
                Affiliate Invite Options
                </h2>
              </div>

              <div className="sharingWrapper desktopViewDisplayBlock" style={{marginBottom: "50px"}}>
                <div className="row card-wrapper">
                  <Card
                    image={linkImg}
                    heading="Link"
                    component={<SocialCardComponant />}
                  />
                  <Card
                    image={emailIcon}
                    heading="Email"
                    component={<EmailCardComponant />}
                  />
                  <Card
                    image={qrCodeIcon}
                    heading="QR"
                    component={<QrCardComponant />}
                  />
                </div>
              </div>
              <div className="mobileSharingWrapper mobileSharingWrapper1 sharingWrapper tabletViewDisplayBlock">
                <div className="card">
                  <TabLayout
                    SocialCardComponant={<SocialCardComponant />}
                    EmailCardComponant={<EmailCardComponant />}
                    QrCardComponant={<QrCardComponant />}
                  />
                </div>
              </div>
{/*   
              <div className="sectionTitle searchWrapper desktopViewDisplayBlock">
                <h2>Your Wallet</h2>
                <form>
                  <div className="formGroup">
                    <Button type="submit" className="btnImage" />
                    <input
                      type="text"
                      className="formControl"
                      placeholder="Search"
                    />
                  </div>
                </form>
              </div> */}
            </div>
       
      
          </Scrollbars>
        </section>
     
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
      <DetailsInfoModal
        openModal={openModal}
        closeModal={handleCloseOpenModal}
      />
    </div>
  );
}

export default MemberRewards;
