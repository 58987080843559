import React from 'react'
import Button from './Button'

const OptionButton = ({ textOptionOne, textOptionTwo, type, className, activeClassName, formikProps, name, label, onClick, groupClassName, value , error , touched }) => {
    return (
        <div className={groupClassName ? groupClassName : ""}>
            <label className="labelStyle">{label}</label>
            <div className=''>
                <Button
                    text={textOptionOne}
                    className={value === textOptionOne ? `left ${className} ${activeClassName}` : `left ${className}`}
                    type={type}
                    onClick={(e) => onClick(name, textOptionOne)}
                />
                <Button
                    text={textOptionTwo}
                    className={value === textOptionTwo ? `right ${className} ${activeClassName}` : `right ${className}`}
                    type={type}
                    onClick={() => onClick(name, textOptionTwo)}
                />
            </div>
            {error && touched && (
        <div className="inputStyle error-messages">{error}</div>
      )}
        </div>
    )
}

export default OptionButton