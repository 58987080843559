import React, { useEffect } from "react";
import IcomoonReact, { iconList } from "icomoon-react";
import Footer from "./Footer";
import iconSet from "../Assets/fonts/selection.json";
import bbbLogo from "../Assets/img/home/bbb-logo.png";
import backArrow from "../Assets/img/icons/back-to-top-arrow.svg";
// Footer list with meida Icon

function FooterWithMediaIcon({ className, iconClassName }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = (e) => {
    const Backurl = document.querySelector("#back-to-top");
    const scrollTop = window.scrollY;
    scrollTop >= 120
      ? Backurl.classList.add("show-back-to-top")
      : Backurl.classList.remove("show-back-to-top");
  };
  const footerIconList = [
    {
      href: "https://www.facebook.com/HelloTripello",
      size: 21,
      iconCode: "uniE904",
      grpName: "fb-size",
    },
    {
      href: "https://twitter.com/Tripello1",
      size: 24,
      iconCode: "uniE90A",
      grpName: "tiwt-size",
    },
    {
      href: "https://www.instagram.com/tripello1/",
      size: 21,
      iconCode: "uniE906",
      grpName: "insta-size",
    },
    {
      href: "https://www.linkedin.com/company/tripello-inc",
      size: 22,
      iconCode: "uniE908",
      grpName: "tiwt-size",
      grpName: "linkdn-size",
    },
    {
      href: "https://www.youtube.com/channel/UC1J6sw7sT_oXQubq6Jy3Ssw",
      size: 24,
      iconCode: "uniE90C",
      grpName: "youtube-size",
    },
    {
      href: "https://www.pinterest.com/HelloTripello",
      size: 21,
      iconCode: "pinterest",
      grpName: "pinterest-size",
    },
    {
      href: "https://www.tiktok.com/@hellotripello",
      size: 21,
      iconCode: "TikTok-_1",
      grpName: "tiktok-size",
    },
  ];

  return (
    <div className="footer-wrap ">
      <footer className="footer">
        <div className="container">
          <Footer />
          {/* <div className="bbb-logo-wrap">
            <img src={bbbLogo} alt="bbb logo" />
          </div> */}
          <div className={`snsIcons ${className}`}>
            {footerIconList.map((item, index) => {
              return (
                <a href={item.href} target="blank" key={index}>
                  <IcomoonReact
                    className={"icon-align " + item.grpName}
                    iconSet={iconSet}
                    color="#5B5B5E"
                    size={item.size}
                    icon={item.iconCode}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </footer>
      <div id="back-to-top" className="back-to-top">
        <button
          className="btn btn-primary"
          title="Back to Top"
          onClick={scrollToTop}
        >
          <img src={backArrow} alt="icon" />
        </button>
      </div>
    </div>
  );
}

export default FooterWithMediaIcon;
