import React, { useEffect, useState } from "react";
import dollarIcon from "../../Assets/img/refferal.png";
import infoIcon from "../../Assets/img/info-icon.png";
import TableComponent from "../../Component/TableComponent";
import DateRangePickerComponent from "../../Component/DatePicker/DateRangePickerComponent";
import { Formik } from "formik";
import moment from "moment";
import Pagination from "../../Component/Pagiantion/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { marketingWebsiteAction } from "../../Stores/Actions/marketingWebsiteAction";




function TabletViewDisplay({ modalOpen, data, setValues, values, walletData }) {

  const [isDateResponsive, setDateResponsive] = useState(false);
  const [isResponsive, setResponsive] = useState(false);
  const [focused2, setFocused2] = useState(null);


  // Mobile Responsive

  const [isMobile, setisMobile] = useState(window.innerWidth < 767 ? true : false);

  const updateSize = () => {
    if (window.innerWidth < 992) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);


  const dispatch = useDispatch();

  const showDateResponsive = (clickedInput) => {
    setDateResponsive(clickedInput);
  };

  const handleDateClick = () => {
    setDateResponsive(!isDateResponsive);
  };

  useEffect(() => {
    console.warn("focused2", focused2)
  }, [focused2])

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "dollaricon",
        Cell: ({ row }) => {
          return (
            <div className="col icon">
              <img src={dollarIcon} alt="alt" />
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "paymentID",
        Cell: ({ row }) => (
          <div className="col titleSection">
            <h3>{row.original.paymentID}</h3>
            <h4>{row.original.dateTime}</h4>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "amount",
        Cell: ({ row }) => {
          return (
            <div className="col priceSection">
              <h5 className="greenText amount-info">
              <button className="btn btn-amount" onClick={() => {
                if (row.original.type == "paypal_transfer") {
                  modalOpen()
                  dispatch(marketingWebsiteAction.getTransactionDetailsAction(row?.original?.transectionId))
                }
              }} >
                {row?.original?.amount}
                {row.original.type == "paypal_transfer" &&
                  <img src={infoIcon} alt="image" />
                }
              </button>
              </h5>
              <h6>{row.original.payPalID}</h6>
            </div>
          );
        },
      },
    ],
    []
  );


  return (
    <div className="walletWrapper tabletViewDisplayBlock">
      <div className="row">
        <div className="col rightontent">
          <div className="card2">
            <div className="contentWrapper">
              <div className="dropdownSearchWrapper">
                <h2>Payout Transactions</h2>
                <div className="filter-wrapper">
                  <div className="dateWrapper">
                    <h6>Filter by Date</h6>

                    {isMobile &&
                      <div class="dateBox h-100 d-flex w-100">
                        <div class="toDate d-flex align-items-center" onClick={() => showDateResponsive("startDate")}>
                          <p class="m-0">{
                            values.start_date
                              ? moment(values.start_date).format("DD/MM/YYYY")
                              : "Start Date"
                          }</p>
                        </div>
                        <div class="fromDate d-flex align-items-center" onClick={() => showDateResponsive("endDate")}>
                          <p class="m-0">{values.end_date
                            ? moment(values.end_date).format("DD/MM/YYYY")
                            : "End Date"}</p>
                        </div>
                      </div>
                    }


                    {isDateResponsive && (
                      <div className="inputStyle showDropdown">

                        <div className="date-picker-popup ">
                          <p to="#" className="closePopup">
                            <span
                              className="icon-close"
                              onClick={() => handleDateClick(false)}
                            />
                          </p>
                          <div className="Date-picker-range">
                            <DateRangePickerComponent
                              isDateResponsive={isDateResponsive}
                              name="dates"
                              startDate={values.start_date}
                              endDate={values.end_date}
                              startDateId="startDate"
                              endDateId="endDate"
                              focusedInput={focused2}
                              onFocusChange={(focusedInput) => {
                                setFocused2(focusedInput);
                              }}
                              isRequired
                              icon="icon-invitation"
                              orientation="vertical"
                              openDirection="down"
                              startDatePlaceholderText="Check-in"
                              endDatePlaceholderText="Check-out"
                              onDatesChange={({ startDate, endDate }) => {
                                setValues({
                                  ...values,
                                  start_date: startDate,
                                  end_date: endDate,
                                });
                                handleDateClick(false)
                              }}
                              isOutsideRange={(day) => {
                                if (focused2 === "startDate")
                                  return (
                                    day.isAfter(moment())
                                  );
                                if (focused2 == "endDate")
                                  return (
                                    day.isAfter(moment())
                                  );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {!isMobile &&
                      <DateRangePickerComponent
                        name="dates"
                        startDate={values?.start_date}
                        endDate={values?.end_date}
                        startDateId="startDate"
                        endDateId="endDate"
                        focusedInput={focused2}
                        onFocusChange={(focusedInput) => {
                          setFocused2(focusedInput);
                        }}
                        isRequired
                        icon="icon-invitation"
                        orientation="horizontal"
                        openDirection="down"
                        startDatePlaceholderText="Start Date"
                        endDatePlaceholderText="End Date"
                        onDatesChange={({ startDate, endDate }) => {
                          setValues({
                            ...values,
                            start_date: startDate,
                            end_date: endDate,
                          });
                        }}
                        isOutsideRange={(day) => {
                          if (focused2 === "startDate")
                            return (
                              day.isAfter(moment())
                            );
                          if (focused2 == "endDate")
                            return (
                              day.isAfter(moment())
                            );
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>



            <div className="rewardsInfo">
              <TableComponent
                data={data}
                columns={columns}
                className="row no-gutters"
              />
            </div>
          </div>
          {
            walletData?.data?.pagination?.total_pages > 1 &&
            <>
              <div className="paginationWrapper">
                <Pagination
                  onPageChange={(e) => setValues({ ...values, page_number: e.selected + 1 })}
                  pageCount={walletData?.data?.pagination?.total_pages}
                  forcePage={walletData?.data?.pagination?.page ? Number(walletData?.data?.pagination?.page) - 1 : 0}
                />
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default TabletViewDisplay;
