import * as Yup from "yup";

export const CarSearchValidation = () => {

    return Yup.object().shape({
      pickUpLocation: Yup.array("").required("This field is required"),
      showDropOffPoint: Yup.boolean(),
      dropOffLocation: Yup.array("")
        .when('showDropOffPoint', (showDropOffPoint, field) => showDropOffPoint?field.required("This field is required"):field),
      pickUpDate: Yup.string("")
        .required("This field is required")
        .nullable(true),
      dropOffDate: Yup.string("")
        .required("This field is required")
        .nullable(true),
    });
};
