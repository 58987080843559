import React, { useEffect } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import LogoWhite from "../../Component/FormComponent/LogoWhite";
import Loader from "../../Component/Loader/Loader";
import { forgotPwdValidation } from "../../Component/Validation/forgotPwdValidation";
import IconInputField from "../../Component/FormComponent/IconInputField";
import Button from "../../Component/FormComponent/Button";
import Icon from "../../Component/FormComponent/Icon";
//import LoginBgVideo from "../../Component/LoginBgVideo";
import Footer from "../../Component/Footer";
import logo from "../../Assets/img/logo.png";
import { authActions } from "../../Stores/Actions/authActions";
import { commonActions } from "../../Stores/Actions/commonActions";

function ForgotPassword() {
  const dispatch = useDispatch();
  const forgotPasswordDetails = useSelector(
    (state) => state.authReducer.forgotPasswordDetails
  );
  const loading = useSelector((state) => state.authReducer.loading);
  const serverErrors = useSelector((state) => state.authReducer.errors);
  const initVal = {
    email: "",
  };
  useEffect(() => {
    return () => {
      dispatch(authActions.resetForgotPasswordDetails());
    };
  }, []);
  const handleSubmit = (value) => {
    const payload = {
      email: value.email.trim(),
      tenant_id: process.env.REACT_APP_TENANT_ID,
    };
    dispatch(commonActions.loaderText("Loading..."));
    dispatch(authActions.forgotPasswordRequest(payload));
  };

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
      formikProps;
    console.log(loading);
    return (
      <div className="pageBg forgotPasswordWrap">
        <Loader showLoader={loading} />
        <form onSubmit={handleSubmit}>
          <div className="pageBoxWrap pageCenterCnt pageForgotPassword min-height-100vh">
            <Link className="logo" to="/">
              <LogoWhite src={logo} />
            </Link>
            <div className="boxBg">
              <div className="textCnt">
                <h6>Forgot Password</h6>
                {!forgotPasswordDetails && (
                  <p>
                    Please enter your email. We will send reset instructions to
                    your registered email.{" "}
                  </p>
                )}
              </div>
              <div className="input-text-wrapper">
                {!forgotPasswordDetails && (
                  <IconInputField
                    // label="Email"
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={values.eamil}
                    onChange={handleChange}
                    iconName="fa fa-envelope"
                    className="iconWrap"
                    onBlur={handleBlur}
                    errors={errors.email}
                    touched={touched.email}
                    successMessage={null}
                    serverErrors={serverErrors}
                  />
                )}
                {forgotPasswordDetails && (
                  <div className="forgotPasswordSuccess">
                    <p className="successMessage">
                      An email with Password Reset Instructions has been sent
                      {values.eamil}.
                    </p>
                    <p className="successMessage">
                      Please also check your Spam or Junk Mail Folders.
                    </p>
                    <p className="successMessage">
                      Contact Support if you do not have access to this email
                      any longer.
                    </p>
                  </div>
                )}
              </div>
              <div className="dflex flexColReverse">
                <Link className="linkStyle" to="/login">
                  <Icon className="iconWrap" iconName="fa fa-angle-left" />
                  Back
                </Link>
                {!forgotPasswordDetails && (
                  <Button text="Send" type="submit" className="btn" />
                )}
              </div>
            </div>
            <Footer />
          </div>
        </form>
      </div>
    );
  };

  return (
    <Formik
      initialValues={initVal}
      onSubmit={handleSubmit}
      validationSchema={forgotPwdValidation()}
    >
      {renderForm}
    </Formik>
  );
}

export default ForgotPassword;
