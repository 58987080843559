import React, { useEffect, useState } from "react";
import Select from "react-select";
import { isEmpty } from "lodash";
function SortBy({ option, mobileSort, searchHotel }) {
  const newOptions = !isEmpty(option)
    ? option.map((ele) => {
        return {
          label: ele.label.replace("High", "high").replace("Low", "low"),
          value: ele.name + "/" + ele.order,
          name: ele.name,
          order: ele.order,
        };
      })
    : [{ label: "Savings % (high to low)", value: "select" }];

  const [showOption, setshowOption] = useState(true);
  const [selectOpt, setSelectOpt] = useState(newOptions[0]);
  function handleSelect(e) {
    setSelectOpt(e);
    let preStoredval = JSON.parse(localStorage.getItem("hotelPayload"))
      ? JSON.parse(localStorage.getItem("hotelPayload"))
      : JSON.parse(localStorage.getItem("mapPayload"));
    // localStorage.setItem("mapPayload", JSON.stringify(payload));
    preStoredval = {
      ...preStoredval,
      sort: { field: e.name, order: e.order },
    };
    localStorage.setItem("hotelPayload", JSON.stringify(preStoredval));
    searchHotel(1, 15, true, e);
  }

  return mobileSort ? (
    <div className="content col">
      <div className="flterOption">
        <p className="whiteLabel" onClick={() => setshowOption(!showOption)}>
          Sort by
        </p>

        <div className={`${showOption ? "" : "d-none"}`}>
          <Select
            className="sort-by-dropdown"
            options={newOptions}
            placeholder="Select..."
            value={selectOpt}
            onChange={(e) => {
              handleSelect(e);
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="content col">
        <p className="whiteText">Sort by</p>
        <div
          onClick={() => setshowOption(!showOption)}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Select
            className="sort-by-dropdown"
            options={newOptions}
            placeholder="Select..."
            value={selectOpt}
            onChange={(e) => {
              {
                handleSelect(e);
              }
            }}
          />
          <span className={`fa fa-angle-${showOption ? "down" : "up"}`}></span>
        </div>
      </div>
    </>
  );
}
export default SortBy;
