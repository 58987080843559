import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import ResultsOnPage from '../../Component/Pagiantion/ResultsOnPage';
import DesktopViewDisplay from './DesktopViewDisplay'
import TabletViewDisplay from './TabletViewDisplay'
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { marketingWebsiteAction } from "../../Stores/Actions/marketingWebsiteAction";
import Pagination from "../../Component/Pagiantion/Pagination";

function PayoutTransactions({ modalOpen }) {
  const dispatch = useDispatch();

  const walletData = useSelector(
    (state) => state.marketingSiteReducer?.walletData
  );



  // Mobile Responsive

  const [isMobile, setisMobile] = useState(window.innerWidth > 992 ? false : true);

  const updateSize = () => {
    if (window.innerWidth < 992) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const [values, setValues] = useState(
    {
      start_date: moment().subtract(7, 'days').format("YYYY-MM-DD"),
      end_date:  moment().format("YYYY-MM-DD"),
      page_number: 1,
      per_page: 15
    }); // initial values

  const getWalletData = (start_date, end_date, page_number, per_page) => {
    const payload = {
      filter: {
        date: {
          start_date: start_date ?  moment(start_date).format("YYYY-MM-DD") : null,
          end_date :end_date ? moment(end_date).format("YYYY-MM-DD") : null,
        }, 
      },
      paging: {
        per_page,
        page_number
      }
    }
    dispatch(marketingWebsiteAction.getWallletTransactionAction(JSON.parse(localStorage.getItem("user")).user_wallet.id, payload))
  }
 
  useEffect(() => {
    getWalletData(values.start_date, values.end_date, values.page_number, values.per_page)
  }, [values])


  const walletResult = walletData?.data?.result.map((ele) => {
    return {
      amount: ele.amount,
      dateTime: ele.date_time,
      dollaricon: "",
      payPalID: ele.comment,
      paymentID: ele.description,
      type: ele.type,
      transectionId : ele?.id
    }
  })


  return (
    <>
      {
        !isMobile && <DesktopViewDisplay modalOpen={modalOpen} data={walletResult || []} values={values} setValues={setValues} />
      }
      <div className="paginationWrapper desktopViewDisplayBlock">
        {
          walletData?.data?.pagination?.total_pages > 1 &&
          <>
            <Pagination
              onPageChange={(e)=>setValues({...values, page_number: e.selected + 1})}
              pageCount={walletData?.data?.pagination?.total_pages}
              forcePage={walletData?.data?.pagination?.page ? Number(walletData?.data?.pagination?.page) - 1 : 0}
            />

            <ResultsOnPage
              className="results hide-for-mobile"
              callback={(a,b)=>setValues({...values, per_page: b})}
              defaultValue={15}
            />
          </>
        }
      </div>
      {
        isMobile && <TabletViewDisplay walletData={walletData} modalOpen={modalOpen} data={walletResult || []} values={values} setValues={setValues} />
      }

    </>
  )
}

export default PayoutTransactions