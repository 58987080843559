import React from "react";
import { isEmpty } from "lodash";
import { GrAccessibility } from "react-icons/gr";
import {
  MdBusinessCenter,
  MdOutlineCleaningServices,
  MdOutlineFamilyRestroom,
  MdOutlineLanguage,
  MdOutlineLocalLaundryService,
  MdOutlineMiscellaneousServices,
  MdOutlinePool,
  MdOutlineRestaurantMenu,
} from "react-icons/md";
import { TbParking } from "react-icons/tb";
import { SiTransportforlondon } from "react-icons/si";
import { AiFillShopping } from "react-icons/ai";
import { HiWifi } from "react-icons/hi";
import { RiUserReceivedLine } from "react-icons/ri";

function SingleAmenities(props) {
  const { group_name, values } = props;

  const amenities = [
    { image: <GrAccessibility />, name: "Accessibility" },
    { image: <MdOutlinePool />, name: "Pool and wellness" },
    { image: <MdOutlineRestaurantMenu />, name: "Food & Drink" },
    { image: <MdOutlineCleaningServices />, name: "Services & Extras" },
    { image: <SiTransportforlondon />, name: "Transport" },
    { image: <MdOutlineLocalLaundryService />, name: "Cleaning services" },
    { image: <MdOutlineMiscellaneousServices />, name: "Miscellaneous" },
    { image: <AiFillShopping />, name: "Shops" },
    { image: <RiUserReceivedLine />, name: "Reception services" },
    { image: <HiWifi />, name: "Internet" },
    { image: <TbParking />, name: "Parking" },
    { image: <MdBusinessCenter />, name: "Business facilities" },
    {
      image: <MdOutlineFamilyRestroom />,
      name: "Entertainment and family services",
    },
    { image: <MdOutlineLanguage />, name: "Language Spoken" },
  ];

  return (
    <div className="col">
      <h3>
        {amenities.find((ele) => ele.name === group_name)?.image || <HiWifi />}
        {group_name ? group_name : "TV Area"}
      </h3>
      <ul className="list-unstyled">
        {!isEmpty(values) &&
          values.map((ele, index) => <li key={`${index}_value`}>{ele}</li>)}
      </ul>
    </div>
  );
}

export default SingleAmenities;
