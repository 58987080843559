import React from "react";
import { useTable } from "react-table";
import { useSelector } from "react-redux";
function TableComponent({ columns, data, className }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} className={className ? className : " "}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className="col ">
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}

        {data?.length == 0 && (
          <div className="not-found-content">
            <h1>Data not found</h1>
          </div>
        )}
      </tbody>
    </table>
  );
}

export default TableComponent;
