import * as Yup from 'yup';

export const PremiumCheckoutValidation = () => {
  const phoneRegExp = /^[0-9\b]+$/;
  const zipCodeRegExp = /^([a-zA-Z0-9]){3,7}$/;
  const cvvRegExp = /^[0-9]{3,4}$/;
  const cardNumberRegExp = /^[0-9]{14,17}$/;
  const mmyyFormatRegExp = /([0-9]{2})\/([0-9]{2})/;

  // Validation for membership premium checkout

  return Yup.object().shape({
    title: Yup.string('').required('Required'),
    firstName: Yup.string('')
      .matches(/^[aA-zZ\s]+$/, 'Must only have alphabet letters')
      .required('Required'),
    lastName: Yup.string('')
      .matches(/^[aA-zZ\s]+$/, 'Must only have alphabet letters')
      .required('Required'),
    email: Yup.string('')
      .email('Enter a valid email address.')
      .required('Required'),
    phoneNumber: Yup.string('')
      .required('Phone number is required'),
    nationality: Yup.string('').required('Required'),
    addressOne: Yup.string('').required('Required'),
    addressTwo: Yup.string(''),
    city: Yup.string('').required('Required'),
    state: Yup.string("").when("stateAvailable", {
      is: true,
      then: Yup.string().required("Required")
    }),
    // zipCode: Yup.string('').min(4, 'Invalid Zip Code').max(6, 'Invalid Zip Code').required('Required'),
    // zipCode: Yup.string("").required("Required").matches(/^([a-zA-Z0-9]){3,7}$/,"Invalid zip code"),
    zipCode: Yup.string("").required("Required").min(3, "Invalid zip code").max(10),
    promoCode: Yup.string('').when("hasPromo", {
      is: true,
      then: Yup.string().required("Promo/Gift Code can not be empty")
    }),
    password: Yup.string("")
      .required("This field is required").min(8, "Must be 8 characters or more"),
    terms: Yup.boolean().oneOf([true], 'Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm password"),

    //card
    
    cardHolderName: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').matches(/^[aA-zZ\s]+$/, 'Must only have alphabet letters')
    }),
    security_code: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').matches(cvvRegExp, 'Enter a valid security code').max(4),
    }),
    exp_month: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    exp_year: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    billing_zip: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    credit_number: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').min(15).max(16).matches(cardNumberRegExp, 'Enter a valid card number.'),
    }),
    billing_country: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string(''),
    }),
    mmyy: Yup.string('').when("hasPromo", {
      is: false,
      then: Yup.string('').required('Required').min(5),
    }),
  });
};


