//Accordian list

export const faqs = [
  {
    id: 0,
    title: "What is the difference between Freemium and Premium Memberships?",
    description:
      "Freemium Members have only limited access to the Tripello Travel Platform. As a Freemium Member you can only book from over 1.5 million Hotels and you can only get savings up to 25%. The massive savings are reserved for our members.",
  },
  {
    id: 1,
    title:
      "What is the difference between a Premium and Premium Plus Membership?",
    description:
      "Both memberships get full access to the Tripello Travel Platform. Premium Plus Members get a better monthly price for their longer commitment. In addition, Premium Plus Members get access to the Tripello Rewards program.",
  },
  {
    id: 2,
    title: "Can I upgrade from Premium to Premium Plus Membership?",
    description:
      "This cannot be done during your active Membership. Once your Premium Membership comes to an end, you can set it to cancel and you will be downgraded to a Freemium Member, then you can upgrade again to a Premium Plus Membership.",
  },
  {
    id: 3,
    title: "Do I have to go through Hoops and Loops to become a Member?",
    description:
      "NO, we don’t ask you to watch a pointless 30 minute presentation just to find out you still need to sign up for a demo or ask for your email first before seeing what we have to offer. At Tripello we strive for true transparency. What you see is what you get. Just sign up with your Name and email for a Freemium Membership and you can explore our platform in your own time. Take as long as you like.",
  },

  {
    id: 4,
    title: "What makes Tripello different?",
    description:
      "Tripello is a new Travel Booking Platform. We only facilitate direct Bookings, NOT Reservations (except for Rental Cars). We approach the booking process, especially Hotels from a different perspective. Our searches always show you the biggest savings first. So to gain the most out of Tripello you shouldn’t be too focused on a specific hotel as we may not have it at the moment (or at discounted prices), instead, your focus should be on how to save the most while still getting a hotel with equal or better ratings to the one you initially thought of staying at. Our goal is to get YOU the most value for your money.",
  },
  {
    id: 5,
    title: "Do you always have the cheapest Hotels?",
    description:
      "Just like anyone else in the industry we cannot claim we always have the cheapest hotel, or the hotel that you are looking for at the cheapest price. We might, we might not. And if we don’t we may have a similar hotel with equal or greater quality (Rating) for a lower price. We can only show you what is truly available at highly discounted rates at the moment of your search and based on your search parameters.",
  },
  {
    id: 6,
    title: "Is Tripello not just the same as KAYAK or TRIVAGO?",
    description:
      "NO, not at all. There is a big difference. Sites like KAYAK and TRIVAGO are called MetaSearch Engines. All that they do is crawl the internet for Travel websites and their pricing and then compile the information to show you the lowest price available. But these are PUBLIC Prices and NOT the prices Tripello has access to. Our Prices are NOT publically available and therefore can only be presented in a Closed User Group (CUG).",
  },
  {
    id: 7,
    title: "Do you have all the same Hotels as the other sites?",
    description:
      "This again varies depending on your search and the dates you choose. Every time you click the search button we initiate a LIVE search, scanning tens of thousands of wholesale contracts throughout the world. We don’t work off a database with paid contracts like the Big Guys do. We show you what is truly available at the lowest price at the time of your search. Most of the time we have the same hotels and other times we have incredible hotel deals that the Big Buys cannot offer or don’t have access to.",
  },
  {
    id: 88,
    title: "What is Dynamic Pricing?",
    description:
      "OK let us explain. Think of it as the NASDAQ of Travel. Prices constantly fluctuate depending on Location, Time, Date, Availability and more. This is what makes our massive Savings possible. Every time you search, we initiate a LIVE Search where we scan tens of thousands of wholesale contracts in Real Time. This happens on the Initial Search and then again when you decide on a specific Hotel and want to see the Room Level. Due to this, sometimes there can be a slight discrepancy between a price on the Search Level compared to the Room Level. It is also possible that the Room showing on the Search Level won’t be available anymore once you reach the Room Level. Or that the Room Level prices are even better than the initial search. We offer multiple options (where possible) and show you Room Only, Room + Breakfast, Half-Board, Full-Board and All-Inclusive Pricing. If a Room has multiple options, it is possible that all of these come from different sources and one source offers the Room including Breakfast at a lower price than a Room Only option from another source. Nothing to worry about, this is normal and you can choose what you would like. We just try to show you everything available. Once you are on the Room Level the prices are locked in for YOU for 20 minutes. After that the search will expire and you will have to initiate a new search. ",
  },
  {
    id: 8,
    title: "How are you able to beat the Big Guys?",
    description:
      "The Travel Industry is very complicated and can be very confusing. Due to our access to Wholesale Pricing, the fact that we chose a subscription based business model and streamlined our process through advanced technology our costs are way lower. So we are able to strip everything. We do not need Commissions, Booking Fees, Mark Ups or Fees of any kind to pay for a massive overhead. We pass the savings on to YOU.",
  },
  {
    id: 9,
    title: "Can I book Hotels and Flights together on one itinerary?",
    description:
      "At the moment this is not possible as these are 2 separate transactions in our system, therefore you would be booking them separately. You will have access to all information and all itineraries in you Bookings section. We are working to integrate this ability in the future.",
  },
  {
    id: 10,
    title: "Can I change my Hotel Booking Date?",
    description:
      "Yes, you can. BUT! Due to the way our platform functions, we CAN NOT guarantee you the same Hotel/Room at the same price on a different date. We always show you what is available RIGHT NOW, at the moment of your search. So be sure when you book that your dates are solid and if you need to make a change, you should cancel the Booking and then initiate a new search with the new dates. Please be aware of the Cancellation Policy for each property.",
  },
  {
    id: 11,
    title: "Do you have a Rewards Program?",
    description:
      "Yes, we do. Our Premium Plus Members have access to our Tripello Rewards Program. They have their own Freemium Member Invitation link or QR Code that they can share with whomever they think enjoys travel. Once a Freemium Member they have invited upgrades to a Premium or Premium Plus Membership they will get their rewards credited to their wallet. They can use these rewards towards any booking on our site (Car Rental Services excluded). The rewards are $25 for each Premium and $50 for each Premium Plus Member.",
  },
  {
    id: 12,
    title: "Do you have Taxes and Fees included in your prices?",
    description:
      "Yes, what you see is what you pay. No unexpected surprises at checkout like some other sites do. All prices include all fees and taxes (except resort fees).",
  },
  {
    id: 13,
    title: "What about Resort Fees?",
    description:
      "Well, Resort Fees can be a bummer and unfortunately there is nothing we can do about them as they are outside of our control and have to be paid on site. But we have a price breakdown for each search result and it will show you if there will be resort fees applied when you check in and how much they are.",
  },
  {
    id: 14,
    title: "Can I book for friends and family?",
    description:
      "Yes, you can. As a Premium and Premium Plus Member, you will have the ability to add 3 Authorized Users to your account, who then can log in with your credentials and book for themselves. You can only add these Authorized Users ONCE and they cannot be changed (choose wisely). You can only change their basic contact information but NOT the Names. The account holder and/or the Authorized Users MUST be on the Itinerary for each booking. You can book a maximum of 4 hotel rooms and/or flights on one itinerary at a time.",
  },

  {
    id: 15,
    title: "Will I get my MILES for flights booked on Tripello?",
    description:
      "Yes, when booking a flight you will have the option to enter your Frequent Flyer Number and then you will be able to collect your miles from the airline and add them to your account.",
  },
  {
    id: 16,
    title: "Can I redeem my MILES?",
    description:
      "No, unfortunately not. Not at this time. But you will be able to accumulate and collect miles on flights you book through Tripello.",
  },
  {
    id: 17,
    title: "Can I use any Third Party Loyalty Rewards or Points on Tripello?",
    description:
      "Sorry, due to our business structure this is not possible at this point.",
  },
  {
    id: 18,
    title: "Do you have Customer Service?",
    description:
      "Yes, we do. We have a Customer Service Team that is ready to help 24/7, worldwide.",
  },
  {
    id: 19,
    title: "Can I see the prices in my local currency?",
    description:
      "Yes, we offer automatic dynamic currency display based on your location. In addition, you still have the option to change the pricing to a large variety of local currencies. Please NOTE that at the time of your booking you will be charged in US $ and it will be converted into your local currency by your bank. The price finally charged by your bank may vary slightly from the price shown at Tripello due to fluctuating currency conversion rates.",
  },
  {
    id: 20,
    title: "Which Payment Options do you have?",
    description:
      "We accept Debit/Credit Cards, PayPal, Apple Pay, Google Pay. In the future we will support WeChat Pay and Crypto Payments.",
  },
  {
    id: 21,
    title: "How do I cancel my subscription?",
    description:
      "We surely would hate to see you leave but we don’t want to make that process a pain either. In your Profile Section you will find a switch that you set and then confirm. Your Membership will be downgraded to a Freemium Membership and you can always come back later if you change your mind and upgrade again. You will receive an additional confirmation email with your Membership status change. Due to our business model that requires a Closed User Group (Members Only) – we cannot refund any of the price for the remaining time of your 6 or 12-month subscription at the time of your cancellation. You have 1 week before renewal to cancel your subscription.",
  },
];
