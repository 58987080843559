import React, { useEffect, useState } from "react";
import Button from "../FormComponent/Button";
import InputRange from "react-input-range";
import { has } from "lodash";

function PriceRange({
  title,
  minPrice,
  maxPrice,
  setFilters,
  filters,
  searchHotel,
  mobileCategory,
  priceRef,
}) {
  const [showFilter, setshowFilter] = useState(false);
  const [rangeVal, setRangeVal] = useState({
    min: 0,
    max: 1,
  });
  useEffect(() => {
    if (has(filters, "minPrice") && has(filters, "maxPrice")) {
      setRangeVal({ min: filters.minPrice, max: filters.maxPrice });
    }
    // else {
    //   setRangeVal({ min: Number(minPrice), max: Number(maxPrice) });
    // }
    if (showFilter) {
      setRangeVal({ min: Number(minPrice), max: Number(maxPrice) });
    }
  }, [showFilter]);

  function handleRangeChange(value) {
    setRangeVal({ min: value.min, max: value.max });
    setFilters({ ...filters, min_price: value.min, maxPrice: value.max });
  }

  function handleResetRange() {
    if (has(filters, "minPrice") || has(filters, "maxPrice")) {
      setRangeVal({ min: Number(minPrice), max: Number(maxPrice) });
      const newFilters = filters;
      delete newFilters.minPrice;
      delete newFilters.maxPrice;
      setFilters({ ...newFilters });
      searchHotel(null, null, true);
    }
  }

  function handleSearchByPrice() {
    searchHotel();
  }

  const currencyAndLanguage =
    (localStorage.getItem("currencyAndLanguage") &&
      JSON.parse(localStorage.getItem("currencyAndLanguage"))) ||
    {};

  const currencySymbol =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency?.symbol || "$";

  return (
    <>
      <div
        className={mobileCategory ? "flterOption" : "cardHead dflex"}
        onClick={() => setshowFilter(!showFilter)}
      >
        {mobileCategory ? <span>{title}</span> : <h6>{title}</h6>}
        <span className={`fa fa-angle-${showFilter ? "up" : "down"}`}></span>
      </div>
      <div className={`priceRange ${!showFilter ? "d-none" : ""}`}>
        <h4>
          {currencySymbol}
          {rangeVal.min}
        </h4>
        <h4>
          {currencySymbol}
          {rangeVal.max}
        </h4>
      </div>
      <div className={`cardBody  ${!showFilter ? "d-none" : ""}`}>
        <div className="inputRange">
          <InputRange
            maxValue={Number(maxPrice)}
            minValue={Number(minPrice)}
            value={rangeVal}
            onChange={(value) => handleRangeChange(value)}
            formatLabel={(e) => {}} //don't remove this it is for removing lable from the range input
            ref={priceRef}
            setRangeVal={setRangeVal}
          />
        </div>
        <div className="priceBtn">
          <Button
            className="btnBorder"
            type="button"
            onClick={handleSearchByPrice}
          >
            Apply
          </Button>
          {mobileCategory ? (
            ""
          ) : (
            <Button
              className="linkColor font14 cancelBtn"
              type="button"
              onClick={handleResetRange}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default PriceRange;