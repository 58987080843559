import React from "react";
import Modal from "react-modal";
import CloseBtn from "../../Assets/img/close-blue-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../Stores/Actions";


function MembershipCancelModal({ showMembershipModal,setShowMembershipModal, setFreemiumUser }) {

  const dispatch = useDispatch();

  const handleCancelMembership = () => {
    // to check if the user is a freemium memeber
    const {type_id} = JSON.parse(localStorage.getItem("user"));
    const freemium_id = process.env.REACT_APP_FREMIUM_USER_TYPE_ID;    
    if(type_id !== freemium_id) {
      setShowMembershipModal(false)
      dispatch(authActions.cancelMembership());
      
    }
  }

  return (
    <Modal
      isOpen={showMembershipModal}
      ariaHideApp={false}
      className={`popup profile-info-popup ${
        showMembershipModal ? "d-block" : ""
      }`}
    >
      <div className="popup-content">
        <div className="popup-title">
          <h3>Membership Cancellation</h3>
          <button
            type="button"
            className="btn close close-btn"
            onClick={() => {
              setShowMembershipModal(false);
              setFreemiumUser(false);
            }}
          >
            <img src={CloseBtn} alt="icon" />
          </button>
        </div>
        <div className="popup-content-box">
          <div className="popup-content-title">
            <h3>Are you sure you want to cancel your membership?</h3>
          </div>
        </div>
        <div className="popup-footer">
          <div className="button-wrap">
            <button 
              type="button" 
              className="btn blue-btn close-btn"
              onClick={() => {
                setShowMembershipModal(false);
                setFreemiumUser(false);
            }}
            >
              Keep it
            </button>
            <button
              type="button"
              className="btn orange-btn cancel-btn"
              onClick={handleCancelMembership}
            >
              Cancel it
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default MembershipCancelModal;
