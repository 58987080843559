// taken from https://github.com/videojs/video.js/blob/master/docs/guides/react.md
import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import posterImg from "../../Assets/img/network/video-section-bg.png";

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      var player2 = videojs("#magic-video-popup-player");
      player2.play();
    });

    if (this.videoNode) {
      this.videoNode.setAttribute("webkit-playsinline", true);
      this.videoNode.setAttribute("playsinline", "playsinline");
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div data-vjs-player>
        <video
          id="magic-video-popup-player"
          poster={posterImg}
          ref={(node) => (this.videoNode = node)}
          className="video-js magic-video-popup-player"
        />
      </div>
    );
  }
}
