import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import SearchAutocomplete from "../../Component/Autocomplete/SearchAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DateRangePickerComponent from "../../Component/DatePicker/DateRangePickerComponent";
import AddRoomForm from "../../Component/Forms/AddRoomForm";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../Component/FormComponent/Button";
import { commonActions, hotelActions } from "../../Stores/Actions";
import arrowImg from "../../Assets/img/flight-mobile-arrows.png";

function HotelWidget({ toggleFilterDrop, isFromDetailPage }) {
  const dispatch = useDispatch();
  const nagivate = useNavigate();
  const [showAutocomplete, setshowAutocomplete] = useState(false);
  const hotelSearchAutoCompleteRes = useSelector(
    (state) => state.commonReducer.autoCompleteData
  );
  const searchLoading = useSelector(
    (state) => state.commonReducer.searchLoading
  );

  const currencyAndLanguage =
    localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const [showDropdown, setshowDropdown] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isResponsive, setResponsive] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [focused2, setFocused2] = useState(null);
  const [selectedValue, setselectedValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDateResponsive, setDateResponsive] = useState(false);
  const [inputFields, setInputFields] = useState([{ adults: 2, children: 0 }]);
  const [selectedId, setSelectedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [hideFilter, setHideFilter] = useState(false);

  const setDropd = () => {
    setOpen(!open);
  };

  // Get the payload data from localStorage and hit the api for hotel search on mounting
  const payloadData = JSON.parse(localStorage.getItem("hotelPayload"))
    ? JSON.parse(localStorage.getItem("hotelPayload"))
    : JSON.parse(localStorage.getItem("mapPayload"));
  // useEffect(() => {
  //   dispatch(hotelActions.searchHotelAction(payloadData));
  // }, []);
  // const roomsCount = payloadData.rooms;

  const roomObject = { adults: 2, child: 0, children: [] };
  const initialValues = {
    check_in: payloadData ? payloadData?.check_in_date : "",
    check_out: payloadData ? payloadData?.check_out_date : "",
    rooms: payloadData ? payloadData?.rooms : [{ ...roomObject }],
    // goingTo: payloadData ? payloadData?.city_name : "",
    goingTo: [JSON.parse(localStorage.getItem("autoCompleteData"))],
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    }
    if (payloadData) {
      setselectedValue(payloadData?.city_name);
      setSelectedId(payloadData.region_id);
    }
    // window.addEventListener("resize", updateSize);
    // return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleOnSearch = (query) => {
    dispatch(commonActions.hotelSearchAutoComplete(query));
  };

  const handleOnSelect = (value, field, formikProps) => {
    // [0]?.name
    formikProps.setFieldValue(field, value);
    setselectedValue(value[0]?.secondary_label);
    setSelectedId(value[0]?.id);
    setIsLoading(false);
    if(value[0]){
      localStorage.setItem("autoCompleteData", JSON.stringify(value[0]));
      handleAutocompleteClick(false);
    }else{
      setselectedValue(JSON.parse(localStorage.getItem("autoCompleteData"))?.secondary_label);
    }
  };

  const handleDateChange = (value, formikProps, field) => {
    formikProps.setFieldValue(field, value);
    if (field === "check_out" && value) setDateResponsive(false);
  };

  const setDropdown = () => {
    setshowDropdown(!showDropdown);
  };

  const setMobileDropdown = () => {
    setIsFilter(true);
  };

  const handleAutocompleteClick = () => {
    setshowAutocomplete(!showAutocomplete);
  };

  const handleDateClick = () => {
    setDateResponsive(!isDateResponsive);
  };

  const showResponsive = () => {
    setResponsive(true);
    handleAutocompleteClick(false);
  };

  const showDateResponsive = () => {
    setDateResponsive(true);
  };


  const handleSubmit = (data) => {
    if (payloadData) {
      // localStorage.removeItem("hotelPayload");
    }
    const payload = {
      view_type: "list",
      rooms: data.rooms.map((ele) => {
        return {
          adults: ele.adults,
          child: ele.child,
          children: ele.children.map((ele) => ele.value),
        };
      }),
      check_in_date: moment(data.check_in).format("YYYY-MM-DD"),
      check_out_date: moment(data.check_out).format("YYYY-MM-DD"),
      city_name: selectedValue,
      currency: currency.isoCode,
      home: false,
      locale: "en-US",
      native_currency_symbol: currency.symbol,
      onlyLocale: "en-US",
      optional: "amenities",
      region_id: selectedId,
      search_type: "hotel",
      source_market: "US",
      token: localStorage.getItem("accessToken"),
      use_wallets: 1,
      filters: {},
      paging: {
        page_number: 1,
        per_page: 15,
      },
      nationality: "US",
      sort: {
        field: "savings_percentage",
        order: "desc",
      },
    };

    localStorage.setItem("mapPayload", JSON.stringify(payload));
    localStorage.setItem("hotelPayload", JSON.stringify(payload));

    if (isFromDetailPage) { 
      nagivate("/hotel-search-result");
    } else {
      localStorage.removeItem("search_key");
      dispatch(hotelActions.searchHotelAction(payload));
    }
  };

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;

    const adultNumber = payloadData?.rooms
      .map((data) => data.adults)
      .reduce((a, b) => a + b);

    const childNumber = payloadData?.rooms
      .map((ele) => ele.child)
      .reduce((a, b) => a + b);

    const adultChildrenCount = values?.rooms
      .map((ele) => ele.adults)
      .reduce((a, b) => a + b);

    const childCount = values?.rooms
      .map((ele) => ele.child)
      .reduce((a, b) => a + b);

    return (
      <div className={hideFilter ? " hide-content" : "filterCnt "}>
        <form onSubmit={handleSubmit}>
          <div className={isFilter ? "filterCnt2 openFilter" : "filterCnt2"}>
            {!isFilter && (
              <div className="mobileShow">
                <div className="mobileSearchBlock" onClick={setMobileDropdown}>
                  <span className="fa fa-search"></span>
                  <div className="selectSearchCnt">
                    <h4>{payloadData?.city_name}</h4>
                    <p>
                      {payloadData?.check_in_date} —{" "}
                      {payloadData?.check_out_date}{" "}
                      <i className="fa fa-circle"></i>
                      {payloadData?.rooms.length}
                      {payloadData?.rooms.length > 1 ? " Rooms" : " Room"}
                      <i className="fa fa-circle"></i>{" "}
                      {adultNumber + childNumber}
                      {childNumber ? " Guest" : " Adult"}
                      {adultNumber > 1 ? "s" : ""}
                    </p>
                  </div>
                  <i className="fa fa-angle-down"></i>
                </div>
              </div>
            )}
            <div className="container dflex mobileHide">
              <div className="locationStartEndWrap dflex">
                {!isMobile && (
                  <div
                    className={
                      !showAutocomplete
                        ? "inputStyle showDropdown"
                        : "inputStyle showDropdown"
                    }
                  >
                    <div className="dropdown-content">
                      <span />
                      <SearchAutocomplete
                        {...formikProps}
                        label=""
                        options={hotelSearchAutoCompleteRes}
                        name="goingTo"
                        onSearch={(query) => handleOnSearch(query)}
                        onChange={(selected) => {
                          handleOnSelect(selected, "goingTo", formikProps);
                        }}
                        defaultSelected={[JSON.parse(localStorage.getItem("autoCompleteData"))]}
                        value={values.goingTo}
                        onBlur={handleBlur}
                        isLoading={searchLoading}
                        // onClick={handleAutocompleteClick}
                        placeholder="Going To"
                        icon="icon-location-on"
                        drop={true}
                      />
                    </div>
                  </div>
                )}
                {/* Search for Mobile */}

                {isMobile && (
                  <div className="responsive-input">
                    <button
                      className="inputStyle grow1"
                      onClick={showResponsive}
                      type="button"
                    >
                      {" "}
                      <i className="icon-location-on" />{" "}
                      { selectedValue || "Going to"}
                    </button>
                  </div>
                )}
                {isResponsive && showAutocomplete && (
                  <div className="inputStyle showDropdown">
                    <div className="mobilePopup">
                      <Link to="#" className="closePopup">
                        <span
                          className="icon-close"
                          onClick={() => handleAutocompleteClick()}
                        />
                      </Link>
                      <h4 className="inputPopTitle">
                        Where are you leaving from?
                      </h4>
                      <div className="dropdown-content">
                        <span />
                        <SearchAutocomplete
                          {...formikProps}
                          label=""
                          options={hotelSearchAutoCompleteRes}
                          name="goingTo"
                          onSearch={(query) => handleOnSearch(query)}
                          onChange={(selected) => {
                            handleOnSelect(selected, "goingTo", formikProps);
                          }}
                          autoFocus={true}
                          onBlur={handleBlur}
                          isLoading={isLoading}
                          defaultSelected={[JSON.parse(localStorage.getItem("autoCompleteData"))]}
                        value={values.goingTo}
                        // onClick={handleAutocompleteClick}
                        placeholder="Search by city or airport"
                        icon="icon-location-on"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* date & room and search */}
              <div className="dateAndBtnWrap">
                {!isMobile && (
                  <div className="Date-picker-range">
                    <DateRangePickerComponent
                      name="dates"
                      startDate={values.check_in}
                      endDate={values.check_out}
                      startDateId="check_in"
                      endDateId="check_out"
                      focusedInput={focused2}
                      onFocusChange={(focusedInput) => {
                        setFocused2(focusedInput);
                      }}
                      isRequired
                      icon="icon-invitation"
                      orientation="horizontal"
                      openDirection="down"
                      startDatePlaceholderText="Check-in"
                      endDatePlaceholderText="Check-out"
                      onDatesChange={({ startDate, endDate }) => {
                        handleDateChange(startDate, formikProps, "check_in");
                        handleDateChange(endDate, formikProps, "check_out");
                      }}
                      isOutsideRange={(day) => {
                        const res = JSON.parse(
                          localStorage.getItem("hotelWidget")
                        )
                          ? moment(
                            JSON.parse(localStorage.getItem("hotelWidget"))
                              .check_in
                          )
                          : moment().add(0, "days");

                        if (focused2 === "startDate")
                          return day.isBefore(moment(res).subtract(1, "days"));
                        if (focused2 === "endDate")
                          return (
                            day.isAfter(
                              moment(values.check_in).add(20, "days")
                            ) ||
                            day.isBefore(
                              moment(values.check_in).subtract(1, "days")
                            )
                          );
                      }}
                    />
                  </div>
                )}
                {isMobile && (
                  <div className="date-picker-type">
                    <div className="check-in-date date-picker-style">
                      <i className="icon-invitation" />
                      <Button
                        className="inputStyle grow1"
                        onClick={showDateResponsive}
                        type="button"
                        text={
                          values.check_in
                            ? moment(values.check_in).format("DD/MM/YYYY")
                            : "Check-in"
                        }
                      />
                    </div>

                    <div className="check-out-date date-picker-style">
                      <i className="icon-invitation" />
                      <Button
                        className="inputStyle grow1"
                        onClick={showDateResponsive}
                        type="button"
                        text={
                          values.check_out
                            ? moment(values.check_out).format("DD/MM/YYYY")
                            : "Check-Out"
                        }
                      />
                    </div>
                  </div>
                )}
                {isMobile && isDateResponsive && (
                  <div className="inputStyle showDropdown">
                    <div className="date-picker-popup ">
                      <Link to="#" className="closePopup">
                        <span
                          className="icon-close"
                          onClick={() => handleDateClick()}
                        />
                      </Link>
                      <h4 className="inputPopTitle">Going to</h4>
                      <div className="Date-picker-range">
                        <DateRangePickerComponent
                          isDateResponsive={"startDate"}
                          name="dates"
                          startDate={values.check_in}
                          endDate={values.check_out}
                          startDateId="check_in"
                          endDateId="check_out"
                          focusedInput={focused2}
                          onFocusChange={(focusedInput) => {
                            setFocused2(focusedInput);
                          }}
                          isRequired
                          icon="icon-invitation"
                          orientation="vertical"
                          openDirection="down"
                          startDatePlaceholderText="Check-in"
                          endDatePlaceholderText="Check-out"
                          onDatesChange={({ startDate, endDate }) => {
                            handleDateChange(
                              startDate,
                              formikProps,
                              "check_in"
                            );
                            handleDateChange(endDate, formikProps, "check_out");
                          }}
                          isDayBlocked={(day) => {
                            if (focused2 === "endDate") {
                              // return (
                              // day > values.check_in.clone().add(21, "days")
                              // );
                            }
                          }}
                          isOutsideRange={(day) => {
                            const res = JSON.parse(
                              localStorage.getItem("hotelWidget")
                            )
                              ? moment(
                                JSON.parse(
                                  localStorage.getItem("hotelWidget")
                                ).check_in
                              )
                              : moment().add(0, "days");

                            if (focused2 === "startDate")
                              return day.isBefore(
                                moment(res).subtract(1, "days")
                              );
                            if (focused2 === "endDate")
                              return (
                                day.isAfter(
                                  moment(values.check_in).add(20, "days")
                                ) ||
                                day.isBefore(
                                  moment(values.check_in).subtract(1, "days")
                                )
                              );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={
                    !open
                      ? "dropdownWrap dropDownMobilePopup toggleClass"
                      : "dropdownWrap dropDownMobilePopup toggleClass openDropdown"
                  }
                >
                  <Button
                    className="btnSml width180 toggleBtn"
                    type="button"
                    onClick={() => setOpen(!open)}
                    text={`${values?.rooms.length} Room${values.rooms.length > 1 ? "s" : ""
                      }, ${adultChildrenCount + childCount}  ${childCount ? "Guest" : "Adult"
                      }${adultChildrenCount > 1 ? "s" : ""}`}
                    icon="fa fa-angle-down"
                  />
                  <div className="dropdownInWrap cabin-class-wrapper">
                    <div className="dropDownSubWrap">
                      <div className="cabinClassWrapper">
                        <Link className="popupCloseBtn toggleBtn" to="#">
                          <span
                            className="icon-close"
                          // onClick={() => setOpen(!open)}
                          />
                        </Link>
                      </div>
                      {open && (
                        <AddRoomForm
                          isOpen={open}
                          inputFields={values?.rooms}
                          roomObject={roomObject}
                          formikProps={formikProps}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mobileBtnWrap">
                  <a
                    href="#"
                    className="mobileShow closeFilter"
                    onClick={() => setIsFilter(false)}
                  >
                    Close <i className="fa fa-angle-up"></i>
                  </a>
                  <button href="#" className="btnBorder" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {renderForm}
      </Formik>
    </>
  );
}

export default HotelWidget;