import React, { useEffect, useState } from "react";
import { warn } from "react-bootstrap-typeahead/types/utils";
import { useSelector } from "react-redux";
import { getCookie } from "../../Util/util";
import Button from "../FormComponent/Button";
import FlightInfoBox from "./FlightInfoBox";
import FlightPlanBox from "./FlightPlanBox";

function FlightListingCard(props) {
  const {
    legs,
    flights,
    flightDetailModal,
    setshowFlightModal,
    showFlightModal,
    prices,
    sequence_no,
    currency_symbol,
    selectedFlightTab,
    currency
  } = props;


  const [isPremium, setIsPremium] = useState(false);
  const [sampleModal, setSampleModal] = useState(false);

  const flightPollingData = useSelector(
    (state) => state.flightReducer?.flightPollingData 
  );  

  const [showMore, setShowMore] = useState(false);
  const [isMobile, setisMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setisMobile(true);
    } else if (window.innerWidth > 767) {
      setisMobile(false);
    }
  }, [window.innerWidth]);


  useEffect(() => {
    setIsPremium(getCookie("is_premium") === "true");
  }, []);

  const plans = [flights?.price?.economy, flights?.price?.premiumEconomy, flights?.price?.business, , flights?.price?.first]

  return (
    <div
      className={showMore ? "flightResult flightResultActive" : "flightResult"}
    >
      <FlightInfoBox
        legs={legs}
        flights={flights}
        flightDetailModal={flightDetailModal}
        setshowFlightModal={setshowFlightModal}
        showFlightModal={showFlightModal}
        sampleModal={sampleModal}
        setSampleModal={setSampleModal}
      />
      <div
        className={showMore ? "flightPlansRow showAllPlans" : "flightPlansRow"}
      >

        {plans.map((item, index) => {
          return <>
            <FlightPlanBox
              item={item}
              cabin_class={item.cabinClass}
              sequenceId={flights?.sequenceId}
              currencySymbol={currency_symbol}
              currency={currency}
              key={index}
              index={index}
              legs={flights?.flightSegments}
              isPremium={isPremium}
            />
          </>
        })}

      </div>
      <button
        className={`mobileShowMoreLink mobileShow ${!showMore ? "show-more-btn" : "show-less-btn"
          }`}
        onClick={() => setShowMore(!showMore)}
      >
        {!showMore ? "Show more" : "Show less"}
        <i className={`fa fa-angle-${showMore ? "up" : "down"}`}></i>
      </button>
    </div>
  )
}

export default FlightListingCard;