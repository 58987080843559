import React from "react";
import paypalImg from "../Assets/img/PayPal.png";
import InputField from "./FormComponent/InputField";

// Email input field

function EmailInput(props) {
  const {
    paypalEmail,
    paypalEmailLabel,
    paypalEmailPlaceholder,
    formikProps,
    confirmPaypalEmail,
    confirmPaypalEmailPlaceholder,
    confirmPaypalEmaillLabel,
  } = props;
  const { values, errors, touched, handleChange, handleBlur } = formikProps;
  return (
    <div className="card2 PaypalInfo">
      <div className="row heading-row paypal-row">
        <div className="col paypal-col">
          <div className="sectionTitle">
            <h2>Paypal Email</h2>
          </div>
        </div>
        <div className="col paypal-col">
          <div className="sectionInfo">
            <p>
              If you do not have an existing PayPal <br />
              account please click{" "}
              <a
                href="https://www.paypal.com/us/webapps/mpp/account-selection"
                target="_blank"
                rel="noreferrer"
              >
                HERE
              </a>{" "}
              to create one.
            </p>
            <div className="paypal-logo">
              <a
                href="https://www.paypal.com/us/webapps/mpp/account-selection"
                target="_blank"
                rel="noreferrer"
              >
                <img src={paypalImg} alt="PayPal" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="paypal-content">
        <p>
          Payouts will be made through PayPal. This will REQUIRE Affiliates to
          have an ACTIVE PayPal account to RECEIVE their earnings. After you sign
          up you will be required to ENTER and VALIDATE your email address
          associated with your PayPal account. The Validation can be done in
          your Profile Section.
        </p>
      </div>
    </div>
  );
}

export default EmailInput;
