import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TabLayout from "../Component/TabLayout";
import { commonActions } from "../Stores/Actions";
import { authActions } from "../Stores/Actions";
import moment from "moment";

function LandingPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("search_key");
    
    const lt = localStorage.getItem("lt");
    const timeDiff = moment(lt).diff(moment(), "hours");
    dispatch(commonActions.hideLoader());
    if(timeDiff > 8){
        dispatch(authActions.getUserDetail());  // token authentication
    }
  }, []);
  return <TabLayout />;
}

export default LandingPage;
