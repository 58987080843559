import React, { useEffect, useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import TabMenu from "./TabMenu";
import PremiumMemberInvites from "./PremiumMemberInvites";
import Link from "./Link";
import Email from "./Email";
import QrCode from "./QrCode";
import TabLayout from "./TabLayout";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import DetailsInfoModal from "./DetailsInfoModal";
import Card from "./Card";
import infoIcon from "../../Assets/img/info-icon-white.png";
import memberInviteImg from "../../Assets/img/member-invite-heading.png";
import linkImg from "../../Assets/img/link.png";
import contentCopyImg from "../../Assets/img/content-copy.png";
import whatsAppImg from "../../Assets/img/whatsapp-bg.png";
import telegramImg from "../../Assets/img/telegram-bg.png";
import tiktokImg from "../../Assets/img/tiktok.png";
import instagramImg from "../../Assets/img/instagram-bg2.png";
import pinterestImg from "../../Assets/img/pinterest.png";
import facebookImg from "../../Assets/img/facebook-bg2.png";
import linkedinImg from "../../Assets/img/linkedin-bg.png";
import twitterImg from "../../Assets/img/twitter-bg2.png";
import qrCodeIcon from "../../Assets/img/qr-code-icon.png";
import qrCodeImage from "../../Assets/img/qr-code-image.png";
import fileDownloadImage from "../../Assets/img/file-download.png";
import appleWalletImage from "../../Assets/img/Apple-Wallet.png";
import gPayImage from "../../Assets/img/G-Pay.png";
import emailIcon from "../../Assets/img/email-icon.png";
import exampleImage from "../../Assets/img/air.png";
import qrImage from "../../Assets/img/qr-code-image.png";
import { useDispatch, useSelector } from "react-redux";

import Scrollbars from "react-custom-scrollbars";

function AffiliateInvites() {
  // State for Modal
  const [openModal, setOpenModal] = useState(false);

  // For opening and closing  modal
  const handleOpenCloseModal = () => {
    if (!openModal) {
      setOpenModal(!openModal);
    } else {
      setOpenModal(false);
    }
  };

  // user data
  const user = JSON.parse(localStorage.getItem("user"));

  const currentHost = process.env.REACT_APP_TRIPELLO_PORTAL_URL;
  const urlLinkText = `${currentHost}/affiliate-sign-up-landing?ref=${user?.email}&fname=${user?.first_name}&lname=${user?.last_name}&template=affiliate`;
  const emailError = useSelector((state) => state.cardsReducer.error);
  const emailStatus = useSelector((state) => state.cardsReducer.emailStatus);

  const SocialCardComponant = () => {
    return (
      <>
        <Link
          para1="Share your individual Freemium Invitation link. Copy the link and paste into your personal email, send it via SMS or share it through your favorite Social Media Channels."
          contentCopyImg={contentCopyImg}
          heading2="Click to Copy Link"
          para2="Enter your recipients contact information and we will directly send and Email with your individual invitation."
          urlLinkText={urlLinkText}
          className="sharingIconsList"
        />
      </>
    );
  };
  const EmailCardComponant = () => {
    return (
      <>
        <Email
          para2="Enter your recipients contact information and we will directly send and Email with your individual Freemium invitation link."
          para="By sending the invite directly you agree to the Terms and Conditions"
          currentUserId={JSON.parse(localStorage.getItem("user")).user_id}
          affiliateLink={urlLinkText}
          userType={"affiliate"}
          emailError={
            emailError?.userType == "affiliate" ? emailError?.message : null
          }
          emailStatus={
            emailStatus?.userType == "affiliate" ? emailStatus?.message : null
          }
        />
      </>
    );
  };
  const QrCardComponant = () => {
    return (
      <>
        <QrCode
          para={
            "Share your individual QR Code directly from this page or download it and share it offline."
          }
          qrImage={qrImage}
          fileDownloadImage={fileDownloadImage}
          heading="Download QR Code (.PNG)"
          appleWalletImage={appleWalletImage}
          gPayImage={gPayImage}
          linkText={urlLinkText}
        />
      </>
    );
  };

  return (
    <div className="siteBg membersCheckoutPageWrap membersRewardsPageWrap marketingDashboard">
      <div className="pageWrap pageWrapInner">
        <div className="fixedHeader">
          <HeaderNavbar />
        </div>
        <section className="mainContent">
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container affiliate-invites-wrapper">
              <TabMenu
                modalOpen={handleOpenCloseModal}
                infoIcon={infoIcon}
                className="btn details-btn"
              />
              <PremiumMemberInvites
                heading="Affiliate Invites"
                para={
                  <>
                    As Tripello Affiliates you have your own personalized
                    Invitation Links and QR Code.
                    <br /> You can target your prospects in Affiliate and
                    Network Marketing directly in person,
                    <br />
                    through Social Media and via Email.
                  </>
                }
                memberInviteImg={memberInviteImg}
              />
              <div className="afilliate-wrap">
                <div className="section-title">
                  <h2>Affiliate Invite Options</h2>
                </div>
                <div className="sharingWrapper sharingWrapperSpace desktopViewDisplayBlock">
                  <div className="row">
                    <div className="col">
                      <div className="card">
                        <div className="linkIcon">
                          <img src={linkImg} alt="icon" />
                          <h3>{Link} Link</h3>
                        </div>
                        <SocialCardComponant />
                      </div>
                    </div>
                    <div className="col">
                      <div className="card">
                        <div className="linkIcon">
                          <img src={emailIcon} alt="icon" />
                          <h3>{Link} Email</h3>
                        </div>
                        <EmailCardComponant />
                      </div>
                    </div>
                    <div className="col">
                      <div className="card">
                        <div className="linkIcon">
                          <img src={qrCodeIcon} alt="icon" />
                          <h3>{Link} QR</h3>
                        </div>
                        <QrCardComponant />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mobileSharingWrapper mobileSharingWrapper1 sharingWrapper tabletViewDisplayBlock">
                  <div className="card">
                    <TabLayout
                      SocialCardComponant={<SocialCardComponant />}
                      EmailCardComponant={<EmailCardComponant />}
                      QrCardComponant={<QrCardComponant />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
      {openModal && (
        <DetailsInfoModal
          openModal={openModal}
          closeModal={handleOpenCloseModal}
        />
      )}
    </div>
  );
}

export default AffiliateInvites;
