import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import CardToolTipDetails from "./CardTooltipDetails";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import CarImg from "../../Assets/img/car1.jpg";

function CarListingCard(props) {
  const {
    image,
    name,
    similar,
    currency,
    totalPrice,
    perDayPrice,
    moreDetails,
    isUnlimitedMiles,
    carCompanyLogo
  } = props;

  console.warn("CarListingCard", props);

  const navigate = useNavigate();
  const scrollToElementRef = useRef(null);

  const saveReservedCarsData = () => {
    localStorage.setItem("selectedCarsDetails", JSON.stringify(props));

    const dataPayload = {
      format: "json",
      refid: process.env.REACT_APP_BASE_CAR_REF_ID,
      api_key: process.env.REACT_APP_CARS_AUTO_DATA_API_KEY,
      ppn_bundle: props.ppn_bundle,
    };

    localStorage.setItem("carCheckOutPayload", JSON.stringify(dataPayload));
    navigate("/cars-checkout");
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

  const detailRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleCloseTooltip = () => {
    setTimeout(() => {
      setShowTooltip(false);
    }, 10);
  };

  const scrollToView = () => {
    scrollToElementRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

    // console.warn("document.documentElement.scrollTop",document.documentElement.scrollTop);
    // console.warn("window.pageYOffset",window.pageYOffset);

    // window.scrollTo({ top: window.pageYOffset + 150, behavior: 'smooth' })
    // var bodyRect = document.body.getBoundingClientRect();

    // let elem = document.querySelector('.cardCnt');
    // let elemRect = elem.getBoundingClientRect();
    // let letoffset = elemRect.top - bodyRect.top;

    // console.warn("rect", letoffset + 450);

    // window.scrollBy({
    //   top: letoffset,
    //   behavior: 'smooth'
    // });

    // window.scroll({
    //   top: window.pageYOffset + 150,
    //   behavior: 'smooth'
    // });

  };

  useOutsideHandler(detailRef, showTooltip, toggleCloseTooltip);

  return (
    <div
      ref={detailRef}
      onClick={() => {
        setShowTooltip(true)
      }
      }
      className={`col-3 ${showTooltip ? "showToolTip" : ""}`}
    >
      <div className="card2"  >
        <div ref={window.innerWidth < 768 ? scrollToElementRef : null} className="cardImg" onClick={scrollToView}>
          <div className="cars-logo">
            <img src={carCompanyLogo === null ? "defaultLogoHere" : carCompanyLogo} alt="" />
          </div>
          <div
            className="cardImage"
            ref={window.innerWidth > 768 ? scrollToElementRef : null}

            style={{
              backgroundImage: `url(${image === null ? CarImg : image})`,
            }}
          ></div>
        </div>

        <div className="cardCnt" onClick={scrollToView}>
          <div>
            <h3 className="cardTitle">{name}</h3>
            <div className="similarVehicle">
              Or similar
              <div className="grayTag">{similar}</div>
            </div>

            {isUnlimitedMiles && (
              <div className="tickNote">
                <span className="icon-tick" />
                Including Unlimited Miles
              </div>
            )}
          </div>

          <div className="cardBtmBox dflex" >
            <div className="perDay">
              {formatter.format(perDayPrice)}
              <span>/day</span>
            </div>

            <div className="totalAmt">
              {formatter.format(totalPrice)}
              <span> total</span>
            </div>
          </div>
        </div>
        {/* <span ref={scrollToElementRef}></span> */}
        {showTooltip && (
          <CardToolTipDetails
            {...moreDetails}
            saveReservedCarsData={saveReservedCarsData}
          />
        )}

      </div>
    </div>
  );
}

export default CarListingCard;
