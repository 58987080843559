import React, { useEffect, useRef, useState } from "react";
import HotelPriceSection from "../../../Component/HotelCard/HotelPriceSection";
import MapView from "../../HotelSearchResult/MapView/MapView";
import HotelInfo from "../../../Component/Hotel/HotelInfo";
import ImageSlider from "../../../Component/ImageSlider/ImageSlider";
import ThumbCount from "../../../Component/ImageSlider/ThumbCount";
import Lightbox from "react-image-lightbox";
import { isEmpty } from "lodash";
import SingleHotelMapView from "../../HotelSearchResult/SingleHotelCard/SingleHotelMapView";
import { useSelector } from "react-redux";
import RoomPriceInfo from "../../../Component/Hotel/RoomPriceInfo";
import Slider from "react-slick";
import BedImg from "../../../Assets/img/bed.png";
import hotelImg from "../../../Assets/img/placeholder-image.png";
function HotelDetailCard({
  scrollToView,
  name,
  address,
  contactNo,
  checkIn,
  checkOut,
  themes,
  images,
  addressObj,
  heroImage,
  rating,
  latitude,
  longitude,
  hotel_id,
  currencySymbol,
  currencyCode,
}) {
  const [showGallery, setshowGallery] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);
  const [indexInit, setindexInit] = useState(0);
  const navigatiorClickHistoryRef = useRef(false);
  const loading = useSelector((state) => state.commonReducer.loader);
  const roomsPrice = useSelector((state) => state.hotelReducer.hotelRoom);
  const fullAddress = !isEmpty(addressObj)
    ? `${address}, ${addressObj.city}, ${addressObj.state_name}, ${addressObj.country_name}`
    : address;
  const toggleShowGallery = (index) => {
    if (index) {
      setphotoIndex(index);
    } else {
      setphotoIndex(1);
    }
    setshowGallery(true);
  };
  const imgRef = useRef(null);
  const bestRoomPrice =
    roomsPrice && !isEmpty(roomsPrice?.rates?.refundable)
      ? roomsPrice?.rates.refundable?.groups[0].packages[0]
      : roomsPrice && !isEmpty(roomsPrice?.rates["non-refundable"])
      ? roomsPrice?.rates["non-refundable"]?.groups[0].packages[0]
      : {};

  // react - slick for image slider

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  return (
    <div className="bannerSlider">
      <div className="row no-gutters">
        <div className="fullImages col">
          {console.log(isEmpty(images), "isEmpty(images)")}
          <div
            className="imagesGroup customShadow"
            style={isEmpty(images) ? {
              backgroundImage: `url(${
                isEmpty(images) ? heroImage : images[0]
              })`,
            } : {}}
            disabled={isEmpty(images)}
          >
            <Slider
              asNavFor={nav2}
              ref={(slider1) => setNav1(slider1)}
              beforeChange={(e) => setindexInit(e + 1)}
              arrows={true}
              slidesToShow={1}
              // slidesToScroll={1}
              // className="imagesGroup customShadow"
            >
              {images &&
                images.map((num, index) => {
                  return (
                    <div className="imagesGroupWrap" key={index}>
                      <div
                        className="imagesGroup customShadow"
                        style={{ backgroundImage: `url(${num})` }}
                        onClick={() => {
                          navigatiorClickHistoryRef.current = true;
                          toggleShowGallery(index);
                        }}
                        onError={(e) => {
                          e.target.src = hotelImg;
                        }}
                        ref={imgRef}
                      ></div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
        <div className="thumbnail row no-gutters desktopViewDisplayNone">
          <ImageSlider
            hotelImages={images}
            toggleShowGallery={toggleShowGallery}
          />
        </div>
        <div className="content image-content-col col">
          {!loading && (
            <SingleHotelMapView
              className="customShadow mobileViewDisplayNone"
              latitude={latitude}
              longitude={longitude}
            />
          )}
          {!loading && !isEmpty(bestRoomPrice) && (
            <div className="infoBox">
              <RoomPriceInfo
                {...bestRoomPrice}
                disableLink={true}
                scrollToView={scrollToView}
                hideSaveMember={true}
                currencySymbol={currencySymbol}
                currencyCode={currencyCode}
              />
            </div>
          )}
        </div>
      </div>
      <div className="thumbnail row no-gutters mobileViewDisplayNone">
        <Slider
          infinite={true}
          centerMode={false}
          centerPadding="0PX"
          slidesToShow={5} // it shoud be 5 , change for testing
          lazyLoad={true}
          asNavFor={nav1}
          variableWidth={false}
          focusOnSelect={true}
          // initialSlide={10}
          className="images col"
          ref={(slider2) => {
            if (!navigatiorClickHistoryRef.current) {
              // slider2?.slickNext();   // it removed for testing
            }
            setNav2(slider2);
          }}
        >
          {images &&
            images.map((num, index) => (
              <img
                src={num}
                alt="hotel-detail-image"
                className="w-100"
                key={`${index}_img`}
                onError={(e) => {
                  e.target.src = hotelImg;
                }}
              />
            ))}
        </Slider>
      </div>
      <div className="SliderContent">
        <HotelInfo
          name={name}
          contactNo={contactNo}
          address={fullAddress}
          checkOut={checkOut}
          checkIn={checkIn}
          themes={themes}
          rating={rating}
          latitude={latitude}
          longitude={longitude}
        />
      </div>
      {showGallery && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setshowGallery(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}

export default HotelDetailCard;
