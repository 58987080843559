import React, { useEffect } from "react";
import Checkbox from "./FormComponent/Checkbox";
import InputField from "./FormComponent/InputField";

function WalletDetailInfo(props) {
  const [showSccessMsg, setShowSccessMsg] = React.useState(null);
  const [remainingDueAmount, setRemainingDueAmount] = React.useState(null);
  const {
    formikProps,
    // handleWalletMaxLimit,
    setUsedWalletAmmout,
    available_balance,
    roomPrice,

    // amountAdded,
    amountAddedLabel,
    amountAddedPlaceholder,

    remainingAmount,
    remainingAmountLabel,
    remainingAmountPlaceholder,
    styleValue,

    billingDetails,
    checkedBoxValue,
    handleCheckbox,
    currencyCode,
    currencySymbol
  } = props;
  const user = JSON.parse(localStorage.getItem("user"));
  const { values, errors, touched, handleChange, handleBlur } = formikProps;

  // Max limit in walllet add amount

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode || "USD",
  });

  const handleWalletMaxLimit = (formikProps, field, value) => {
    var reg = new RegExp('^[0-9]*$');
    if (reg.test(value)==false) {
      return false;
    }
    formikProps.setFieldValue(field, value);
    setShowSccessMsg(null);
  };

  const ApplyWallet = () => {
    if(values.amountAdded < 1){
      formikProps.setFieldError(
        "amountAdded",
        "Value can not be 0"
      );
      return
    }
    if (values.amountAdded > roomPrice) {
      return formikProps.setErrors({
        amountAdded: `You can use maximum ${roomPrice} on this booking.`,
      });
    } else if (values.amountAdded > available_balance) {
      formikProps.setFieldError(
        "amountAdded",
        "Amount is greater than your balance"
      );
    }
    if (
      !errors.amountAdded &&
      values.amountAdded <= available_balance &&
      values.amountAdded != ""
    ) {
      setUsedWalletAmmout(values.amountAdded);
      setRemainingDueAmount((roomPrice - values.amountAdded).toFixed(2));
      setShowSccessMsg(
         `${formatter.format(values.amountAdded)} of your ${formatter.format(available_balance)} towards this booking`
      );
    } else {
      setShowSccessMsg(null);
    }
  };

  return (
    <div className="card2 cardWalletInfo">
      <div className="WalletInfoTitle">
        <div className="boxTitle">
          <div className="checkBoxContent">
            <Checkbox
              name="use_wallet"
              value={values.use_wallet}
              onChange={() =>
                handleCheckbox(formikProps, "use_wallet", !values.use_wallet)
              }
              text={user.type_id !== process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID ? "Apply your Tripello Wallet to this booking" : "Apply your Tripello Rewards to this booking"}
              className="checkbox-label"
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      {values.use_wallet && (
        <div
          className="add-wallet-balance-row"
          style={styleValue ? { display: "none" } : { display: "block" }}
        >
          <div className="walletToggleInfoWrap">
            <div className="walletToggleInfo">
              <h5>
                Wallet Balance : <strong>{formatter.format(available_balance)}</strong>
              </h5>
              <h5>You do not have enough wallet balance to apply.</h5>
            </div>
            <div className="or-option"></div>
            <div className="wallet-amount-with-button">
              <div className="row">
                <div className="col input-field">
                  <h5>Wallet Amount Added To Checkout</h5>
                  <InputField
                    name={"amountAdded"}
                    // label={amountAddedLabel}
                    placeholder={amountAddedPlaceholder}
                    value={values.amountAdded ? currencySymbol+ values.amountAdded : ""}
                    // value={values.amountAdded ? "$" + values.amountAdded : ""}
                    error={errors.amountAdded}
                    touched={touched.amountAdded}
                    placeHolder={`${currencySymbol}0.00`}
                    onChange={(e) =>
                      handleWalletMaxLimit(
                        formikProps,
                        "amountAdded",
                        e.target.value?.replaceAll(currencySymbol, "")
                      )
                    }
                    onBlur={handleBlur}
                    className="second-col"
                    type="text"
                  />
                </div>
                <div className="col input-field">
                  <h5>Remaining Amount Due</h5>

                  <InputField
                    name={remainingAmount}
                    placeholder={remainingAmountPlaceholder}
                    // label={remainingAmountLabel}
                    value={remainingDueAmount ? currencySymbol + remainingDueAmount?.replaceAll(currencySymbol, "") : `${currencySymbol}${roomPrice}`} 
                    // value={remainingDueAmount ? "$" + remainingDueAmount?.replaceAll("$", "") : `${currencySymbol}${roomPrice}`} 
                    error={errors.remainingAmount}
                    touched={touched.remainingAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    disabled={true}
                  />
                </div>
                <div className="col col-button">
                  <h5></h5>
                  <button onClick={ApplyWallet} type="button" className="btn">
                    Apply
                  </button>
                </div>
              </div>
              {showSccessMsg && <p>{showSccessMsg}</p>}
            </div>
            {/* <div className="walletToggleInfo">
          <h5>
            Wallet Balance : <strong>$ 100.00</strong>
          </h5>
          <div className="walletInfoInputField">
            <input type="text" placeholder="Enter Amount" className="formControl" />
            <button type="button" className="btn">
              Apply
            </button>
          </div>
          <div className="hint-text">
            <p>
              You can use <strong>$40</strong> of your <strong>$100</strong>
              towards this booking.
            </p>
          </div>
        </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default WalletDetailInfo;