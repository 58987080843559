import { carsConstants } from "../Constants";
import { commonActions } from "./commonActions";
import { carsService } from "../../api/carsService";

function searchCarsAction(payload) {
  return (dispatch) => {
    dispatch(request(carsConstants.SEARCH_CARS_REQUEST));
    dispatch(commonActions.loaderText("SEARCHING..."));
    carsService.searchCarService(payload).then(
      (response) => {
        if (response?.data?.getCarResultsV3?.hasOwnProperty("error")) {
          dispatch(
            failure(
              carsConstants.SEARCH_CARS_FAILURE,
              response?.data?.getCarResultsV3?.error?.status
            )
          );
          dispatch(commonActions.hideLoader());
        } else {
          dispatch(success(carsConstants.SEARCH_CARS_SUCCESS, response));
          dispatch(commonActions.hideLoader());
        }
      },
      (error) => {
        dispatch(failure(carsConstants.SEARCH_CARS_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function getCarContract(payload) {
  return (dispatch) => {
    dispatch(request(carsConstants.CARS_CONTRACT_REQUEST));
    dispatch(commonActions.loaderText("Validating..."));
    carsService.getCarContract(payload).then(
      (response) => {
        if (response?.data?.getCarContractRequest?.hasOwnProperty("error")) {
          dispatch(
            failure(
              carsConstants.CARS_CONTRACT_FAILURE,
              response?.data?.getCarContractRequest?.error?.status
            )
          );
          dispatch(commonActions.hideLoader());
        } else {
          localStorage.setItem("carsContract", JSON.stringify(response.data));
          dispatch(success(carsConstants.CARS_CONTRACT_SUCCESS, response));
          dispatch(commonActions.hideLoader());
        }
      },
      (error) => {
        dispatch(failure(carsConstants.CARS_CONTRACT_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function bookCarAction(dataPayload, formData) {
  return (dispatch) => {
    dispatch(request(carsConstants.BOOK_CAR_REQUEST));
    dispatch(commonActions.showLoader());
    carsService.bookCarService(dataPayload, formData).then(
      (response) => {
        if (response?.data?.getCarBookRequest?.hasOwnProperty("error")) {
          dispatch(
            failure(
              carsConstants.BOOK_CAR_FAILURE,
              response?.data?.getCarBookRequest?.error?.status
            )
          );
        } else {
          localStorage.setItem("carsBookingRes", JSON.stringify(response.data.getCarBookRequest.results))
          dispatch(success(carsConstants.BOOK_CAR_SUCCESS, response));
        }
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(failure(carsConstants.BOOK_CAR_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function retriveBooking(payload) {
  return (dispatch) => {
    dispatch(request(carsConstants.RETRIVE_BOOKING_REQUEST));
    return carsService
      .retriveBooking(payload)
      .then((res) => {
        dispatch(success(carsConstants.RETRIVE_BOOKING_SUCCESS, res));
        return res;
      })
      .catch((error) => {
        dispatch(failure(carsConstants.RETRIVE_BOOKING_FAILURE, error));
      });
  };
}

function validateZip(zip) {
  return (dispatch) => {
    dispatch(request(carsConstants.VALIDATE_ZIP_REQUEST));
    dispatch(commonActions.showLoader());
    dispatch(commonActions.loaderText("fetching..."));
    return carsService
      .validateZip(zip)
      .then((res) => {
        dispatch(success(carsConstants.VALIDATE_ZIP_SUCCESS, res));
        dispatch(commonActions.hideLoader());
        return res;
      })
      .catch((error) => {
        dispatch(failure(carsConstants.VALIDATE_ZIP_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}

export const carsActions = {
  searchCarsAction,
  getCarContract,
  bookCarAction,
  validateZip,
  retriveBooking
};
