import * as Yup from "yup";
import moment from "moment";

export const FlightCheckoutValidation = () => { 
  // Validation for affiliate premium membership

  return Yup.object().shape({
    firstName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    lastName: Yup.string("")
      .matches(/^[aA-zZ\s]+$/, "Must only have alphabet letters")
      .required("Required"),
    email: Yup.string("")
      .email("Required")
      .required("Required"),
    phoneNumber: Yup.string("")
      .nullable(true)
      .required("Required"),
    addressLineOne: Yup.string("").required("Required"),
    addressLineTwo: Yup.string(""),
    city: Yup.string("").required("Required"),
    state: Yup.string("").required("Required"),
    cardHolderName: Yup.string("").required("Required"),
    zipCode: Yup.string("") 
      .required("Required"),
    nationality: Yup.string("").required("Required"), 
    title: Yup.string("").required("Required"),
    terms: Yup.bool().oneOf([true], "Please Accept Terms & Conditions"),

    adults: Yup.array().of(
      Yup.object().shape({
        frequentFlyerNumber :  Yup.string("").when("check", { is: true, then: Yup.string().min(5,"Must be at least 5 characters").max(15,"Must be at most 15 characters").required("Required").nullable(true)}).nullable(true),
        passportNumber :  Yup.string("").when("check", { is: true, then: Yup.string().required("Required").nullable(true)}),
        phoneNumber :  Yup.string("").when("check", { is: true, then: Yup.string().required("Required")}),
        title: Yup.string("").when("check", { is: true, then: Yup.string().required("Required")}),  
        gender: Yup.string("").when("check", { is: true, then: Yup.string().required("Required").nullable(true)}).nullable(true),
        firstName: Yup.string("").when("check", { is: true, then: Yup.string().required("Required")}),
        lastName: Yup.string("").when("check", { is: true, then: Yup.string().required("Required")}),
        nationality: Yup.string("").when("check", { is: true, then: Yup.string().required("Required")}),
        passportCountry: Yup.string("").when("check", { is: true, then: Yup.string().required("Required")}),
        dob: Yup.date(null).when("check", { is: true, then: Yup.date(null).required("Required").test(
          "dob",
          "Age should be more than 18 years.",
          (date) => moment().diff(moment(date), "years") >= 18
        ).nullable(true)}).nullable(true),
        passportExpiryDate: Yup.date(null).when("check", { is: true, then: Yup.date(null).required("Required").nullable(true)}).nullable(true),
      })
    ),

    childs: Yup.array().of(
      Yup.object().shape({
        frequentFlyerNumber :  Yup.string("").required("Required").min(5,"Must be at least 5 characters").max(15,"Must be at most 15 characters").nullable(true),
        title: Yup.string("").required("Required"),
        passportNumber: Yup.string("").required("Required").nullable(true),
        gender: Yup.string("").required("Required").nullable(true),
        phoneNumber: Yup.string("").required("Required"),
        firstName: Yup.string("").required("Required"),
        passportCountry: Yup.string("").required("Required"),
        lastName: Yup.string("").required("Required"),
        nationality: Yup.string("").required("Required"),
        passportExpiryDate: Yup.date(null).required("Required").nullable(true),
        dob: Yup.date(null)
          .required("Required")
          .nullable(true)
          .test(
            "dob",
            "Age should be less than 18 and more than 2.",
            (date) =>
              moment().diff(moment(date), "years") >= 2 &&
              moment().diff(moment(date), "years") < 18
          ),
      })
    ),
    infants: Yup.array().of(
      Yup.object().shape({
        frequentFlyerNumber :  Yup.string("").required("Required").min(5,"Must be at least 5 characters").max(15,"Must be at most 15 characters").nullable(true),
        title: Yup.string("").required("Required"),
        passportNumber: Yup.string("").required("Required").nullable(true),
        gender: Yup.string("").required("Required").nullable(true),
        phoneNumber: Yup.string("").required("Required"),
        firstName: Yup.string("").required("Required"),
        passportCountry: Yup.string("").required("Required"),
        lastName: Yup.string("").required("Required"),
        nationality: Yup.string("").required("Required"),
        passportExpiryDate: Yup.date(null).required("Required").nullable(true),
        dob: Yup.date(null)
          .required("Required")
          .nullable(true)
          .test(
            "dob",
            "Age should be less than 2 years.",
            (date) =>
              moment().diff(moment(date), "years") < 2 &&
              moment().diff(moment(date), "years") >= 0
          ),
      })
    ),
  });
};