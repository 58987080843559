import React, { useRef, useState } from "react";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import Button from "../FormComponent/Button";
import Select from "react-select";
import { isEmpty, has } from "lodash";
function FlightSortByFilter({ label, sortBy, searchFlight }) {
  const [showOption, setshowOption] = useState(false);
  const sortRef = useRef(null);
  const toggleCloseSort = () => {
    setshowOption(false);
  };
  useOutsideHandler(sortRef, showOption, toggleCloseSort);
  // SortBy options for filter
  const sortByOptions = !isEmpty(sortBy)
    ? sortBy.map((item, index) => {
        return {
          field: item.name,
          order: item.order,
          label: item.label,
        };
      })
    : [{ label: "Price (Low to High)", value: "select" }];
  const [selectOpt, setSelectOpt] = useState(sortByOptions[0]);
  function handleSelect(e) {
    let preStoredVal = JSON.parse(localStorage.getItem("flightPayload"));
    preStoredVal = {
      ...preStoredVal,
      sort: { field: e.field, order: e.order, label: e.label },
    };
    localStorage.setItem("flightPayload", JSON.stringify(preStoredVal));
    setSelectOpt(e);
    searchFlight(1, 15, false, e);
  }
  return (
    <div className="filterDropdownWrap sortByOption" ref={sortRef}>
      <div className="flterOption" onClick={() => setshowOption(!showOption)}>
        <div className="white-text">
          <span className="whiteLabel">{label}</span>
          {/* <span className="desktopViewDisplayBlock">{selectOpt.label}</span> */}
          <span className="tabletViewDisplayBlock">{selectOpt.label}</span>
        </div>

        <Select
          className="sort-by-dropdown"
          options={sortByOptions}
          placeholder="Select..."
          value={selectOpt}
          onChange={(e) => {
            handleSelect(e);
          }}
        />
        <span className={`fa fa-angle-${showOption ? "up" : "down"}`}></span>
      </div>
    </div>
  );
}
export default FlightSortByFilter;
