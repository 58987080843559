import { authService } from "../../api/authService";
import { authConstants } from "../Constants/authConstants";
import { commonActions } from "./commonActions";

export const authActions = {
  loginRequest,
  forgotPasswordRequest,
  resetPasswordRequest,
  resetServeError,
  isEmailExistRequest,
  freemiumSignupRequest,
  premiumSignupRequest,
  validatePromocodeRequest,
  premiumPlusSignupRequest,
  getUserByAffiliateId,
  logoutRequest,
  getUserDetail,
  upgradeMembershipRequest,
  updateUserProfileAction,
  updatePassowrdAction,
  addUserAction,
  resetForgotPasswordDetails,
  resetUserData,
  cancelMembership,
  resetCancelMembershipStatus,
  getGuestListAction,
  updateGuestAction,
  confirmEmailAddressAction,
  validateCouponCodeRequest
};

function resetUserData() {
  return (dispatch) => {
    dispatch(request(authConstants.REST_USER_ID));
  };
}

function isEmailExistRequest(payload) {
  return async (dispatch) => {
    dispatch(request(authConstants.IS_EMAIL_EXIST_REQUEST));
    dispatch(commonActions.loaderText("Signing up..."));
    dispatch(commonActions.showLoader());
    try {
      const response = await authService
        .isEmailExistService(payload);
      dispatch(commonActions.hideLoader());
      if (response.status == 200)
        dispatch(success(authConstants.IS_EMAIL_EXIST_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(failure(authConstants.IS_EMAIL_EXIST_FAILURE, error));
      dispatch(commonActions.hideLoader());
      return error;
    }
  };
}

function resetForgotPasswordDetails() {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_FORGOT_PASSWORD));
  };
}

function freemiumSignupRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.FREEMIUM_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("Signing up..."));
    dispatch(commonActions.showLoader());
    return authService
      .freemiumSignupService(payload)
      .then((response) => {
        dispatch(success(authConstants.FREEMIUM_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        localStorage.removeItem("accessToken");
        return response;
      })
      .catch((error) => {
        dispatch(failure(authConstants.FREEMIUM_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}

function premiumSignupRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.PREMIUM_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("Signing up..."));
    dispatch(commonActions.showLoader());
    authService
      .premiumSignupService(payload)
      .then((response) => {
        dispatch(success(authConstants.PREMIUM_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        localStorage.removeItem("accessToken");
      })
      .catch((error) => {
        dispatch(failure(authConstants.PREMIUM_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function validatePromocodeRequest(payload) {
  return (dispatch) => {
    // dispatch(request(authConstants.PREMIUM_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("validating..."));
    dispatch(commonActions.showLoader());
    return authService
      .validatePromocodeService(payload)
      .then((response) => {
        // dispatch(success(authConstants.PREMIUM_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      })
      .catch((error) => {
        // dispatch(failure(authConstants.PREMIUM_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}

function validateCouponCodeRequest(payload) {
  return (dispatch) => {
    // dispatch(request(authConstants.PREMIUM_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("validating..."));
    dispatch(commonActions.showLoader());
    return authService
      .validateCouponCodeService(payload)
      .then((response) => {
        // dispatch(success(authConstants.PREMIUM_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      })
      .catch((error) => {
        // dispatch(failure(authConstants.PREMIUM_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}
function premiumPlusSignupRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.PREMIUM_PLUS_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("Signing up..."));
    dispatch(commonActions.showLoader());
    authService
      .premiumPlusSignupService(payload)
      .then((response) => {
        dispatch(success(authConstants.PREMIUM_PLUS_SIGNUP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        localStorage.removeItem("accessToken");
      })
      .catch((error) => {
        dispatch(failure(authConstants.PREMIUM_PLUS_SIGNUP_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function upgradeMembershipRequest(id, payload) {
  return (dispatch) => {
    dispatch(request(authConstants.UPGRADE_MEMBERSHIP_REQUEST));
    dispatch(commonActions.loaderText("upgrading..."));
    dispatch(commonActions.showLoader());
    authService
      .upgradeMembershipService(id, payload)
      .then((response) => {
        dispatch(success(authConstants.UPGRADE_MEMBERSHIP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(failure(authConstants.UPGRADE_MEMBERSHIP_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function getUserByAffiliateId(id) {
  return (dispatch) => {
    dispatch(commonActions.loaderText("Signing up..."));
    dispatch(commonActions.showLoader());
    dispatch(request(authConstants.GET_USER_BY_ID_REQUEST));
    return authService
      .getUserByAffiliateId(id)
      .then((response) => {
        dispatch(success(authConstants.GET_USER_BY_ID_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response;
      })
      .catch((error) => {
        dispatch(failure(authConstants.GET_USER_BY_ID_FAILURE, error));
        dispatch(commonActions.hideLoader());
        return error;
      });
  };
}

function loginRequest(payload, history) {
  return (dispatch) => {
    dispatch(request(authConstants.LOGIN_REQUEST));
    dispatch(commonActions.loaderText("Signing in..."));
    dispatch(commonActions.showLoader());
    authService
      .loginService(payload)
      .then((response) => {
        if (response.status === 200 && response.data.access_token) {
          dispatch(getUserDetail(history,"landing"));
          localStorage.setItem("accessToken", response.data.access_token);
          localStorage.setItem("lt", new Date());
        }
        dispatch(success(authConstants.LOGIN_SUCCESS, response.status));
        // dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(
          failure(authConstants.LOGIN_FAILURE, error?.response?.data?.message)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function getUserDetail(history,redirectTo) {
  return (dispatch) => {
    dispatch(request(authConstants.GET_USER_DETAIL_REQUEST));
    // dispatch(commonActions.loaderText("Loading..."));
    // dispatch(commonActions.showLoader());
    authService
      .getUserService()
      .then((response) => {
        if (localStorage.getItem("accessToken")) {
          localStorage.setItem("lt", new Date());
          if (
            response.data.type_id != process.env.REACT_APP_FREMIUM_USER_TYPE_ID
          ) {
            document.cookie = "is_premium=true;";
          } else {
            document.cookie = "is_premium=false;";
          }
        }
        if (response.data.type_id == process.env.REACT_APP_AFFILIATE_PREMIUM_USER_TYPE_ID) {
          localStorage.setItem("user", JSON.stringify(response.data));
          history(`/${redirectTo}`);
          dispatch(success(authConstants.GET_USER_DETAIL_SUCCESS, response));
        } else {
          console.log("unauthorized, its not affiliate user");
          dispatch(failure(authConstants.IS_AFFILIATE_USER_SUCCESS, "These credentials do not match our records"));
          localStorage.clear();
        }
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(commonActions.hideLoader());
        if (error && error.response && error.response.status === 401)
          dispatch(failure(authConstants.GET_USER_DETAIL_FAILURE, error));
      });
  };
}

function logoutRequest(payload) {
  return (dispatch) => {
    document.cookie =
      "is_premium=false; expires= Thu, 21 Aug 2014 20:00:00 UTC; ";
    dispatch(request(authConstants.LOGOUT_REQUEST));
    dispatch(commonActions.loaderText("Signing out..."));
    dispatch(commonActions.showLoader());

    authService
      .logoutService(payload)
      .then((response) => {
        dispatch(success(authConstants.LOGOUT_SUCCESS, response));
        document.cookie =
          "is_premium=false; expires= Thu, 21 Aug 2014 20:00:00 UTC; ";
        dispatch(commonActions.hideLoader());
        localStorage.clear();
      })
      .catch((error) => {
        dispatch(success(authConstants.LOGOUT_SUCCESS, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function forgotPasswordRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.FORGOT_PASSWORD_REQUEST));
    dispatch(commonActions.loaderText("Loading..."));
    commonActions.showLoader();

    authService.forgotPasswordService(payload).then(
      (response) => {
        dispatch(success(authConstants.FORGOT_PASSWORD_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(failure(authConstants.FORGOT_PASSWORD_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function resetPasswordRequest(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_PASSWORD_REQUEST));
    dispatch(commonActions.loaderText("Loading..."));
    commonActions.showLoader();

    return authService.resetPasswordService(payload).then(
      (response) => {
        dispatch(success(authConstants.RESET_PASSWORD_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        return response
      },
      (error) => {
        dispatch(failure(authConstants.RESET_PASSWORD_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function resetServeError() {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_SERVER_ERROR));
  };
}

function updateUserProfileAction(payload, userId) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_USER_DETAIL_REQUEST));
    dispatch(commonActions.loaderText("Updating..."));
    commonActions.showLoader();
    authService.updateUserProfileService(payload, userId).then(
      (response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        dispatch(success(authConstants.UPDATE_USER_DETAIL_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(failure(authConstants.UPDATE_USER_DETAIL_FAILURE, error));
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function updatePassowrdAction(payload) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_PASSWORD_REQUEST));
    dispatch(commonActions.loaderText("Updating..."));
    commonActions.showLoader();
    authService.updatePassowrdService(payload).then(
      (response) => {
        dispatch(success(authConstants.UPDATE_PASSWORD_SUCCESS, response));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(
          failure(authConstants.UPDATE_PASSWORD_FAILURE, error.response)
        );
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function addUserAction(payload, user_id, setShowAddUserFinalModal, resetForm) {
  return (dispatch) => {
    dispatch(request(authConstants.ADD_USER_DETAIL_REQUEST));
    dispatch(commonActions.loaderText("Adding..."));
    dispatch(commonActions.showLoader());
    authService.addUserService(payload, user_id).then(
      (response) => {
        dispatch(success(authConstants.ADD_USER_DETAIL_SUCCESS, response));
        resetForm();
        setShowAddUserFinalModal(false);
        dispatch(getGuestListAction(user_id));
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(
          failure(authConstants.ADD_USER_DETAIL_FAILURE, error.response)
        );
        dispatch(commonActions.hideLoader());
      }
    );
  };
}

function updateGuestAction(
  payload,
  user_id,
  guest_id,
  setShowAddUserFinalModal
) {
  return (dispatch) => {
    dispatch(request(authConstants.UPDATE_GUEST_DETAIL_REQUEST));
    dispatch(commonActions.loaderText("Updating..."));
    dispatch(commonActions.showLoader());
    authService
      .updateGuestService(payload, user_id, guest_id)
      .then((response) => {
        dispatch(success(authConstants.UPDATE_GUEST_DETAIL_SUCCESS, response));
        setShowAddUserFinalModal(false);
        dispatch(getGuestListAction(user_id));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(
          failure(authConstants.UPDATE_GUEST_DETAIL_FAILURE, error.request)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function getGuestListAction(user_id) {
  return (dispatch) => {
    dispatch(request(authConstants.GET_GUEST_LIST_REQUEST));
    dispatch(commonActions.loaderText("Loading..."));
    dispatch(commonActions.showLoader());

    authService
      .getGuestList(user_id)
      .then((response) => {
        dispatch(commonActions.hideLoader());
        dispatch(success(authConstants.GET_GUEST_LIST_SUCCESS, response));
      })
      .catch((error) => {
        dispatch(commonActions.hideLoader());
        dispatch(failure(authActions.GET_GUEST_LIST_FAILURE, error.response));
      });
  };
}

function cancelMembership() {
  return (dispatch) => {
    dispatch(request(authConstants.CANCEL_MEMBERSHIP_REQUEST));
    dispatch(commonActions.loaderText("canceling..."));
    dispatch(commonActions.showLoader());
    authService
      .cancelMembership()
      .then((res) => {
        dispatch(success(authConstants.CANCEL_MEMBERSHIP_SUCCESS, res));
        dispatch(commonActions.hideLoader());
      })
      .catch((error) => {
        dispatch(
          failure(authConstants.CANCEL_MEMBERSHIP_FAILURE, error.response)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}

function resetCancelMembershipStatus() {
  return (dispatch) => {
    dispatch(request(authConstants.RESET_CANCEL_MEMBERSHIP_STATUS));
  };
}

function confirmEmailAddressAction(payload, navigate) {
  return (dispatch) => {
    dispatch(request(authConstants.CONFIRM_EMAIL_REQUEST));
    dispatch(commonActions.loaderText("Confirming..."));
    authService.confirmEmailAddressService(payload).then(
      (response) => {
        dispatch(success(authConstants.CONFIRM_EMAIL_SUCCESS, response));
        localStorage.setItem("confirmEmailResponse", response);
        navigate("/thankyou");
        dispatch(commonActions.hideLoader());
      },
      (error) => {
        dispatch(commonActions.hideLoader());
        dispatch(failure(authConstants.CONFIRM_EMAIL_FAILURE, error.response));
      }
    );
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
