import React, { Fragment } from "react";

export const TriptekDifference = () => {
  return (
    <Fragment>
      <h3 className="sctionTitle">The Tripello Difference</h3>
      <div className="content-wrapper">
        <p>
          Tripello does Travel Booking in a{" "}
          <strong>New and Different Way.</strong>
        </p>
        <p>
          Our <strong>Powerful Technology</strong> enables You to book{" "}
          <strong>directly</strong> from thousands of{" "}
          <strong>Wholesale Travel Providers</strong> on one platform.
        </p>
        <p>
          No Mark-Ups, No Commissions, No Booking Fees — <strong>EVER.</strong>
        </p>
        <p>
          <strong>Dynamic, True Wholesale / Net Rates </strong> in{" "}
          <strong>Real Time, </strong> directly to <strong>YOU.</strong>
        </p>

        <p>
          More Savings, More Freedom, More Choices,
          <strong> Better Experiences.</strong>
        </p>
      </div>
    </Fragment>
  );
};
