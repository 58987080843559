import React from 'react';

function Pagination() {
	return (
		<div className="paginationWrapper">
			<ul className="pagination">
				<li><span>Prev</span></li>
				<li><span className="active">1</span></li>
				<li><span>2</span></li>
				<li><span>3</span></li>
				<li className="hide-for-mobile"><span>4</span></li>
				<li className="hide-for-mobile"><span>...</span></li>
				<li className="hide-for-mobile"><span>23</span></li>
				<li><span>Next</span></li>
			</ul>
		</div>
	);
}

export default Pagination;
