import React, { useEffect, useRef, useState } from "react";
import Button from "../FormComponent/Button";
import useOutsideHandler from "../../Stores/Utils/useOutsideHandler";
import {useFilters} from "../../Stores/ConstomHooks/useFilters"

import { has, pull, isEmpty } from "lodash";
function FlightCheckBoxFilter({
  label,
  filterOptions,
  // filters,
  // setFilters,
  searchFlight,
  type,
}) {
  const [globalfilters,setGlobalFilters,removeFilter,appliedFiltersList] = useFilters("flightFilters")
  const [showOption, setshowOption] = useState(false);
  const [showMore, setshowMore] = useState(false);
  const [isMobile, setisMobile] = useState(false);
  const filterRef = useRef(null);

  const [filters, setFilters] = useState({})

useEffect(() => {
 if(filters?.connections){
  setGlobalFilters("connections",filters?.connections)
 }else{
  removeFilter("connections")
 }
}, [filters])


  useEffect(() => {
    if (window.innerWidth <= 767) {
      setisMobile(true);
    } else {
      setisMobile(false);
    } 
  }, [window.innerWidth]);

  // console.log(":::filterOptions::::",type,"::::,",filterOptions)

  const toggleCloseFilter = () => {
    setshowOption(false);
  };
  useOutsideHandler(filterRef, showOption, toggleCloseFilter);

  function handleFilterChange(e, value) {
  
      let newFilters = filters;
      if (e.target.checked && has(filters, type)) {
        setFilters({ ...filters, [type]: [...filters[type], value] });
        newFilters = {
          ...newFilters,
          [type]: [...newFilters[type], value],
        };
      } else if (e.target.checked) {
        setFilters({ ...filters, [type]: [value] });
        newFilters = { ...newFilters, [type]: [value] };
      } else {
        setFilters({ ...filters, [type]: pull(filters[type], value) });
        newFilters = { ...newFilters, [type]: pull(filters[type], value) };
      }
      if (has(newFilters, type) && isEmpty(newFilters[type])) {
        const flightFilters = newFilters;
        delete flightFilters[type];
        setFilters({ ...flightFilters });
      }
 
  }
  function handleApply() {
    searchFlight();
    setshowOption((prev) => !prev);
  }
  return (
    <div
      className={`filterDropdownWrap  ${
        type == "connections" && "connecting-airlines-wrap "
      } ${showOption && "openedOption"}`}
      ref={filterRef}
    >
      <div className="flterOption" onClick={() => setshowOption(!showOption)}>
        <span>{label}</span>
        <span className={`fa fa-angle-${showOption ? "up" : "down"}`} />
      </div>
      <div className={`filterDropdown ${showOption ? "d-block" : ""}`}>
        {/* <span>{label}</span> */}
        {filterOptions &&
          !isMobile &&
          filterOptions.map((item, index) => {
            return (
              <label className="customCheckbox" key={index}>
                <input
                  type="checkbox"
                  name={item.name}
                  value={item.value}
                  checked={
                    filters?.connections?.find((opt) => opt === item.value) !==
                        undefined ? "checked" : false
                  }
                  onChange={(e) => handleFilterChange(e, item.value)}
                />
                <span>{item.label}</span>
              </label>
            );
          })}
        {filterOptions && isMobile ? (
          <div className="showMoreWrap">
            <div className="showMoreInWrap">
              {showMore
                ? filterOptions.map((item, index) => (
                    <label className="customCheckbox" key={index}>
                      <input
                        type="checkbox"
                        name={item.name}
                        value={item.value}
                        checked={
                          filters[type]?.find((opt) => opt === item.value) !==
                              undefined ? "checked" : false
                        }
                        onChange={(e) => handleFilterChange(e, item.value)}
                      />
                      <span>{item.label}</span>
                    </label>
                  ))
                : filterOptions.map((item, index) => {
                    if (index < 4) {
                      return (
                        <label
                          className="customCheckbox"
                          key={index}
                          // htmlFor={item.name}
                        >
                          <input
                            type="checkbox"
                            name={item.name}
                            value={item.value}
                            checked={
                              filters[type]?.find((opt) => opt === item.value) !==
                                  undefined ? "checked" : false
                            }
                            onChange={(e) => handleFilterChange(e, item.value)}
                          />
                          <span>{item.label}</span>
                        </label>
                      );
                    }
                  })}
            </div>
            {filterOptions.length >= 5 && (
              <Button
                className="mobileShow showMore"
                onClick={() => setshowMore(!showMore)}
              >
                Show {showMore ? "less" : "more"}{" "}
                <i className={`fa fa-angle-${showMore ? "up" : "down"}`} />
              </Button>
            )}
          </div>
        ) : null}
        {!isMobile && (
          <div className="btnsCnt">
            <Button className="borderBtn" onClick={handleApply}>
              Apply
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
export default FlightCheckBoxFilter;