import React from "react";
import { Link } from "react-router-dom";
import Checkbox from "./FormComponent/Checkbox";

//Terms Checkbox

function TermsCheckbox(props) {
  const { value, onChange, name, onBlur, touched, error } = props;

  return (
    <>
      <div className="checkBoxContent">
        <label className="checkBoxContentWrap">
          <Checkbox
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          <p className={touched && error ? "color-red" : ""}>
            By clicking «Pay Now», I agree to the{" "}
            <Link to="/terms-conditions" target="_blank">Terms and Conditions</Link> and{" "}
            <Link to="/privacy-policy" target="_blank">Privacy Policy </Link>
          </p>

          {/* <p className="color-red">{touched && error}</p> */}
        </label>
      </div>
    </>
  );
}

export default TermsCheckbox;
