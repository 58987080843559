import React, { useEffect, useState } from "react";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HeaderNavbar from "../../Component/HeaderNavbar";
import TotalCommissionBox from "./TotalCommissionBox";
import Commissions from "./Commissions";
import { CommissionsTabMenuData } from "../../Data/AffiliateDashboardMockData";
import TabMenu from "../AffiliateDashbord/TabMenu";
import infoIcon from "../../Assets/img/info-icon-white.png";
import DetailsInfoModal from "../AffiliateDashbord/DetailsInfoModal";
import { useDispatch } from "react-redux";
import { cardsAction } from "../../Stores/Actions/cardsAction";
import Scrollbars from "react-custom-scrollbars";

function AffiliateDashboard() {
  // State for details info modal
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  // user Id
  const user = JSON.parse(localStorage.getItem("user"));

  // useEffect(() => {
  //   const userId = user.user_id;
  //   dispatch(cardsAction.dashboardSummary(userId));
  // }, []);

  // Handle open modal
  const handleOpenCloseModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  return (
    <div className="siteBg landingPage dashboard membersRewardsPageWrap">
      <div className="pageWrap pageWrapInner">
        <div className="fixedHeader">
          <HeaderNavbar />
        </div>
        <section className="mainContent">
          <Scrollbars
            autoHeight={true}
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="container reward-dashbaord-wrapper">
              <TabMenu
                infoIcon={infoIcon}
                modalOpen={handleOpenCloseModal}
                className="btn details-btn"
              />

              <TotalCommissionBox />

              <Commissions data={CommissionsTabMenuData} />
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
        {openModal && (
          <DetailsInfoModal
            openModal={openModal}
            closeModal={handleOpenCloseModal}
          />
        )}
      </div>
    </div>
  );
}

export default AffiliateDashboard;
