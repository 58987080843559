import React, { useState, useEffect } from "react";
import moment from "moment";
import PortraitModeModel from "../Component/PortraitModeModel";
export default function Footer({ copyright }) {
  const [portraitViewModal, setportraitViewModal] = useState(window.outerWidth > 768 && window.outerWidth < 1025 ? true : false);
  useEffect(() => {
    window.addEventListener('resize', function (event) {

      if (window.outerWidth > 767 && window.outerWidth < 1366) {
        if (window.outerWidth < window.outerHeight) {
          // document.getElementsByTagName("body")[0].classList.add("open-popup");
          setportraitViewModal(true)
        } else {

          setportraitViewModal(false)
          // document.getElementsByTagName("body")[0].classList.remove("open-popup");  
        }
      }
      else {
        setportraitViewModal(false)
        // document.getElementsByTagName("body")[0].classList.remove("open-popup");
      }
    });
  }, []);

  const handleOpenClosePortraitModal = () => {
    setportraitViewModal(false);
  };
  return (
    //footer Section
    <>
      <PortraitModeModel
        portraitViewModal={portraitViewModal}
        closeModal={handleOpenClosePortraitModal}
      />

      <p className="footCopy">
        Copyright &copy; {moment().year()} Tripello Inc. All Rights Reserved.
      </p>
    </>
  );
}
