import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Component/FormComponent/Button";

function MessageBox({ message1, message2, booking_id, email }) {
  return (
    // Greeting message box
    <div className={"messageBox centerAlign"}>
      <h3 className="boxTitle">{message1}</h3>
      {booking_id ? <p className={"boxCopy" + (booking_id ? " p-10" : "")}>Booking Id - <strong>{booking_id}</strong></p> : ""}
      {email ? <p className={"boxCopy" + (booking_id ? " p-10" : "")}>Email - <strong>{email}</strong></p> : ""}
      <p className={"boxCopy"}>{message2}</p>
      
      <div className="boxBtnWrap">
        <Button className="btn" onClick={() => window.print()}>
          <span className="icon-printer"></span>Print Receipt
        </Button>

        <a
          className="btnBorder"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://secure.rezserver.com/car_rentals/help/review/?refid=${process.env.REACT_APP_CARS_AUTO_DATA_REF_ID}#manage`}
        >
          Cancel Itinerary
        </a>
      </div>
    </div>
  );
}

export default MessageBox;
