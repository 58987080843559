import React, { useState } from "react";
import Button from "../../Component/FormComponent/Button";

function HotelRoomTab({
  changeTab,
  selectedTab,
  refundableRooms,
  nonRefundableRooms,
  currencySymbol,
}) {
  return (
    <ul className="list-unstyled d-flex m-0">
      <li>
        <button
          className={selectedTab === 0 ? "active" : ""}
          onClick={(e) => changeTab(e, 0)}
        >
          Non Refundable{" "}
          {nonRefundableRooms.length ? (
            <span>
              (Starting from {currencySymbol}
              {nonRefundableRooms[0].packages[0].price.final_price})
            </span>
          ) : (
            ""
          )}
        </button>
      </li>
      <li>
        <button
          className={selectedTab === 1 ? "active" : ""}
          onClick={(e) => changeTab(e, 1)}
        >
          Refundable{" "}
          {refundableRooms.length ? (
            <span>
              (Starting from {currencySymbol}
              {refundableRooms[0].packages[0].price.final_price})
            </span>
          ) : (
            ""
          )}
        </button>
      </li>
    </ul>
  );
}

export default HotelRoomTab;
