import React from "react";

//Info box having details about hotel, car and flight

function InfoBox({
  divClassName,
  iconName,
  detail1,
  detail2,
  iconName2,
  detail,
}) {
  return (
    <div className={`dflex ${divClassName}`}>
      <span className="font14 lightText">
        <i className={iconName}>
          <span class="path1"></span>
        </i>
        <span className="mobileHide">{detail1}</span>
      </span>
      <span className="font14 darkText">
        {iconName2 && <i className={iconName2}></i>}
        {detail && <span className="mobileShow">{detail}</span>}
        {detail2}
      </span>
    </div>
  );
}

export default InfoBox;
