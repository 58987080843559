import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../Assets/img/logo.png";
import Button from './FormComponent/Button';

const UpgradeBanner = ({ScrollIntoCard , btnText}) => {
    return (
        <div className="row no-gutters">
            <div className="col page-logo">
                <Link to="/home" className="logoWrap">
                    {" "}
                    <img src={logo} alt="Tripello" />
                </Link>
            </div>
            <div className="col title">
                <h1>
                    Get <strong>Full Access</strong> to <strong>ALL</strong>{" "} Savings
                </h1>
            </div>
            <div className="col button-wrap">
                <Button
                    type="button"
                    className="btn orange-btn"
                    text={btnText ? btnText : "Upgrade Now"}
                    onClick={ScrollIntoCard}
                />
            </div>
        </div>
    )
}

export default UpgradeBanner