import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { getCookie } from "../../Util/util";

import TotalNightPrice from "./TotalNightPrice";
import Button from "../FormComponent/Button";
import CancelPolicyModal from "../../Views/HotelDetail/Rooms/CancelPolicyModal";

function RoomPriceInfo({
  price,
  free_cancellation_date,
  hideSaveMember,
  roomId,
  hotel_id,
  disableLink,
  scrollToView,
  roomNo, 
  currencyCode,
  cancellation_policy, 
}) {
  const [selectedTab, setselectedTab] = useState(1);
  const [showUpdate, setShowUpdate] = useState(false);
  const [isPremium, setIsPremium] = useState(null);
  const [cancelPolicyModal, setCancelPolicyModal] = useState(false);

  console.warn("price from  RoomPriceInfo ", price);

  const perNightPriceOfTotalPrice = Number(price?.per_night);
  const taxFeeOfTotalPrice = price?.sales_tax + price?.room_tax;
  const taxFeeOfNightlyPrice =
    (price?.sales_tax + price?.room_tax) / price?.no_of_nights;
  const mandatoryFee =
    price?.mandatory_fee?.length &&
    price?.mandatory_fee?.map((ele) => ele.amount)
      .reduce((a, b) => parseInt(a) + parseInt(b));
  // Tax for nightly price

  const tax_Nightly = (price?.per_night - price?.per_night_without_tax).toFixed(
    2
  );
  const [prices, setPrices] = useState({});

  const {
    savings_percentage,
    public_price,
    final_price,
    no_of_rooms,
    no_of_nights,
    savings_amount,
    net_rate,
    room_tax,
    mandatory_fee,
    per_night_public_price,
    per_night_without_tax,
    per_night_savings_amount,
    per_night,
    voucher_credit,
    save_more_percentage,
    save_more,
    save_more_final_price,
  } = price; // price from parent componant

  const premiumPrices = {
    savings: savings_percentage,
    publicPrice: public_price,
    from: final_price,
    roomCount: no_of_rooms,
    nightCount: no_of_nights,
    mandatory_fee: mandatoryFee,
    hasMandatoryFee: !isEmpty(mandatory_fee),
    free_cancellation_date: free_cancellation_date || null,
    currency: price?.mandatory_fee[0]?.currency,
    // price details
    perNight: net_rate,
    tax: room_tax,
    totalPriceToPay: final_price,
    savingPrice: savings_amount,

    // Night prices
    night_savings: savings_percentage,
    night_publicPrice: per_night_public_price,
    night_from: per_night,
    night_roomCount: no_of_rooms,
    night_nightCount: no_of_nights,
    night_mandatory_fee: mandatoryFee,
    night_hasMandatoryFee: !isEmpty(price?.mandatory_fee),
    night_free_cancellation_date: free_cancellation_date || null,
    night_currency: price?.mandatory_fee[0]?.currency,
    // price details
    night_perNight: per_night_without_tax,
    night_tax: tax_Nightly,
    night_totalPriceToPay: per_night,
    night_savingPrice: per_night_savings_amount,
  };
  const fremiumPrices = {
    savings: savings_percentage,
    publicPrice: public_price,
    from: final_price,
    roomCount: no_of_rooms,
    nightCount: no_of_nights,
    mandatory_fee: mandatoryFee,
    hasMandatoryFee: !isEmpty(mandatory_fee),
    free_cancellation_date: free_cancellation_date || null,
    currency: price?.mandatory_fee[0]?.currency,
    // price details
    perNight: net_rate,
    tax: room_tax,
    totalPriceToPay: final_price,
    savingPrice: voucher_credit,

    // Night prices
    night_savings: savings_percentage,
    night_publicPrice: per_night_public_price,
    night_from: per_night,
    night_roomCount: no_of_rooms,
    night_nightCount: no_of_nights,
    night_mandatory_fee: mandatoryFee,
    night_hasMandatoryFee: !isEmpty(price?.mandatory_fee),
    night_free_cancellation_date: free_cancellation_date || null,
    night_currency: price?.mandatory_fee[0]?.currency,
    // price details
    night_perNight: per_night_without_tax,
    night_tax: tax_Nightly,
    night_totalPriceToPay: per_night,
    night_savingPrice: parseFloat(voucher_credit) / parseFloat(no_of_nights),
  };
  const premiumForFremium = {
    savings: save_more_percentage,
    publicPrice: public_price,
    from: save_more_final_price,
    roomCount: no_of_rooms,
    nightCount: no_of_nights,
    mandatory_fee: mandatoryFee,
    hasMandatoryFee: !isEmpty(mandatory_fee),
    free_cancellation_date: free_cancellation_date || null,
    currency: price?.mandatory_fee[0]?.currency,
    // price details
    perNight: net_rate,
    tax: room_tax,
    totalPriceToPay: final_price,
    savingPrice: save_more,

    // Night prices
    night_savings: savings_percentage,
    night_publicPrice: per_night_public_price,
    night_from: per_night,
    night_roomCount: no_of_rooms,
    night_nightCount: no_of_nights,
    night_mandatory_fee: mandatoryFee,
    night_hasMandatoryFee: !isEmpty(price?.mandatory_fee),
    night_free_cancellation_date: free_cancellation_date || null,
    night_currency: price?.mandatory_fee[0]?.currency,
    // price details
    night_perNight: per_night_without_tax,
    night_tax: tax_Nightly,
    night_totalPriceToPay: per_night,
    night_savingPrice: per_night_savings_amount,
  };
  useEffect(() => {
    setIsPremium(getCookie("is_premium") === "true");
    // setIsPremium(false);
  }, []);

  useEffect(() => {
    if (isPremium == true) {
      setPrices(premiumPrices);
    } else if (isPremium == false) {
      setPrices(fremiumPrices);
    }
    if (showUpdate) setPrices(premiumForFremium);
  }, [showUpdate, isPremium,price]);

  const closeCancelPolicyModal = () => {
    setCancelPolicyModal(false);
  };

  return (
    <div className="col price-box-col">
      <div className="infoBox box-info">
        <div className="title d-flex">
          <Button
            className={`nightlyPriceTab ${selectedTab === 0 ? "active" : ""}`}
            onClick={() => setselectedTab(0)}
          >
            Nightly Price
          </Button>
          <Button
            className={`totalPriceTab ${selectedTab === 1 ? "active" : ""}`}
            onClick={() => setselectedTab(1)}
          >
            Total Price
          </Button>
        </div>
        <div className="row no-gutters ">
          <div className="col info">
            <div className="infoBoxWrapper">
              <div
                id="total-price-toggle"
                className={`price-container ${
                  selectedTab === 0 ? "activeTab" : ""
                }`}
              >
                {selectedTab === 0 && (
                  <>
                    <TotalNightPrice
                      savings={prices?.night_savings}
                      publicPrice={prices.night_publicPrice}
                      from={prices?.night_from}
                      roomCount={prices?.night_roomCount}
                      nightCount={prices?.night_nightCount}
                      savingPrice={prices?.night_savingPrice}
                      // perNight={perNightPriceOfTotalPrice}
                      perNight={prices?.night_perNight}
                      totalPriceToPay={prices?.night_totalPriceToPay}
                      no_of_nights={1}
                      // tax={taxFeeOfTotalPrice}
                      tax={prices?.night_tax}
                      mandatory_fee={prices.night_mandatory_fee}
                      hasMandatoryFee={!isEmpty(prices?.night_hasMandatoryFee)}
                      free_cancellation_date={
                        prices.night_free_cancellation_date || null
                      }
                      currency={prices.night_currency}
                      currencyCode={currencyCode}
                      nightPrice={true}
                    />
                  </>
                )}
              </div>
              <div
                id="nightly-price-toggle"
                className={`price-container ${
                  selectedTab === 1 ? "activeTab" : ""
                }`}
              >
                {selectedTab === 1 && (
                  <TotalNightPrice
                    savings={prices?.savings}
                    publicPrice={prices?.publicPrice}
                    from={prices?.from}
                    roomCount={prices?.roomCount}
                    nightCount={prices?.nightCount}
                    savingPrice={prices?.savingPrice}
                    // perNight={perNightPriceOfTotalPrice}
                    no_of_nights={no_of_nights}
                    perNight={prices?.perNight}
                    totalPriceToPay={prices?.totalPriceToPay}
                    // tax={taxFeeOfTotalPrice}
                    tax={prices?.tax}
                    mandatory_fee={prices.mandatory_fee}
                    hasMandatoryFee={!isEmpty(prices?.hasMandatoryFee)}
                    free_cancellation_date={
                      prices?.free_cancellation_date || null
                    }
                    currency={prices.currency}
                    currencyCode={currencyCode}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col buttonsGroup btn-right">
            {!showUpdate && (
              <div className="centerAlign">
                {!disableLink ? (
                  <Link
                    to={`/hotel-checkout/${hotel_id}/${roomId}/${roomNo}`}
                    className="btn"
                  >
                    Book Room
                  </Link>
                ) : (
                  <p onClick={() => scrollToView("rooms")} className="btn">
                    Select Room
                  </p>
                )}
              </div>
            )}

            {showUpdate ? (
              <div className="centerAlign buttonSpace upgradeBtn">
                <Link to="/membership-upgrade" className="btn">
                  <i className="icon-border-plain"></i>
                  Upgrade
                </Link>
                <button onClick={() => setShowUpdate(!showUpdate)}>
                  Cancel
                </button>
              </div>
            ) : (
              !isPremium &&
              !disableLink &&
              prices?.savingPrice > 0 && (
                <div className="centerAlign">
                  <Link
                    to="#"
                    className="btnBorder2"
                    onClick={() => setShowUpdate(!showUpdate)}
                  >
                    <i className="icon-border-plain"></i>
                    See Member Price
                  </Link>
                </div>
              )
            )}

            {!hideSaveMember &&
              cancellation_policy?.cancellation?.length !== 0 && (
                <div className="centerAlign cancellation-policy">
                  <button onClick={() => setCancelPolicyModal(true)}>
                    Cancellation Policy
                  </button>
                </div>
              )}

            {cancelPolicyModal && (
              <CancelPolicyModal
                isOpen={cancelPolicyModal}
                closeModal={closeCancelPolicyModal}
                policies={cancellation_policy?.cancellation}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default RoomPriceInfo;
