import React, { useState } from "react";
import { isEmpty } from "lodash";
import PersonLogo from "../../Assets/img/person.png";
import NoSmokeImg from "../../Assets/img/no-smoking.svg";
import SmokeImg from "../../Assets/img/smoking.svg";
import BedImg from "../../Assets/img/bed.png";
import RoomPriceInfo from "./RoomPriceInfo";
import Button from "../FormComponent/Button";
import RoomDetaisModal from "../../Views/HotelDetail/Rooms/RoomDetaisModal";
import Extras from "./Extras";
import RoomDetails from "./RoomDetails";
import { useEffect } from "react";

function RoomCard({
  images,
  group_name,
  packages,
  index,
  hotel_id,
  currencySymbol,
  currencyCode,
  isRefundable,
}) {
  const [openModal, setOpenModal] = useState(false);

  let selectedFlagPriceTemp;
  let defaultRadioSelect;

  if (packages[0].price?.room_only?.price) {
    selectedFlagPriceTemp = packages[0]?.price?.room_only;
    defaultRadioSelect = "rooms";
    console.warn("rooms only is selected default");
  } else if (packages[0].price?.breakfast?.price) {
    selectedFlagPriceTemp = packages[0]?.price?.breakfast;
    defaultRadioSelect = "breakfast";
    console.warn("breakfast is selected default");
  } else if (packages[0].price?.half_board?.price) {
    selectedFlagPriceTemp = packages[0]?.price?.half_board;
    defaultRadioSelect = "halfBoard";
    console.warn("half board is selected default");
  } else if (packages[0].price?.full_board?.price) {
    selectedFlagPriceTemp = packages[0]?.price?.full_board;
    defaultRadioSelect = "fullBoard";
    console.warn("full board is selected default");
  }

  const [selectedFlagPrice, setSelectedFlagPrice] = useState(
    selectedFlagPriceTemp
  );
  // const [defaultRadioSelect, setDefaultRadioSelect] = useState("");
  // const [roomId, setRoomId] = useState(222);
  // const [roomNo, setRoomNo] = useState(444);
  const isSmokingAllowed = !isEmpty(packages)
    ? packages[0].smoking_flag
    : false;
  const availableRooms = !isEmpty(packages) ? packages[0].available_rooms : 0;
  const occupancy =
    !isEmpty(packages) && !isEmpty(packages[0].occupancy)
      ? packages[0].occupancy.adult
      : 0;
  const bedType = !isEmpty(packages) ? packages[0].bed_type : "";
  const savingPercent = !isEmpty(packages)
    ? packages[0].price?.savings_percentage
    : 0;

  const breakfast =
    !isEmpty(packages) && parseInt(packages[0].price_of_breakfast) > 0
      ? { price: packages[0].price_of_breakfast }
      : null;

  const additionPrice = "";
  // const roomId =
  //   additionPrice === "breakfast"
  //     ? packages[0].room_no_with_breakfast
  //     : packages[0].room_no;

  // const roomNo =
  //   additionPrice === "breakfast"
  //     ? packages[0].room_id_with_breakfast
  //     : packages[0].id;

  // useEffect(()=>{
  //   if(packages[0].price?.room_only?.price){
  //     setSelectedFlagPrice(packages[0]?.price?.room_only)
  //     setDefaultRadioSelect("rooms")
  //     console.warn("rooms only is selected default")
  //   }else if(packages[0].price?.breakfast?.price){
  //     setSelectedFlagPrice(packages[0]?.price?.breakfast)
  //     setDefaultRadioSelect("breakfast")
  //     console.warn("breakfast is selected default")
  //   }else if (packages[0].price?.half_board?.price){
  //     setSelectedFlagPrice(packages[0]?.price?.half_board)
  //     setDefaultRadioSelect("halfBoard")
  //     console.warn("half board is selected default")
  //   }else if(packages[0].price?.full_board?.price){
  //     setSelectedFlagPrice(packages[0]?.price?.full_board)
  //     setDefaultRadioSelect("fullBoard")
  //     console.warn("full board is selected default")
  //   }
  // },[])

  useEffect(() => {
    console.warn("selectedFlagPrice", selectedFlagPrice);
  }, [selectedFlagPrice]);

  const updatePrice = (flag) => {
    console.warn("flag", flag);

    if (flag === "rooms") {
      setSelectedFlagPrice(packages[0].price?.room_only);
    }
    if (flag === "breakfast") {
      setSelectedFlagPrice(packages[0].price?.breakfast);
    }
    if (flag === "halfBoard") {
      setSelectedFlagPrice(packages[0].price?.half_board_price);
    }
    if (flag === "fullBoard") {
      setSelectedFlagPrice(packages[0].price?.full_board);
    }
  };

  console.warn("packages[0].price", packages[0].price);

  return (
    <div className="col leftContent">
      <div className="imageContent">
        <div className="mobileViewDisplayNone">
          <div className="row no-gutters heading-wrap">
            <div className="col heading-group">
              <div className=" heading">
                <h3>{group_name}</h3>
                <Button
                  className="room-details-show-popup"
                  type="button"
                  onClick={() => setOpenModal(!openModal)}
                >
                  Room Details and Photos
                </Button>
              </div>
            </div>
            <div className="col room-status-type">
              <div className="smoking-status">
                <img src={isSmokingAllowed ? SmokeImg : NoSmokeImg} />{" "}
                <span>Non Smoking</span>
              </div>
              <div className="room-types">
                <h5
                  className={
                    isRefundable ? "btn-refundable" : "btn-non-refundable"
                  }
                >
                  {isRefundable ? "Refundable" : "Non Refundable"}
                </h5>
              </div>
            </div>
            <div className="col heading-group2">
              <div className="availableRoomsWrap mobileViewDisplayNone">
                <h4 className="availableRooms">
                  {availableRooms} Rooms available
                </h4>
              </div>
              <div className="savings-heading">
                {savingPercent === 0 ? "" : <p>{savingPercent}% Savings</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <RoomDetails
            images={images}
            setOpenModal={setOpenModal}
            openModal={openModal}
            occupancy={occupancy}
            bedType={bedType}
            isSmokingAllowed={isSmokingAllowed}
            availableRooms={availableRooms}
            isRefundable={isRefundable}
          />

          <div className="col content">
            <Extras
              breakfast={packages[0].price?.breakfast?.price}
              halfBoard={packages[0].price?.half_board_price?.price}
              fullBoard={packages[0].price?.full_board?.price}
              roomsOnly={packages[0].price?.room_only?.price}
              updatePrice={updatePrice}
              index={index}
              currencySymbol={currencySymbol}
              defaultRadioSelect={defaultRadioSelect}
            />
          </div>
          {!isEmpty(selectedFlagPrice.price_detail) && (
            <RoomPriceInfo
              {...packages[0]}
              roomId={selectedFlagPrice.room_no}
              hotel_id={hotel_id}
              roomNo={packages[0].id}
              price={selectedFlagPrice.price_detail}
              currencySymbol={currencySymbol}
              currencyCode={currencyCode}
            />
          )}
        </div>
        <div className="mobileViewDisplayNone">
          <div className="hotelFacilities">
            {/* <h4>351 sq ft</h4> */}
            <h4>
              <img src={PersonLogo} alt="icons" />
              <span>Sleeps {occupancy}</span>
            </h4>
            {bedType && (
              <h4>
                <img src={BedImg} alt="icons" />
                <span>{bedType}</span>
              </h4>
            )}
          </div>
        </div>
      </div>
      {openModal && (
        <RoomDetaisModal
          isOpen={openModal}
          closeModal={setOpenModal}
          hotelImage={images.length ? images[0] : ""}
          group_name={group_name}
        />
      )}
    </div>
  );
}

export default RoomCard;
