import paypal from "../../Assets/img/checkout/paypal.png";
import bitcoin from "../../Assets/img/checkout/bitcoin.png";
import applePay from "../../Assets/img/checkout/apple-pay.png";
import googlePay from "../../Assets/img/checkout/google-pay.png";
import weChatPay from "../../Assets/img/checkout/wechat-pay.png";

import Checkbox from "../../Component/FormComponent/Checkbox";

const WalletDetails = () => {
  return (
    <div className="walletOptionWrap">
      <ul>
        <li>
          <div className="checkBoxContent">
            <label
              htmlFor="paypal-id"
              className="customCheckbox checkbox-label"
            >
              <input type="radio" id="paypal-id" name="WalletPayment" />
              <span></span>
              <div className="wallet-icon-wrap">
                <div className="wallet-icon">
                  <img src={paypal} alt="wallet icon" />
                </div>
                <h6>PayPal</h6>
              </div>
            </label>
          </div>
        </li>

        <li>
          <div className="checkBoxContent">
            <label htmlFor="apple-id" className="customCheckbox checkbox-label">
              <input type="radio" id="apple-id" name="WalletPayment" />
              <span></span>
              <div className="wallet-icon-wrap">
                <div className="wallet-icon">
                  <img src={applePay} alt="wallet icon" />
                </div>
                <h6>Apple Pay</h6>
              </div>
            </label>
          </div>
        </li>

        <li>
          <div className="checkBoxContent">
            <label
              htmlFor="Google-id"
              className="customCheckbox checkbox-label"
            >
              <input type="radio" id="Google-id" name="WalletPayment" />
              <span></span>
              <div className="wallet-icon-wrap">
                <div className="wallet-icon">
                  <img src={googlePay} alt="wallet icon" />
                </div>
                <h6>Google Pay</h6>
              </div>
            </label>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default WalletDetails;