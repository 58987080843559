import { authService } from "../../api/authService";
import { authConstants } from "../Constants/authConstants";
import { affiliateConstants } from "../Constants/affiliateConstants";
import { commonActions } from "./commonActions";
import { affiliateService } from "../../api/affiliateService";

export const affiliateActions = {
  affiliateSignupRequest,
  upgradeAffiliateMembershipRequest,
  getUserByMemberIdAction
};
 
function affiliateSignupRequest(payload) {
  return async (dispatch) => {
    dispatch(request(affiliateConstants.AFFILIATE_PREMIUM_SIGNUP_REQUEST));
    dispatch(commonActions.loaderText("Loading..."));
    dispatch(commonActions.showLoader()); 
   try {
      const response = await affiliateService.affiliateSignupService(payload);
      dispatch(success(affiliateConstants.AFFILIATE_PREMIUM_SIGNUP_SUCCESS, response));
      dispatch(commonActions.hideLoader());
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      return response;
    } catch (error) {
      dispatch(failure(affiliateConstants.AFFILIATE_PREMIUM_SIGNUP_FAILURE, error));
      dispatch(commonActions.hideLoader());
    }
  };
}

function getUserByMemberIdAction(id) {
  return (dispatch) => {
    dispatch(request(affiliateConstants.GET_AFFILIATE_USER_BY_MEMBER_ID_REQUEST));
    dispatch(commonActions.loaderText("Loading..."));
    dispatch(commonActions.showLoader());
    return affiliateService
      .getUserByMemberIdService(id)
      .then(response => {
        dispatch(success(affiliateConstants.GET_AFFILIATE_USER_BY_MEMBER_ID_SCUCCES, response));
        dispatch(commonActions.hideLoader());
      })
      .catch(error => {
        dispatch(failure(affiliateConstants.GET_AFFILIATE_USER_BY_MEMBER_ID_FAILURE, error));
        dispatch(commonActions.hideLoader());
      });
  };
}

function upgradeAffiliateMembershipRequest(payload) { 
  return (dispatch) => {
    dispatch(request(affiliateConstants.AFFILIATE_UPGRADE_MEMBERSHIP_REQUEST));
    dispatch(commonActions.loaderText("Loading..."));
    dispatch(commonActions.showLoader());
    affiliateService
      .affiliateUpgradeMembershipService(payload)
      .then((response) => {
        dispatch(success(affiliateConstants.AFFILIATE_UPGRADE_MEMBERSHIP_SUCCESS, response));
        dispatch(commonActions.hideLoader());
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
      })
      .catch((error) => {
        dispatch(
          failure(affiliateConstants.AFFILIATE_UPGRADE_MEMBERSHIP_FAILURE, error)
        );
        dispatch(commonActions.hideLoader());
      });
  };
}
 


function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
