import planImage from "../../../Assets/img/membership-upgrade/grid-item1.jpg";
import hotelImage from "../../../Assets/img/membership-upgrade/grid-item2.jpg";
import carImage from "../../../Assets/img/membership-upgrade/grid-item3.jpg";
import homeImage from "../../../Assets/img/membership-upgrade/grid-item4.jpg";

export const FEATURES = [
  {
    title: "Global Flights",
    link: "/home",
    imgUrl: planImage,
    desc: "Big savings on Flights, especially in premium cabins, from all Global Carries and over140 low-cost carriers fromaround the world.",
  },
  {
    title: "Hotels & Resorts",
    link: "/home",
    imgUrl: hotelImage,
    desc: "Huge savings on over 1.5 million Hotels and Resorts worldwide, Including the most popular destinations.",
  },
  {
    title: "Rental Cars & Services",
    link: "/home",
    imgUrl: carImage,
    desc: "Save on Rental Cars from the top brands around the world and a global Chauffeur Service with Airport Transfer (coming soon).",
  },
  {
    title: "Vacation Homes",
    link: "/home",
    imgUrl: homeImage,
    desc: "More than 1.5 Million bookable Vacation rentals incl. Condos, Cabins, Villas, Bungalows, Beach Houses and more.",
  },
];

export const PLANS = [
  {
    planName: "Premium Membership",
    link: `upgrade/membership/${process.env.REACT_APP_PREMIUM_USER_TYPE_ID}`,
    months: "(6 Month)",
    price: "$32.50",
    type: "premium",
    heading: "($195.00 total at checkout*)",
    para: "*Credit/Debit Card required for Sign Up and Bookings.",
    arrayList: [
      "Credit/Debit Card Required*",
      "Unlimited Platform Access",
      "Savings of up to 65% and more",
      "Access to 1.5 million Hotels and Resorts",
      "Access to 1.5 million Vacation Homes",
      "Access to Rental Cars and upcoming Chauffeur Service",
      "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
    ],
  },
  {
    planName: "Premium Plus Membership",

    link: `upgrade/membership/${process.env.REACT_APP_PREMIUM_PLUS_USER_TYPE_ID}`,
    months: "(12 Month)",
    price: "$24.95 ",
    type: "premium-plus",
    heading: "($299.40 total at checkout*)",
    para: "*Credit/Debit Card required for Sign Up and Bookings.",
    arrayList: [
      "Credit/Debit Card Required*",
      "Unlimited Platform Access",
      "Savings of up to 65% and more",
      "Access to 1.5 million Hotels and Resorts",
      "Access to 1.5 million Vacation Homes",
      "Access to Rental Cars and upcoming Chauffeur Service",
      "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
      <>
        Access to Member Referral Rewards Program <br />• $25 for each Premium
        Member
        <br /> • $50 for each Premium Plus Member
      </>,
    ],
  },
];
