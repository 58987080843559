import React, { useEffect, useState } from "react";
import StarImg from "../../Assets/img/star.png";
import { pull, has } from "lodash";
import YellowStarImg from "../../Assets/img/yellow-star.png";
function StarRating({
  filters,
  setFilters,
  searchHotel,
  mobileCategory,
  title,
  starRemove,
  setStarRemove,
}) {
  const [ratingStars, setRatingStars] = useState([]);
  const [showFilter, setshowFilter] = useState(true);
  const [star, setStar] = useState([]);
  useEffect(() => {
    if (has(filters, "rating")) {
      setRatingStars([...filters.rating]);
      setStar([...filters.rating]);
    }
  }, [filters]);

  // for remove selected star after click on clear filter option in UI
  useEffect(() => {
    if (starRemove) {
      setStar([]);
      setStarRemove(false);
    }
  }, [starRemove]);

  function handleRantingStar(value) {
    const alreadyPresent = ratingStars.find((ele) => ele === value);
    if (alreadyPresent) {
      setRatingStars(pull(ratingStars, value));
    } else {
      setRatingStars([...ratingStars, value]);
    }
    if (has(filters, "rating")) {
      const alreadyPresent1 = filters.rating.find((ele) => ele === value);
      if (alreadyPresent1) {
        setFilters({ ...filters, rating: pull(filters.rating, value) });
      } else {
        setFilters({ ...filters, rating: [...filters.rating, value] });
      }
    } else if (!alreadyPresent) {
      setFilters({ ...filters, rating: [value] });
    }
  }
  function handleApply() {
    searchHotel();
  }
  return mobileCategory ? (
    <>
      <div className="flterOption" onClick={() => setshowFilter(!showFilter)}>
        <span> {title} </span>
        <span className={`fa fa-angle-${showFilter ? "up" : "down"}`}></span>
      </div>
      <div
        className={`cardBody  star-rating ${!showFilter ? "d-none" : ""}`}
        style={{ display: "flex" }}
      >
        <div
          className="rating"
          data-id="1"
          onClick={(e) => {
            handleRantingStar(1);
            star.includes(1)
              ? setStar(
                  star.filter((ele) => ele !== Number(e.target.dataset.id))
                )
              : setStar([...star, Number(e.target.dataset.id)]);
          }}
        >
          <img
            src={star.includes(1) ? YellowStarImg : StarImg}
            alt="star"
            width="38px"
          />
        </div>
        <div
          className="rating"
          data-id="2"
          onClick={(e) => {
            handleRantingStar(2);
            star.includes(2)
              ? setStar(
                  star.filter((ele) => ele !== Number(e.target.dataset.id))
                )
              : setStar([...star, Number(e.target.dataset.id)]);
          }}
        >
          <img
            src={star.includes(2) ? YellowStarImg : StarImg}
            alt="star"
            width="38px"
          />
        </div>
        <div
          className="rating"
          data-id="3"
          onClick={(e) => {
            handleRantingStar(3);
            star.includes(3)
              ? setStar(
                  star.filter((ele) => ele !== Number(e.target.dataset.id))
                )
              : setStar([...star, Number(e.target.dataset.id)]);
          }}
        >
          <img
            src={star.includes(3) ? YellowStarImg : StarImg}
            alt="star"
            width="38px"
          />
        </div>
        <div
          className="rating"
          data-id="4"
          onClick={(e) => {
            handleRantingStar(4);
            star.includes(4)
              ? setStar(
                  star.filter((ele) => ele !== Number(e.target.dataset.id))
                )
              : setStar([...star, Number(e.target.dataset.id)]);
          }}
        >
          <img
            src={star.includes(4) ? YellowStarImg : StarImg}
            alt="star"
            width="38px"
          />
        </div>
        <div
          className="rating"
          data-id="5"
          onClick={(e) => {
            handleRantingStar(5);
            star.includes(5)
              ? setStar(
                  star.filter((ele) => ele !== Number(e.target.dataset.id))
                )
              : setStar([...star, Number(e.target.dataset.id)]);
          }}
        >
          <img
            src={star.includes(5) ? YellowStarImg : StarImg}
            alt="star"
            width="38px"
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        className="cardHead dflex"
        onClick={() => setshowFilter(!showFilter)}
      >
        <h6>{title}</h6>
        <span className={`fa fa-angle-${showFilter ? "up" : "down"}`}></span>
      </div>
      <div className={`cardBody ${!showFilter ? "d-none" : ""}`}>
        <div className="rate" style={{ display: "flex" }}>
          <div
            className="ratings"
            data-id="1"
            onClick={(e) => {
              handleRantingStar(1);
              star.includes(1)
                ? setStar(
                    star.filter((ele) => ele !== Number(e.target.dataset.id))
                  )
                : setStar([...star, Number(e.target.dataset.id)]);
            }}
          >
            1{" "}
            <img src={star.includes(1) ? YellowStarImg : StarImg} alt="star" />
          </div>
          <div
            className="ratings"
            data-id="2"
            onClick={(e) => {
              handleRantingStar(2);
              star.includes(2)
                ? setStar(
                    star.filter((ele) => ele !== Number(e.target.dataset.id))
                  )
                : setStar([...star, Number(e.target.dataset.id)]);
            }}
          >
            2{" "}
            <img src={star.includes(2) ? YellowStarImg : StarImg} alt="star" />
          </div>
          <div
            className="ratings"
            data-id="3"
            onClick={(e) => {
              handleRantingStar(3);
              star.includes(3)
                ? setStar(
                    star.filter((ele) => ele !== Number(e.target.dataset.id))
                  )
                : setStar([...star, Number(e.target.dataset.id)]);
            }}
          >
            3{" "}
            <img src={star.includes(3) ? YellowStarImg : StarImg} alt="star" />
          </div>
          <div
            className="ratings"
            data-id="4"
            onClick={(e) => {
              handleRantingStar(4);
              star.includes(4)
                ? setStar(
                    star.filter((ele) => ele !== Number(e.target.dataset.id))
                  )
                : setStar([...star, Number(e.target.dataset.id)]);
            }}
          >
            4{" "}
            <img src={star.includes(4) ? YellowStarImg : StarImg} alt="star" />
          </div>
          <div
            className="ratings"
            data-id="5"
            onClick={(e) => {
              handleRantingStar(5);
              star.includes(5)
                ? setStar(
                    star.filter((ele) => ele !== Number(e.target.dataset.id))
                  )
                : setStar([...star, Number(e.target.dataset.id)]);
            }}
          >
            5{" "}
            <img src={star.includes(5) ? YellowStarImg : StarImg} alt="star" />
          </div>
        </div>
        <button className="btnBorder" type="button" onClick={handleApply}>
          Apply
        </button>
      </div>
    </>
  );
}
export default StarRating;