import React from "react";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";

// QR Code component
function QrCode({
  para,
  qrImage,
  heading,
  fileDownloadImage,
  appleWalletImage,
  gPayImage,
  linkText,
}) {
  // user data
  const user = JSON.parse(localStorage.getItem("user"));

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcodeWrapper");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
      console.log(pngUrl, "canvas")
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `Qrcode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <p>{para}</p>
      <div className="qrCodeWrapper">
        <QRCode
          value={linkText}
          size={190}
          bgColor={"#ffffff"}
          fgColor={"#42A4BC"}
          level={"L"}
          includeMargin={false}
          id="qrcodeWrapper"
        />
      </div>
      <div className="fileDownload" onClick={() => downloadQRCode()}>
        <img src={fileDownloadImage} alt="icon" />
        <h5>{heading}</h5>
      </div>
      <div className="apple-wallet-wrap">
        <Link className="apple-wallet-icon" to="#">
          <img src={appleWalletImage} alt="wallet icon" />
        </Link>
        <Link className="gpay-wallet-icon" to="#">
          <img src={gPayImage} alt="wallet icon" />
        </Link>
      </div>
    </>
  );
}

export default QrCode;
