import { AsyncTypeahead } from "react-bootstrap-typeahead";
import React, { useState, useEffect } from "react";

function SearchAutocomplete(props) {
  const {
    label,
    name,
    placeholder,
    onSearch,
    options,
    onChange,
    isLoading,
    disabled,
    isRequired,
    defaultSelected = [],
    value,
    onInputChange,
    icon,
    type,
    errors,
    autoFocus,
    flightIcon,
    drop,
  } = props;
  const [isMobile, setMobile] = useState(drop);

  const errorMsg = "";
  const filterData = options.map((ele) => ({
    name: ele.label,
    id: type === "flight" ? ele.id : ele.expedia_id,
    secondary_label: type === "flight" ? ele.countryName : ele.secondary_label,
    state: ele.state,
    country: ele.country_code,
    type_name: ele.type_name,
  }));
  const getType = (type) => {
    if (type === "city") {
      return "City";
    } else if (type === "airport") {
      return "Airport";
    } else if (type === "neighborhood") {
      return "Neighborhood";
    } else if (type === "province_state") {
      return "State";
    } else if (type === "point_of_interest") {
      return "Point of interest";
    } else if (type === "multi_city_vicinity") {
      return "Vicinity";
    } else if (type === "high_level_region") {
      return "Region";
    } else if (type === "property") {
      return "Property";
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    }
  }, []);
  console.log(value, "defaultSelected")
  return (
    <>
      <label htmlFor={name} className="label">
        {label}
      </label>
      {icon && <i className={icon} />}
      <AsyncTypeahead
        autoFocus={autoFocus}
        inputProps={{ className: "inputDropdown widget-dropdown" }}
        // defaultInputValue={value}
        onInputChange={onInputChange}
        selected={value}
        defaultSelected={defaultSelected}
        labelKey={(option) => option.secondary_label}
        isLoading={isLoading}
        filterBy={() => true}
        minLength={3}
        onSearch={onSearch}
        options={filterData}
        placeholder={placeholder}
        // cache={false}
        onChange={onChange}
        clearButton
        searchText="Searching..."
        name={name}
        id={name}
        dropup={!isMobile}
        disabled={disabled}
        emptyLabel="Type to search..."
        // className="inputDropdown"
        renderMenuItemChildren={(option) => (
          <div className="dorpdown-content">
            <span className="icon-location-on"></span>
            <span className="locationCode">
              {type === "flight" && (
                <>
                  {option.name} <br />
                </>
              )}
              {option.secondary_label}
              {!flightIcon ? (
                <small className="d-flex justify-content-between text-light autocompleteSubText">
                  {option.state && option.state.length
                    ? option.state + ", " + option.country
                    : option.country}
                  {option.type_name ? (
                    <span>{getType(option.type_name)}</span>
                  ) : (
                    ""
                  )}
                </small>
              ) : null}
            </span>
          </div>
        )}
      />
      <div className="invalid-feedback">{errors[name]}</div>
    </>
  );
}

export default SearchAutocomplete;
