import React from "react";

function InputField(props) {
  const {
    className,
    name,
    type,
    label,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    touched,
  } = props;

  return (
    <div className={`col inputStyle inputStyle2 ${className}`}>
      {label && (
        <label className="labelStyle" htmlFor="">
          {label}
        </label>
      )}
      <input
        name={name}
        label={label}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && touched && <div className="error-messages">{error}</div>}
    </div>
  );
}

export default InputField;