import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FlightListingCard from "../../Component/FlightListing/FlightListingCard";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HeaderNavbar from "../../Component/HeaderNavbar";
import Pagination from "../../Component/Pagiantion/Pagination";
import { flightActions } from "../../Stores/Actions/flightActions";
import FlightListingFilters from "./FlightListingFilters";
import FlightWidgetBar from "./FlightWidgetBar";
import FlightTab from "../Widgets/FlightTab";
import { Scrollbars } from "react-custom-scrollbars";
import { isEmpty, has } from "lodash";
import moment from "moment";
import FlightFilterTag from "../../Component/Filters/FlightFilterTag";
import ResultsOnPage from "../../Component/Pagiantion/ResultsOnPage";
import { useDispatch, useSelector } from "react-redux";

function FlightSearchResult(props) {
  const {
    flights,
    flightHotel,
    isPolling,
    flightPollingData,
    searchFlightPollingAction,
  } = props;
 

  const [showFlightModal, setshowFlightModal] = useState(false); 
  const [openNavbar, setOpenNavbar] = useState(false); 

  const filters = useSelector(
    (state) => state.flightReducer.flightFilters
  );

  
  const flightPayload = JSON.parse(localStorage.getItem("flightPayload"));

  const currencyAndLanguage =
    localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(localStorage.getItem("currencyAndLanguage"));

  const currency = currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

    const [selectedFlightTab,setSelectedFlightTab] = useState(null);
   
  useEffect(() => {
    const prevFilter = JSON.parse(localStorage.getItem("flightFilter"));
    const payload = { ...flightPayload };
    payload.currency = currency?.isoCode;  

    if (prevFilter) {
      payload.filters = prevFilter;
    }  
    flightHotel(payload);
   
  }, []);

  // Api polling for cabin price
  useEffect(() => {
    var timer = null;
    if (timer && !isPolling) {
      clearTimeout(timer);
    } else if (isPolling) {
      timer = setTimeout(() => {
        var payload = {
          filters: flightPayload.filters,
          paging: flightPayload.paging,
          // sort: {field: "price", order: "asc", label: "Price (Low to High)"}
        };
        // if (flightPollingData?.is_pulling !== false) {
        //   searchFlightPollingAction(payload);
        // }
        searchFlightPollingAction(payload);
      }, 3000);
    }
  }, [isPolling, flights, flightPollingData]);

  const totalFlightCount = !isEmpty(flights) ? flights?.pagination?.totalRecords : 0;
  // Filters
  const airLines =
    !isEmpty(flights) && !isEmpty(flights.filtersAvailable)
      ? flights.filtersAvailable.airlines
      : [];
  const sortBy =
    !isEmpty(flights) && !isEmpty(flights.sortAvailable)
      ? flights.sortAvailable
      : [];
  const priceFilter =
    !isEmpty(flights) && !isEmpty(flights.filtersAvailable) ? flights.filtersAvailable.price : {};
  const [no_use_filters, setFilters] = useState({});
  useEffect(() => {
    if (!isEmpty(flights?.request?.filters)) {
      setFilters({ ...flights?.request?.filters });
    }
  }, [flights]);
  useEffect(() => {
    if (!isEmpty(filters)) {
      localStorage.setItem("flightFilter", JSON.stringify(filters));
    } else {
      localStorage.removeItem("flightFilter");
    }
  }, [filters]);
  // Flight Search
  function searchFlight(page, per_page, withoutFilter = false, sort) {
    let preStoredVal = JSON.parse(localStorage.getItem("flightPayload"));
    if (filters?.airline?.length == flights?.filtersAvailable?.airlines?.length) {
      let temp = filters
      delete temp.airline
      setFilters(temp) 
     } 

    let payload = {
      adults: flights.request.adults,
      children: flights.request.children,
      infants: flights.request.infants, 
      currency: flights.request.currency, 
      cabinClass:  flights.request.cabinClass, 
      paginate: {
        page: page ? page : flights?.pagination.page,
        perPage: per_page ? per_page : flights?.pagination.perPage,
      },
      sort: flights.request.sort,
      searchType: flights.request.searchType, 
      filters: {
        refundableOnly: 0,
        ...filters,
      },
      flightQueries: flights.request.flightQueries,
    };
    if (sort) {
      payload.sort = {
        field: sort.field,
        order: sort.order,
        label: sort.label,
      };
    } else {
      payload.sort = {
        field: preStoredVal.sort.field,
        order: preStoredVal.sort.order,
        label: preStoredVal.sort.label,
      };
    }
    if (withoutFilter) {
      payload.filters = { refundableOnly: 0 };
    }
    flightHotel(payload,null,true);   // on pagination change and filter //  true == use search id
  }

  // Remove filter
  function searhByRemoveFilters(newFilters, page = 1, per_page = 15) {
    let payload = {
      adults: flights.request.adults,
      children: flights.request.children,
      infants: flights.request.infants, 
      cabinClass:  flights.request.cabinClass, 
      currency: currency.isoCode,
      cabin: flights.request.cabin,
      paginate: {
        page: page ? page : flights?.pagination.page,
        perPage: per_page ? per_page : flights?.pagination.perPage,
      },
      sort: flights.request.sort,
      searchType: flights.request.searchType, 
      filters: {
        refundableOnly: 0,
        ...filters,
      },
      flightQueries: flights.request.flightQueries,
    };
    if (!isEmpty(newFilters)) {
      payload.filters = newFilters;
    }
    flightHotel(payload,null,true);  //  true == use search id  // for remove filters
  }
  // Handle Pagination
  function handlePageClick(page, per_page_count) { 
    if (per_page_count) {
      searchFlight(1, per_page_count);
    } else {
      searchFlight(page.selected + 1);
    }
  }
  // Get Origin and destination of flight journey
  let multiCityForm = JSON.parse(localStorage.getItem("multiCityForm"));
  let FlightOrigin = JSON.parse(localStorage.getItem("origin"));
  let FlightDestination = JSON.parse(localStorage.getItem("destination"));



  const hideFunc = () => {
    setOpenNavbar((preVal) => !preVal);
  };

  return (
    <div
      className={
        "siteBg listingPage listingFlight listingPageWrap  " +
        (showFlightModal ? "popupOpned" : "")
      }
    >
      <div className="pageWrap">
        <div className="fixedHeader">
          <HeaderNavbar hideFunc={hideFunc} />
        </div>
        <section className="mainContent">
          <div
            className={`filterCnt openFilter ${openNavbar ? "hide-content" : ""
              }`}
          >
            <div className="container">
              <FlightTab isFromLisitng={true} setSelectedFlightTab={setSelectedFlightTab}/>
            </div>
            {/* <FlightWidgetBar flights={flights} /> */}
          </div>
          <Scrollbars
            // autoHeight={`calc(100vh - 110px)`}
            autoHeight
            autoHeightMin={120}
            autoHeightMax={`calc(100%)`}
            className="pageInternalScroll"
          >
            <div className="pageInternalScrollTopPadding">
              <div className="container">
                <div className="listingNote mobileHide">
                  {totalFlightCount} flights found from{" "}  {flights?.request?.searchType == "multi-city" ? (multiCityForm && multiCityForm[0]?.from?.value) : FlightOrigin?.value}
                    {" "}to{" "}
                  {flights?.request?.searchType == "multi-city" ? multiCityForm[multiCityForm?.length - 1]?.to?.value : FlightDestination?.value}
                </div>
                <FlightListingFilters
                  airLines={airLines}
                  filters={filters}
                  priceFilter={priceFilter}
                  setFilters={setFilters}
                  searchFlight={searchFlight}
                  sortBy={sortBy} 
                  selectedFlightTab={selectedFlightTab}
                />
                <FlightFilterTag
                  filters={filters}
                  clearFilter={setFilters}
                  searchFlight={searhByRemoveFilters} 
                />
                {!isEmpty(flights) &&
                  !isEmpty(flights?.resultfilter_message_texts) === null ? (
                  ""
                ) : (
                  <p>{flights?.filter_message_text}</p>
                )}
                {flights?.data?.map((item, index) => (
                  <FlightListingCard
                    {...item}
                    flights={item}
                    key={index}
                    setshowFlightModal={setshowFlightModal}
                    showFlightModal={showFlightModal}
                    selectedFlightTab={selectedFlightTab}
                    currency={currency?.isoCode}
                  />
                ))}

                {!flights?.data.length && <>
                  <div className="not-found-content">
                          <h2>No Flights Found</h2>
                  </div>
                </>}

                {!isEmpty(flights?.data) && (
                  <div className="paginationWrapper">
                    <Pagination
                      onPageChange={handlePageClick}
                      pageCount={flights?.pagination?.totalPages}
                      forcePage={flights?.pagination?.page - 1}
                    />
                    <ResultsOnPage
                      className="results hide-for-mobile"
                      callback={handlePageClick}
                      defaultValue={flights?.pagination?.perPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </Scrollbars>
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  flights: state.flightReducer?.flightSearchData,
  flightErrors: state.flightReducer.flightErrors,
  isPolling: state.flightReducer?.isPolling,
  flightPollingData: state.flightReducer.flightPollingData,
});
const mapDispatchToProps = (dispatch) => ({
  flightHotel: (payload,usenavigate,useSearchId) => dispatch(flightActions.searchFlightAction(payload,usenavigate,useSearchId)),
  searchFlightPollingAction: (payload) =>
    dispatch(flightActions.searchFlightPollingAction(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FlightSearchResult);