import React, { useState } from "react";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import Scrollbars from "react-custom-scrollbars";
import AcademyTab from "../Tabs/AcademyTab";
import memberInviteImg from "../../Assets/img/member-invite-heading.png";

function TripelloAcademy() {
  return (
    <div className="siteBg academy-wrapper FaqPageWrap ">
      <div className="pageWrap faq-scroll">
        <div className="fixedHeader">
          <HeaderNavbar />
        </div>
      </div>
      <section className="mainContent">
        <Scrollbars
          autoHeight={true}
          autoHeightMin={120}
          autoHeightMax={`calc(100%)`}
          className="pageInternalScroll"
        >
          <div className="container pageInternalScrollTopPadding">
            <div className="row no-gutters rightContent-bottom">
              <AcademyTab name={"academy"} />
              <div className="col rightContent">
                <div className="card3">
                  <div class="row no-gutters">
                    <div class="col leftContent">
                      <div class="container">
                        <div class="content-title">
                          <div className="content-wrapper">
                            <h1>Welcome to the Tripello Academy.</h1>
                            <p>
                              <span>
                                This is your resource center regarding
                                everything Tripello and the Tripello Network.
                              </span>
                              <span>
                                {" "}
                                We have compiled a variety of resources for you
                                , to offer you an expanding library of various
                                topics to support
                              </span>
                              <span>
                                {" "}
                                your personal and professional development and
                                growth.
                              </span>
                            </p>
                          </div>
                          <div className="text-right">
                            <p>
                              "Do not fear to be your true self, for everything
                              you want, wants you."
                            </p>
                            <p>- Genevieve Behrend -</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col rightContent">
                      <div class="image">
                        <img src={memberInviteImg} alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </section>
      <FooterWithMediaIcon className="white-icon" iconClassName="icon-white" />
    </div>
  );
}

export default TripelloAcademy;
