import React, { useEffect, useState } from "react";
import ExpireRate from "../../../Component/ExpireRate/ExpireRate";
import HotelRoomTab from "../HotelRoomTab";
import RoomContainer from "../../../Component/Hotel/RoomContainer";
import { hotelActions } from "../../../Stores/Actions";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

function Rooms({ hotel_id, ref, currencySymbol, currencyCode }) {
  const dispatch = useDispatch();
  const storedPayload = localStorage.getItem("hotelPayload")
    ? JSON.parse(localStorage.getItem("hotelPayload"))
    : JSON.parse(localStorage.getItem("mapPayload"));
  const user = JSON.parse(localStorage.getItem("user"));
  const hotelRooms = useSelector((state) => state.hotelReducer.hotelRoom);

  const currencyAndLanguage =
    localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const initialPayload = {
    rooms: storedPayload?.rooms,
    region_id: storedPayload?.region_id,
    check_in_date: storedPayload?.check_in_date,
    check_out_date: storedPayload?.check_out_date,
    children_count: 0,
    city_name: storedPayload?.city_name,
    city_name: storedPayload?.city_name,
    currency: currency.isoCode,
    home: storedPayload?.home,
    hotel_id: hotel_id,
    locale: storedPayload?.locale,
    onlyLocale: storedPayload?.onlyLocale,
    optional: "amenities,moreDetails,policy,moreRatings",
    preventCache: 0.9299991871126359,
    search_type: storedPayload?.search_type,
    source_market: storedPayload?.source_market,
    token: localStorage.getItem("accessToken"),
    type: storedPayload?.search_type,
    nationality: storedPayload?.nationality,
    use_wallets:
      user.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID ? 1 : 0,
  };
  const refundableRooms =
    hotelRooms?.rates && !isEmpty(hotelRooms?.rates?.refundable)
      ? hotelRooms?.rates?.refundable?.groups
      : [];
  const nonRefundableRooms =
    hotelRooms?.rates && !isEmpty(hotelRooms?.rates["non-refundable"])
      ? hotelRooms?.rates["non-refundable"].groups
      : [];
  useEffect(() => {
    dispatch(hotelActions.getHotelRooms(initialPayload, hotel_id));
    return () => {
      dispatch(hotelActions.resetHotelRooms());
    };
  }, []);
  const [selectedTab, setSelectedTab] = useState(
    nonRefundableRooms.length > 0 ? 0 : 1
  );

  const changeTab = (e, tab) => {
    e.preventDefault();
    setSelectedTab(tab);
  };
  return (
    <>
      <div id="rooms" ref={ref} className="selectRoomTabs">
        <div className="sectionTitle">
          <h2>Select Your Room</h2>
          <ExpireRate
            className="expireTimer desktopViewDisplayNone"
            showIcon={false}
            text="(Rate will expire)"
            time={1200000}
          />
          <ExpireRate
            className="expireTimer mobileViewDisplayNone"
            showIcon={true}
            text="Rate will expire"
            time={1200000}
          />
        </div>
        <div className="refundToggle d-flex justify-content-between">
          <HotelRoomTab
            changeTab={changeTab}
            selectedTab={selectedTab}
            refundableRooms={refundableRooms}
            nonRefundableRooms={nonRefundableRooms}
            currencySymbol={currencySymbol}
            currencyCode={currencyCode}
          />
        </div>
      </div>
      <div className="selectRoomContentMain">
        {selectedTab === 0 && !nonRefundableRooms.length && (
          <div className="refundable-empty-box">
            <h4>No Non-Refundable Rooms Found </h4>
          </div>
        )}
        <div className="selectRoomContent">
          {selectedTab === 0 && (
            <div id="refundable-rooms" className="new-card-section activeTab">
              {nonRefundableRooms.length
                ? nonRefundableRooms.map((ele, index) => {
                  return (
                    <RoomContainer
                      {...ele}
                      index={`${index}_room`}
                      key={`${index}_refun_room`}
                      hotel_id={hotel_id}
                      currencySymbol={currencySymbol}
                      currencyCode={currencyCode}
                      isRefundable={false}
                    />
                  );
                })
                : null}
            </div>
          )}
          {selectedTab === 1 && (
            <div
              id="non-refundable-rooms"
              className="new-card-section activeTab"
            >
              {refundableRooms.length
                ? refundableRooms.map((ele, index) => {
                  return (
                    <RoomContainer
                      {...ele}
                      index={`${index}_non_ref_room`}
                      key={`${index}_non_ref_room`}
                      hotel_id={hotel_id}
                      currencySymbol={currencySymbol}
                      currencyCode={currencyCode}
                      isRefundable={true}

                    />
                  );
                })
                : null}
            </div>
          )}
        </div>
        {selectedTab === 1 && !refundableRooms.length && (
          <div className="refundable-empty-box">
            <h4>No Refundable Rooms Found</h4>
          </div>
        )}
      </div>
    </>
  );
}
export default Rooms;
