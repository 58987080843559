import React, { useEffect, useState, useRef } from "react";
import { hotelDetailMockData } from "../../Data/hotelMockData";
import HotelDetailsTabs from "./HotelDetailsTabs";
import HotelDetailCard from "./HotelDetailCard/HotelDetailCard";
import Overview from "./Overview/Overview";
import Rooms from "./Rooms/Rooms";
import Amenities from "./Amenities/Amenities";
import PropertiesDetails from "./PropertyDetails/PropertiesDetails";
import Reviews from "./Reviews/Reviews";
import { useParams, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hotelActions } from "../../Stores/Actions";
import HeaderNavbar from "../../Component/HeaderNavbar";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import HotelWidget from "../HotelSearchResult/HotelWidget";
import { Scrollbars } from "react-custom-scrollbars";
import { convertTime12To24 } from "../../Util/util";

function HotelDetail() {
  const params = useParams();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const storedPayload = localStorage.getItem("hotelPayload")
    ? JSON.parse(localStorage.getItem("hotelPayload"))
    : JSON.parse(localStorage.getItem("mapPayload"));

  const hotelDetails = useSelector((state) => state.hotelReducer.hotelDetails);
  const [hideFilter, setHideFilter] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [toggleFilterDrop, setToggleFilterDrop] = useState(true);
  const [activeTab, setActiveTab] = useState("overview");

  const scrollToRoomsRef = useRef(null);
  const scrollToOverviewRef = useRef(null);
  const scrollToAmenitiesRef = useRef(null);
  const scrollToPropertyRef = useRef(null);
  const scrollToReviewsRef = useRef(null);

  const currencyAndLanguage =
    localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const currentGeoLocation =
    localStorage.getItem("currentGeoLocation") &&
    JSON.parse(localStorage.getItem("currentGeoLocation"));

  const isUS = currentGeoLocation === "US";

  const scrollToView = (key) => {
    switch (key) {
      case "overview":
        scrollToOverviewRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setActiveTab("overview");
        break;
      case "amenities":
        scrollToAmenitiesRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setActiveTab("amenities");
        break;
      case "property":
        scrollToPropertyRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setActiveTab("property");
        break;
      case "reviews":
        scrollToReviewsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setActiveTab("reviews");
        break;
      case "rooms":
        scrollToRoomsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setActiveTab("rooms");
    }
  };

  const initialPayload = {
    rooms: storedPayload?.rooms.length,
    region_id: storedPayload?.region_id,
    check_in_date: storedPayload?.check_in_date,
    check_out_date: storedPayload?.check_out_date,
    children_count: 0,
    city_name: storedPayload?.city_name,
    currency: currency.isoCode,
    home: storedPayload?.home,
    hotel_id: params?.hotel_id,
    locale: storedPayload?.locale,
    onlyLocale: storedPayload?.onlyLocale,
    optional: "amenities,moreDetails,policy,moreRatings",
    preventCache: 0.9299991871126359,
    search_type: storedPayload?.search_type,
    source_market: storedPayload?.source_market,
    token: localStorage.getItem("accessToken"),
    type: storedPayload?.search_type,
    nationality: storedPayload?.nationality,
    use_wallets:
      user.type_id === process.env.REACT_APP_FREMIUM_USER_TYPE_ID ? 1 : 0,
  };

  useEffect(() => {
    dispatch(hotelActions.getHotelDetails(initialPayload, params?.hotel_id));
    return () => {
      dispatch(hotelActions.resetHotelDetails());
    };
  }, []);

  function hideFunc() {
    setHideFilter((prev) => !prev);
  }

  return (
    <div className="siteBg listingPage  detailPageWrap">
      <div className="pageWrap ">
        <div className="fixedHeader">
          {!hideHeader && <HeaderNavbar hideFunc={hideFunc} />}
        </div>
        <section className="mainContent">
          {!hideFilter && (
            <HotelWidget
              toggleFilterDrop={toggleFilterDrop}
              isFromDetailPage={true}
            />
          )}

          {hotelDetails && (
            <Scrollbars
              // autoHeight={`calc(100vh - 110px)`}
              // autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container">
                <HotelDetailCard
                  {...hotelDetailMockData}
                  scrollToView={scrollToView}
                  name={hotelDetails ? hotelDetails?.hotel_data?.name : ""}
                  images={
                    hotelDetails && hotelDetails.static_data
                      ? hotelDetails?.static_data?.images
                      : []
                  }
                  heroImage={
                    hotelDetails && hotelDetails?.hotel_data?.images
                      ? hotelDetails.hotel_data.images[0]
                      : ""
                  }
                  checkIn={
                    hotelDetails
                      ? isUS
                        ? hotelDetails?.hotel_data.check_in_time_format1
                        : convertTime12To24(
                          hotelDetails?.hotel_data.check_in_time_format1
                        )
                      : ""
                  }
                  checkOut={
                    hotelDetails
                      ? isUS
                        ? hotelDetails?.hotel_data.check_out_time_format1
                        : convertTime12To24(
                          hotelDetails?.hotel_data.check_out_time_format1
                        )
                      : ""
                  }
                  address={hotelDetails ? hotelDetails?.hotel_data.address : ""}
                  addressObj={
                    hotelDetails
                      ? hotelDetails?.hotel_data.address_components
                      : {}
                  }
                  contactNo={hotelDetails ? hotelDetails?.hotel_data.phone : ""}
                  themes={
                    hotelDetails && hotelDetails?.hotel_data?.themes
                      ? hotelDetails?.hotel_data?.themes
                      : []
                  }
                  rating={hotelDetails ? hotelDetails?.hotel_data?.rating : 0}
                  latitude={
                    hotelDetails && hotelDetails?.hotel_data?.latitude
                      ? hotelDetails?.hotel_data?.latitude
                      : 0
                  }
                  longitude={
                    hotelDetails && hotelDetails?.hotel_data?.longitude
                      ? hotelDetails?.hotel_data?.longitude
                      : 0
                  }
                  currencySymbol={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.symbol_native
                  }
                  currencyCode={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.code
                  }
                />
                <div className="overviewTitle sectionTitle desktopViewDisplayNone">
                  <h2>Overview</h2>
                </div>
                <div className="tabsWrapper mobileViewDisplayNone">
                  <HotelDetailsTabs
                    activeTab={activeTab}
                    scrollToView={scrollToView}
                    hasReviews={hotelDetails?.reviews?.review_count != 0}
                  />
                </div>
                <span ref={scrollToOverviewRef}></span>
                <Overview
                  reviews={hotelDetails ? hotelDetails?.reviews : {}}
                  scrollToView={scrollToView}
                  amenities={
                    hotelDetails ? hotelDetails?.static_data.top_amenities : []
                  }
                />
                <p ref={scrollToRoomsRef}></p>
                <Rooms
                  hotel_id={params?.hotel_id}
                  currencySymbol={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.symbol_native
                  }
                  currencyCode={
                    hotelDetails &&
                    hotelDetails?.currency &&
                    hotelDetails?.currency?.code
                  }
                />
                <span ref={scrollToAmenitiesRef}></span>
                <Amenities
                  amenities={
                    hotelDetails && hotelDetails?.static_data?.facilities
                      ? hotelDetails.static_data.facilities
                      : []
                  }
                />
                <span ref={scrollToPropertyRef}></span>
                <PropertiesDetails
                  description={
                    hotelDetails && hotelDetails?.static_data?.description
                      ? hotelDetails.static_data.description
                      : "No Description..."
                  }
                  nearBy={
                    hotelDetails && hotelDetails?.static_data?.attractions
                      ? hotelDetails.static_data.attractions
                      : "No NearBy..."
                  }
                />
                <span ref={scrollToReviewsRef}></span>

                {hotelDetails?.reviews?.review_count != 0 && (
                  <Reviews
                    {...hotelDetailMockData.reviews}
                    reviews={hotelDetails ? hotelDetails?.reviews : {}}
                  />
                )}
              </div>
            </Scrollbars>
          )}
          {!hotelDetails && (
            <Scrollbars
              // autoHeight={`calc(100vh - 110px)`}
              // autoHeight={true}
              autoHeightMin={120}
              autoHeightMax={`calc(100%)`}
              className="pageInternalScroll"
            >
              <div className="container">
                <div className="not-found-content">
                  <div className="not-found-content-wrap text-center">
                    <h2>Hotel sold out.</h2>
                    <p>
                      The hotel you have selected is currently sold out, Please
                      try again!
                    </p>
                    <NavLink className="btn" to={`/hotel-search-result/`}>
                      Search Again
                    </NavLink>
                  </div>
                </div>
              </div>
            </Scrollbars>
          )}
        </section>
        <FooterWithMediaIcon
          className="white-icon"
          iconClassName="icon-white"
        />
      </div>
    </div>
  );
}

export default HotelDetail;
