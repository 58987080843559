import React, { useEffect, useState } from 'react'

function Extras({
  updatePrice,
  roomsOnly,
  breakfast,
  halfBoard,
  fullBoard,
  index,
  currencySymbol, 
  defaultRadioSelect
}) {
  const [extras, setExtras] = useState(defaultRadioSelect);
  const handlepriceUpdate = (field) => {
    setExtras(field);
    updatePrice(field); 
  };

  useEffect(()=>{
    setExtras(defaultRadioSelect);
  },[])
 
  return (
    <div className="content-wrap">
      <div className="toggle-link-wrap">
        <div className="extras">
          <div className="extra-title">
            <h4>Extras</h4>
          </div>
          {roomsOnly && (
          <div className="row">
            <div className="col extras-left-ontent">
              <div className="custom-radio">
                <label className="form-check-label" htmlFor={`${index}_room`}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name={index}
                    id={`${index}_room`}
                    checked={extras == "rooms"}
                    onChange={(e) => handlepriceUpdate("rooms")}
                  />
                  Room Only
                </label>
              </div>
            </div>
            <div className="col extras-right-ontent">
              <h6>+ {currencySymbol} 0.00</h6>
            </div>
          </div>
          )}
          {breakfast && (
            <div className="row">
              <div className="col extras-left-ontent">
                <div className="custom-radio">
                  <label
                    className="form-check-label"
                    htmlFor={`${index}_breakfast`}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name={index}
                      id={`${index}_breakfast`}
                      checked={extras == "breakfast"}
                      onChange={() => handlepriceUpdate("breakfast")}
                    />
                    Breakfast Only
                  </label>
                </div>
              </div>
              <div className="col extras-right-ontent">
                <h6>+ {currencySymbol}{breakfast}</h6>
              </div>
            </div>
          )}

          {halfBoard && (
            <div className="row">
              <div className="col extras-left-ontent">
                <div className="custom-radio">
                  <label
                    className="form-check-label"
                    htmlFor={`${index}_halfboard`}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name={index}
                      id={`${index}_halfboard`}
                      checked={extras == "halfBoard"}
                      onChange={() => handlepriceUpdate("halfBoard")}
                    />
                    Half Board
                    <div class="tooltip icon-info">
                      <span
                        class="tooltiptext">Breakfast,
                        Lunch <br /> or Dinner</span>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col extras-right-ontent">
                <h6>+ {currencySymbol}{halfBoard}</h6>
              </div>
            </div>
          )}

          {fullBoard && (
            <div className="row">
              <div className="col extras-left-ontent">
                <div className="custom-radio">
                  <label
                    className="form-check-label"
                    htmlFor={`${index}_fullboard`}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name={index}
                      id={`${index}_fullboard`}
                      checked={extras == "fullBoard"}
                      onChange={() => handlepriceUpdate("fullBoard")}
                    />
                    Full Board
                    <div class="tooltip icon-info">
                      <span
                        class="tooltiptext">Breakfast,
                        Lunch and Dinner</span>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col extras-right-ontent">
                <h6>+ {currencySymbol}{fullBoard}</h6>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Extras