import React from "react";
import Modal from "react-modal";
import warningImg from "../../Assets/img/warning.svg";
import { isEmpty, has } from "lodash";
import moment from "moment";

function HotelCheckoutModal(props) {
  const {
    modalOpen,
    modalClose,
    image,
    locationGray,
    datePicker,
    rightArrowImg,
    timerImg,
    infoGray,
    hotels,
    rates,
    input,
    usedWalletAmmout,
    currencyCode,
    isRefundable,
  } = props;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  });

  const currentGeoLocation =
    localStorage.getItem("currentGeoLocation") &&
    JSON.parse(localStorage.getItem("currentGeoLocation"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  let numberOfAdults = !isEmpty(input)
    ? input.rooms.map((ele, i) => ele.adults).reduce((a, b) => a + b)
    : [];

  let numberOfChild = !isEmpty(input)
    ? input.rooms.map((ele, i) => ele.child).reduce((a, b) => a + b)
    : [];

  // <Modal isOpen={openModal}
  // className="popup detail-info-popup popup-open"
  // ariaHideApp={false}>
  // 	<div className="popup-content">
  // 		<div className="popup-title">
  // 			<h3>
  // 				<img src={infoGreyImg} alt="icon" />Dashboard Details
  // 			</h3>
  // 			<button className="btn close" onClick={closeModal}>
  // 				<img src={closeIcon} alt="icon" />
  // 			</button>
  // 		</div>
  // 		<div className="box-content">
  // 			{AffiliateInvitesCommissionList.map(list => (
  // 				<>
  // 					{list.title && <h4>{list.title}</h4>}
  // 					{list.description && <p>{list.description}</p>}
  // 					{list.title2 && <h3>{list.title2}</h3>}
  // 				</>
  // 			))}
  // 		</div>
  // 	</div>
  // </Modal>

  return (
    <Modal
      isOpen={modalOpen}
      ariaHideApp={false}
      id="flightDetailPopup"
      className="popup hotelDetailPopup hotelDetailCheckoutPopup popup-open"
    >
      <div className="popup-content">
        <button className="btn close" onClick={modalClose}>
          <span className="icon-close"></span>
        </button>
        <h3 className="popup-title">Booking Details</h3>
        <div className="popupScrollBox">
          <div className="infoBox">
            <div className="customShadow">
              <div
                className="imgWrap bgImg"
                style={{
                  backgroundImage: `url('${hotels.thumbnail_image_url}')`,
                }}
              ></div>
            </div>
            <div className="box-content">
              <div className="contentGroup">
                <h2 className="cartTitle">{hotels.original_name}</h2>
                <div className="contactInfo">
                  <div className="location">
                    <img src={locationGray} alt="icon" /> {hotels.address}
                  </div>
                  <div className="phone">
                    <span className="icon-phone"></span> {hotels.phone}
                  </div>
                </div>
              </div>
              <div className="contentGroup timeWrapper">
                <div className="d-flex">
                  <img src={datePicker} alt="icon" />
                  <span>
                    {!isEmpty(hotels) &&
                      moment(hotels.check_in_date_format4, "DD-MM-YYYY").format(
                        dateDisplayFormat
                      )}
                  </span>
                  <img src={rightArrowImg} alt="icon" />
                  <span>
                    {!isEmpty(hotels) &&
                      moment(
                        hotels.check_out_date_format4,
                        "DD-MM-YYYY"
                      ).format(dateDisplayFormat)}
                  </span>
                </div>
                <div className="d-flex checkin-checkout-detail">
                  <div className=" tooltip">
                    <img src={timerImg} className="clock-icon  " alt="icon" />
                    <span class="tooltiptext">Check-in / Check-out Time</span>
                  </div>
                  <span>{hotels.check_in_time}</span>
                  <img src={rightArrowImg} alt="icon" />
                  <span>{hotels.check_out_time}</span>
                </div>
              </div>
              <div className="contentGroup roomInfo">
                <h3>
                  <span>{!isEmpty(rates) && rates.room_type}</span>
                  <button
                    className={
                      isRefundable ? "btn-refundable" : "btn-non-refundable"
                    }
                  >
                    {isRefundable ? "Refundable" : "Non Refundable"}
                  </button>
                </h3>
                <div className="d-flex justify-content-between">
                  <h5>Number of Nights:</h5>
                  <h5>
                    <strong>
                      {!isEmpty(rates) && rates.no_of_nights} Nights
                    </strong>
                  </h5>
                </div>
                <div className="d-flex justify-content-between">
                  <h5>Number of Guests:</h5>
                  <h5>
                    <strong>{numberOfAdults + numberOfChild} Guests</strong>
                  </h5>
                </div>
              </div>
              <div className="amountCalculation">
                <div className="amountInfo">
                  <p>2 nights incl. taxes & fees</p>
                  <div className="d-flex justify-content-between publicPrice">
                    <h5>Public Price:</h5>
                    <h4>
                      {!isEmpty(rates) &&
                        formatter.format(rates.price.public_price)}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-between savings">
                    {!isEmpty(rates) && rates.price.savings_amount_usd !== 0 && (
                      <>
                        <h5>Savings:</h5>
                        <h4>
                          {!isEmpty(rates) &&
                            formatter.format(rates.price.savings_amount_usd)}
                        </h4>
                      </>
                    )}
                  </div>
                </div>

                <div className="payWrapper">
                  <div className="d-flex justify-content-between savings">
                    <h5>
                      You Pay:
                      <img src={infoGray} alt="icon" />
                    </h5>
                    <h4>
                      {!isEmpty(rates) &&
                        formatter.format(rates.price.final_price)}
                      <span>
                        {" "}
                        (
                        {!isEmpty(rates) &&
                          formatter.format(rates.price.per_night)}
                        /night)
                      </span>
                    </h4>
                  </div>

                  {/* {usedWalletAmmout > 0 &&
                    <> */}
                  <div className="pay-deduction">
                    {usedWalletAmmout > 0 && (
                      <div className="d-flex justify-content-between">
                        <h5>Your Wallet Deduction:</h5>
                        <h4>${usedWalletAmmout}</h4>
                      </div>
                    )}
                    <div className="d-flex justify-content-between amount-due">
                      <h5>Amount Due Today:</h5>
                      <h4>
                        {" "}
                        {!isEmpty(rates) &&
                          formatter.format(
                            rates.price.final_price - usedWalletAmmout
                          )}
                      </h4>
                    </div>
                  </div>
                  {/* </>
                  } */}
                </div>
              </div>

              {!isEmpty(rates) && !isEmpty(rates.price.mandatory_fee) ? (
                <div className="resortFeessection">
                  <h3>
                    <span className="circle">
                      <img src={warningImg} alt="icon" />
                    </span>
                    <strong>
                      Resort Fees per Room:&nbsp;&nbsp;{" "}
                      {`${rates.price.mandatory_fee[0].currency} ${rates.price.mandatory_fee[0].amount}`}
                    </strong>
                  </h3>
                  <p>
                    (<strong>NOT </strong> included in the price, due at the
                    Hotel)
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default HotelCheckoutModal;
