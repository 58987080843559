import React from "react";
import Button from "../FormComponent/Button";
import PersonLogo from "../../Assets/img/person.png";
import NoSmokeImg from "../../Assets/img/no-smoking.svg";
import SmokeImg from "../../Assets/img/smoking.svg";
import BedImg from "../../Assets/img/bed.png";
import DefaultImage from "../../Assets/img/placeholder-image.png";

function RoomDetails({
  setOpenModal,
  openModal,
  images,
  isSmokingAllowed,
  occupancy,
  bedType,
  availableRooms,
  isRefundable
}) {
  return (
    <div className="col image">
      <div className="customShadow">
        {/* <Button
          className="room-details-show-popup"
          type="button"
          onClick={() => setOpenModal(!openModal)}
        >
          <i className="icon-picture"></i> Show all photos
        </Button> */}
        <img src={images.length ? images[0] : DefaultImage} alt="detail-page-image" />
      </div>
      <div className="desktopViewDisplayNone">
        <div className="heading-wrap">
          <div className="heading">
            <div className="heading-title">
              <h3>Standard Deluxe Room</h3>
              <Button
                className="room-details-show-popup"
                type="button"
                onClick={() => setOpenModal(!openModal)}
              >
                Room Details and Photos
              </Button>
            </div>
            <div className="savings-heading">
              <p>25% Savings</p>
            </div>
          </div>
          <div className="status-wrap">
            <div className="smoking-status">
              <img src={isSmokingAllowed ? SmokeImg : NoSmokeImg} />{" "}
              <span>Non Smoking</span>
            </div>
            <div className="room-types">
              <h5 className={isRefundable ? "btn-refundable" : "btn-non-refundable"}>
                {isRefundable ? "Refundable" : "Non Refundable"}
              </h5>
            </div>
            <div className="availableRoomsWrap">
              <h4 className="availableRooms">
                {availableRooms} Rooms available
              </h4>
            </div>
          </div>
        </div>
        <div className="hotelFacilities">
          {/* <h4>351 sq ft</h4> */}
          <h4>
            <img src={PersonLogo} alt="icons" />
            <span>Sleeps {occupancy}</span>
          </h4>
          {bedType && (
            <h4>
              <img src={BedImg} alt="icons" />
              <span>{bedType}</span>
            </h4>
          )}
        </div>
      </div>
    </div>
  );
}

export default RoomDetails;
