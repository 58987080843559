import React, { useState } from 'react'
import TableComponent from '../../Component/TableComponent'
import dollarIcon from '../../Assets/img/refferal.png'
import infoIcon from '../../Assets/img/info-icon.png'
import DateRangePickerComponent from '../../Component/DatePicker/DateRangePickerComponent'
import { Formik } from 'formik'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { marketingWebsiteAction } from "../../Stores/Actions/marketingWebsiteAction";


function DesktopViewDisplay({ values, setValues, modalOpen, data }) {
  const dispatch = useDispatch()

  const [focused2, setFocused2] = useState(null);

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'dollaricon',
        Cell: ({ row }) => {
          return (
            <img src={dollarIcon} alt="alt" />
          )
        }
      },
      {
        Header: '',
        accessor: 'paymentID',
        Cell: ({ row }) => <h3>  {row.original.paymentID}</h3>
      },
      {
        Header: '',
        accessor: 'payPalID',
        Cell: ({ row }) => {
          return <h4>{row.original.payPalID}</h4>
        }
      },
      {
        Header: '',
        accessor: 'dateTime',
        Cell: ({ row }) => {
          return <h4>{row.original.dateTime}</h4>
        }
      },
      {
        Header: '',
        accessor: 'amount',
        Cell: ({ row }) => {
          console.warn("new row", row)
          return (
            <h5 className="greenText amount-info">
              <button className="btn btn-amount" onClick={() => {
                if (row.original.type == "paypal_transfer") {
                  modalOpen()
                  dispatch(marketingWebsiteAction.getTransactionDetailsAction(row?.original?.transectionId))
                }
              }} >
                {row?.original?.amount}
                {row.original.type == "paypal_transfer" &&
                  <img src={infoIcon} alt="image" />
                }
              </button>
            </h5>
          )
        }
      }
    ],
    []
  )


  return (
    <div className="transaction-wrapper card2 desktopViewDisplayBlock">
      <div className="table-heading-info">
        <h2>Payout Transactions</h2>
        <div className="filter-wrapper">
          <div className='dateWrapper'>
            <h6>Filter by Date</h6>
            <div className='dateBox h-100 d-flex w-100'>
              <div className="Date-picker-range">
                <DateRangePickerComponent
                  name="dates"
                  startDate={values?.start_date}
                  endDate={values?.end_date}
                  startDateId="startDate"
                  endDateId="endDate"
                  focusedInput={focused2}
                  onFocusChange={(focusedInput) => {
                    setFocused2(focusedInput);
                  }}
                  isRequired
                  icon="icon-invitation"
                  orientation="horizontal"
                  openDirection="down"
                  startDatePlaceholderText="Start Date"
                  endDatePlaceholderText="End Date"
                  onDatesChange={({ startDate, endDate }) => {
                    setValues({
                      ...values,
                      start_date: startDate,
                      end_date: endDate,
                    });
                  }}
                  isOutsideRange={(day) => {
                    if (focused2 === "startDate")
                      return (
                        day.isAfter(moment())
                      );
                    if (focused2 == "endDate")
                      return (
                        day.isAfter(moment())
                      );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableComponent data={data} columns={columns} />

    </div>
  )
}

export default DesktopViewDisplay