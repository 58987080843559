import React, { useEffect, useState } from "react";
import CommissionTabMenu from "../../Component/Dashboard/CommissionTabMenu";
import { CommissionTabHeadingData } from "../../Data/AffiliateDashboardMockData";
import FreemiumMemberTravel from "../../Component/Dashboard/FreemiumMemberTravel"; 
import { useDispatch, useSelector } from "react-redux";
import { cardsAction } from "../../Stores/Actions/cardsAction";

import Pagination from "../../Component/Pagiantion/Pagination";
import ResultsOnPage from "../../Component/Pagiantion/ResultsOnPage";



function Commissions({ data }) {

  const dispatch = useDispatch();


  const [selectedTab, setSelectedTab] = useState(
    {
      id: 0,
      commission_type: "freemium_member_travel",
      label: "Freemium Member Travel",
      filter: {
        value: 'this_week',
        label: 'This Week'
      },
      currentPage: 0,
      per_page : 15
    });


  const commisionSummary = useSelector(
    (state) => state.cardsReducer.commissionSummary
  );


  useEffect(() => {
    const payload = {
      commission: selectedTab?.commission_type ,
      filter: {
        date_range: selectedTab?.filter?.value  
      },
      paging: {
        per_page: selectedTab.per_page || 15,
        page_number: selectedTab?.currentPage ? currentPage + 1 : 1
      } 
      
    }

    dispatch(cardsAction.dashboardSummary(JSON.parse(localStorage.user).user_id, payload ,selectedTab?.commission_type));
  }, [selectedTab]);


 
  
  return (
    <div className="commission-tabs">
      <div className="section-title">
        <h2>Commissions</h2>
      </div>
      <div className="commission-content-tabs">
        <div className="row">
          {commisionSummary?.data?.meta?.commisions?.map((item, index) => {
            return (
              <CommissionTabMenu
                key={index}
                item={item}
                id={index}
                setSelectedTab={setSelectedTab}
                commisionSummary={commisionSummary}
                selectedTab={selectedTab}
              />
            );
          })}
        </div>
      </div>
      <div
        className="commission-content-info active-content"
      >
        <FreemiumMemberTravel
          {...CommissionTabHeadingData[0]} 
          selectedTab={selectedTab}
          commisionSummary={commisionSummary}
          setSelectedTab={setSelectedTab}
        />
      </div>

      {commisionSummary?.data?.results?.length > 0 &&
        <div className="paginationWrapper">
          <Pagination
            pageCount={commisionSummary?.data?.pagination?.total_pages}
            onPageChange={(e) => setSelectedTab({ ...selectedTab, currentPage: e.selected })}
            forcePage={0}
          />
          <ResultsOnPage defaultValue={15} callback={(a, per_page) => setSelectedTab({ ...selectedTab, per_page: per_page })} className="results hide-for-mobile" />
        </div>
      }

    </div>

  );
}

export default Commissions;
