

import React, { useRef, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Button from "../FormComponent/Button";

function HotelName({
  searchableData,
  setFilters,
  filters,
  searchHotel,
  mobileCategory,
  searchRef,
}) {
  const ref2 = useRef(null);
  function clearField() {
    ref2.current.clear();
    const newFilters = filters;
    delete newFilters.id;
    setFilters({ ...newFilters });
  }
  const [showFilter, setshowFilter] = useState(true);
  const handleSearch = (item) => {
    if (item) {
      const searchValue = searchableData.options.length
        ? searchableData.options.find((ele) => ele.value === item.value)
        : null;
      if (searchValue) setFilters({ ...filters, id: searchValue.value });
    } else {
      const newFilters = filters;
      delete newFilters.id;
      setFilters({ ...newFilters });
    }
  };

  const handleSearchByName = () => {
    searchHotel();
  };
  return mobileCategory ? (
    <>
      <div className="showDropdown">
        <AsyncTypeahead
          options={searchableData.options}
          isLoading={false}
          inputProps={{ className: "inputDropdown" }}
          onInputChange={(e) => console.log("value: ", e)}
          onSearch={(e) => console.log("value: ", e)}
          onChange={(e) => handleSearch(e[0])}
          labelKey={(option) => option.label}
          minLength={3}
          placeholder="Hotel Name Search"
          searchText="Searching..."
          name="hotel_search"
          id="hotel_search"
          renderMenuItemChildren={(option) => (
            <div className="hotel-list">
              <span className="locationCode">{option.label}</span>
            </div>
          )}
          ref={searchRef}
        />
      </div>
      {/* <Button className="btnBorder" type="button" onClick={searchHotel}>
        Apply
      </Button> */}
    </>
  ) : (
    <>
      <div
        className="cardHead dflex"
        onClick={() => setshowFilter(!showFilter)}
      >
        <h6>Hotel Name</h6>
        <span className={`fa fa-angle-${showFilter ? "up" : "down"}`}></span>
      </div>
      <div className={`cardBody ${!showFilter ? "d-none" : ""}`}>
        <div className="showDropdown">
          <AsyncTypeahead
            options={searchableData.options}
            isLoading={false}
            inputProps={{ className: "inputDropdown" }}
            onInputChange={(e) => console.log("value: ", e)}
            onSearch={(e) => console.log("value: ", e)}
            onChange={(e) => handleSearch(e[0])}
            labelKey={(option) => option.label}
            minLength={3}
            placeholder="Hotel Name Search"
            searchText="Searching..."
            name="hotel_search"
            id="hotel_search"
            renderMenuItemChildren={(option) => (
              <div className="hotel-list">
                <span className="locationCode">{option.label}</span>
              </div>
            )}
            // ref={{searchRef}}
            ref={ref2}
          />
        </div>
        <Button
          className={`btnBorder ${!filters?.id ? "disabled" : ""}`}
          disabled={filters?.id ? false : true}
          type="button"
          onClick={() => {
            handleSearchByName();
            clearField();
          }}
        >
          Apply
        </Button>
      </div>
    </>
  );
}

export default HotelName;