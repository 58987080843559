import http from "./http";
export const marketingSiteService = {
    getWallletTransactionService,
    getTransactionDetailsService
}

function getWallletTransactionService(walletId,payload) { 
    return http.post(`${process.env.REACT_APP_WALLET_BASE_URL}/api/v2/wallet/${walletId}/transactions`,
    {
      ...payload,
      params : {
        use_wallet_api_key : true,
      }
    }
    )
    .then((res) => res);
  }

function getTransactionDetailsService(transactionID) { 
    return http.get(`https://dev-cards.travelsaving.com/api/transaction/${transactionID}`)
    .then((res) => res);
  }