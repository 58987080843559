import { cardsConstant } from "../Constants";

const initialState = {
  loading: false,
  error: null,
  commissionSummary: null,
  emailStatus : null
};

export default function cardsReducer(state = initialState, action) {
  switch (action.type) {
    case cardsConstant.AFFILIATE_DASHBOARD_SUMMARY:
      return {
        ...state,
        loading: true,
        commissionSummary: null,
        error: null,
      };
    case cardsConstant.AFFILIATE_DASHBOARD_SUMMARY_SUCCESS:
      return {
        ...state,
        commissionSummary: action.response.data,
        error: null,
      };
    case cardsConstant.AFFILIATE_DASHBOARD_SUMMARY_FAILURE:

      return {
        ...state,
        loading: false,
        commissionSummary: null,
        error: action.error,
      };

    case cardsConstant.ASSIGN_CARDS_REQUEST:
      return {
        ...state,
        cardAssignLoader: true,
        sort: false,
        error: null,
        loading: true,
        emailStatus :null
      };
    case cardsConstant.ASSIGN_CARDS_SUCCESS:
      return {
        ...state,
        cardAssigned: true,
        cardAssignLoader: false,
        emailStatus: {message:action?.response?.res?.data?.messgae , userType : action?.response?.userType },
        sort: true,
        error: null,
      };
    case cardsConstant.ASSIGN_CARDS_FAILURE:
    
      return {
        ...state,
        cardAssignLoader: false,
        error:{ message: action.error.error.response.data.message ,userType : action.error.userType },
        loading: false,
        
      };

    default:
      return state;
  }
}