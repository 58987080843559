import axios from "axios";

const headers = {
  "Content-type": "application/json",
  accept: "application/json",
};

axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  const search_key = localStorage.getItem("search_key");
  // const flight_search_key = localStorage.getItem(
  //   config?.params?.flight_search_label ||
  //     config?.data?.params?.flight_search_label
  // );
  const flight_search_key = localStorage.getItem("flight-search-key-1");
  if (token) config.headers.Authorization = `Bearer ${token}`;

  if (config?.data?.params?.use_form_data || config?.params?.use_form_data) {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
  }
  if (config?.data?.params?.use_wallet_api_key) {
    config.headers.common["x-api-key"] = process.env.REACT_APP_WALLET_API_KEY;
  }
    if (
    (search_key && config?.params?.search_key) ||
    (search_key && config?.data?.params?.search_key)
  )
    config.headers["Search-Key"] = search_key;
  if (
    (flight_search_key && config?.params?.flight_search_key) ||
    (flight_search_key && config?.data?.params?.flight_search_key)
  ) {
    config.headers[
      config?.params?.headerName || config?.data?.params?.headerName
    ] = flight_search_key;
  }

  if (config?.data?.params?.use_cards_api_key) {
    config.headers.common["x-api-key"] = process.env.REACT_APP_CARDS_API_KEY;
  }

  if (config?.data?.params?.use_wallet_api_key) {
    config.headers.common["x-api-key"] = process.env.REACT_APP_WALLET_API_KEY;
  }

  if ((config?.data?.params?.use_search_key || config?.params?.use_search_key) && localStorage.getItem("search_key"))
    config.headers["Search-Key"] = localStorage.getItem("search_key");

    if ((config?.data?.params?.use_flight_search_id || config?.params?.use_flight_search_id) && localStorage.getItem("flight_search_id")){
 
      config.headers["Search-Key"] = localStorage.getItem("flight_search_id").replaceAll('"', "");
 
    }

  if (config?.data?.params?.use_stripe_api)
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_STRIPE_KEY}`;
  delete config?.params;
  delete config?.data?.params;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("lt");  // login time
      window.localStorage.removeItem("user");  // login time
      window.localStorage.setItem("prevPath", window.location.href);
      if(window.location.pathname != "/login"){
        window.location = "/login";
      }
    }
    //something with error
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  headers,
};