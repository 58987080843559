import React from "react";
import moment from "moment";
import MonthYearPicker from "../MonthYearPicker/MonthYearPicker";

import { SingleDatePicker } from "react-dates";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

export default function DateInput(props) {

  const currentGeoLocation =
    localStorage.getItem("currentGeoLocation") &&
    JSON.parse(localStorage.getItem("currentGeoLocation"));

  const dateDisplayFormat =
    currentGeoLocation === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY";

  const {
    label,
    name,
    placeholder,
    value,
    errors,
    touched,
    isRequired = false,
    onDateChange,
    onFocusChange,
    focused,
    disabled,
    isDayBlocked,
    customClassName,
    icon,
    isOutsideRange,
    isDateResponsive,
    renderMonthYearSelector,
    arrayFieldArray,
    openDirection
  } = props; 
  let isValidClass = "";
  let fieldClass = "form-control rounded-0 ";
  const isTouched =
    touched !== undefined && touched[name] !== undefined && touched[name];

  let errorMsg = "";
  if (arrayFieldArray) {
    errorMsg = errors;
  } else {
    if (isTouched && errors[name] !== undefined && errors[name]) {
      fieldClass += " is-invalid";
      isValidClass = "datePickerInvalid";
      errorMsg = errors[name];
    } else if (isTouched && !errors[name] && value) {
      fieldClass += " is-valid";
      isValidClass = "datePickerValid";
    }
  }

  return (
    <>
      {label && <h5 className="">{label}</h5>}

      <SingleDatePicker
        numberOfMonths={1}
        placeholder={placeholder}
        // date={value}
        date={value ? moment(new Date(value)) : value}
        onDateChange={onDateChange}
        customInputIcon={<i className={icon} />}
        inputIconPosition="before"
        focused={focused || isDateResponsive}
        onFocusChange={onFocusChange}
        id={name}
        disabled={disabled}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        readOnly={true}
        openDirection={openDirection ? "up" : "down"}
        renderMonthElement={
          renderMonthYearSelector ? MonthYearPicker : undefined
        }
        displayFormat={dateDisplayFormat}
      />
    </>
  );
}
