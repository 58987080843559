import React, { useRef, useState, useEffect } from "react";
import Header from "../../Component/Header";
import Card from "../../Component/Card";
import downArrow from "../../Assets/img/network/down-arrrow.svg";
import Background from "../../Assets/img/tripello-diffrenece.png";
import flightImage from "../../Assets/img/flightImg.png";
import hotelImg from "../../Assets/img/cardImg2.png";
import carImg from "../../Assets/img/cardImg3.png";
import cardImage from "../../Assets/img/cardImg4.png";
import Video from "../../Component/Video";
import plusImg from "../../Assets/img/plus-img.png";
import FooterWithMediaIcon from "../../Component/FooterWithMediaIcon";
import posterBg from "../../Assets/img/home/Homepage-Banner-Video.jpg";
import homeVideoDesktop from "../../Assets/video/home/new-tripello-homepage-top-video.mp4";
import homeVideoipad from "../../Assets/video/home/new-tripello-homepage-top-video-ipad5.mp4";
import homeVideowebm from "../../Assets/video/home/new-tripello-homepage-top-video.webm";
import homeVideoMobile from "../../Assets/video/home/new-tripello-homepage-top-video.webm";
import playButton from "../../Assets/img/network/play-button.png";
import featuredOnImg from "../../Assets/img/home/featured-on.png";
import Button from "../../Component/FormComponent/Button";
import AccordianOne from "../../Component/AccordianOne";
import { faqs } from "../../main/CustomConstants/Home";
import ModalHome from "../../Component/ModalHome";
import PlanInfoTwo from "../../Component/PlanInfoTwo";
import { TriptekDifference } from "./TriptekDifference";
import { TripelloWayWrapper } from "./TripelloWayWrapper";
import MoreLessButton from "../../Component/MoreLessButton";
import VideoModal from "../../Component/VideoModal";
import PortraitModeModel from "../../Component/PortraitModeModel";

function Home() {
  const scrollPage = useRef(null);
  const [isFaqExpanded, setIsFaqExpanded] = useState(false);
  const [openedAccordian, setOpenedAccordian] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  //state for opening video modal
  const [openModal, setOpenModal] = useState(false);
  const [portraitViewModal, setportraitViewModal] = useState(
    window.outerWidth > 767 && window.outerWidth < 1025 ? true : false
  );
  //state for show top sticky header on scroll
  const [showHeader, setShowHeader] = useState(false);

  // state for scroll button
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

  // for video play and pause button
  // const [buttonPlay, setButtonPlay] = useState(false);
  // const desktopVideoRef = useRef(null);
  // const mobileVideoRef = useRef(null);

  // const playAndPauseButton = () => {
  //   setButtonPlay(!buttonPlay);
  //   desktopVideoRef.current.play();
  // };

  // const playAndPauseMobileButton = () => {
  //   setButtonPlay(!buttonPlay);
  //   mobileVideoRef.current.play();
  // };

  //getting scroll button while scrolling down
  useEffect(() => {
    scrollPage.current.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", function (event) {
      if (window.outerWidth > 767 && window.outerWidth < 1025) {
        setportraitViewModal(true);
      } else {
        setportraitViewModal(false);
      }
    });
    return () => {
      scrollPage.current.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", function (event) {
        if (window.outerWidth > 767 && window.outerWidth < 1025) {
          setportraitViewModal(true);
        } else {
          setportraitViewModal(false);
        }
      });
    };
  }, []);

  //setting length after which sticky header and scrollToTop button show
  const handleScroll = () => {
    if (
      (scrollPage.current.scrollTop >= 4 && !showHeader) ||
      scrollPage.current.scrollTop >= 150
    ) {
      setShowHeader(true);
      setShowScrollTopBtn(true);
    }
    if (
      scrollPage.current.scrollTop < 4 ||
      scrollPage.current.scrollTop < 150
    ) {
      setShowHeader(false);
      setShowScrollTopBtn(false);
    }
  };

  //jumping to top of the page on button click
  const scrollElementToTop = () => {
    scrollPage.current.scrollTop = 0;
  };

  //handle open and close accordian list
  const handleOpenClose = (id) => {
    if (id === openedAccordian) {
      setOpenedAccordian(null);
    } else {
      setOpenedAccordian(id);
    }
  };

  var sectionStyle = {
    backgroundImage: `url(${Background})`,
  };

  //handle open and close video modal

  const handleOpenCloseVideoModal = () => {
    if (!openModal) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handleOpenClosePortraitModal = () => {
    if (portraitViewModal) {
      setportraitViewModal(false);
    } else {
      setportraitViewModal(true);
    }
  };
  //Modal open and close
  const handleOpenCloseModal = () => {
    if (!modalOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };

  // scroll view function

  const scrollView = (e) => {
    let triptekDifference = document.querySelector("#triptek-difference");
    e.preventDefault();
    triptekDifference &&
      triptekDifference.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  // scroll view function end

  // Accordian list
  const getFaqs = (faqList) => {
    let faqListNodes = [];

    // render the whole list
    faqList.forEach((faq, index) => {
      if (isFaqExpanded) {
        faqListNodes.push(
          <AccordianOne
            id={faq.id}
            key={faq.title + index}
            heading={faq.title}
            content={faq.description}
            openedAccordian={openedAccordian}
            handleOpenClose={handleOpenClose}
          />
        );
      } else {
        // render list with limit of 5 items
        if (index < 5) {
          faqListNodes.push(
            <AccordianOne
              id={faq.id}
              key={faq.title + index}
              heading={faq.title}
              content={faq.description}
              openedAccordian={openedAccordian}
              handleOpenClose={handleOpenClose}
            />
          );
        }
      }
    });

    return faqListNodes;
  };

  return (
    <>
      <div
        className={
          !showHeader
            ? `grayBg indexPageWrap`
            : "grayBg indexPageWrap header-sticky"
        }
      >
        <div ref={scrollPage}>
          <section className="bannerSec home-banner">
            <div className="home-iframe-wrapper home-banner-video">
              <video
                playsInline="playsinline"
                autoPlay="autoplay"
                muted="muted"
                loop="loop"
                className="bannerImg video-player"
                poster={posterBg}
              >
                <source src={homeVideoDesktop} type="video/mp4" />
                <source src={homeVideoipad} type="video/mp4" />
                <source src={homeVideowebm} type="video/webm" />
              </video>
            </div>
            <div className="fixedHeader">
              <Header />
            </div>
            <div className="bannerCont centerAlign">
              <div className="container">
                <h3>
                  We Are <strong>Here</strong> To Get You <strong>There</strong>
                </h3>
                <p>Wholesale Travel Prices For Everyone</p>
              </div>
              <div className="scrollBtm">
                <a
                  to="#triptek-difference"
                  className="scroll-down"
                  onClick={scrollView}
                >
                  <div class="mousey">
                    <div class="scroller"></div>
                  </div>
                </a>
              </div>
            </div>
          </section>
          <section
            id="triptek-difference"
            className="triptek-difference-wrapper"
            style={sectionStyle}
          >
            <div className="container sectionSpace sideSpace centerAlign">
              <TriptekDifference />
            </div>
          </section>
          <div className="container sectionSpace2 sideSpace triptek-inventory-wrapper">
            <h3 className="sctionTitle centerAlign">The Tripello Inventory</h3>
            <div className="colCard">
              <Card
                title={<h5>Global Flights</h5>}
                description="Big savings on Flights, especially in premium cabins, from all Global Carries and over 140 low-cost carriers from around the world."
                image={flightImage}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />

              <Card
                title={<h5>Hotels & Resorts</h5>}
                description="Huge savings on over 1.5 million Hotels and Resorts worldwide, Including the most popular destinations."
                image={hotelImg}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
              <Card
                title={<h5>Rental Cars & Services</h5>}
                description="Great Deals on Rental Cars by the best and most trusted brands from around the world."
                image={carImg}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
              <Card
                title={<h5>Vacation Homes</h5>}
                description=" More than 1.5 Million bookable Vacation rentals incl. Condos, Cabins, Villas, Bungalows, Beach Houses and more."
                image={cardImage}
                colClassName="col"
                styleClassName="cardStyle"
                imgClassName="cardImg"
                textClassName="cardText"
                backgroundImage={true}
              />
            </div>
          </div>
          <div className="container sectionSpace2 sideSpace centerAlign tripello-way-wrapper">
            <TripelloWayWrapper />
          </div>
          <div className="gradiantBg videoSpace sideSpace centerAlign tripello-magic-wrapper">
            <div className="sctionTitle">The Tripello Magic</div>
            <p>Give us a minute to show you how</p>
            <div className="cardStyle">
              <div className="videoBox">
                <div className="videoInBox">
                  <div className="desktop-video">
                    <Button
                      type="button"
                      className="video-play-btn"
                      imageSrc={playButton}
                      onClick={handleOpenCloseVideoModal}
                    />
                  </div>
                  <div className="mobile-video">
                    <Button
                      type="button"
                      className="video-play-btn"
                      imageSrc={playButton}
                      onClick={handleOpenCloseVideoModal}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="tripello-magic-content">
              <div className="video-section-content-wrap">
                <h6>
                  If you like to <strong>find out more</strong> about{" "}
                  <strong>WHAT</strong>, <strong>HOW</strong>, and{" "}
                  <strong>WHY</strong> we do what <strong>WE</strong> do,
                  <strong>
                    <button
                      onClick={handleOpenCloseModal}
                      className="tripello-magic-link"
                    >
                      CLICK HERE.
                    </button>
                  </strong>
                </h6>
              </div>
              <div className="featured-on-icons">
                <img src={featuredOnImg} alt="image" />
              </div>
            </div>
          </div>
          <div className="planCont">
            <div className="container sideSpace centerAlign">
              <h3 className="sctionTitle">
                You Choose Which One Is Right For You
              </h3>
              <div className="container">
                <div className="choosePlan">
                  <PlanInfoTwo
                    freePlan="free-plan"
                    planName="Freemium Membership"
                    arrayList={[
                      "No Credit/Debit Card Required*",
                      "Limited Platform Access – Hotels and Resorts Only",
                      "Savings of up to 25%",
                      "Access to 1.5 million Hotels and Resorts",
                    ]}
                    planNote="* Not required for Sign Up but will be required for Hotel
                    Bookings"
                    heading="Free"
                    para="(no time limit)"
                    btnName="I'm In"
                    link="freemium-signup"
                  />
                  <PlanInfoTwo
                    planName="Premium Membership"
                    months="(6 Month)"
                    arrayList={[
                      "Credit/Debit Card Required*",
                      "Unlimited Platform Access",
                      "Savings of up to 65% and more",
                      "Access to 1.5 million Hotels and Resorts",
                      "Access to 1.5 million Vacation Homes",
                      "Access to Rental Cars and upcoming Chauffeur Service",
                      "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
                    ]}
                    planNote="* Required for Sign Up and Bookings"
                    heading={
                      <>
                        $32<sub>.50</sub>
                        <span>/ per month</span>
                      </>
                    }
                    para="($195.00 total at checkout)"
                    btnName="Sign Me Up"
                    link="membership-premium-checkout"
                  />
                  <PlanInfoTwo
                    planName="Premium Membership"
                    months="(12 Month)"
                    imgPlus={
                      <>
                        <img src={plusImg} alt="image" />
                      </>
                    }
                    arrayList={[
                      "Credit/Debit Card Required*",
                      "Unlimited Platform Access",
                      "Savings of up to 65% and more",
                      "Access to 1.5 million Hotels and Resorts",
                      "Access to 1.5 million Vacation Homes",
                      "Access to Rental Cars and upcoming Chauffeur Service",
                      "Access to Flight Inventory of all Global Carriers and 140 Low Cost Carriers",
                      <>
                        Access to Member Referral Rewards Program <br />
                        • $25 for each Premium Member <br />• $50 for each
                        Premium Plus Member{" "}
                      </>,
                    ]}
                    planNote="* Required for Sign Up and Bookings"
                    heading={
                      <>
                        $24<sub>.95</sub>
                        <span>/ per month</span>
                      </>
                    }
                    para="($299.40 total at checkout)"
                    btnName="Sign Me Up"
                    link="membership-premium-plus-checkout"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="home-faq"
            className="container sectionSpace2 sideSpace faqCnt home-faq"
          >
            <h3 className="centerAlign sctionTitle">
              Here are Some Answers to Common Questions
            </h3>
            <div className="accordion">
              <div className="cardStyle accordWrap">
                <div className="toggle-more-accordions">{getFaqs(faqs)}</div>
              </div>

              {/* more and less button below accordian */}

              {faqs.length > 5 && (
                <MoreLessButton
                  isExpandAccordian={isFaqExpanded}
                  setIsExpandAccoridan={setIsFaqExpanded}
                />
              )}
            </div>
          </div>
        </div>
        <FooterWithMediaIcon />
        {showScrollTopBtn ? (
          <div
            id="back-to-top"
            className="back-to-top"
            onClick={scrollElementToTop}
          >
            <button className="btn btn-primary" title="Back to Top">
              <svg
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 31.479 31.479"
                style={{ enableBackground: "new 0 0 31.479 31.479" }}
                width="18px"
                height="18px"
                className="svg lazyloading replaced-svg"
              >
                <path
                  style={{ fill: "#004185" }}
                  d="M26.477,10.274c0.444,0.444,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-8.047-8.047  v26.555c0,0.619-0.492,1.111-1.111,1.111c-0.619,0-1.127-0.492-1.127-1.111V3.813l-8.031,8.047c-0.444,0.429-1.159,0.429-1.587,0  c-0.444-0.444-0.444-1.143,0-1.587l9.952-9.952c0.429-0.429,1.143-0.429,1.571,0L26.477,10.274z"
                ></path>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>
          </div>
        ) : null}
      </div>

      {/* Modal open and close  */}

      {modalOpen && (
        <ModalHome openModal={modalOpen} closeModal={handleOpenCloseModal} />
      )}
      {openModal && (
        <VideoModal
          openModal={openModal}
          closeModal={handleOpenCloseVideoModal}
        />
      )}
    </>
  );
}

export default Home;
