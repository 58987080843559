import React, { Fragment } from "react";

export const TripelloWayWrapper = () => {
  return (
    <Fragment>
      <h3 className="sctionTitle">The Tripello Way</h3>
      <div className="cardStyle cardBox">
        <p>
          <strong>Five Star Experiences at Three Star Prices.</strong>
        </p>
        <p>
          The <strong>First True</strong> subscription based
          <strong> Wholesale Travel Service</strong> offering our <br />
          Members
          <strong> Savings </strong> of up to <strong> 65% </strong> and
          sometimes even more.
        </p>

        <p>
          <strong>JOIN</strong> for <strong>FREE, </strong>explore our platform
          and see <strong>ALL Products and Member Pricing.</strong>
        </p>
        <p>
          You can book <strong>Hotels</strong> (only) as a{" "}
          <strong> FREEMIUM Member,</strong> but still with <br />{" "}
          <strong>Great Savings</strong> of up to <strong>25%</strong> over
          other Online Travel Sites.
        </p>
        <p>
          <strong>No Time Limit</strong> on our{" "}
          <strong> FREEMIUM Membership.</strong>
        </p>
        <p>
          You can <strong>Upgrade</strong> whenever you{" "}
          <strong>are ready</strong> to enjoy{" "}
          <strong>ALL Member Savings.</strong>
        </p>
      </div>
    </Fragment>
  );
};
