import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LogoWhite from "./FormComponent/LogoWhite";
import networkLogo from "../Assets/img/network/network-logo.png";
import logo2 from "../Assets/img/logo2.png";
function NetworkHeader() {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = (e) => {
    const header = document.querySelector("body");
    const scrollTop = window.scrollY;
    scrollTop >= 120
      ? header.classList.add("header-sticky")
      : header.classList.remove("header-sticky");
  };
  return (
    <div className="fixedHeader">
      <header className="header">
        <div className="container">
          <div className="logo">
            <Link to="/" className="logoWrap">
              <LogoWhite src={networkLogo} className="logo1" />
              <div className="network-logo-sticky">
                <img src={logo2} alt="Tripello" className="logo-sticky" />
                <span>Network</span>
              </div>
            </Link>
          </div>
          <nav className="navigation">
            <ul>
              <li className="getstarted-btn">
                <a href="https://tripello.com/blog/" target="_blank" rel="noopener noreferrer">Tripello Hub</a>
              </li>
              <li>
                <a href="https://tripello.com/login" className="signin-btn">
                  Sign In
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default NetworkHeader;
