import _ from "lodash";
import React, { useState } from "react";
import Button from "../FormComponent/Button";

function ResultsOnPage({ className, callback, defaultValue }) {
  const [perPage, setPerPage] = useState(defaultValue);
  const SetPageHandler = (value) => {
    setPerPage(value);
    callback(null, value);
  };

  return (
    <ul className={className}>
      <li>Results on page:</li>
      <li>
        <span
          className={`${perPage === 15 ? "active" : ""}`}
          onClick={() => SetPageHandler(15)}
        >
          15
        </span>
      </li>
      <li>
        <span
          className={`${perPage === 30 ? "active" : ""}`}
          onClick={() => SetPageHandler(30)}
        >
          30
        </span>
      </li>
      <li>
        <span
          className={`${perPage === 45 ? "active" : ""}`}
          onClick={() => SetPageHandler(45)}
        >
          45
        </span>
      </li>
    </ul>
  );
}

export default ResultsOnPage;
