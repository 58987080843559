import React, { useState, useEffect } from "react";
import moment from "moment";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Component/FormComponent/Button";
import SearchAutocomplete from "../../Component/Autocomplete/SearchAutocomplete";
import DateRangePickerComponent from "../../Component/DatePicker/DateRangePickerComponent";
import AddRoomForm from "../../Component/Forms/AddRoomForm";
import { commonActions } from "../../Stores/Actions/commonActions";
import { hotelActions } from "../../Stores/Actions";
import Loader from "../../Component/Loader/Loader";
import { HotelSearchValidation } from "../../Component/Validation/HotelSearchValidation";

export default function HotelTab() {
  const nagivate = useNavigate();
  const dispatch = useDispatch();
  const hotelSearchAutoCompleteRes = useSelector(
    (state) => state.commonReducer.autoCompleteData
  );
  const loading = useSelector((state) => state.commonReducer.loader);
  const searchLoading = useSelector(
    (state) => state.commonReducer.searchLoading
  );
  const [showDropdown, setshowDropdown] = useState(false);
  const [showAutocomplete, setshowAutocomplete] = useState(false);
  const [isResponsive, setResponsive] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [focused2, setFocused2] = useState(null);
  const [selectedValue, setselectedValue] = useState(null);
  const [isDateResponsive, setDateResponsive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputFields, setInputFields] = useState({ adults: 2, rooms: 1 });
  const [roomCount, setRoomCount] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [currFocus, setCurrFocus] = useState(null);
  const roomObject = { adults: 2, child: 0, children: [] };

  const currencyAndLanguage =
    localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  useEffect(() => {
    if (focused2) {
    }
  }, [focused2]);

  var hotelWidget = JSON.parse(localStorage.getItem("hotelWidget"));
  const initialValues = {
    check_in: hotelWidget
      ? moment(hotelWidget.check_in)
      : moment().add(1, "days"),
    check_out: hotelWidget
      ? moment(hotelWidget.check_out)
      : moment().add(4, "days"),
    rooms: [{ ...roomObject }],
    goingTo: "",
  };
  useEffect(() => {
    localStorage.removeItem("hotelFilter");
    localStorage.removeItem("hotelPayload");
    localStorage.removeItem("mapPayload");
    localStorage.removeItem("flightFilter");
    localStorage.removeItem("search_key");


    if (window.innerWidth < 768) {
      setMobile(true);
    }
    // window.addEventListener("resize", updateSize);
    // return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleOnSearch = (query) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    dispatch(commonActions.hotelSearchAutoComplete(query));
  };

  const handleOnSelect = (value, field, formikProps) => {
    // the item selected
    formikProps.setFieldValue(field, value);
    setselectedValue(value[0]?.secondary_label);
    setSelectedId(value[0]?.id);
    setIsLoading(false);

    if(value[0]){
      localStorage.setItem("autoCompleteData", JSON.stringify(value[0]))
      handleAutocompleteClick(false); 
    }else{
      // formikProps.setFieldValue(field, [JSON.parse(localStorage.getItem("autoCompleteData"))]); 
      setselectedValue(JSON.parse(localStorage.getItem("autoCompleteData"))?.secondary_label);
    }
  };
  const handleAutocompleteClick = () => {
    setshowAutocomplete(!showAutocomplete);
  };
  const handleDateClick = () => {
    setDateResponsive(!isDateResponsive);
  };
  const setDropdown = () => {
    setshowDropdown(!showDropdown);
  };
  const handleDateChange = (value, formikProps, field) => {
    formikProps.setFieldValue(field, value);
    if (field === "check_out" && value) setDateResponsive(false);
  };
  const showResponsive = () => {
    setResponsive(true);
    handleAutocompleteClick(false);
  };
  const showDateResponsive = (clickedInput) => {
    setDateResponsive(clickedInput);
  };
  const handleSubmit = (data) => {
    const payload = {
      view_type: "list",
      rooms: data.rooms.map((ele) => {
        return {
          adults: ele.adults,
          child: ele.child,
          children: ele.children.map((ele) => ele.value),
        };
      }),
      check_in_date: moment(data.check_in).format("YYYY-MM-DD"),
      check_out_date: moment(data.check_out).format("YYYY-MM-DD"),
      city_name: selectedValue,
      currency: currency.isoCode,
      home: false,
      locale: "en-US",
      native_currency_symbol: currency.symbol,
      onlyLocale: "en-US",
      optional: "amenities",
      region_id: selectedId,
      search_type: "hotel",
      source_market: "US",
      token: localStorage.getItem("accessToken"),
      use_wallets: 1,
      filters: {},
      paging: {
        page_number: 1,
        per_page: 15,
      },
      nationality: "US",
      sort: {
        field: "savings_percentage",
        order: "desc",
      },
    };

    localStorage.setItem("hotelPayload", JSON.stringify(payload));
    navigateTo();
  };

  function navigateTo() {
    return nagivate("/hotel-search-result");
  }

  const renderForm = (formikProps) => {
    const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
      formikProps;

    const adultChildrenCount = values.rooms
      .map((ele) => ele.adults)
      .reduce((a, b) => a + b);

    const childCount = values.rooms
      .map((ele) => ele.child)
      .reduce((a, b) => a + b); 

    return (
      <form onSubmit={handleSubmit}>
        <div className="tabContent activeTab" id="apartmentsTab">
          <div className="dflex flexStart mobileFullWidth">
            <div className="btnGroup">
              <div
                className={
                  !showDropdown
                    ? "dropdownWrap dropDownMobilePopup toggleClass"
                    : "dropdownWrap dropDownMobilePopup toggleClass openDropdown"
                }
              >
                {/* selecting room  for desktop view */}
                {!isMobile && (
                  <Button
                    className="btnSml toggleBtn"
                    type="button"
                    onClick={setDropdown}
                    text={`${values.rooms.length} Room${values.rooms.length > 1 ? "s" : ""
                      }, ${adultChildrenCount + childCount}  ${childCount ? "Guest" : "Adult"
                      }${adultChildrenCount > 1 ? "s" : ""}`}
                    icon="fa fa-angle-down"
                  />
                )}

                {/* selecting room for mobile view */}
                {isMobile && (
                  <div>
                    <Button
                      className="btnSml toggleBtn"
                      type="button"
                      onClick={setDropdown}
                      text={`${values.rooms.length} Room${values.rooms.length > 1 ? "s" : ""
                        }, ${adultChildrenCount + childCount}  ${childCount ? "Guest" : "Adult"
                        }${adultChildrenCount > 1 ? "s" : ""}`}
                      icon="fa fa-angle-down"
                    />
                  </div>
                )}
                {/* Mobile view of adding room */}
                <div className="dropdownInWrap mobileFullWidthPopup">
                  <div className="mobileFullWidthPopupContent">
                    <div className="mobileShow popupHeader">
                      <h4>Travelers and Rooms</h4>
                      <Link className="popupCloseBtn toggleBtn" to="#">
                        <span className="icon-close" onClick={setDropdown} />
                      </Link>
                    </div>
                    {/* {console.log("values: ", values?.rooms)} */}
                    {/* Add room component */}
                    {showDropdown && (
                      <AddRoomForm
                        isOpen={showDropdown}
                        inputFields={values.rooms}
                        roomObject={roomObject}
                        formikProps={formikProps}
                        handleClose={setDropdown}
                      />
                    )}
                  </div>
                  <div className="dropdownBtnWrap mobileShow">
                    <Button
                      className="btn"
                      text="Apply"
                      type="button"
                      onClick={setDropdown}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* autocomplete component integration */}
          <div className="filterSecondRow input-for-mobile">
            {!isMobile && (
              <div
                className={
                  !showAutocomplete
                    ? "inputStyle showDropdown"
                    : "inputStyle showDropdown"
                }
              >
                <div className="dropdown-content">
                  <span />
                  <SearchAutocomplete
                    {...formikProps}
                    label=""
                    options={hotelSearchAutoCompleteRes}
                    name="goingTo"
                    onSearch={(query) => handleOnSearch(query)}
                    onChange={(selected) => {
                      handleOnSelect(selected, "goingTo", formikProps);
                    }}
                    value={values.goingTo}
                    onBlur={handleBlur}
                    isLoading={searchLoading}
                    onClick={handleAutocompleteClick}
                    placeholder="Going to"
                    icon="icon-location-on"
                    errors={errors}
                    drop={false}
                  />
                </div>
              </div>
            )}
            {isMobile && (
              <div className="responsive-input">
                <button
                  className="inputStyle grow1"
                  onClick={showResponsive}
                  type="button"
                >
                  {" "}
                  <i className="icon-location-on" />{" "}
                  {selectedValue ? selectedValue : "Going to"}
                </button>
              </div>
            )}
            {isResponsive && showAutocomplete && (        // show autocomplete popup on mobile view
              <div className="inputStyle showDropdown">
                <div className="mobilePopup">
                  <Link to="#" className="closePopup">
                    <span
                      className="icon-close"
                      onClick={() => handleAutocompleteClick(true)}
                    />
                  </Link>
                  <h4 className="inputPopTitle">Going to</h4>
                  <div className="dropdown-content">
                    <span />
                    <SearchAutocomplete
                      {...formikProps}
                      label=""
                      options={hotelSearchAutoCompleteRes}
                      name="goingTo"
                      onSearch={(query) => handleOnSearch(query)}
                      onChange={(selected) => {
                        handleOnSelect(selected, "goingTo", formikProps);
                      }}
                      onBlur={handleBlur}
                      isLoading={searchLoading}
                      value={values.goingTo}
                      // onClick={handleAutocompleteClick}
                      placeholder="Going to"
                      icon="icon-location-on" 
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Date range picker component(desktop)  // main */}
            {!isMobile && (
              <div className="Date-picker-range">
                <div className="custom-date-range">
                  <p>{values.check_out && `${moment(values.check_in).format("ddd, MMM DD")} `} <i className='icon-arrow'></i> {` ${moment(values.check_out).format("ddd, MMM DD")} [ ${moment(values.check_out).diff(moment(values.check_in), 'days')} night stay ]`}  </p>
                </div>
                <DateRangePickerComponent
                  name="dates"
                  startDate={values.check_in}
                  endDate={values.check_out}
                  startDateId="check_in"
                  endDateId="check_out"
                  focusedInput={focused2}
                  onFocusChange={(focusedInput) => {
                    setFocused2(focusedInput);
                  }}
                  isRequired
                  icon="icon-invitation"
                  orientation="horizontal"
                  openDirection="up"
                  startDatePlaceholderText="Check-in"
                  endDatePlaceholderText="Check-out"
                  onDatesChange={({ startDate, endDate }) => {
                    handleDateChange(startDate, formikProps, "check_in");
                    handleDateChange(endDate, formikProps, "check_out");
                  }}

                  // isDayBlocked={(day) => {
                  //   if (focused2 === "endDate") {
                  //     return day.isBefore(
                  //       moment(values.check_in).subtract(0, "days")
                  //     )
                  //   }
                  // }}
                  isOutsideRange={(day) => {
                    const res = hotelWidget ? moment(hotelWidget.check_in) : moment().add(0, "days");
                    if (focused2 === "startDate")
                      return day.isBefore(moment(res).subtract(0, "days"));
                    if (focused2 === "endDate")
                      return (
                        day.isAfter(moment(values.check_in).add(20, "days")) ||
                        day.isBefore(
                          moment(values.check_in).subtract(1, "days")
                        )
                      );
                  }}
                  
                />
              </div>
            )}

            {/* button for responsice check in-out fields */}
            {isMobile && (
              <div className="date-picker-type">
                <div className="check-in-date date-picker-style">
                  <i className="icon-invitation" />
                  <Button
                    className="inputStyle grow1"
                    onClick={() => showDateResponsive("startDate")}
                    type="button"
                    text={
                      values.check_in
                        ? moment(values.check_in).format("DD/MM/YYYY")
                        : "Check-in"
                    }
                  />
                </div>
                <div className="check-out-date date-picker-style">
                  <i className="icon-invitation" />
                  <Button
                    className="inputStyle grow1"
                    onClick={() => showDateResponsive("endDate")}
                    type="button"
                    text={
                      values.check_out
                        ? moment(values.check_out).format("DD/MM/YYYY")
                        : "Check-Out"
                    }
                  />
                </div>
                <div className="custom-date-range">
                  <p>{values.check_out && `${moment(values.check_in).format("ddd, MMM DD")} `} <i className='icon-arrow'></i> {` ${moment(values.check_out).format("ddd, MMM DD")} [ ${moment(values.check_out).diff(moment(values.check_in), 'days')} night stay ]`}  </p>
                </div>
              </div>
            )}

            {isMobile && isDateResponsive && (
              <div className="inputStyle showDropdown">

                <div className="date-picker-popup ">
                  <Link to="#" className="closePopup">
                    <span
                      className="icon-close"
                      onClick={() => handleDateClick(false)}
                    />
                  </Link>
                  <h4 className="inputPopTitle">Going to</h4>
                  <div className="Date-picker-range">
                    <DateRangePickerComponent
                      isDateResponsive={isDateResponsive}
                      name="dates"
                      startDate={values.check_in}
                      endDate={values.check_out}
                      startDateId="check_in"
                      endDateId="check_out"
                      focusedInput={focused2}
                      onFocusChange={(focusedInput) => {
                        setFocused2(focusedInput);
                      }}
                      isRequired
                      icon="icon-invitation"
                      orientation="vertical"
                      openDirection="down"
                      startDatePlaceholderText="Check-in"
                      endDatePlaceholderText="Check-out"
                      onDatesChange={({ startDate, endDate }) => {
                        handleDateChange(startDate, formikProps, "check_in");
                        handleDateChange(endDate, formikProps, "check_out");
                      }}
                      isDayBlocked={(day) => {
                        if (focused2 === "endDate") {
                          // return day > values.check_in.clone().add(21, "days");
                          return day.isBefore(
                            moment(values.check_in).subtract(0, "days")
                          )
                        }
                      }}
                      isOutsideRange={(day) => {
                        const res = hotelWidget
                          ? moment(hotelWidget.check_in)
                          : moment().add(0, "days");
                        if (focused2 === "startDate")
                          return day.isBefore(moment(res).subtract(0, "days"));
                        if (focused2 === "endDate")
                          return (
                            day.isAfter(
                              moment(values.check_in).add(20, "days")
                            ) ||
                            day.isBefore(
                              moment(values.check_in).subtract(1, "days")
                            )
                          );
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* {isMobile && isDateResponsive && (
              <div className="date-picker-popup ">
                <Link to="#" className="closePopup">
                  <span
                    className="icon-close"
                    onClick={() => handleDateClick(false)}
                  ></span>
                </Link>
              </div>
            )} */}
            <div className=" searchBtnWrap">
              <Button type="submit" className="btn d-block" text="Search" />
            </div>
          </div>
        </div>
        {/* </div> */}
      </form>
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={HotelSearchValidation()}
      >
        {renderForm}
      </Formik>
      {loading && <Loader />}
    </>
  );
}