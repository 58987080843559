import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchAutocomplete from "../../Component/Autocomplete/SearchAutocomplete";
import DateInput from "../../Component/DatePicker/DateInput";
import Button from "../../Component/FormComponent/Button";
import IconButton from "../../Component/FormComponent/IconButton";
import SelectComponent from "../../Component/Select/SelectComponent";
import { carsActions, commonActions } from "../../Stores/Actions";
import {
  timingOptions,
  timingOptions12HourFormat,
} from "../../Stores/Constants/siteConstants/dropdownConstants";
import { CarSearchValidation } from "../../Component/Validation/CarSearchValidation";
import { convertTime12To24, convertTime24To12 } from "../../Util/util";

const CarsWidget = ({ toggleFilter, resetFilters }) => {
  const dispatch = useDispatch();

  const originalPayload = JSON.parse(localStorage.getItem("carsPayload"));
  const showDropOffPoint = JSON.parse(localStorage.getItem("showDropOffPoint"));
  const originalPickUpLocation = JSON.parse(
    localStorage.getItem("pickUpLocation")
  );
  const originalDropOffLocation = JSON.parse(
    localStorage.getItem("dropOffLocation")
  );

  const [isMobile, setMobile] = useState(false);
  const [hideFilter, setHideFilter] = useState(true);

  const [dateInputFocused, setDateInputFocused] = useState({});
  const [isDateResponsive, setDateResponsive] = useState({
    pickUpDate: false,
    dropOffDate: false,
  });
  const [selectedLocations, setSelectedLocations] = useState({});

  const [isResponsive, setResponsive] = useState(false);
  const [showAutocomplete, setShowAutocomplete] = useState({
    pickUpLocation: false,
    dropOffLocation: false,
  });

  const currencyAndLanguage =
    localStorage.getItem("currencyAndLanguage") &&
    JSON.parse(localStorage.getItem("currencyAndLanguage"));

  const currency =
    currencyAndLanguage?.currencyLanguageReducer?.data?.currency || {};

  const currentGeoLocation =
    localStorage.getItem("currentGeoLocation") &&
    JSON.parse(localStorage.getItem("currentGeoLocation"));

  const isUS = currentGeoLocation === "US";

  const selectedTimingOptions = isUS
    ? timingOptions12HourFormat
    : timingOptions;

  // states to track timing options for pickup time options
  const [pickupTimeOptions, setPickupTimeOptions] = useState(
    isUS ? timingOptions12HourFormat : timingOptions
  );
  // states to track timing options for dropoff time options
  const [dropoffTimeOptions, setDropoffTimeOptions] = useState(
    isUS ? timingOptions12HourFormat : timingOptions
  );

  const showDateResponsive = (fieldName) => {
    setDateResponsive((preVal) => {
      return {
        ...preVal,
        [fieldName]: !preVal[fieldName],
      };
    });
  };

  const { searchLoading, autoCompleteData: locationSearchAutoCompleteData } =
    useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (window.innerWidth < 991) {
      setMobile(true);
    }
  }, []);

  const handleOnSearch = (query) => {
    const queryPayload = {
      format: "json",
      refid: process.env.REACT_APP_CARS_AUTO_DATA_REF_ID,
      api_key: process.env.REACT_APP_CARS_AUTO_DATA_API_KEY,
      string: query,
      numCities: 6,
      numAirports: 5,
      numPOIs: 3,
      numPartnerLocations: 4,
      max_results: 40,
    };
    dispatch(commonActions.carSearchAutoComplete(queryPayload));
    resetFilters();
  };

  const handleAutocompleteClick = (fieldName) => {
    setShowAutocomplete((preVal) => {
      return {
        ...preVal,
        [fieldName]: !preVal[fieldName],
      };
    });
  };

  const handleOnSelect = (value, fieldName, formikProps) => {
    // [0]?.name
    formikProps.setFieldValue(fieldName, value);
    setSelectedLocations((prevVal) => {
      return { ...prevVal, [fieldName]: value[0]?.id };
    });

    if (value[0]) {
      localStorage.setItem("CarsautoCompleteData", JSON.stringify(value[0]));
    }

    handleAutocompleteClick(fieldName);
  };

  const handleSelectChange = (value, fieldName, formikProps) => {
    formikProps.setFieldValue(fieldName, value);
  };

  const handleDateChange = (value, formikProps, field) => {
    formikProps.setFieldValue(field, moment(value)._d);

    setDateInputFocused((preVal) => {
      return { ...preVal, dropOffDate: true };
    });
  };

  const handleFocusChange = (field, value) => {
    setDateInputFocused((prevVal) => {
      return { ...prevVal, [field]: value };
    });
  };

  const showResponsive = (fieldName) => {
    setResponsive(true);
    handleAutocompleteClick(fieldName);
  };

  const initialValues = {
    pickUpLocation: originalPickUpLocation,
    dropOffLocation: originalDropOffLocation,
    pickUpDate: originalPayload?.pickup_date,
    dropOffDate: originalPayload?.dropoff_date,
    showDropOffPoint: showDropOffPoint ? showDropOffPoint : false,
    pickUpTime: {
      value: isUS
        ? convertTime24To12(originalPayload?.pickup_time)
        : originalPayload?.pickup_time,
      label: isUS
        ? convertTime24To12(originalPayload?.pickup_time)
        : originalPayload?.pickup_time,
    },
    dropOffTime: {
      value: isUS
        ? convertTime24To12(originalPayload?.dropoff_time)
        : originalPayload?.dropoff_time,
      label: isUS
        ? convertTime24To12(originalPayload?.dropoff_time)
        : originalPayload?.dropoff_time,
    },
    rentalCarCompany: "",
    carType: "",
    discountCouponCode: "",
    corporateID: "",
    couponCode: "",
    productCode: "",
  };

  const handleSubmit = (values) => {
    const dataPayload = {
      home: false,
      format: "json",
      currency: currency?.isoCode,
      native_currency_symbol: currency.symbol,
      refid: process.env.REACT_APP_CARS_AUTO_DATA_REF_ID,
      api_key: process.env.REACT_APP_CARS_AUTO_DATA_API_KEY,

      children_count: originalPayload?.children_count,

      pickup_time: isUS
        ? convertTime12To24(values.pickUpTime.value)
        : values.pickUpTime.value,
      dropoff_time: isUS
        ? convertTime12To24(values.dropOffTime.value)
        : values.pickUpTime.value,
      pickup_code:
        selectedLocations?.pickUpLocation === undefined
          ? originalPayload.pickup_code
          : selectedLocations?.pickUpLocation,
      dropoff_code:
        selectedLocations?.dropOffLocation === undefined
          ? originalPayload.dropoff_code
          : selectedLocations?.dropOffLocation,
      pickup_date: moment(values.pickUpDate).format("YYYY-MM-DD"),
      dropoff_date: moment(values.dropOffDate).format("YYYY-MM-DD"),
    };

    dispatch(carsActions.searchCarsAction(dataPayload));

    localStorage.setItem(
      "pickUpLocation",
      JSON.stringify(values.pickUpLocation)
    );

    localStorage.setItem(
      "dropOffLocation",
      JSON.stringify(values.dropOffLocation)
    );

    localStorage.setItem("carsPayload", JSON.stringify(dataPayload));
  };

  const handleDateClick = () => {
    setDateResponsive({ pickUpDate: false, dropOffDate: false });
  };

  const [flag1, setFlag1] = useState(true);
  const [flag2, setFlag2] = useState(true);

  const renderForm = (formikProps) => {
    const { values, touched, errors, handleBlur, handleSubmit } = formikProps;

    useEffect(() => {
      if (
        moment(values.pickUpDate).format("YYYY-MM-DD") ===
        moment(values.dropOffDate).format("YYYY-MM-DD")
      ) {
        let timeOptions = selectedTimingOptions.map((obj) => {
          if (
            moment(values.pickUpTime.value, "h:mma").isBefore(
              moment(obj.value, "h:mma")
            )
          ) {
            return obj;
          } else {
            let tempObj = { ...obj, isDisabled: true };
            return tempObj;
          }
        });
        setDropoffTimeOptions(timeOptions);
        if (flag1) {
          setFlag1(false);
        } else {
          let timeObj = timeOptions.find((obj) => {
            return moment(values.pickUpTime.value, "h:mma").isBefore(
              moment(obj.value, "h:mma")
            );
          });
          formikProps.setFieldValue("dropOffTime", timeObj);
        }
      } else {
        if (flag1) {
          setFlag1(false);
        } else {
          formikProps.setFieldValue("dropOffTime", values.pickUpTime);
        }
      }
    }, [values.pickUpTime]);

    useEffect(() => {
      if (
        moment(values.pickUpDate).format("YYYY-MM-DD") ===
        moment(values.dropOffDate).format("YYYY-MM-DD")
      ) {
        let timeOptions = selectedTimingOptions.map((obj) => {
          if (moment().isBefore(moment(obj.value, "h:mma"))) {
            return obj;
          } else {
            let tempObj = { ...obj, isDisabled: true };
            return tempObj;
          }
        });
        setPickupTimeOptions(timeOptions);
        setDropoffTimeOptions(timeOptions);
        if (flag2) {
          setFlag2(false);
        } else {
          let timeObj = timeOptions.find((obj) => {
            return moment().isBefore(moment(obj.value, "h:mma"));
          });
          formikProps.setFieldValue("pickUpTime", timeObj);
        }
      } else {
        setPickupTimeOptions(selectedTimingOptions);
        setDropoffTimeOptions(selectedTimingOptions);
      }
    }, [values.pickUpDate, values.dropOffDate]);
    return (
      <form
        className={toggleFilter ? " filterCnt hide-content " : "filterCnt "}
        onSubmit={handleSubmit}
      >
        {hideFilter && (
          <div className="mobileShow">
            <div
              className="mobileSearchBlock"
              onClick={() => setHideFilter((pre) => !pre)}
            >
              <span className="fa fa-search"></span>
              <div className="selectSearchCnt">
                <h4>
                  {originalPickUpLocation[0]?.secondary_label} (and vicinity)
                  <span className="arrow">&#8594;</span>{" "}
                  {originalPayload?.pickup_code ===
                  originalPayload?.dropoff_code
                    ? "Same as Pick-up"
                    : originalPayload?.dropoff_code}
                </h4>
                <p>
                  {originalPayload?.pickup_date}, {originalPayload?.pickup_time}{" "}
                  —{originalPayload?.dropoff_date},{" "}
                  {originalPayload?.dropoff_time}
                </p>
              </div>
              <i className="fa fa-angle-down"></i>
            </div>
          </div>
        )}

        <div
          className={`container dflex mobile-filter ${
            hideFilter ? "mobileHide" : ""
          }`}
        >
          <div className="locationStartEndWrap dflex">
            <div className="inputStyle showDropdown input-for-mobile">
              {!isMobile && (
                <SearchAutocomplete
                  {...formikProps}
                  options={locationSearchAutoCompleteData}
                  name="pickUpLocation"
                  onSearch={(query) => handleOnSearch(query)}
                  onChange={(selected) => {
                    handleOnSelect(selected, "pickUpLocation", formikProps);
                  }}
                  defaultSelected={[
                    JSON.parse(localStorage.getItem("CarsautoCompleteData")),
                  ]}
                  value={values.pickUpLocation}
                  onBlur={handleBlur}
                  onClick={() => handleAutocompleteClick("pickUpLocation")}
                  placeholder="Pick-up"
                  icon="icon-location-on"
                  isLoading={searchLoading}
                  errors={errors}
                  drop={true}
                />
              )}
 
              {isMobile && (
                <div className="responsive-input">
                  <button
                    className="inputStyle grow1"
                    onClick={() => showResponsive("pickUpLocation")}
                    type="button"
                  >
                    {" "}
                    <i className="icon-location-on" />{" "}
                    {values?.pickUpLocation
                      ? values?.pickUpLocation[0]?.secondary_label || JSON.parse(localStorage.getItem("CarsautoCompleteData"))?.secondary_label
                      : "Pick-up"}
                  </button>
                </div>
              )}  

              {isResponsive && showAutocomplete.pickUpLocation && (
                <div className="inputStyle showDropdown">
                  <div className="mobilePopup">
                    <Link to="#" className="closePopup">
                      <span
                        className="icon-close"
                        onClick={() =>
                          handleAutocompleteClick("pickUpLocation")
                        }
                      />
                    </Link>
                    <h4 className="inputPopTitle">
                      Where are you leaving form?
                    </h4>
                    <div className="dropdown-content">
                      <SearchAutocomplete
                        {...formikProps}
                        options={locationSearchAutoCompleteData}
                        name="pickUpLocation"
                        onSearch={(query) => handleOnSearch(query)}
                        onChange={(selected) => {
                          handleOnSelect(
                            selected,
                            "pickUpLocation",
                            formikProps
                          );
                        }}
                        onBlur={handleBlur}
                        value={values.pickUpLocation || "Pick-up"}
                        onClick={() =>
                          handleAutocompleteClick("pickUpLocation")
                        }
                        placeholder="Pick-up"
                        icon="icon-location-on"
                        isLoading={searchLoading}
                        errors={errors}
                        autoFocus={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {values.showDropOffPoint && (
              <div className="inputStyle drop-off">
                <div className="inputStyle showDropdown input-for-mobile">
                  {!isMobile && (
                    <SearchAutocomplete
                      {...formikProps}
                      options={locationSearchAutoCompleteData}
                      name="dropOffLocation"
                      onSearch={(query) => handleOnSearch(query)}
                      onChange={(selected) =>
                        handleOnSelect(selected, "dropOffLocation", formikProps)
                      }
                      value={values.dropOffLocation}
                      placeholder="Drop-off"
                      icon="icon-location-on"
                      isLoading={searchLoading}
                      drop={true}
                    />
                  )}

                 {isMobile && (
                    <div className="responsive-input">
                      <button
                        className="inputStyle grow1"
                        onClick={() => showResponsive("dropOffLocation")}
                        type="button"
                      >
                        {" "}
                        <i className="icon-location-on" />{" "}
                        {values?.dropOffLocation
                          ? values.dropOffLocation[0]?.secondary_label || JSON.parse(localStorage.getItem("CarsautoCompleteData"))?.secondary_label
                          : "Drop-off"}
                      </button>
                    </div>
                  )}  

                  {isResponsive && showAutocomplete.dropOffLocation && (
                    <div className="inputStyle showDropdown">
                      <div className="mobilePopup">
                        <Link to="#" className="closePopup">
                          <span
                            className="icon-close"
                            onClick={() =>
                              handleAutocompleteClick("dropOffLocation")
                            }
                          />
                        </Link>
                        <h4 className="inputPopTitle">Where are you going?</h4>
                        <div className="dropdown-content">
                          <SearchAutocomplete
                            {...formikProps}
                            options={locationSearchAutoCompleteData}
                            name="dropOffLocation"
                            onSearch={(query) => handleOnSearch(query)}
                            onChange={(selected) =>
                              handleOnSelect(
                                selected,
                                "dropOffLocation",
                                formikProps
                              )
                            }
                            onBlur={handleBlur}
                            value={values.dropOffLocation}
                            onClick={() =>
                              handleAutocompleteClick("dropOffLocation")
                            }
                            placeholder="Drop-off"
                            icon="icon-location-on"
                            isLoading={searchLoading}
                            errors={errors}
                            autoFocus={true}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="dateAndBtnWrap">
            <div className="dateTimeWrap dflex">
              <div className="filterDateTimeWrap dflex">
                <div className="inputStyle dateWrap">
                  {!isMobile && (
                    <DateInput
                      {...formikProps}
                      placeholder="Pick-up date"
                      value={
                        values.pickUpDate &&
                        moment(values.pickUpDate).format("MM/DD/YYYY")
                      }
                      icon="icon-invitation"
                      name="pickUpDate"
                      onDateChange={(event) =>
                        handleDateChange(event, formikProps, `pickUpDate`)
                      }
                      focused={dateInputFocused?.pickUpDate}
                      onFocusChange={({ focused }) =>
                        handleFocusChange("pickUpDate", focused)
                      }
                      errors={errors}
                      touched={touched}
                    />
                  )}

                  {isMobile && (
                    <div className="date-picker-type">
                      <div className="check-in-date date-picker-style">
                        <i className="icon-invitation" />
                        <Button
                          className="inputStyle grow1"
                          onClick={() => showDateResponsive("pickUpDate")}
                          type="button"
                          text={
                            values.pickUpDate &&
                            moment(values.pickUpDate).format("MM/DD/YYYY")
                          }
                        />
                      </div>
                    </div>
                  )}

                  {isMobile && isDateResponsive.pickUpDate && (
                    <div className="inputStyle showDropdown">
                      <div className="date-picker-popup ">
                        <Button className="closePopup">
                          <span
                            className="icon-close"
                            onClick={() => handleDateClick()}
                          />
                        </Button>

                        <h4 className="inputPopTitle">Pick-up date </h4>

                        <DateInput
                          {...formikProps}
                          isDateResponsive={isDateResponsive}
                          placeholder="Pick-up date"
                          value={
                            values.pickUpDate &&
                            moment(values.pickUpDate).format("MM/DD/YYYY")
                          }
                          icon="icon-invitation"
                          name="pickUpDate"
                          onDateChange={(event) =>
                            handleDateChange(event, formikProps, `pickUpDate`)
                          }
                          focused={dateInputFocused?.pickUpDate}
                          onFocusChange={({ focused }) =>
                            handleFocusChange("pickUpDate", focused)
                          }
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="timeWrap toggleClass">
                  <div className="selectBtn toggleBtn">
                    <span className="icon-timer"></span>
                    <SelectComponent
                      isComponents={false}
                      className=" toggleBtn"
                      name="pickUpTime"
                      placeholder="Pick-up time"
                      options={pickupTimeOptions}
                      onChange={(target) => {
                        handleSelectChange(target, "pickUpTime", formikProps);
                      }}
                      value={values.pickUpTime}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="dateTimeWrap dflex">
              <div className="filterDateTimeWrap dflex">
                <div className="inputStyle dateWrap">
                  {!isMobile && (
                    <DateInput
                      {...formikProps}
                      placeholder="Drop-off date"
                      value={
                        values.dropOffDate &&
                        moment(values.dropOffDate).format("MM/DD/YYYY")
                      }
                      icon="icon-invitation"
                      name="dropOffDate"
                      onDateChange={(event) =>
                        handleDateChange(event, formikProps, `dropOffDate`)
                      }
                      focused={dateInputFocused?.dropOffDate}
                      onFocusChange={({ focused }) =>
                        handleFocusChange("dropOffDate", focused)
                      }
                    />
                  )}

                  {isMobile && (
                    <div className="date-picker-type">
                      <div className="check-in-date date-picker-style">
                        <i className="icon-invitation" />
                        <Button
                          className="inputStyle grow1"
                          onClick={() => showDateResponsive("dropOffDate")}
                          type="button"
                          text={
                            values.dropOffDate &&
                            moment(values.dropOffDate).format("MM/DD/YYYY")
                          }
                        />
                      </div>
                    </div>
                  )}

                  {isMobile && isDateResponsive.dropOffDate && (
                    <div className="inputStyle showDropdown">
                      <div className="date-picker-popup ">
                        <IconButton
                          className="closePopup"
                          type="button"
                          icon="icon-close"
                          onClick={() => handleDateClick()}
                        />

                        <h4 className="inputPopTitle">Drop-off date </h4>

                        <DateInput
                          {...formikProps}
                          isDateResponsive={isDateResponsive}
                          numberOfMonths={1}
                          placeholder="Drop-off date"
                          value={
                            values.dropOffDate &&
                            moment(values.dropOffDate).format("MM/DD/YYYY")
                          }
                          icon="icon-invitation"
                          name="dropOffDate"
                          onDateChange={(event) =>
                            handleDateChange(event, formikProps, `dropOffDate`)
                          }
                          focused={dateInputFocused?.dropOffDate}
                          onFocusChange={({ focused }) =>
                            handleFocusChange("dropOffDate", focused)
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="timeWrap toggleClass">
                  <div className="selectBtn toggleBtn">
                    <span className="icon-timer"></span>
                    <SelectComponent
                      isComponents={false}
                      className=" toggleBtn"
                      name="dropOffTime"
                      placeholder="Drop-off time"
                      options={dropoffTimeOptions}
                      onChange={(target) => {
                        handleSelectChange(target, "dropOffTime", formikProps);
                      }}
                      value={values.dropOffTime}
                    />
                  </div>
                  <div className="dropdownInWrap">
                    <ul></ul>
                  </div>
                </div>
              </div>
            </div>
            {isMobile && (
              <div className="dateTimeWrap dflex">
                <div className="filterDateTimeWrap dflex">
                  <label className="customCheckbox cars-checkbox">
                    <input
                      type="checkbox"
                      name="showDropOffPoint"
                      checked={values.showDropOffPoint}
                      value={values.showDropOffPoint}
                      onChange={() => {
                        formikProps.setFieldValue(
                          "showDropOffPoint",
                          !values.showDropOffPoint
                        );
                        formikProps.setFieldValue("dropOffLocation", "");
                      }}
                    />
                    <span>Return car at a different location</span>
                  </label>
                </div>
              </div>
            )}
            <div className="mobileBtnWrap">
              <a
                href="#"
                className="mobileShow closeFilter"
                onClick={() => setHideFilter(true)}
              >
                Close <i className="fa fa-angle-up"></i>
              </a>
              <button className="btnBorder" type="submit">
                Search
              </button>
            </div>
          </div>
        </div>
        {/* checkbox container */}
        {!isMobile && (
          <div className="container">
            <label className="customCheckbox cars-checkbox">
              <input
                type="checkbox"
                name="showDropOffPoint"
                checked={values.showDropOffPoint}
                value={values.showDropOffPoint}
                onChange={() => {
                  formikProps.setFieldValue(
                    "showDropOffPoint",
                    !values.showDropOffPoint
                  );
                  formikProps.setFieldValue("dropOffLocation", "");
                }}
              />
              <span>Return car at a different location</span>
            </label>
          </div>
        )}
      </form>
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CarSearchValidation()}
      >
        {renderForm}
      </Formik>
    </>
  );
};

export default CarsWidget;